.letter {
    padding: 50px 60px 36px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--chat-default-bg);

    &__text {
        display: block;
        margin-bottom: 5px;
        word-wrap: break-word;
    }

    &__form {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__textarea {
        width: 100%;
        background: var(--chat-message-bg);
        border-radius: 5px;
        border: none;
    }

    &__textarea, &__message {
        flex: 1;
    }

    &__textarea {
        height: 100%;
        resize: none;
        color: #161616;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: 100%;
        overflow-y: auto;
        background: rgba(64, 64, 64, 0.90);
        backdrop-filter: blur(2.5px);
        z-index: 110;
    }

    .button_rounded {
        padding: 0 25px;
        min-width: 130px;
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        text-transform: none;
    }

    .button_round {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__footer {
        position: relative;
        margin-top: 20px;

        .chat__dropdown {
            left: 0;


            .dropdown__list {
                min-width: 150px;
                max-height: 200px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 2px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #6A6A6A;
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba(197, 197, 197, .2);
                }

                &::after, &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 40px;
                    z-index: 10;
                }

                &::before {
                    top: -2px;
                    background: linear-gradient(180deg, #FFF 34.29%, rgba(255, 255, 255, 0.00) 100%);
                }

                &::after {
                    background: linear-gradient(0deg, #FFF 34.29%, rgba(255, 255, 255, 0.00) 100%);
                    bottom: 0;
                }

                &:hover {
                    &::after, &::before {
                        content: none;
                    }
                }
            }

            .dropdown__item:not(:last-child) {
                .dropdown__link {
                    border-bottom: none;
                }
            }

            .dropdown__link {
                padding: 5px 0;
            }
        }

        .chat__caption {
            left: 0;
            bottom: 100%;
        }
    }

    &__additional {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }

    &__thumb {
        position: relative;

        .button {
            position: absolute;
            top: 2px;
            right: 2px;
            padding: 2px;
            z-index: 10;
        }

        .image {
            max-width: toRem(120px);
        }
    }

    &__image-container {
        position: relative;
        margin: auto auto 0;
        max-width: 512px;
        padding: 0 20px;
        text-align: center;

        .button_download {
            position: absolute;
            width: 26px;
            height: 26px;
            bottom: 20px;
            right: 30px;
            padding: 2px;
            line-height: 1;
            border-radius: 5px;
            background: rgba(7, 7, 7, 0.80);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            transition: opacity .3s ease;
            z-index: 10;

            &:hover {
                opacity: .85;
            }
        }
    }

    &__image {
        border-radius: 10px;
    }

    &_view {
        .letter__subject {
            margin-bottom: 30px;
            font-size: 25px;
            color: var(--letter-message-color)
        }

        .letter__message {
            font-size: 22px;
            color: var(--letter-subject-color)
        }

        .letter__footer {
            text-align: center;
        }
    }

    &_images {
        background: transparent;

        .letter__textarea {
            margin-bottom: 40px;
            border: 0;
            height: auto;
            background: transparent;
        }

        .letter__form, .letter__footer {
            position: relative;
            z-index: 20;
            background-color: transparent;
        }

        .letter__message {
            flex: 1;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &.letter_view {
            .letter__footer {
                text-align: right;
            }
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .letter {
        padding: 25px 30px 16px;

        &_view {
            .letter__subject {
                margin-bottom: 20px;
                font-size: toRemSm(18px);
            }

            .letter__message {
                font-size: toRemSm(16px);
            }
        }
    }
}
@include create-mq($grid__bp-4xl, "min") {
    .letter {
        .button_rounded:not(.button_sound), .button_send {
            min-width: 150px;
            height: 46px;
            line-height: 44px;
        }
    }
}
