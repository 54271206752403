.header {
    padding: toRem(12px) toRem(15px);

    &__container {
        display: flex;
        align-items: center;
    }
    .content {
        display: contents;
    }
    &:not(.header_mobile) {
        .header__container {
            justify-content: space-between;
        }
    }

    &_dark {
        background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.7) 0%,
                rgba(51, 41, 68, 0) 100%
        );

        .menu__link {
            &,
            &:hover {
                color: var(--color-white);
            }
        }

        .button_link {
            color: #fff;
        }

        .button_sign {
            display: none;
        }

        .button_menu {
            span,
            span::after,
            span::before {
                background-color: var(--color-white);
            }
        }
    }

    &_flex {
        display: flex;
        align-items: center;
    }

    &_shadow {
        box-shadow: 0 5px 20px rgba(197, 197, 197, 0.1);
    }

    &_light {
        background-color: var(--color-white);

        .logo {
            color: #332944;

            &__image {
                &.is-light {
                    display: none;
                }
            }
        }

        .button_link {
            color: var(--dark-gray);
            transition: color 0.25s ease;

            &:hover {
                color: var(--gray);
            }
        }

        .button_sign {
            display: inline-block;
        }
    }

    &_fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 110;
        ~ div > .page_static {
            padding-top: 120px;
        }
    }
    &_mobile {
        min-height: 60px;
        padding: 8px 15px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.06);
        backdrop-filter: blur(20px);
    }
}

@include create-mq($grid__bp-xl - 1, "max") {
    .header {
        .button_link {
            .button__text {
                display: none;
            }
        }
    }
}

@include create-mq($grid__bp-lg - 1, "max") {
    .header {
        &__actions {
            margin: 0 0 0 auto;
            display: flex;
            align-items: center;
        }
    }
}

@include create-mq($grid__bp-md, 'min') {
    .header {
        &_dark {
            .logo {
                &__image {
                    background-image: url(../images/logo/logo-white.svg);
                }
            }
        }
    }
}

@include create-mq($grid__bp-lg, "min") {
    .header:not(.header_mobile) {
        padding: 20px 80px;

        .content {
            max-width: 1920px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }

        .button_menu {
            display: none;
        }

        .navbar {
            margin: 0 auto;
        }
    }
}
@include create-mq($grid__bp-xl, "min") {
    .header {
        &_fixed {
            ~ div > .page_static {
                padding-top: 160px;
            }
        }
    }
}

@include create-mq($grid__bp-2xl, "min") {
    .header {
        padding: 20px 50px 18px;
    }
}
