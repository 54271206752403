.contact {
    position: relative;
    display: flex;
    align-items: center;

    &__info {
        position: relative;
        @extend .text-truncate;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 0;
        align-items: flex-start;

    }

    &__avatar {
        position: relative;
        margin-right: 5px;

        .status {
            position: absolute;
            bottom: -2px;
            left: 2px;
            z-index: 10;
        }
    }

    &__title {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

    &__name {
        max-width: 98px;
        font-weight: 700;
    }

    &__age {
        position: relative;

        &::before {
            content: ",";
            margin-right: 5px;
        }
    }

    &__name,
    &__message {
        @extend .text-truncate;
        white-space: nowrap;
    }

    &__message {
        max-width: 100%;
        font-weight: 600;
        font-size: toRem(14px);
        color: var(--heading-color);
    }

    .status__icon {
        border: 2px solid var(--color-white)
    }

    .tooltip, .date {
        position: absolute;

    }

    .tooltip {
        top: 0;
        right: 4px;
    }

    .date {
        top: 5px;
        right: 68px;
        font-size: toRem(12px);
        text-align: right;

        &_old {
            color: #C5C5C5
        }
    }
}


@media (min-width: 1280px) and (max-width: 1919px) {
    .contact__avatar {
        .avatar {
            width: 40px;
            height: 40px;
        }

        .tooltip {
            top: 5px
        }
    }
}
