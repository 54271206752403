.person {
    &__name {
        font-weight: 700;
        line-height: 1;
        @extend .text-truncate;
    }

    &__age {
        &_inline {
            &::before {
                content: ",";
                margin-right: 5px;
            }
        }
    }

    &__avatar {
        flex: 1;
        background: var(--body-bg);
        padding: 5px;
    }

    &__date {
        .icon {
            position: relative;
            top: -2px;
            margin-right: 8px;
        }
    }

    &__icon {
        display: block;
        background: none;
    }
    &__location {
        display: block;
        .icon {
            margin-right: 5px;
            width: 12px;
        }
    }

    &_card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        .person__info {
            position: relative;
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .person__location {
                margin-top: 5px;
                max-width: 100%;
                flex-basis: 100%;
                font-size: toRemSm(12px);
            }
        }

        .person__icon {
            margin-left: 5px;
        }

        .person__name,
        .person__age {
            font-size: toRemSm(14px);
        }

        .person__name {
            white-space: nowrap;
            max-width: 130px;
        }

        .person__age {
            color: var(--color-white);
        }
    }
}

@include create-mq($grid__bp-4xl, "min") {
    .person {
        .person__name,
        .person__age {
            font-size: toRem(20px);
        }

        .person__info {
            .person__location {
                font-size: toRemSm(14px);
            }
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px)  {

    .person_card {
        .icon_zodiac {
            width: 18px;
            height: 18px;
        }

        .person__name, .person__age {
            font-size: toRemSm(14px);
        }

        .person_inline .person__info {
            font-size: toRemSm(20px);
        }

        .person__info {
            .person__location {
                font-size: toRemSm(12px);
            }
        }

    }
}
