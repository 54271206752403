.statistic {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding-top: toRem(70px);
    padding-bottom: toRem(55px);
    background-image: url(../images/about/bg-section2_mob.png);
    background-repeat: no-repeat;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../images/about/bg2_mob.svg);
        background-repeat: no-repeat;
        background-position: center;
    }

    &__item {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__number,
    &__title {
        display: block;
    }

    &__number {
        display: block;
        font-size: toRem(58px);
        font-weight: 700;
        letter-spacing: -0.03em;
        color: var(--color-white);
    }

    &__title {
        font-size: toRem(24px);
        font-weight: 500;
        line-height: 1.1;
        color: rgba(255, 255, 255, 0.7);
    }

    &__list {
        @extend .grid;
        @extend .grid_4;
    }

    @include create-mq($grid__bp-md, 'min') {
        background-image: url(../images/about/bg-section2.png);
        &::after {
            background-image: url(../images/about/bg2.svg);
        }
    }
    @include create-mq($grid__bp-xl, 'min') {
        padding-bottom: toRem(90px);
    }
}
