.modal {
    display: none;

    &__heading {
        color: var(--button-color2);
        text-align: center;
    }
    &__title {
        font-weight: 500;
        font-size: toRem(32px);
        margin: 0;
    }

    &__link {
        @extend .text-gradient;
    }

    &__bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        background: rgba(19, 19, 19, 0.75);
        opacity: 0;
        pointer-events: none;
        z-index: 1000;
        overflow-y: auto;
        overflow-x: hidden;
    }


    &__container {
        transform: scale(0);
        will-change: transform;
        transition: 0.56s transform ease;
        background: var(--body-bg);
        color: var(--color-text);
        overflow-y: auto;
        z-index: 1020;
        @include create-mq($grid__bp-lg, 'min') {
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
        }
    }

    &__close {
        @extend .link;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 10;
    }

    &.is-active {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: calc(var(--vh, 1vh) * 100);
        z-index: 1010;
        display: flex;
        flex-direction: column;

        .modal__container {
            transform: scale(1);
        }

        .modal__bg {
            opacity: 1;
            pointer-events: auto;
        }

        @include create-mq($grid__bp-md, 'min') {
            &.modal_sidebar {
                .modal__container {
                    transform: translateX(0);
                }
            }
        }
    }


    &_lg, &_sm, &_md {
        @include create-mq($grid__bp-md - 1, 'max') {
            &:not(.modal_sidebar) {

                .modal__container {
                    width: 100vw;
                    height: calc(var(--vh, 1vh) * 100);
                    border-radius: 0;
                }

                .modal__container_contactus {
                    width: auto;
                    height: auto;
                    margin: auto 20px;
                    border-radius: 10px;
                    background-image: url("../images/modal/hablario.svg");
                    background-repeat: no-repeat;
                    background-position: left -140px top -86px;
                    top: 0;
                    left: 0;
                    box-shadow: 0 4px 32px 0 rgba(19, 19, 19, 0.12);
                }
            }
            &.modal_account, &.modal_tariffs {
                .modal__container {
                    padding: 40px 25px 60px;
                }
            }
        }
        @include create-mq($grid__bp-md, 'min') {
            &:not(.modal_sidebar) {
                .modal__container {
                    margin: auto;
                    border-radius: 10px
                }

                .modal__container_contactus {
                    padding: 65px 125px;
                    background-image: url("../images/modal/hablario.svg");
                    background-repeat: no-repeat;
                    background-position: left -140px top -86px;
                    top: 0;
                    left: 0;
                    box-shadow: 0 4px 32px 0 rgba(19, 19, 19, 0.12);
                }
            }
        }
    }

    &_sm, &_md {
        .form__footer {
            margin-top: 20px;
        }
    }

    &_md {
        @include create-mq($grid__bp-md, 'min') {
            .modal__container {
                padding: 45px 20px;
                width: 700px;
            }
        }
        @include create-mq($grid__bp-4xl, 'min') {
            .modal__container {
                padding: 95px 60px;
            }
        }
    }

    &_notify {
        display: block;

        &.is-active {
            top: 60px;
            height: auto;

            .modal__container {
                transform: translateY(0);
            }
        }

        .modal__container {
            padding: 15px 20px;
            box-shadow: none;
            backdrop-filter: blur(10px);
            background: rgba(0,0,0, .3);
            transform: translateY(-100%);
            transition: transform .85s cubic-bezier(0.19, 1, 0.22, 1);
        }

        .modal__body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.25rem;
            font-weight: 500;
            color: var(--color-white);

            .button_collapse {
                width: auto;
                height: auto;
                padding: 7px 25px;
                margin: 0 0 0 20px;
                color: var(--color-white);
                border-color: var(--color-white);
                &:hover {
                    opacity: .85;
                }
            }
        }

        .modal__message:first-letter {
            text-transform: uppercase;
        }

        @include create-mq($grid__bp-xl, 'min') {
            &.is-active {
                top: 72px
            }

            .modal__body {
                justify-content: center;
            }
        }
        @include create-mq($grid__bp-xl - 1, 'max') {
            .is-desktop {
                display: none;
            }
        }
    }

    &_sm {
        .modal__container {
            width: auto;
            padding: 45px 20px 20px;
        }

        @include create-mq($grid__bp-sm, 'min') {
            .modal__container {
                padding: 45px 50px;
            }
            .form__footer {
                margin-top: 40px;
            }
        }
    }

    &_lg {
        @include create-mq($grid__bp-md, 'min') {
            .modal__container {
                width: 80vw;
                height: auto;
            }
        }
    }

    &_account {
        .modal__title {
            margin-bottom: 20px;
            @extend .text-gradient;
            font-size: toRem(32px);
            font-weight: 400;
        }
        .modal__heading {
            font-size: toRem(20px);
            text-align: center;
        }
        .ui-textarea {
            resize: none;
            min-height: 240px;
        }
        @include create-mq($grid__bp-md, 'min') {
            .form__footer {
                text-align: right;
                .button {
                    width: auto;
                }
            }
        }
    }
    &_interests {
        .modal__container {
            padding-top: 25px;
            padding-bottom: 30px;
        }
        .modal__body {
            text-align: center;
        }
        .modal__heading {
            margin-bottom: 30px;
        }
        .list_hobbies {
            grid-template-columns: repeat(4, 1fr);
            justify-items: center
        }
        .modal__footer {
            margin-top: 40px;
            text-align: center;
        }
        .list_hobbies {
            width: 90%;
            margin: auto;
        }
        @include create-mq($grid__bp-md, 'min') {
            .list_hobbies {
                width: 50%;
            }
            .modal__footer {
                margin-top: toRem(40px);
                text-align: center;
            }
        }
        @include create-mq($grid__bp-xl, 'min') {
            .modal__container {
                width: 748px;
            }
            .list_hobbies {
                width: 60%;
            }
        }
    }

    &_about, &_search {
        .form {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 30px;
            &__footer {
                grid-column: 1/3;
            }
        }
        @include create-mq($grid__bp-md, 'min') {
            .modal__container {
                width: auto;
            }

        }
        @include create-mq($grid__bp-xl, 'min') {
            .form {
                grid-template-columns: repeat(3, 270px);
                &__footer {
                    grid-column: 1/4;
                    .button {
                        width: 270px;
                    }
                }
            }
        }
    }

    &_install {
        .modal__container {
            padding: 40px 20px 60px;
        }
        .is-desktop {
            display: none;
        }

        @include create-mq($grid__bp-lg, 'min') {
            .is-mobile {
                display: none;
            }
            .is-desktop {
                display: block;
            }
        }
    }

    &_sidebar {
        .modal__container {
            display: flex;
            padding: 10% 5% 20%;
            flex-direction: column;
            height: calc(var(--vh, 1vh) * 100);

            .form {
                margin: auto;
                .button + .button {
                    margin-top: 10px;
                    .button__text {
                        color: var(--color-text);
                    }
                }
            }
        }

        @include create-mq($grid__bp-lg, 'min') {
            display: block;
            .modal__container {
                position: fixed;
                right: 0;
                padding: 2%;
                width: 55vw;
                border-radius: 10px 0 0 10px;
                transform: translateX(100%);
                background-image: url(../images/modal/hablario.svg);
                background-repeat: no-repeat;
                background-position: 0 0;

                .form {
                    width: 370px;
                }
            }
        }
        @include create-mq($grid__bp-xl, 'min') {
            .modal__container {
                width: 39vw;
            }
        }
    }
    &_support-chat {
        .modal__container {
            padding: 0;
            background: none;
            border-radius: 10px;
        }
    }
    &_tariffs {
        .modal__container {
            padding-bottom: 30px;
        }
        .modal__header {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .modal__title {
            margin-bottom: 0;
            @extend .text-gradient;
            font-size: toRem(32px);
        }
        .modal__label {
            font-size: toRem(20px);
            font-weight: 700;
        }
        .form {
            &__footer {
                padding-top: 15vh;
                font-size: toRem(14px);
                text-align: center;
                .icon {
                    margin-right: 10px;
                }
            }
            &__text {
                display: block;
                margin-bottom: 14px;
            }
            &__link {
                @extend .text-gradient;
                @extend .link;
                margin-right: 10px;
            }

            &__button {
                margin-top: 30px;
                text-align: center;

                .button_presentation {

                    > span {
                        padding: 0 55px 0  35px;
                    }

                    .button__icon {
                        background: none;
                        right: -4px;
                        top: -7px;
                    }
                }
            }
        }
    }

    &_payment {
        .modal__container {
            padding: 42px 25px 25px;
        }
        .modal__title {
            margin-bottom: 10px;
            font-size: toRem(30px);
            text-align: center;
        }
        .modal__body, .modal__header {
            position: relative;
            &::after{
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: var(--gradient);
            }
        }
        .modal__body {
            padding: 20px 0;
            &::after {
                bottom: 0;
            }
        }
        .modal__footer {
            padding: 20px 0;
            text-align: center;
            font-size: toRem(14px);
            .list_payment {
                margin-top: 10px;
            }
        }
        .form {
            &__section {
                margin-top: 5px;
                display: flex;
                .button_payment {
                    margin-right: 10px;
                }
            }
        }
    }
    &_payment-table {
        .modal__header {
            padding-bottom: 20px;
            .heading {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: toRem(30px);
                white-space: nowrap;
                .icon {
                    margin-left: 5px;
                }
            }
        }
    }
    &_gifts {
        .modal__container {
            background-image: url("../images/icons/gifts/bg.png");
            display: flex;
            flex-direction: column;
            align-items: center;
            background-repeat: no-repeat;
        }

        .modal__header {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            padding: 50px 46px 0;
            gap: 11px;
            align-self: flex-start;
            white-space: nowrap;
        }
        .modal__title {
            font-weight: 400;
        }
    }

    &_new-story {
        .modal__container {
            padding: 30px 40px 28px;
        }
        .form {
            &__label {
                font-weight: 500;
            }
            &__col {
                margin-bottom: 10px;

                @include create-mq($grid__bp-xl, 'min') {
                    width: 310px;
                    margin-right: 34px;
                    margin-bottom: 0;
                }
            }
            &__footer {
                padding-top: 5vh;
                text-align: center;
            }
            .ui-textarea {
                min-height: 70px;
            }
            .is-inline-flex {
                align-items: flex-end;

                @include create-mq($grid__bp-xl - 1, 'max') {
                    display: block;
                }
            }
        }
    }
    &_complain {
        .modal__container {
            padding: 30px 38px;
        }
        .modal__title {
            font-size: 1rem;
            margin-bottom: 10px;
            text-align: center;
        }
        .form {
            .ui-textarea {
                min-height: 200px;
            }
            &__footer {
                .button {
                    width: 100%;
                }
            }
        }
    }
    &_block-user {
        .modal__container {
            padding: 30px 20px;
        }
        .modal__header {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: var(--gradient);
            }
        }
        .modal__title {
            font-size: toRem(28px);
            margin-bottom: 10px;
            text-align: center;
            color: var(--color-red);
            .icon {
                width: 32px;
                height: 32px;
            }
        }
        .modal__body {
            font-size: toRem(20px);
            text-align: center;
        }
        .modal__footer {
            margin-top: 10vh;
            display: flex;
            justify-content: center;
            .button + .button {
                margin-left: 20px;
            }
        }
    }
    &_search {
        .modal__header {
            display: flex;
            align-items: center;
        }
        .button_link {
            margin-left: auto;
            color: var(--color-text);
            .button__icon {
                margin-left: 8px;
            }
        }
        .button__link-mobile {
            display: none;
        }
        .form {
            &__col {
                display: flex;
                margin-bottom: 5px;
                .ui-input +.ui-input {
                    margin-left: 20px;
                }
            }
            &__separator {
                margin-bottom: 20px;
                border-bottom: 1px solid var(--light-gray);
                .form__label {
                    display: block;
                    margin-bottom: 2px;
                    color: #C4C4C4;
                }
            }
            .ui-input[type="number"] {
                appearance: none;
                pointer-events: none;
                &:disabled {
                    color: var(--color-text);
                }
                &::-webkit-inner-spin-button {
                    appearance: none;
                    margin: 0;
                }
            }
        }
    }
    &_services {
        .modal__container {
            padding: 40px;
        }
        .modal__title {
            margin: 0 0 20px;
            font-size: toRem(40px);
            text-align: center;
        }
        .modal__subtitle {
            margin: 0 0 20px;
            font-size: toRem(26px);
        }
        .modal__footer {
            text-align: center;
        }
        .link {
            color: var(--color-text);
            text-decoration: underline;
        }
        .centered {
            display: block;
            margin: toRem(30px) auto;
            text-align: center;
        }
        .is-mobile {
            display: block;
        }
        .is-desktop {
            display: none;
        }
        p {
            margin-bottom: 10px;
        }
    }
    &_cropper {
      /*  margin: 10px auto !important;*/
        .modal__close {
            top: 0;
            right: 0;
        }
        .modal__container {
            /*width: 300px;*/
            margin: auto;
            padding: 20px;
        }
    }
    &_promo {
        padding: 20px;
        overflow-y: auto;
        .modal__container {
            max-width: 80vw;
            width: 440px;
            margin: auto;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border-radius: 5px;
            box-shadow: none;
            background-color: transparent;
        }
        .modal__close {
            top: 10px;
            right: 10px;
            width: 28px;
            height: 28px;
            line-height: 20px;
            padding: 5px;
            border-radius: 5px;
            font-size: 1rem;
            background-color: #3a3a3a;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
            text-align: center;
        }
    }
}
@include create-mq($grid__bp-md - 1, 'max') {
    .modal {
        &_tariffs, &_payment {
            .modal__container {
                display: flex;
                flex-direction: column;
            }
        }
        &_tariffs {
            .modal__container {
                padding-top: 50px;
                padding-bottom: 50px;
            }
            .form {
                display: flex;
                flex-direction: column;
                flex: 1;
                &__footer {
                    margin-top: auto;
                }
            }
            .modal__header {
                margin-bottom: 50px;
            }
        }
        &_payment {
            .modal__body {
                flex: 1
            }
            .modal__header {
                .heading {
                    font-size: toRem(30px);
                }
            }
        }
    }
}
@include create-mq($grid__bp-xl - 1, "max") {
    .modal {
        &_search {
            .button_link {
                display: none;
            }
            .button_link-mobile {
                display: block;
                margin-bottom: 10px;
            }
            .form {
                &_primary {
                   .form__row {
                       &:nth-child(3) {
                           grid-area: 1/2;
                       }
                       &:nth-child(2) {
                           grid-column: 1/3;
                       }
                   }
                }
                &_secondary {
                    .form__row {
                        &:nth-child(5), &:nth-child(6) {
                            grid-column: 1/3;
                        }
                    }
                }
            }
        }
        &_about {
            .form {
                &__row:nth-last-child(2) {
                    grid-column: 1/3;
                }
            }
        }
        &_payment {
            .modal__footer {
                .text {
                    display: block;
                }
            }
        }
        &_gifts {
            .modal__header{
                padding-left: calc(50% - 345px);
                gap: 55px;
            }
            //.modal__body {
            //    overflow-y: hidden;
            //}
            &.modal_lg {
                .modal__container {
                    width: 100vw;
                    height: calc(var(--vh, 1vh) * 100);
                    border-radius: 0;
                }
            }

        }
    }
}
@include create-mq($grid__bp-lg, "min") {
    .modal {
        &_promo {
            .modal__container {
                width: 340px;
            }
        }
    }
}
@include create-mq($grid__bp-xl, "min") {
    .modal {
        &_support-chat {
            .modal__container {
                min-height: 90vh;
                margin: auto;
            }
            .chat, .chat__container {
                height: 100%;
            }
        }
        &_payment {
            .modal__container {
                width: 860px;
                padding: 62px 60px 50px;
            }
            .modal__body {
                padding: 46px 80px 54px;
            }
            .modal__footer {
                display: flex;
                padding-top: 25px;
                padding-bottom: 0;
                .list_payment {
                    margin-left: auto;
                    margin-top: 0;
                }
            }
        }
        &_payment-table {
            .modal__body {
                padding: 46px 0 54px;
            }
            .modal__footer {
                display: block;
            }
        }
        &_new-story {
            .form__footer {
                .button {
                    min-width: 270px;
                }
            }
        }
        &_search {
            .modal__container {
                width: auto;
                padding: 80px 60px;
                background-image: url("../images/modal/hablario.svg");
                background-repeat: no-repeat;
            }
        }
        &_complain {
            .modal__container {
                width: 430px;
            }
        }
        &_services {
            .is-mobile {
                display: none;
            }
            .is-desktop {
                display: block;
            }
        }
        &_cropper {
            .modal__container {
                /*width: 600px;*/
            }
        }
        &_promo {
            .modal__container {
                max-width: none;
                width: 1137px;
                height: 973px;
                padding-bottom: 0;
            }
            .modal__background {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
        &_gifts {
            .modal__container{
                width: 1170px;
                max-height: 90vh;
                overflow-x: hidden;
            }
            //.modal__body {
            //    overflow-y: auto;
            //}
        }
    }
}
@include create-mq($grid__bp-3xl, "min") {
    .modal {
        &_search {
            .modal__container {
                padding: 120px 150px 200px;
            }
        }
        &_services {
            .modal__container {
                padding: 80px 100px;
            }
            .modal__title {
                font-size: toRem(60px);
                margin: 0 0 50px;
            }
            .modal__subtitle {
                font-size: toRem(32px);
                margin-bottom: 32px;
            }
        }
        &_promo {
            .modal__container {
                width: 1140px;
                height: 980px;
            }
        }
    }
}
@include create-mq($grid__bp-4xl, "min") {
    .modal {
        &_support-chat {
            .modal__container {
                max-width: 830px;
                min-height: 74vh;
            }
        }
        &_new-story {
            .modal__container {
                max-width: 1090px;
            }
        }
    }
}
@include create-mq($grid__bp-sm, 'max') {
    .modal {
        &_gifts {
            .modal__header {
                padding-left: calc(50% - 170px);
            }
        }
    }
}
@media (min-width: 577px) and (max-width: 968px) {
    .modal {
        &_gifts {
            .modal__header {
                padding-left: calc(50% - 227px);
            }
        }
    }
}
@media (min-width: 1280px) and (max-width: 1920px){
    .modal {
        &_gifts {
            .modal__header{
                padding-left: calc(50% - 345px);
                gap: 55px;
            }
        }

        &_tariffs {
             .form__button {

                 .button_presentation {

                     > span {
                         padding: 0 35px 0  25px;
                     }

                     .icon_buy-credits {
                         width: 45px;
                         height: 45px;
                     }
                 }
             }
        }
    }
}
