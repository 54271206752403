.landing_welcome {
    position: relative;
    background: #fff;
    min-height: calc(var(--vh, 1vh)* 100);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;

        .container {
            display: flex;
            align-items: center;
        }
    }

    .section_intro {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height:calc(var(--vh, 1vh)* 100);
        z-index: 10;
    }

    .video_responsive {
        visibility: visible;
    }

    .footer {
        position: relative;
        z-index: 10;
        background: var(--body-bg);
        margin-top: calc(var(--vh, 1vh)* 100);

        &__section_menu {
            max-width: 1920px;
            justify-content: center;
            margin: 0 auto;
        }

        .page__older-men {
            .section_service {
                .container {
                    .section__content p {
                        font-size: 20px;
                    }
                }
            }
        }

        &__content {
            margin: auto 0 0;
            padding: 0 20px 20vh;

            .heading, .subheading {
                display: block;
                letter-spacing: .04em;
                line-height: 1;
                text-transform: none;
                text-align: left;
            }

            .is-block {
                white-space: nowrap;
            }

            .text {
                display: block;
                white-space: nowrap;
            }

            &_asia {

                .heading {
                    position: relative;
                    padding-left: 20px;
                    font-weight: 600;
                    font-size: 2.5rem;
                    margin-bottom: 1.25rem;
                    text-transform: uppercase;
                    color: #fff;

                    .divider {
                        position: absolute;
                        display: block;
                        top: 5%;
                        left: 0;
                        height: 90%;
                        width: 10px;
                        background: #8a43ff;
                    }
                }

                .subheading {
                    font-family: Work Sans,sans-serif;
                    font-size: 1.8rem;
                    text-transform: uppercase;
                    color: #8a43ff;
                }
            }

            &_black {

                .heading {
                    font-weight: 600;
                    font-size: 3.5rem;
                    margin-bottom: 1.25rem;
                    color: #fff;
                }

                .subheading {
                    padding: 10px;
                    background: #ce2074;
                    font-size: 1.8rem;
                    font-family: Work Sans,sans-serif;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: #000;
                }
            }

                &_older-men {
                    margin: calc(var(--vh, 1vh)* 70) auto;
                    padding: 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Roboto,sans-serif;
                        font-variant: none;
                    }

                    .subheading {
                        color: #FFF;
                        text-align: center;
                        font-variant: none;
                    }
                }

            &_mature {

                .heading {
                    font-family: Abril Fatface,cursive;
                    font-size: 3.5rem;
                    margin-bottom: 1.25rem;
                    color: #fff;
                }

                .subheading {
                    font-family: Aguafina Script,cursive;
                    font-size: 1.8rem;
                    font-weight: 500;
                    color: #ff285c;
                }
            }
        }

        &__body {
            position: absolute;
            top: calc(var(--vh, 1vh)* -100);
            left: 0;
            width: 100%;
            height: calc(var(--vh, 1vh)* 100);
            display: flex;
            z-index: 10;
            justify-content: center;
        }

        &__caption {
            position: absolute;
            transform: translate(-50%, -50%);
            top: calc(var(--vh, 1vh)* -10);
            left: 50%;
            //width: 100%;
            z-index: 100;

            .text {
                display: none;
                margin: 0;
                color: #fff;
                font-size: 1.5rem;
                line-height: 1.4;
            }
            .text-older {
                display: none;
            }
            .text-ret5 {
                display: block;
            }
            .button {
                width: 280px;
            }
        }

        &__caption_older-men50 {

            .button {
               display: none;
            }
        }

        .container {
            position: relative;
            max-width: 100%;
            padding: 0;
        }

    }
}
//279
@media only screen and (min-width: 279px) {
    .landing_welcome {

        .footer {

            &__content {

                .button_primary {
                    height: 48px;
                    padding: 13px 20px;
                    font-size: 1.25rem;
                }

                &_older-men {
                    //margin: calc(var(--vh, 1vh) * 50) auto 0;
                    //padding: 0;
                    margin: auto auto 5% auto;
                    padding:0 0 0 0;

                    .heading {
                        color: #FFF;

                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Roboto,sans-serif;
                        font-size: toRem(30px);
                        font-weight: 162;
                        line-height: 1;
                        //font-variant: all-small-caps;
                        text-transform: uppercase;

                        .first {
                            display: block;
                        }

                        .accent {
                            color: #FFF;
                            text-shadow: 0 1.5px 2px #000;
                            font-family: inherit;
                            font-size: toRem(30px);
                            font-weight: 300;
                            line-height: 1.35;
                            text-transform: lowercase;
                            display: block;
                        }

                    }

                    .subheading {
                        font-family: Oswald, sans-serif;
                        font-size: toRem(40px);
                        font-weight: 600;
                        line-height: 1.35;
                        text-transform: uppercase;
                    }
                    .button_primary {
                        width: 250px;
                        margin-top: 64px;
                    }
                }

                &_older-men50 {
                    //margin: calc(var(--vh, 1vh)* 50) auto 0;
                    //padding: 0;
                    margin: auto auto 5% auto;
                    padding:0 0 0 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(30px);
                        font-weight: 300;
                        line-height: 1;
                        font-variant: none;
                        text-transform: uppercase;
                        white-space: nowrap;

                        .first {
                            display: block;
                        }
                    }

                    .accent {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(30px);
                        font-weight: 300;
                        line-height: 1;
                        text-transform: lowercase;
                        display: block;
                        padding-right: 20px;
                    }

                    .subheading {
                        color: #FFCD29;
                        font-size: toRem(50px);
                        font-family: Inter, sans-serif;
                        font-weight: 700;
                        text-transform: capitalize;
                        display: block;
                        text-align: center;
                    }

                    .slogan {
                        display: none;
                    }

                    .button_primary {
                        width: 250px;
                        margin-top: 64px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                     .icon_aws{
                       display: none;
                    }
                }

                &_ret5 {
                    margin: auto auto 5% auto;
                    padding:0 0 0 0;

                    .before {
                        color: #312F2F;
                        text-align: left;
                        font-family: Oswald,sans-serif;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding-left: 30px;
                    }
                    .heading {
                        color: #FFF;
                        text-align: center;
                        -webkit-text-stroke-width: 2px;
                        -webkit-text-stroke-color: #4326F4;
                        font-family: Roboto,sans-serif;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 1.2px;
                        text-transform: uppercase;
                    }

                    .accent {
                        color: #2B2A2A;
                        text-align: center;
                        font-family: Roboto,sans-serif;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.1;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }

                    .slogan {
                        display: block;
                    }
                    .button_primary{
                        width: 100%;
                        margin-top: 60px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        padding: 13px 50px;
                        height: 60px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
                    }
                    .text {
                     display: none;
                    }
                    .icon_aws{
                        display: none;
                    }
                }

            }
        }
    }
}
//428
@include create-mq($grid__bp-xs - 1, "min") {
    .landing_welcome {

        .footer {

            &__content {

                &_older-men {
                    //margin: calc(var(--vh, 1vh) * 50) auto 0;
                    //padding: 0;

                    .heading {
                        text-shadow: 0 2px 2px #000;
                        font-size: toRem(50px);
                        font-weight: 162;
                        line-height: 1;
                        text-transform: uppercase;

                        .first {
                            display: block;
                        }

                        .accent {
                            color: #FFF;
                            text-shadow: 0 1.5px 2px #000;
                            font-family: inherit;
                            font-size: toRem(40px);
                            font-weight: 300;
                            line-height: 1;
                            text-transform: lowercase;
                            display: block;
                        }
                    }

                    .subheading {
                        font-family: Oswald, sans-serif;
                        font-size: toRem(80px);
                        font-weight: 600;
                        line-height: 1;
                        text-transform: uppercase;
                    }
                }
                &_older-men50 {
                    //margin: calc(var(--vh, 1vh)* 50) auto 0;
                    //padding: 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(50px);
                        font-weight: 300;
                        line-height: 1;
                        font-variant: none;
                        text-transform: uppercase;
                        white-space: nowrap;

                        .first {
                            display: block;
                        }
                    }

                    .accent {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(35px);
                        font-weight: 300;
                        line-height: 1;
                        text-transform: lowercase;
                        display: block;
                        padding-right: 0;
                    }

                    .subheading {
                        color: #FFCD29;
                        font-size: toRem(60px);
                        font-family: Inter,sans-serif;
                        font-weight: 700;
                        text-transform: capitalize;
                        display: block;
                    }
                    .slogan {
                   display: none;
                    }
                    .button_primary{
                        width: 330px;
                        margin-top: 64px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &_ret5 {
                    .before {
                        color: #312F2F;
                        text-align: left;
                        font-family: Oswald,sans-serif;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding-left: 30px;
                    }
                    .heading {
                        color: #FFF;
                        text-align: center;
                        -webkit-text-stroke-width: 2px;
                        -webkit-text-stroke-color: #4326F4;
                        font-family: Roboto,sans-serif;
                        font-size: 70px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 1.4px;
                        text-transform: uppercase;
                    }

                    .accent {
                        color: #2B2A2A;
                        text-align: center;
                        font-family: Roboto,sans-serif;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                    }
                    .slogan {
                        display: block;
                    }
                    .button_primary{
                        width: 390px;
                        margin-top: 60px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        //padding: 13px 50px;
                        border-radius: 5px;
                        background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
                    }
                }

            }
        }
    }
}
//576
@include create-mq($grid__bp-sm - 1, "min") {
    .landing_welcome {

        .footer {

            &__content {

                .button_primary {
                    height: 48px;
                    padding: 13px 20px;
                    font-size: 1.25rem;
                }

                &_older-men {
                    //margin: calc(var(--vh, 1vh) * 40) auto 0;
                    //padding: 0;

                    .heading {

                        font-size: toRem(60px);
                        font-weight: 162;
                        line-height: 1;
                    text-transform: uppercase;

                        .first {
                            display: block;
                        }

                        .accent {
                            color: #FFF;
                            text-shadow: 0 1.5px 2px #000;
                            font-family: inherit;
                            font-size: toRem(40px);
                            font-weight: 300;
                            line-height: 1.35;
                            text-transform: lowercase;
                            display: block;
                        }
                    }

                    .subheading {
                        font-family: Oswald, sans-serif;
                        font-size: toRem(80px);
                        font-weight: 600;
                        line-height: 1.35;
                        text-transform: uppercase;
                    }


                }
                &_older-men50 {
                    //margin: calc(var(--vh, 1vh)* 50) auto 0;
                    //padding: 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(60px);
                        font-weight: 300;
                        line-height: 1;
                        font-variant: none;
                        text-transform: uppercase;
                        white-space: nowrap;

                        .first {
                            display: block;
                        }
                    }

                    .accent {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(40px);
                        font-weight: 300;
                        line-height: 1;
                        text-transform: lowercase;
                        display: block;
                        padding-right: 0;
                    }

                    .subheading {
                        color: #FFCD29;
                        font-size: toRem(80px);
                        font-family: Inter,sans-serif;
                        font-weight: 700;
                        text-transform: capitalize;
                        display: block;
                    }
                    .slogan {
                     display: none;
                    }
                }
                &_ret5 {
                    .before {
                        color: #312F2F;
                        text-align: left;
                        font-family: Oswald,sans-serif;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding-left: 30px;
                    }
                    .heading {
                        color: #FFF;
                        text-align: center;
                        -webkit-text-stroke-width: 2px;
                        -webkit-text-stroke-color: #4326F4;
                        font-family: Roboto,sans-serif;
                        font-size: 70px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 1.4px;
                        text-transform: uppercase;
                    }

                    .accent {
                        color: #2B2A2A;
                        text-align: center;
                        font-family: Roboto,sans-serif;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                    }
                    .slogan {
                        display: block;
                    }
                }
            }
        }
    }
}
//768
@include create-mq($grid__bp-md, "min") {
    .landing_welcome {

        .footer {

            &__content {

                &_older-men {
                    //margin: calc(var(--vh, 1vh) * 40) auto 0;
                    //padding: 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Roboto,sans-serif;
                        font-size: toRem(80px);
                        font-weight: 200;
                        line-height: 1;
                        font-variant: none;
                        text-transform: capitalize;

                        .first {
                            display: inline-block;
                        }


                        .accent {
                            color: #FFF;
                            text-shadow: 0 1.5px 2px #000;
                            font-family: inherit;
                            font-size: toRem(60px);
                            font-weight: 300;
                            line-height: 1.75;
                            text-transform: lowercase;
                            display: block;
                        }
                    }

                    .subheading {
                        color: #FFF;
                        font-family: Oswald,sans-serif;
                        text-shadow: 0 2px 2px #1A1A1A;
                        font-size: toRem(80px);
                        font-weight: 600;
                        line-height: 1.35;
                        text-transform: uppercase;
                    }
                    .button_primary{
                        width: 390px;
                        height: 70px;
                        left: 0;
                        transform: translate(0, 0);
                        font-size: toRem(30px);
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }
                &_older-men50 {
                    //margin: calc(var(--vh, 1vh)* 50) auto 0;
                    //padding: 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(50px);
                        font-weight: 300;
                        line-height: 1;
                        font-variant: none;
                        text-transform: uppercase;
                        white-space: nowrap;

                        .first {
                            display: block;
                        }
                    }

                    .accent {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(50px);
                        font-weight: 300;
                        line-height: 1;
                        text-transform: lowercase;
                        display: block;
                        padding-right: 0;
                    }

                    .subheading {
                        color: #FFCD29;
                        font-size: toRem(80px);
                        font-family: Inter,sans-serif;
                        font-weight: 700;
                        text-transform: capitalize;
                        display: block;
                    }
                    .slogan {
                        display: none;
                    }
                }

                &_ret5 {

                    .before {
                        color: #312F2F;
                        text-align: left;
                        font-family: Oswald,sans-serif;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding-left: 40px;
                    }
                    .heading {
                        color: #FFF;
                        text-align: center;
                        -webkit-text-stroke-width: 3px;
                        -webkit-text-stroke-color: #4326F4;
                        font-family: Roboto,sans-serif;
                        font-size: 100px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 3px;
                        text-transform: uppercase;
                    }

                    .accent {
                        color: #2B2A2A;
                        text-align: center;
                        font-family: Roboto,sans-serif;
                        font-size: 60px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                    }
                    .slogan {
                        display: block;
                    }
                }
            }
        }
    }
}
//1024
@include create-mq($grid__bp-lg - 1, "min") {
    .landing_welcome {

        .footer {

            &__content {

                &_older-men {
                    //margin: calc(var(--vh, 1vh) * 40) auto 0;
                    //padding: 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Roboto,sans-serif;
                        font-size: toRem(80px);
                        font-weight: 200;
                        line-height: 1;
                        font-variant: none;
                        text-transform: capitalize;

                        .first {
                            display: inline-block;
                        }


                        .accent {
                            color: #FFF;
                            text-shadow: 0 1.5px 2px #000;
                            font-family: inherit;
                            font-size: toRem(60px);
                            font-weight: 300;
                            line-height: 1.75;
                            text-transform: lowercase;
                            display: block;
                        }
                    }

                    .subheading {
                        color: #FFF;
                        font-family: Oswald,sans-serif;
                        text-shadow: 0 2px 2px #1A1A1A;
                        font-size: toRem(80px);
                        font-weight: 600;
                        line-height: 1.35;
                        text-transform: uppercase;
                    }
                    .button_primary{
                        width: 330px;
                        height: 70px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: toRem(30px);
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }
                &_older-men50 {
                    //margin: calc(var(--vh, 1vh)* 50) auto 0;
                    //padding: 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(50px);
                        font-weight: 300;
                        line-height: 1;
                        font-variant: none;
                        text-transform: uppercase;
                        white-space: nowrap;

                        .first {
                            display: block;
                        }
                    }

                    .accent {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(50px);
                        font-weight: 300;
                        line-height: 1;
                        text-transform: lowercase;
                        display: block;
                        padding-right: 0;
                    }

                    .subheading {
                        color: #FFCD29;
                        font-size: toRem(80px);
                        font-family: Inter,sans-serif;
                        font-weight: 700;
                        text-transform: capitalize;
                        display: block;
                    }
                    .slogan {
                        display: none;
                    }
                }
                &_ret5 {

                    .before {
                        color: #312F2F;
                        text-align: left;
                        font-family: Oswald,sans-serif;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding-left: 40px;
                    }
                    .heading {
                        text-align: left;
                        -webkit-text-stroke-width: 3px;
                        -webkit-text-stroke-color: #4326F4;
                        font-family: Roboto,sans-serif;
                        font-size: 160px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 3.2px;
                        text-transform: uppercase;
                    }

                    .accent {
                        color: #2B2A2A;
                        text-align: left;
                        font-family: Roboto,sans-serif;
                        font-size: 90px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                    }

                    .slogan {
                        display: flex;
                        flex-direction: row;
                        gap:30px;
                    }

                    .button_primary{
                        width: 390px;
                        margin-top: 60px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        //padding: 13px 50px;
                        border-radius: 5px;
                        background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
                    }
                    .text {
                        display: none;
                    }
                    .icon_aws{
                        display: none;
                    }
                }
            }
        }
    }
}
//1140
@include create-mq($grid__bp-2xl, "min") {
    .landing_welcome {

         .footer {
            .container {
                max-width: 100%;
            }
        }

        .footer {
            &__content {
                &_mature {
                    .footer__text {
                        width: 1020px;
                    }
                    .heading {
                        font-size: 10rem;
                        margin-bottom: 3rem;
                    }

                    .subheading {
                        width: 640px;
                        font-size: 6rem;
                    }
                }
                &_older-men {
                    //margin: calc(var(--vh, 1vh) * 40) auto 0;
                    //padding: 0;

                    .heading {
                        .first {
                            display: inline-block;
                        }


                        .accent {
                            font-size: inherit;
                            font-weight: inherit;
                            line-height: inherit;
                            text-transform: inherit;
                            display: inline-block;
                        }
                    }
                }
                &_older-men50 {
                    //margin: calc(var(--vh, 1vh)* 50) auto 0;
                    //padding: 0;
                    //margin-left: unset;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(60px);
                        font-weight: 300;
                        line-height: 1;
                        font-variant: none;
                        text-transform: uppercase;
                        white-space: nowrap;

                        .first {
                            display: block;
                        }
                    }

                    .accent {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(60px);
                        font-weight: 300;
                        line-height: 1;
                        text-transform: lowercase;
                        display: block;
                        padding-right: 0;
                    }

                    .subheading {
                        color: #FFCD29;
                        font-size: toRem(80px);
                        font-family: Inter,sans-serif;
                        font-weight: 700;
                        text-transform: capitalize;
                        display: block;
                    }
                    .slogan {
                      display: none;
                    }
                }
                &_ret5 {

                    .before {
                        color: #312F2F;
                        text-align: left;
                        font-family: Oswald,sans-serif;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding-left: 40px;
                    }
                    .heading {
                        text-align: center;
                        -webkit-text-stroke-width: 2px;
                        -webkit-text-stroke-color: #4326F4;
                        font-family: Roboto,sans-serif;
                        font-size: 110px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 2.2px;
                        text-transform: uppercase;
                    }

                    .accent {
                        color: #2B2A2A;
                        text-align: center;
                        font-family: Roboto,sans-serif;
                        font-size: 60px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                    }
                    .slogan {
                        display: flex;
                        flex-direction: row;
                        gap:30px;
                    }
                    .button_primary{
                        width: 390px;
                        margin-top: 60px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        //padding: 13px 50px;
                        border-radius: 5px;
                        background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
                    }
                    .icon_aws{
                        display: none;
                    }
                }
            }
        }
    }
}

//1280
@include create-mq($grid__bp-xl, "min") {
    .landing_welcome {

        .footer {
            &__caption {
                display: flex;
                align-items: center;
                gap: 24px;

                .text {
                    text-align: left;
                }

                .text-older {
                    display: none;
                }
                .text-ret5 {
                    display: block;
                }

            }

            .page__older-men {
                .section_service {
                    .container {
                        .section__content p {
                            font-size: 16px;
                        }
                    }
                }
            }

            &__content {
                margin: auto 0;
                padding: 0;

                &_asia {

                    .footer__text {
                        width: 820px;
                    }

                    .heading {
                        font-size: 6rem;
                        margin-bottom: 4rem;
                    }

                    .subheading {
                        width: 720px;
                        font-size: 4rem;
                    }
                }

                &_black {
                    .footer__text {
                        width: 780px;
                    }

                    .heading {
                        font-size: 7rem;
                        margin-bottom: 3rem;
                    }

                    .subheading {
                        font-size: 4rem;

                        .is-block {
                            text-align: right;
                        }
                    }
                }

                &_older-men {
                    //margin: calc(var(--vh, 1vh)* 60) auto 0;
                    //padding: 0;
                    margin: auto auto 0 auto;
                    padding:0 0 5% 0;

                    .heading {
                        font-size: toRem(80px);
                        font-weight: 200;
                        line-height: 1;
                        font-variant: none;
                        text-transform: capitalize;

                        .first {
                            display: inline-block;
                        }


                        .accent {
                            font-size: inherit;
                            font-weight: inherit;
                            line-height: inherit;
                            text-transform: inherit;
                            display: inline-block;
                        }
                    }

                    .subheading {
                        font-size: toRem(70px);
                        font-weight: 400;
                        line-height: 1;
                        text-transform: capitalize;
                    }
                    .button_primary{
                        min-width: 330px;
                        height: 70px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: toRem(30px);
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }

                &_older-men50 {
                    //margin: 0 auto calc(var(--vh, 1vh)* 10);
                    //padding: 0;
                    margin: auto auto 0 0;
                    padding:0 0 5% 0;
                    //margin-left: unset;

                    .footer__text{
                        padding-left: 15%;
                        line-height: 1;
                    }

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(50px);
                        font-weight: 500;
                        line-height: 1;
                        font-variant: none;
                        text-transform: capitalize;
                        white-space: nowrap;

                        .first {
                            display: inline-block;
                        }
                    }

                    .accent {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(50px);
                        font-weight: 500;
                        line-height: 1;
                        text-transform: capitalize;
                        display: inline-block;
                        padding-right: 20px;
                    }

                    .subheading {
                        color: #FFCD29;
                        font-size: toRem(50px);
                        font-family: Inter,sans-serif;
                        font-weight: 700;
                        text-transform: capitalize;
                        display: inline-block;
                    }
                    .slogan {
                        color: white;
                        font-size: toRem(20px);
                        font-family: Inter,sans-serif;
                        font-weight: 500;
                        line-height: 1px;
                        display: block;
                        text-align: left;
                        margin-top: 34px;
                    }
                    .button_primary{
                        width: 330px;
                        height: 70px;
                        margin-top: 34px;
                        left: 0;
                        transform: translate(0, 0);
                        font-size: toRem(30px);
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                    .icon_aws{
                        display: block;
                        width: 111px;
                        height: 151px;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                }

                &_ret5 {
                    margin: auto auto 0 65px;
                    padding:0 0 5% 0;

                    .before {
                        color: #312F2F;
                        text-align: left;
                        font-family: Oswald,sans-serif;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding-left: 40px;
                    }
                    .heading {
                        text-align: left;
                        -webkit-text-stroke-width: 2px;
                        -webkit-text-stroke-color: #4326F4;
                        font-family: Roboto,sans-serif;
                        font-size: 110px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 2.2px;
                        text-transform: uppercase;
                    }

                    .accent {
                        color: #2B2A2A;
                        text-align: left;
                        font-family: Roboto,sans-serif;
                        font-size: 60px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                    }
                    .slogan {
                        display: flex;
                        flex-direction: row;
                        gap:30px;
                        padding-top: 100px;
                    }
                    .button_primary{
                        margin-top: 34px;
                        left: 0;
                        transform: translate(0, 0);
                        font-size: toRem(30px);
                        font-weight: 700;
                        text-transform: uppercase;
                        width: 323px;
                    }

                    .text {
                        display: block;
                        color: #4E4E4E;
                        font-family: Roboto,sans-serif;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding-top: 34px;
                        margin-top: 0;
                    }
                    .icon_aws{
                        display: none;
                    }
                }

                &_mature {
                    .footer__text {
                        width: 820px;
                    }

                    .heading {
                        font-size: 6rem;
                        margin-bottom: 2rem;
                    }

                    .subheading {
                        width: 520px;
                        font-size: 4rem;

                        .is-block {
                            text-align: right;
                        }
                    }
                }
            }

            &__caption {
                //padding-right: 20%;

                .text {
                    display: inline-block;
                }
                .text-older {
                    display: none;
                }
                .text-ret5 {
                    display: block;
                }

                .button {
                    width: 330px;
                }
            }
        }
    }
}
@media only screen and (max-width: 1279px) {
    .landing_welcome {

        .footer {
            .page__older-men {
                .section_service {

                    .container {
                        .section__description {
                            width: auto;
                            font-size: 20px;
                            margin: 0;
                        }
                        .section__content p {
                            font-size: 16px;
                        }
                    }


                    }
                }
            .section_participate {
                .media__image {
                    display: none;
                }
            }
            }

        }
    }

//1440
@include create-mq($grid__bp-2xl, "min") {
    .landing_welcome {

        .footer {
            .page__older-men {
                .section_service {
                    .container {
                        .section__content p {
                            font-size: 18px;
                        }
                    }
                }
                .section_participate {
                    .container {
                        max-width: 100%;
                    }
                }
            }
            &__content {

                &_older-men {
                    //margin: calc(var(--vh, 1vh) * 60) auto 0;
                    //padding: 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Roboto,sans-serif;
                        font-size: toRem(80px);
                        font-weight: 200;
                        line-height: 1;
                        font-variant: none;
                        text-transform: capitalize;

                        .first {
                            display: inline-block;
                        }


                        .accent {
                            font-size: inherit;
                            font-weight: inherit;
                            line-height: inherit;
                            text-transform: inherit;
                            display: inline-block;
                        }
                    }

                    .subheading {
                        color: #FFF;
                        font-family: Roboto,sans-serif;
                        font-size: toRem(80px);
                        font-weight: 400;
                        line-height: 1;
                        text-transform: capitalize;
                    }
                }

                &_older-men50 {
                    //margin: 0 auto calc(var(--vh, 1vh)* 10);
                    //padding: 0;
                    margin-left: unset;

                    .footer__text{
                        padding-left: 15%;
                        line-height: 1;
                    }

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(80px);
                        font-weight: 500;
                        line-height: 1;
                        font-variant: none;
                        text-transform: capitalize;
                        white-space: nowrap;

                        .first {
                            display: inline-block;
                        }
                    }

                    .accent {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(80px);
                        font-weight: 500;
                        line-height: 1;
                        text-transform: capitalize;
                        display: inline-block;
                        padding-right: 20px;
                    }

                    .subheading {
                        color: #FFCD29;
                        font-size: toRem(80px);
                        font-family: Inter,sans-serif;
                        font-weight: 700;
                        text-transform: capitalize;
                        display: inline-block;
                    }
                    .slogan {
                        color: white;
                        font-size: toRem(30px);
                        font-family: Inter,sans-serif;
                        font-weight: 500;
                        line-height: 1px;
                        display: block;
                        text-align: left;
                    }
                }

                &_ret5 {
                    margin: auto auto 0 106px;
                    padding:0 0 5% 0;

                    .before {
                        color: #312F2F;
                        text-align: left;
                        font-family: Oswald,sans-serif;
                        font-size: 60px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding-left: 60px;
                    }
                    .heading {
                        text-align: left;
                        -webkit-text-stroke-width: 3px;
                        -webkit-text-stroke-color: #4326F4;
                        font-family: Roboto,sans-serif;
                        font-size: 160px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 3.2px;
                        text-transform: uppercase;
                    }

                    .accent {
                        color: #2B2A2A;
                        text-align: left;
                        font-family: Roboto,sans-serif;
                        font-size: 90px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                    }
                    .slogan {
                        display: flex;
                        flex-direction: row;
                        gap:30px;
                        padding-top: 100px;
                    }
                    .icon_aws{
                        display: block;
                        width: 111px;
                        height: 151px;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}
//1920
@include create-mq($grid__bp-4xl - 1, "min") {
    .landing_welcome {

        .footer {
            .page__older-men {
                .section_service {
                    .container {
                        .section__content p {
                            font-size: 24px;
                        }
                    }
                }
            }
            &__content {

                &_older-men {
                    //margin: calc(var(--vh, 1vh) * 60) auto 0;
                    //padding: 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Roboto,sans-serif;
                        font-size: toRem(80px);
                        font-weight: 200;
                        line-height: 1;
                        font-variant: none;
                        text-transform: capitalize;

                        .first {
                            display: inline-block;
                        }


                        .accent {
                            font-size: inherit;
                            font-weight: inherit;
                            line-height: inherit;
                            text-transform: inherit;
                            display: inline-block;
                        }
                    }

                    .subheading {
                        color: #FFF;
                        font-family: Roboto,sans-serif;
                        font-size: toRem(80px);
                        font-weight: 400;
                        line-height: 1;
                        text-transform: capitalize;
                    }
                }

                &_older-men50 {
                    //margin: 0 auto calc(var(--vh, 1vh)* 10);
                    //padding: 0;
                    margin-left: unset;

                    .footer__text{
                        padding-left: 15%;
                    }

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(100px);
                        font-weight: 500;
                        line-height: 1;
                        font-variant: none;
                        text-transform: capitalize;
                        white-space: nowrap;

                        .first {
                            display: inline-block;
                        }
                    }

                    .accent {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(100px);
                        font-weight: 500;
                        line-height: 1;
                        text-transform: capitalize;
                        display: inline-block;
                        padding-right: 20px;
                    }

                    .subheading {
                        color: #FFCD29;
                        font-size: toRem(100px);
                        font-family: Inter,sans-serif;
                        font-weight: 700;
                        text-transform: capitalize;
                        display: inline-block;
                    }
                    .slogan {
                        color: white;
                        font-size: toRem(40px);
                        font-family: Inter,sans-serif;
                        font-weight: 500;
                        line-height: 1px;
                        display: block;
                        text-align: left;
                    }
                }

                &_ret5 {

                    .before {
                        color: #312F2F;
                        text-align: left;
                        font-family: Oswald,sans-serif;
                        font-size: 60px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding-left: 60px;
                    }
                    .heading {
                        text-align: left;
                        -webkit-text-stroke-width: 3px;
                        -webkit-text-stroke-color: #4326F4;
                        font-family: Roboto,sans-serif;
                        font-size: 160px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 3.2px;
                        text-transform: uppercase;
                    }

                    .accent {
                        color: #2B2A2A;
                        text-align: left;
                        font-family: Roboto,sans-serif;
                        font-size: 90px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                    }
                    .slogan {
                        display: flex;
                        flex-direction: row;
                        gap:30px;
                        padding-top: 100px;
                    }
                }
            }
        }
    }
}
//3440
@include create-mq($grid__bp-6xl, "min") {
    .landing_welcome {

        .footer {

            &__content {

                &_older-men {
                    //margin: calc(var(--vh, 1vh)* 60) auto 0;
                    //padding: 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Roboto,sans-serif;
                        font-size: toRem(110px);
                        line-height: 1;
                        font-weight: 200;
                        font-variant: none;
                        text-transform: capitalize;
                        white-space: nowrap;

                        .first {
                            display: inline-block;
                        }


                        .accent {
                            font-size: inherit;
                            font-weight: inherit;
                            line-height: inherit;
                            text-transform: inherit;
                            display: inline-block;
                        }
                    }

                    .subheading {
                        color: #FFF;
                        font-family: Roboto,sans-serif;
                        font-size: toRem(110px);
                        font-weight: 400;
                        line-height: 1;
                        text-transform: capitalize;
                        text-align: center;
                        white-space: nowrap;
                    }
                }

                &_older-men50 {
                    //margin: 0 auto calc(var(--vh, 1vh)* 10);
                    //padding: 0;
                    margin-left: unset;

                    .footer__text{
                        padding-left: 15%;
                        line-height: 1.25;
                    }

                    .heading {
                        color: #FFF;
                        text-align: left;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(100px);
                        font-weight: 500;
                        line-height: 1.2;
                        font-variant: none;
                        text-transform: capitalize;
                        white-space: nowrap;

                        .first {
                            display: inline-block;
                        }
                    }

                    .accent {
                        color: #FFF;
                        text-align: left;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(100px);
                        font-weight: 500;
                        line-height: 1;
                        text-transform: capitalize;
                        display: inline-block;
                        padding-right: 50px;
                    }

                    .subheading {
                        color: #FFCD29;
                        font-size: toRem(100px);
                        font-family: Inter,sans-serif;
                        font-weight: 700;
                        text-transform: capitalize;
                        display: inline-block;
                    }
                    .slogan {
                        color: white;
                        font-size: toRem(40px);
                        font-family: Inter,sans-serif;
                        font-weight: 500;
                        line-height: 1;
                        display: block;
                        text-align: left;
                    }
                }

                &_ret5 {
                    margin: auto auto 0 318px;
                    padding:0 0 5% 0;

                    .before {
                        color: #312F2F;
                        text-align: left;
                        font-family: Oswald,sans-serif;
                        font-size: 80px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding-left: 80px;
                    }

                    .heading {
                        text-align: left;
                        -webkit-text-stroke-width: 4px;
                        -webkit-text-stroke-color: #4326F4;
                        font-family: Roboto,sans-serif;
                        font-size: 200px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 4px;
                        text-transform: uppercase;
                    }

                    .accent {
                        text-align: left;
                        color: #2B2A2A;
                        font-family: Roboto,sans-serif;
                        font-size: 110px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                    }
                    .slogan {
                        display: flex;
                        flex-direction: row;
                        gap:30px;
                        padding-top: 150px;
                    }
                }
            }
        }
    }
}
//3840
@media only screen and (min-width: 3840px){
    .landing_welcome {

        .footer {

            &__content {

                &_older-men {
                    //margin: calc(var(--vh, 1vh)* 60) auto 0;
                    //padding: 0;

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Roboto,sans-serif;
                        font-size: toRem(160px);
                        font-weight: 200;
                        line-height: 1.12;
                        font-variant: none;
                        text-transform: capitalize;
                        white-space: nowrap;

                        .first {
                            display: inline-block;
                        }


                        .accent {
                            font-size: inherit;
                            font-weight: inherit;
                            line-height: inherit;
                            text-transform: inherit;
                            display: inline-block;
                        }
                    }

                    .subheading {
                        color: #FFF;
                        font-family: Roboto,sans-serif;
                        font-size: toRem(160px);
                        font-weight: 400;
                        line-height: 1.12;
                        text-transform: capitalize;
                        white-space: nowrap;
                    }
                }

                &_older-men50 {
                    //margin: 0 auto calc(var(--vh, 1vh)* 10);
                    //padding: 0;
                    margin-left: unset;

                    .footer__text{
                        padding-left: 15%;
                        line-height: 1.25;
                    }

                    .heading {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(200px);
                        font-weight: 500;
                        line-height: 1.2;
                        font-variant: none;
                        text-transform: capitalize;
                        white-space: nowrap;

                        .first {
                            display: inline-block;
                        }
                    }

                    .accent {
                        color: #FFF;
                        text-align: center;
                        text-shadow: 0 2px 2px #000;
                        font-family: Inter, sans-serif;
                        font-size: toRem(200px);
                        font-weight: 500;
                        line-height: 1.2;
                        text-transform: capitalize;
                        display: inline-block;
                        padding-right: 50px;
                    }

                    .subheading {
                        color: #FFCD29;
                        font-size: toRem(200px);
                        font-family: Inter,sans-serif;
                        font-weight: 700;
                        text-transform: capitalize;
                        display: inline-block;
                    }
                    .slogan {
                        color: white;
                        font-size: toRem(80px);
                        font-family: Inter,sans-serif;
                        font-weight: 500;
                        line-height: 1.2px;
                        display: block;
                        text-align: left;
                    }
                }

                &_ret5 {

                    .before {
                        color: #312F2F;
                        text-align: left;
                        font-family: Oswald,sans-serif;
                        font-size: 100px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding-left: 90px;
                    }

                    .heading {
                        text-align: left;
                        -webkit-text-stroke-width: 5px;
                        -webkit-text-stroke-color: #4326F4;
                        font-family: Roboto,sans-serif;
                        font-size: 270px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.2;
                        letter-spacing: 5.4px;
                        text-transform: uppercase;

                    }

                    .accent {
                        color: #2B2A2A;
                        font-family: Roboto,sans-serif;
                        font-size: 150px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1.2;
                        text-transform: uppercase;
                    }
.text {
    color: #4E4E4E;
    font-family: Roboto,sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 34px;
    margin-top: 0;
}
                    .slogan {
                        padding-top: 250px;
                    }

                }
            }
        }
    }
}
