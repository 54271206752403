.pagination {
    @extend .list;
    display: flex;
    justify-content: center;

    &__item {
        &:not(:last-child) {
            margin-right: 5px;
        }
    }

    &__button {
        display: block;
        width: 36px;
        height: 36px;
        line-height: 35px;
        border-radius: 5px;
        background: var(--body-bg);
        border: 1px solid var(--light-gray);
        font-size: toRem(14px);
        text-align: center;
        text-decoration: none;
        color: var(--color-black);
        transition: background-color .35s linear;

        &.is-active:not(.no-active),
        &:active:not(.no-active),
        &:hover:not(.no-active) {
            background-color: var(--gray);
            border-color: var(--gray);
            color: var(--color-white);
        }

        &_nav {
            color: #000;

            &:disabled,
            &.is-disabled {
                opacity: 0.45;
                pointer-events: none;
            }
        }
    }

    @include create-mq($grid__bp-xl, 'min') {
        &__button {
            font-size: 1rem;
        }
    }
}

@media (hover: hover) {
    .pagination__button:not(.no-active) {
        cursor: pointer;
    }
}
