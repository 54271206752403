:root {
  line-height: 1.15;
  text-size-adjust: 100%; // prevent zooming text
  -webkit-overflow-scrolling: auto; // Lets it scroll lazy
  -webkit-touch-callout: none; //context menu on element is not displayed
}
body {
  margin: 0;
  font-smooth: antialiased; // Smooth the font on the level of the pixel, as opposed to the subpixel
  text-rendering: optimizeLegibility; //should emphasize readability at the expense of rendering speed and geometric accuracy
}
html,
body {
  min-height: 100%;
}
body * {
  -webkit-tap-highlight-color: rgba(
    255,
    255,
    255,
    0
  ); //sets the color of the highlight that appears over a link while it's being tapped
  -webkit-focus-ring-color: rgba(
    255,
    255,
    255,
    0
  ); // styling outline effect for focused
  outline: none;
}
* {
  box-sizing: border-box;
  &:before,
  &:after {
    box-sizing: border-box;
  }
}
img,
a {
  -webkit-touch-callout: none;
  user-select: none;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
figcaption,
figure,
main {
  display: block;
}
figure {
  margin-top: 0;
  margin-bottom: 1.5rem;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img,
picture,
video {
  max-width: 100%;
  height: auto;
  border: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  line-height: 1.15;
  margin: 0;
}
button,
select {
  text-transform: none;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
details {
  display: block;
}
summary {
  display: list-item;
}
audio,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
summary {
  display: list-item;
}
canvas {
  display: inline-block;
}
svg:not(:root) {
  overflow: hidden;
}
[hidden] {
  display: none;
}
