.countdown {
  position: relative;
  color: inherit;
  display: flex;
  gap: 10px;
  &__label {
    display: block;
    font-size: toRem(14px);
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
  }
  &__item {

    text-align: center;
  }
  &__value {
    display: inline-block;
    width: 55px;
    height: 48px;
    padding: 5px;
    background-color: var(--color-white);
    border-radius: 5px;
    font-size: toRem(40px);
    line-height: 1;
    color: #333232;
    font-weight: 700;
  }
}
