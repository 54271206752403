.tabs {
    display: flex;
    @extend .list;

    &__tab {
        flex: 1;
    }

    &__link {
        position: relative;
        display: block;
        padding: 8px;
        text-align: center;
        font-size: toRem(12px);
        white-space: nowrap;
        color: var(--light-gray);
        background: linear-gradient(
                357.97deg,
                rgba(255, 255, 255, 0.9) 2.22%,
                rgba(255, 255, 255, 0) 98.82%
        );
        transition: color var(--transition-duration) var(--transition-easing);

        &:hover,
        &:active,
        &.is-active {
            background: var(--body-bg);
            font-weight: 500;
            color: var(--button-color1);

            .icon {
                opacity: 1;
            }
        }

        .icon {
            width: 18px;
            height: 18px;
            margin-right: 5px;
            opacity: 0.5;
        }
    }
}

@media (hover: hover) {
    .tabs__link {
        cursor: pointer;
    }
}
@include create-mq($grid__bp-sm, "min") {
    .tabs {
        &__link {
            padding: 12px;
            font-size: toRem(14px);
        }
    }
}
