.banner {
  position: relative;
  margin: 0 auto 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: toRem(12px) toRem(24px);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  &__heading {
    position: absolute;
    top: -20px;
    left: 24px;
    width: 200px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  &__timer {
    margin-left: auto;
    text-align: center;
  }
  &__title {
    margin: 0;
    font-size: 1rem;
    text-transform: uppercase;
    color: inherit;
  }
  &_photo {
    width: 750px;
    height: 100px;
    background-image: url(../images/banner/freechat/banner-freechat.png);
    color: #fff;
    .banner__heading {
      background-image: url(../images/holidays/photo/heading.svg);
    }
  }
  &_photo_free_chat {
    background-image: url(../images/modal/freechat/freechat-mobile-modal.jpg);
    color: #fff;
    .banner__heading {
      background-image: url(../images/holidays/photo/heading.svg);
    }
  }
}
