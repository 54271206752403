.nav {
    &__header {
        .button_menu {
            margin-right: 8px;
        }
    }

    &__container {
        padding: 50px 50px 60px;
    }
}
@include create-mq($grid__bp-xl, "min") {
    .nav {
        &__container {
            padding: toRem(30px);
        }
        &__menu-sub {

            &.menu_sub-settings {
                width: 70%;
            }
        }
    }
}
@include create-mq($grid__bp-5xl, "min") {
    .nav {
        &__container {
            padding: 50px;
        }
    }
}
