.caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: toRem(58px) toRem(27px) toRem(24px);
    background: linear-gradient(
            360deg,
            #131313 -25%,
            rgba(19, 19, 19, 0) 100%
    );
    color: var(--color-white);
    z-index: 5;
}
