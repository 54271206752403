    :root {
    --vh: 1vh;
    --color-white: #fff;
    --color-light: #fafafa;
    --color-light1: #fbfbfb;
    --color-light2: #ebe1ff;
    --color-light3: #f6f6f6;
    --color-red: #e52a62;
    --color-green: #2BA33E;
    --color-light-green: #3AC624;
    --color-blue: #4d8dec;
    --color-orange: #ff8754;

    --dark-gray: #131313;
    --dark-gray2: #1B1B1B;
    --dark-gray3: #1F1F1F;
    --light-gray: #C5C5C5;
    --light-gray1: #D9D9D9;
    --gray: #7e7e7e;
    --gray1: #757575;
    --gray2: #989898;
    --color-black: #000;
        --color-date: #262626;

    --gradient: linear-gradient(227deg, #FF63F9 1.25%, #766DE6 44.67%, #00DCDC 94.77%);
    --gradient-reverse: linear-gradient(-227deg, #00DCDC 5.23%, #766DE6 55.33%, #FF63F9 98.75%);
    --gradient-text: linear-gradient(221deg, #5886FF 16.48%, #D00BF0 93.67%);

    --gradient-price-base:linear-gradient(259deg, #1BA8F7 -5.37%, #00DBDB 82.72%);
    --gradient-price-expert:linear-gradient(259deg, #5F32E0 -5.37%, #3A94FE 82.72%);
    --gradient-price-pro:linear-gradient(259deg, #C323EB -5.37%, #5939D8 82.72%);
    --gradient-price-top:linear-gradient(259deg, #E04285 -5.37%, #AD07D7 82.72%);
    --gradient-price-starting:linear-gradient(264deg, #FF54D9 -6.48%, #5E78FF 44.88%, #00DBDB 102.04%);

    --state-success: var(--color-green);
    --state-online: #00FC29;
    --state-info: var(--color-blue);
    --state-warning: var(--color-orange);
    --state-danger: var(--color-red);

    --body-bg: var(--color-white);
    --color-text: var(--gray);
    --heading-font-weight: 700;
    --font-size: 1rem;
    --font-size-xs: .625rem;
    --font-size-s: .75rem;
    --font-size-sm: .875rem;
    --font-size-md: var(--font-size);
    --font-size-lg: 1.125rem;
    --font-size-xl: 1.25rem;
    --line-height: 1.4;
    --font-base: 'Roboto', sans-serif;
    --heading-color: var(--dark-gray);
    --heading-font-family: var(--font-base);
    --heading-line-height: 1.2;


    --transition-duration: 0.35s;
    --transition-easing: cubic-bezier(0.5, 0, 0.5, 1);
}
.mode-light {
    --body-bg: var(--color-white);
    --nav-bg: var(--color-white);
    --nav-header-bg: var(--color-light1);
    --sidebar-bg: var(--color-light1);
    --sidebar-color: var(--color-light);
    --snotify-bg: rgba(41, 27, 27, 0.60);
    --btn-snotify: #ffffff;
    --card-bg: var(--color-light3);
    --color-text: var(--gray);
    --heading-color: var(--dark-gray);
    --chat-default-bg: var(--color-white);
    --chat-message-bg: var(--color-light);
    --chat-input-bg: var(--color-white);
    --letter-message-color: #1F1F1F;
    --letter-subject-color: #4A4A4A;
    --button-bg1: var(--color-light1);
    --button-bg2: var(--color-white);
    --button-bg3: #302F2F;
    --button-color1: var(--dark-gray);
    --button-color2: var(--gray);
    --button-nav-bg: #f8f8f8;
    --button-color3: var(--light-gray);
    --drobdown-hover-bg: #EEEEEE;
    --icon-box-bg: var(--color-light1);
    --media-bg: var(--color-light2);
    --message-admin-color: var(--gray1);
    --profile-title: var(--gray);
    --footer-color: var(--color-black);
    --color-date: var(--color-white);

    .is-dark {
        display: none;
    }
    .is-light {
        display: initial;
    }

    .chat {
        &__letter {
            .chat__header {
                &.bg-1, &.bg-2, &.bg-3 {
                    .contact__title {
                        color: var(--color-black);
                    }

                    .button_filled {
                        border: 1px solid var(--color-black);
                    }
                }
            }
        }
    }
}

.mode-dark {
    --body-bg: var(--dark-gray);
    --nav-header-bg: #272727;
    --nav-bg: #272727;
    --sidebar-bg: #272727;
    --button-nav-bg: #272727;
    --sidebar-color: var(--dark-gray);
    --snotify-bg: rgba(61, 61, 61, .56);
    --btn-snotify: #fff;
    --card-bg: #272727;
    --color-text: var(--color-white);
    --heading-color: var(--color-white);
    --chat-default-bg: var(--dark-gray);
    --chat-message-bg: #262626;
    --chat-input-bg: #262626;
    --letter-message-color: #FAFAFA;
    --letter-subject-color: #E1E1E1;
    --button-bg1: #272727;
    --button-bg2: var(--dark-gray);
    --button-bg3: var(--light-gray);
    --button-color1: var(--color-white);
    --button-color2: var(--color-white);
    --button-color3: var(--dark-gray);
    --drobdown-hover-bg: var(--gray);
    --icon-box-bg: transparent;
    --media-bg: var(--gray);
    --message-admin-color: var(--gray1);
    --profile-title: var(--gray2);
    --footer-color: var(--color-white);
    --color-date: var(--dark-gray);

    .is-light {
        display: none;
    }
    .is-dark {
        display: initial;
    }
    .dropdown {
        &__menu {
            &::before {
                background-image: url(../images/icons/corner-dark.svg);
            }
        }
    }

    .icon_letter-white {
        background-image: url(../images/icons/letter-dark.svg);
    }
    .icon_letter-open {
        background-image: url(../images/icons/letter-dark-open.svg);
    }

    .chat {
        .chat__check-icon:not(.is-read) {
            color: var(--light-gray)
        }

        &__item {

            &.is-start {

                .chat__check-icon {
                    color: var(--light-gray)
                }
                .message {
                    background: var(--chat-message-bg);
                    color: var(--light-gray);
                    background-size: cover;
                    background-repeat: no-repeat;

                    .message__triangle {
                        path {
                            fill: var(--chat-message-bg);
                        }
                    }
                }
            }
            &.is-end {
                .message {
                    color: var(--color-white);

                    //&_color1 {
                    //    background: linear-gradient(180deg, #ED7DFF 0%, #9584FF 100%);
                    //    .message__triangle {
                    //        path {
                    //            fill: #9584FF;
                    //        }
                    //    }
                    //}
                    &_color2 {
                        background: linear-gradient(44deg, #1C708B -9.91%, #028766 43.92%, #47972B 100%), #EEE;
                        //.message__triangle {
                        //    path {
                        //        fill: #47972B;
                        //    }
                        //}
                    }
                    &_color3 {
                        background: linear-gradient(45deg, #8E9122 -8.88%, #971C1C 100%), #EEE;
                        //.message__triangle {
                        //    path {
                        //        fill: #971C1C;
                        //    }
                        //}
                    }
                    &_color4 {
                        background: linear-gradient(180deg, #A21271 0%, #752B87 100%);
                        //.message__triangle {
                        //    path {
                        //        fill: #752B87;
                        //    }
                        //}
                    }
                    &_color5 {
                        background: linear-gradient(180deg, #B88012 0%, #497600 100%);
                        //.message__triangle {
                        //    path {
                        //        fill: #497600;
                        //    }
                        //}
                    }
                    &_color6 {
                        background: linear-gradient(180deg, #181D48 0%, #198F88 100%);
                        //.message__triangle {
                        //    path {
                        //        fill: #198F88;
                        //    }
                        //}
                    }
                    &_color7 {
                        background: linear-gradient(44deg, #00AB8C -9.91%, #3B49BA 49.51%, #A50076 100%), #EEE;
                        //.message__triangle {
                        //    path {
                        //        fill:  #A50076;
                        //    }
                        //}
                    }
                    &_color8 {
                        background: #21242C;
                        //.message__triangle {
                        //    path {
                        //        fill: #21242C;
                        //    }
                        //}
                    }
                }
            }
        }
        &__wrapper {
            &.bg-1 {
                background: linear-gradient(180deg, #9A0066 0%, #3C1986 56.43%, #176399 100%);
            }
            &.bg-2 {
                background: linear-gradient(180deg, #4C3556 0%, #14101A 55.41%, #233235 100%);
            }
            &.bg-3 {
                background: linear-gradient(180deg, #4F0362 0%, #0F1733 64.17%, #29222F 100%);
            }
            &.bg-4 {
                background: linear-gradient(180deg, #806400 0%, #33230F 55.77%, #132210 100%);
            }
            &.bg-5 {
                background: linear-gradient(180deg, #036251 0%, #0F2633 64.17%, #29222F 100%);
            }
            &.bg-6 {
                background: linear-gradient(180deg, #80001F 0%, #330F2D 64.17%, #2F222B 100%);
            }
        }
        &_support {
            .chat__wrapper {
                background-image: url(../images/support-chat/dark/bg-dark.webp);
                //.status {
                //    &_badge {
                //        color: #FFFFFF;
                //    }
                //}
            }
            .chat__item {
                &.is-start {
                    .message:not(.message_simple) {
                        color: var(--color-white);
                    }
                }
                &.is-end {
                    .message {
                        color: #000;
                    }
                    .button {
                        color: var(--color-text);
                    }
                }
            }
            .contact {
               &__avatar {
                   background-image: url(../images/support-chat/support-logo-dark.svg);
                }


            }
        }
        &_settings {
            .chat__color {
                &_1 {
                    background: linear-gradient(180deg, #FF8AD7 6.15%, #E168FF 20.46%, #9584FF 37.05%, rgba(142, 123, 255, 0.92) 41.33%, #95BFFE 57.15%, rgba(111, 255, 246, 0.86) 70.68%, #54FFFF 83.06%, #7BFFD7 100%);
                }
                &_2 {
                    background: linear-gradient(44deg, #98E6FF -9.91%, #B6FFED 43.92%, #BEFFA7 100%), #EEE;
                }
                &_3 {
                    background: linear-gradient(45deg, #8E9122 -8.88%, #971C1C 100%), #EEE;
                }
                &_4 {
                    background: linear-gradient(180deg, #A21271 0%, #752B87 100%);
                }
                &_5 {
                    background: linear-gradient(180deg, #B88012 0%, #497600 100%);
                }
                &_6 {
                    background: linear-gradient(180deg, #181D48 0%, #198F88 100%);
                }
                &_7 {
                    background: linear-gradient(44deg, #00AB8C -9.91%, #3B49BA 49.51%, #A50076 100%), #EEE;
                }
                &_8 {
                    background: linear-gradient(0deg, #21242C 0%, #21242C 100%), #EEE;
                }
            }
            .chat__bg {
                &_default {
                    background: var(--chat-default-bg);
                }
                &_1 {
                    background: linear-gradient(180deg, #9A0066 0%, #3C1986 56.43%, #176399 100%);
                }
                &_2 {
                    background: linear-gradient(180deg, #4C3556 0%, #14101A 55.41%, #233235 100%);
                }
                &_3 {
                    background: linear-gradient(180deg, #4F0362 0%, #0F1733 64.17%, #29222F 100%);
                }
                &_4 {
                    background: linear-gradient(180deg, #806400 0%, #33230F 55.77%, #132210 100%);
                }
                &_5 {
                    background: linear-gradient(180deg, #036251 0%, #0F2633 64.17%, #29222F 100%);
                }
                &_6 {
                    background: linear-gradient(180deg, #80001F 0%, #330F2D 64.17%, #2F222B 100%);
                }
            }
        }
        &__preview, &__caption {
            background: rgba(38, 38, 38, .85);
        }
        &_settings {
            .chat__overlay {
                background: rgba(19, 19, 19, 0.65);
            }
        }
        &_mobile:not(.chat_support) {
            .chat__header {
                background: rgba(19, 19, 19, 0.8);
            }
            .chat__input {
                box-shadow: 0 -10px 25px #000000;
            }
        }
    }
    .panel {
        &::after {
            background: linear-gradient(0deg, #272727 34.29%, rgba(255, 255, 255, 0) 100%);
        }
        &__handler {
            &::before {
                background: #000;
                border: 1px solid #131313;
            }

        }
    }
    .tabs {
        &__link {
            background: linear-gradient(
                    357.97deg,
                    rgba(19, 19, 19, 0.9) 2.22%,
                    rgba(19, 19, 19, 0) 98.82%
            );
        }
    }
    .page {
        &_payment {
            .page__heading, .page__section {
                color: var(--color-white);
            }
            .page__list-price_bg {
                background: none;
            }
        }
        &_mobile {
            .page__filter {
                .tag, .button_link {
                    background: rgba(39, 39, 39, .8);
                }
            }
        }
    }
    .ui-radio_payment {
        &:checked + .label, &:not(checked) + .label:hover {
            background: #262626;
        }
    }
    .status {
        &_badge {
            background: rgba(39, 39, 39, 0.1);
        }
    }
    .header {
        &_mobile {
            background: rgba(19, 19, 19, 0.80);
            backdrop-filter: blur(5px);
            box-shadow: none;
        }
    }
    .button_back {
        background: #272727;
    }
    .button_like.is-active {
        background-image: url(../images/icons/heart-white.svg);
    }

    .letter {
        &__textarea {
            color: #f2f2f2;
        }
        &__footer {
            .chat__dropdown {
                .dropdown__list {
                    &::after, &::before {
                        content: none
                    }
                }
            }
        }
        .button_rounded {
            span {
                color: #fff;
            }
        }
    }

    .images__body, .chat__letter_images {
        &::after {
            background: rgba(26, 26, 26, .9);
        }
    }

    .button {
        &_white {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        }
    }

    .message_letter-images {

        &::before {
            border: 2px solid #262626;
            background: rgba(74, 74, 74, 0.50);
        }

        .message__date, .button_link {
            background: #262626;
        }

        .button_link {
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
        }
    }

}
.no-webp {
    .mode-dark {
        .chat {
            &_support {
                .chat__wrapper {
                    background-image: url(../images/support-chat/dark/bg-dark.jpg);
                }
            }
        }
    }
}

@include create-mq($grid__bp-sm - 1, "max") {
    .mode-dark {
        .chat {
            &_support {
                .chat__wrapper {
                    background-image: url(../images/support-chat/mobile/dark/bg-dark.webp);
                }
            }
        }
    }
    .no-webp {
        .mode-dark {
            .chat {
                &_support {
                    .chat__wrapper {
                        background-image: url(../images/support-chat/mobile/dark/bg-dark.jpg);
                    }
                }
            }
        }
    }
}
