.description {
    &__item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    &__key,
    &__value {
        display: inline-block;
    }

    &__key {
        padding: 5px 10px;
        margin-right: 8px;
        min-width: 80px;
        font-size: toRem(14px);
        background: rgba(255, 255, 255, 0.15);
        border-radius: 20px;
        white-space: nowrap;
    }

    &__value {
        @extend .text-truncate;
        font-weight: 500;
        font-size: toRem(14px);
        white-space: nowrap;
        line-height: 140%;

        .icon {
            margin-right: 5px;
        }
    }

    &_tariffs {
        .description__item {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            background: rgba(197, 197, 197, 0.15);
            border-radius: 5px;
            font-size: toRem(20px);

            &:not(:last-child) {
                margin-bottom: toRem(15px);
            }

            .icon {
                margin-right: 10px;
            }
        }

        .description__credit {
            margin: 0 0 0 auto;
            @extend .text-gradient;
            font-weight: 700;
        }
    }

    &_personal {
        .description__item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            &:nth-child(-n+4) {
                .description__key {
                    background: rgba(255,99,249, .15);
                }
            }

            &:nth-child(n+5):nth-child(-n+8) {
                .description__key {
                    background: rgba(118,109,290, .15);
                }
            }

            &:nth-child(n+9):nth-child(-n+12) {
                .description__key {
                    background: rgba(0,220,220, .15);
                }
            }
        }

        .description__key {
            min-width: 84px;
            margin-right: 20px;
            font-size: toRem(14px);
            font-weight: 400;
            background: rgba(197, 197, 197, .15);
        }

        .description__value {
            font-weight: 700;
            font-size: toRem(16px);
            color: var(--heading-color);
        }

        &.description_first {
            .description__item {
                .description__key {
                    background: rgba(255,99,249, .15);
                }
            }
        }

        &.description_second {
            .description__item {
                .description__key {
                    background: rgba(118,109,290, .15);
                }
            }
        }

        &.description_third {
            .description__item {
                .description__key {
                    background: rgba(0,220,220, .15);
                }
            }
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .description {
        &__key {
            max-width: 60px;
            font-size: toRemSm(10px);
        }

        &__value {
            font-size: toRemSm(12px);
        }

        &_tariffs {
            .description__item {
                font-size: toRemSm(12px);
            }
        }

        &_personal {
            .description__key {
                min-width: 64px;
                margin-right: 10px;
                font-size: toRemSm(12px);
            }

            .description__value {
                font-size: toRemSm(12px);
            }
        }
    }
}
