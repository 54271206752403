.custom-select {
    position: relative;
    user-select: none;

    &__option {
        display: flex;
        align-items: center;

        &_selected {
            position: relative;
            @extend .ui-select;
        }
    }

    &__options {
        @extend .ui-scroll;
        position: absolute;
        top: 105%;
        left: 0;
        right: 0;
        max-height: 150px;
        overflow-y: auto;
        background-color: var(--nav-bg);
        border: 1px solid var(--button-color3);
        border-radius: 10px;
        z-index: 100;
        color: var(--light-gray);

        .custom-select__option {
            padding: 0.5rem 1rem;

            &:hover {
                background-color: var(--chat-message-bg);
            }
        }
    }

    .icon {
        margin-right: 5px;
    }
}
