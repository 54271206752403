.icon-box {
    display: inline-block;
    width: 68px;
    height: 68px;
    padding: 6px 5px 12px;
    background: var(--icon-box-bg);
    border-radius: 5px;
    text-align: center;

    &__title {
        display: block;
        margin-top: 5px;
        @extend .title;
        font-size: toRem(12px);
    }

    .icon {
        width: 34px;
        height: 34px;
    }
}
