.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;

    &:not(.loader_local) {
        position: fixed;
        z-index: 50000;
        background: rgba(255, 255, 255);
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
    }

    &_local {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;

        .loader__spinner {
            width: 20px;
            height: 20px;

            &::before, &::after {
                width: 20px;
                height: 20px;
                animation: rotate-local 1.5s cubic-bezier(0.4, 0, 1, 1) infinite;
            }
            &::after {
                background: var(--color-light2);
                animation-delay: .85s;
            }
        }
    }

    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-top: 120px;
        font-size: toRem(20px);
        font-weight: 500;
        z-index: 10;
    }

    &__spinner {
        position: relative;
        width: 50px;
        height: 50px;

        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            animation: rotate 1.5s cubic-bezier(0.4, 0, 1, 1) infinite;
        }

        &::before {
            background: var(--gradient);
        }

        &::after {
            background: var(--color-light3);
            animation-delay: .85s;
        }
    }

}

@keyframes rotate {
    0%, 100% {
        transform: translate(25px, 0);
    }
    25% {
        transform: scale(0.25);

    }
    50% {
        transform: translate(-25px, 0);
        z-index: 1;
    }
    75% {
        transform: scale(1);

    }
}

@keyframes rotate-local {
    0%, 100% {
        transform: translate(10px, 0);
    }
    25% {
        transform: scale(0.25);

    }
    50% {
        transform: translate(-10px, 0);
        z-index: 1;
    }
    75% {
        transform: scale(1);

    }
}
