.link {
    transition: opacity var(--transition-duration) var(--transition-easing);

    &:hover {
        opacity: 0.85;
    }
}

@media (hover: hover) {
    .link {
        cursor: pointer;
    }
}
