.ui-radio {
    vertical-align: middle;
    margin: 0 3px 0 0;
    width: 20px;
    height: 20px;
}

.ui-radio + .label {
    user-select: none;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    line-height: 20px;
}

.ui-radio:not(checked) {
    position: absolute;
    opacity: 0;
}

.ui-radio:not(checked) + .label {
    position: relative;
    padding: 0 0 0 25px;
    color: var(--gray);
    font-family: var(--font-base);
}

.ui-radio:not(checked) + .label::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    background-color: var(--color-white);
    border: 1px solid var(--gray);
    border-radius: 50%;
}

.ui-radio:not(checked) + .label::after {
    content: '';
    position: absolute;
    display: none;
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    background: var(--gradient);
    border-radius: 50%;
}

.ui-radio:checked + .label {
    color: var(--dark-gray)
}

.ui-radio:checked + .label::after {
    display: block;
}

.ui-radio:hover + .label::before {
    border: 1px solid var(--dark-gray);
}
.ui-radio_payment {
    &:not(checked) + .label {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 20px;
        background-color: var(--nav-bg);
        border-radius: 10px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        transition: box-shadow .25s ease-in-out;
        &:hover {
            filter: none;
            background: #F5F4F4;
        }
        &::after {
            top: 24px;
            left: 22px;
        }
        &::before {
            top: 22px;
            left: 20px;
            border-color: #A7A1A1;
        }
    }
    &:checked + .label {
        background: #F5F4F4;
    }
    + .label > .label__title {
        padding: 0 0 0 25px;
        color: var(--button-color1)
    }
    + .label > .label__subtitle {
        margin-left: auto;
        img {
            margin-left: 5px;
        }
    }
}
@include create-mq($grid__bp-xl - 1, "max") {
    .ui-radio_payment {
        &:not(checked) + .label,
        & + .label > .label__title,
        & + .label > .label__subtitle  {
            display: block;
        }
    }
    .ui-radio_payment:not(checked) + .label {
        height: auto;
        padding: 10px;
    }
    .ui-radio_payment + .label > .label__title {
        margin: 10px 0;
        padding-left: 40px;
    }
}
