.images {
    padding: 20px 25px;
    display: flex;
    flex-direction: column;

    &__list {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 20px;
    }

    &__body {
        position: relative;
        padding: 30px 25px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;

        &::after {
            content: '';
            position: absolute;
            background-color: rgba(255, 255, 255, .8);
            backdrop-filter: blur(2.5px);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            z-index: 10;
        }
    }

    &__container, &__actions {
        position: relative;
        z-index: 20;
    }

    &__item {
        position: relative;
        width: 33.3%;
        max-height: 110px;
        max-width: 110px;

        &::after, &::before {
            content: '';
            position: absolute;
            z-index: 25;

        }

        &::before {
            top: 50%;
            left: 50%;
            width: 32px;
            height: 32px;
            background: url(../images/icons/checkmark.svg) no-repeat center;
            background-size: contain;
            transform: translate(-50%, -50%);
            opacity: 0;
        }

        &::after {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            background: rgba(11, 11, 11, 0.40);
            transition: opacity .25s ease-out;
        }

        &.is-checked, &:hover {
            &::after {
                opacity: 0;
            }
        }

        &.is-checked {
            &::before {
                opacity: 1;
            }
        }
    }

    &__actions {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__thumbnail {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@include create-mq($grid__bp-xl, "min") {
    .images {

        &__item {
            max-height: 145px;
            max-width: 145px;
        }
    }
}

@include create-mq($grid__bp-2xl, "min") {
    .images {

        //&__body {
        //    padding: 40px 125px;
        //}
    }
}

@include create-mq($grid__bp-3xl, "min") {
    .images {

        &__item {
            max-height: 185px;
            max-width: 185px;
        }
    }
}

@include create-mq($grid__bp-4xl, "min") {
    .images {
        padding: 60px 35px;

        &__body {
            padding: 40px 80px;
        }

        &__item {
            &::before {
                width: 48px;
                height: 48px;
            }
        }

        &__actions {
            margin-top: 40px;
        }
    }
}
