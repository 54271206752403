.form {
    &__input {
        position: relative;
    }

    &__buttons {
        .button + .button {
            margin-left: 10px;
        }
    }

    &__heading,
    &__sub-heading {
        line-height: 1.1;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
    }

    &__heading {
        margin: 0 0 10px;
        font-size: toRem(40px);
    }

    &__sub-heading {
        margin: 0 0 20px;
        font-size: toRem(20px);
        background-image: var(--gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }

    &__row {
        margin-bottom: 15px;
    }

    &__row-wrapper {
        position: relative;
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        z-index: 1;
    }

    &__label {
        font-size: toRem(14px);
        color: var(--gray);
    }

    &__link {
        background-image: var(--gradient);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }

    &__footer {
        .form__link {
            font-weight: 500;
        }
        &.is-flex {
            justify-content: space-between;
            align-items: center;
            .button + .button {
                margin-left: 5px;
            }
        }
    }

    &_horizontal {
        .ui-input,
        .ui-textarea,
        .ui-select,
        .button,
        .form__text,
        .form__error {
            width: 100%;
        }

        .form__label {
            display: block;
            margin-bottom: 5px;
        }

        .form__text {
            display: block;
            margin-top: 20px;
            text-align: center;
            color: var(--light-gray);
        }

        .is-flex {
            justify-content: space-between;

            .ui-select,
            .custom-radio {
                flex: 1;

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .form__link {
                margin-left: 5px;
            }
        }
    }

    .is-error {
        .ui-input, .ui-textarea, .ui-select {
            border-color: var(--color-red);
        }
    }
    .form__error {
        display: block;
        margin-top: 5px;
        color: var(--color-red);
    }
}

@media (hover: hover) {
    .form__link:hover {
        cursor: pointer;
    }
}
