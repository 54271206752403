.mockup {
    position: relative;
    overflow: hidden;
    .video_landing {
        clip-path: polygon(1% 1%, 99% 0%, 99% 99%, 0% 99%);
    }

    &_mobile {
        width: 100%;
        padding-top: 188%;
        margin: auto;
    }

    &_desktop {
        display: none;
        top: 10px;
        left: 10px;
        width: 606px;
        height: 346px;
    }

    @include create-mq($grid__bp-lg, 'min') {
        &_mobile {
            display: none;
        }
        &_desktop {
            display: block;
        }
    }
}

@include create-mq($grid__bp-xs, "min") {
    .mockup_mobile {
        width: 340px;
        height: 630px;
        padding-top: 0;
    }
}
@include create-mq($grid__bp-4xl, "min") {
    .mockup_desktop {
        width: 1006px;
        height: 558px;
        left: -60px
    }
}

@include create-mq($grid__bp-5xl, "min") {
    .mockup_desktop {
        left: 10px;
    }
}
