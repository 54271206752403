.list {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1;

    &_payment, &_tags {
        display: inline-flex;

        .list__item {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }

    &_partners {
        .list__item {
            &:first-child {
                margin-bottom: 1rem;
            }
        }
    }

    &_features {
        @extend .grid;
        @extend .grid_3;

        .list__item {
            position: relative;
            text-align: center;

            .animation {
                position: absolute;
                z-index: 10;
            }
        }
    }

    &_page {
        .list__item {
            margin-bottom: 5px;
            border-bottom: 1px solid rgba(197, 197, 197, 0.4);
        }

        .list__link {
            display: block;
            padding: 16px 0;
            font-size: toRem(18px);
            font-weight: 500;
            color: var(--heading-color);
            text-decoration: none;
        }
    }

    &_sort {
        display: flex;
        align-items: center;

        .list__item {
            &:not(:last-child) {
                margin-right: 5px;

                .list__link {
                    &::after {
                        content: "/";
                        margin-left: 5px;
                    }
                }
            }

            &.is-active {
                .list__link {
                    color: var(--dark-gray);
                }
            }
        }

        .list__link {
            display: block;
            font-weight: 500;
            color: var(--gray);
        }
    }

    &_emoji {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
    }

    &_hobbies {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        align-items: center;
    }

    &_help, &_marked {
        margin-top: 10px;
        margin-bottom: 20px;
        list-style: none;
        line-height: 1.8;
        text-align: left;

        li {
            position: relative;
            padding: 0 0 0 25px;

            &::after {
                content: "";
                position: absolute;
                display: block;
                top: 5px;
                left: 0;
                width: 18px;
                height: 22px;
                background: url(../images/icons/triangle-gradient.svg) no-repeat center center;
                background-size: contain;
            }
        }

        .list__link {
            text-decoration: none;
            cursor: pointer;
        }
    }

}

@include create-mq($grid__bp-xl - 1, "max") {
    .list_features {
        .media__content {
            display: none;
        }
    }
}

@include create-mq($grid__bp-lg - 1, "max") {
    .list_features {
        .list__item {
            .animation {
                transform: rotate(90deg);
            }

            &:nth-child(1) {
                .animation {
                    right: 0;
                }
            }

            &:nth-child(2) {
                .animation {
                    left: 0;
                }
            }
        }
    }
}

@include create-mq($grid__bp-md - 1, "max") {
    .list_features {
        .list__item {
            &:nth-child(1) {
                .animation {
                    right: -100px;
                }
            }

            &:nth-child(2) {
                .animation {
                    left: -100px;
                }
            }
        }
    }
}

@include create-mq($grid__bp-lg, "min") {
    .list_payment {
        .list__icon {
            width: 40px;
        }
    }
    .list_features {
        .list__item {
            .animation {
                top: -22px;
                right: 0;
                margin-right: -140px;
            }

            &:nth-child(2) {
                .animation {
                    top: 15%;
                }
            }
        }
    }
    .list_page {
        .list__link {
            padding: 20px 0;
            font-size: toRem(20px);
        }
    }
}

@include create-mq($grid__bp-md, "min") {
    .list {
        &_emoji {
            grid-template-columns: repeat(8, 1fr);
            gap: 18px;
        }
    }
}

@include create-mq($grid__bp-xl, "min") {
    .list {
        &_payment {
            .list__icon {
                width: 50px;
            }
        }

        &_help {
            margin-top: 20px;
            margin-bottom: 50px;
        }
    }
}

@media (min-width: 1280px) and (max-width: 1679px) {
    .list {
        &_emoji {
            grid-template-columns: repeat(6, 1fr);
            gap: 10px;
        }
    }
}
