.post {
    &__heading {
        line-height: 1.1;
    }

    &__poster {
        video,
        audio {
            width: 100%;
            max-width: 100%;
        }

        &_locked {
            position: relative;
            overflow: hidden;

            .poster__media {
                filter: blur(15px);
            }
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        border-radius: 10px;
        z-index: 10;

    }

    &__overlay-content {
        margin: auto;
        text-align: center;

        &, .title {
            color: var(--color-white);
        }

        .title {
            margin: 5px 0 20px;
            text-transform: uppercase;
        }

        .sub-title {
            display: block;
            margin-top: toRem(10px);
            font-weight: 500;
        }

        .button {
            height: 40px;
            padding-left: 25px;
            padding-right: 25px;
            line-height: 38px;
            font-size: toRem(18px);
            text-transform: none;
        }
    }

    &__category {
        text-transform: uppercase;

        &::before {
            content: '/';
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__body {
        line-height: 1.4;
    }

    &__content {
        display: flex;
    }

    &__button {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
    }

    &__icon {
        margin-right: 10px;
        position: relative;
        top: -2px;
    }

    &__comment {
        display: flex;
        word-break: break-word;

        .avatar {
            margin-right: 15px;
        }
    }

    &__input, &__textarea {
        width: 100%;
    }

    &__input {
        .form__buttons {
            position: absolute;
            top: 5px;
            right: 14px;
            display: flex;
            align-items: center;
            z-index: 10;
        }
    }

    &__textarea {
        appearance: none;
        flex: 1;
        height: 50px;
        padding: 16px 90px 2px 30px;
        background: var(--body-bg);
        color: var(--color-text);
        box-shadow: 0 10px 25px rgba(136, 136, 136, 0.1);
        border-radius: 50px;
        border: 0;
        resize: none;
    }
    &__comments {
        .title {
            margin-top: 1.125rem;
            margin-bottom: 1.25rem;
        }
    }
    &__comment-author, &__comment-date {
        display: block;
        margin-bottom: 5px;
    }
    &__comment-author {
        font-weight: 500;
    }
    &__comment-date {
        font-size: toRem(14px);
    }
    &__comments-media {
        display: flex;
        margin: 1rem 0;
        &:not(:last-child) {
            border-bottom: 2px solid rgba(197, 197, 197, 0.2);
        }
    }
    &__comments-body {
        flex: 1;
        margin-left: 10px;
    }
    &__editor {
        position: relative;
        .form {
            &__buttons {
                display: flex;
                align-items: center;
                margin: 10px 0;
                .button + .button {
                    margin-left: 10px;
                }
            }
        }
    }

    &:not(.post_single) {
        .post__heading {
            @extend .text-truncate;
        }
    }

    &_single {
        .post__poster {
            margin-top: 30px;
        }
    }

    &_blog, &_stories {
        .post__heading {
            font-size: toRem(32px);
            line-height: 1.1;
        }

        .post__heading {
            margin-top: 20px;
        }

        .post__meta-data {
            position: relative;
            display: inline-flex;
            align-items: center;
            font-size: toRem(14px);
            background-color: var(--nav-bg);
            padding-right: 20px;
            z-index: 10;
        }
        .post__meta {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                margin-top: -1px;
                right: 0;
                width: 100%;
                height: 1px;
                background-color: rgba(197, 197, 197, 0.6);
            }
        }
    }

    &_stories {
        position: relative;
        .poster {
            &::after {
                padding-bottom: 60.24%;
            }
        }
        .post__body {
            position: relative;
            padding-bottom: 30px;
        }

        .post__info {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: toRem(14px);
            line-height: 1;
        }

        .post__content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        .post__short-text {
            margin-bottom: 0;
            @extend .text-truncate;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .post__view {
            margin-left: 20px;
            display: inherit;
            align-items: center;
        }

        .post__poster {
            margin-bottom: 20px;
        }

        .post__form-title {
            margin-bottom: 20px;
            font-size: toRem(20px);
            color: var(--color-text);
        }

        .post__like {
            margin-left: 10px;
        }

        .post__less {
            position: absolute;
            bottom: 10px;
            right: 0;
            z-index: 10;

            .button__icon {
                color: var(--color-text);
                transform: rotate(180deg);
            }
        }
        .button_edit {
            position: absolute;
            right: 0;
            top: 20px;
            z-index: 10;
        }
        .slider_portfolio {
            margin: 20px 0;
        }
    }

    &_blog {
        .button_more {
            margin-top: 20px;
        }

        .post__poster {
            margin-bottom: 40px;
        }

        .post__date {
            margin-right: 20px;
        }
    }

}
@media (min-width: 1280px) and (max-width: 1919px)  {
    .post {
        &__overlay-content {
            .button {
                height: 30px;
                padding-left: 25px;
                padding-right: 25px;
                line-height: 28px;
                font-size: toRemSm(12px);
            }
            .title {
                font-size: toRemSm(14px);
            }
        }
        &_stories {
            font-size: toRemSm(10px);
            .post__heading {
                font-size: toRemSm(20px);
            }
            .post__form-title, .post__comments-title {
                font-size: toRemSm(12px);
            }
            .post__textarea {
                height: 30px;
                padding: 10px 90px 5px 20px;
                font-size: toRemSm(10px);
            }
            .avatar {
                margin-top: 0;
                width: 30px;
                height: 30px;
            }
            .post__comment-date, .post__info, .button_link, .post__meta-data  {
                font-size: toRemSm(10px);
            }
            .icon_send {
                width: 30px;
                height: 30px;
            }
            .post__input {
                .form__buttons {
                    top: 0
                }
            }
        }
    }
}
@include create-mq($grid__bp-md - 1, "max") {
    .post_blog {
        .post__meta {
            margin-top: 30px;
            padding-top: 20px;
            border-top: 1px solid rgba(197, 197, 197, 0.6);
            &::after {
                content: none
            }
            .post__view {
                margin: 0 0 0 auto;
            }
        }
        .post__meta-data {
            display: flex;
            padding-right: 0;
        }
    }
    .post_stories {
        .post__poster {
            margin-right: -15px;
            margin-left: -15px;
        }
        .post__media, .post__overlay {
            border-radius: 0;
        }
        .post__content {
            flex-direction: column;
        }
        .post__info {
            margin-bottom: 12px;
            order: -1;
        }
        .post__short-text {
            margin-bottom: 12px;
        }
    }
}
@include create-mq($grid__bp-sm - 1, "max") {
    .post_stories {
        .post__poster {
            &::after {
                padding-bottom: 100%;
            }
        }
    }
}
@include create-mq($grid__bp-xl, "min") {
    .post {
        &__editor {
            .form {
                &__footer {
                    display: flex;
                    align-items: center;
                }
                &__buttons {
                    margin: 0 0 0 auto;
                }
            }
        }
        &_stories {
            .post__info {
                max-width: toRem(130px);
                flex: 0 0 toRem(130px);
                margin-left: toRem(40px);
            }
            .post__short {
                position: relative;
                top: toRem(5px);
                display: flex;

                .button_link {
                    margin-left: toRem(10px);
                }
            }

        }
    }
}
