.image-cropper {
    text-align: center;

    &__container {
        width: 100%;

        .cropper-container {
            margin: auto;
        }

        img {
            display: block;
            max-width: 100%;
            margin: auto;
        }
    }

    &__buttons {
        margin-top: 1rem;
        display: inline-flex;
        align-items: center;

        .button:not(.image-cropper__button) {
            min-width: 120px;
            margin: 0 10px;
            text-transform: none;
        }
    }

    &__button {
        width: toRem(30px);
        display: inline-block;
        background-color: #6366F1;
    }
}

.cropped-image img {
    max-width: 100%;
}
