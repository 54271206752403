.carousel {
    position: absolute;
    width: 492px;
    height: 492px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 30;


    &__wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 30;
    }

    &__slide {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
       /* z-index: 30;
        width: 492px;
        position: absolute;
        display: block;*/
    }

    /*&__slide_1 {
        top: 111px;
        left: 111px;
    }*/
}

@media (min-width: 430px) and (max-width: 1279px) {


    .carousel {
        width: 246px;
        height: 246px;


      /*  &__slide {
            width: 246px;
        }

        &__slide_1 {
            top: 56px;
            left: 56px;
        }*/
    }
}

@media (max-width: 429px) {
    .carousel {
        width: 246px;
        height: 246px;
        

       /* &__slide {
            width: 246px;
        }

        &__slide_1 {
            top: 56px;
            left: 56px;
        }*/
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .carousel {
        width: 369px;
        height: 369px;


       /* &__slide {
            width: 369px;
            display: block;
        }

        &__slide_1 {
            top: 84px;
            left: 84px;
        }*/
    }
}
