.price {
    @extend .list;

    &:not(.price_mobile):not(.price_starting) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 30px;
        margin-bottom: 40px;
    }

    &:not(.price_mobile) {
        .price__header {
            height: 80px;
            width: 240px;
            padding: 20px;
            border-radius: 0 0 10px 10px;
        }

        .price__button {
            margin-top: auto;
            width: 100%;
        }

        .price__description {
            padding-bottom: 15px;
            border-bottom: 1px solid  rgba(216, 216, 216, 0.75);
            margin-bottom: 26px;
        }

        .price__body {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 40px 15px 21px;
            text-align: center;
        }

        .price__features {
            padding: 0 15px;
            text-align: left;
        }

        .price__item {
            display: flex;
            flex-direction: column;
            background: var(--nav-bg);
            box-shadow: 0 10px 35px rgba(126, 126, 126, 0.1);
            border-radius: 10px;
            transition: transform .25s ease-in-out;
            will-change: transform;
            width: 270px;
            height: 481px;
            //margin-bottom: 40px;
            margin-top: 27px;

            &:hover {
                transform: scale(1.1);
            }

            &_base {
                .price__header {
                    background-image: url(../images/payment/header-bg1.svg);
                    align-self: center;
                    margin-top: -27px;
                }

            }

            &_expert {
                .price__header {
                    background-image: url(../images/payment/header-bg2.svg);
                    align-self: center;
                    margin-top: -27px;
                }
            }

            &_pro {
                .price__header {
                    background-image: url(../images/payment/header-bg3.svg);
                    align-self: center;
                    margin-top: -27px;
                }
            }

            &_top {
                .price__header {
                    background-image: url(../images/payment/header-bg4.svg);
                    align-self: center;
                    margin-top: -27px;
                }
            }
        }
    }

    &__header, &__desription {
        text-align: center;
    }

    &__header {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        text-align: center;
        color: var(--color-white);
    }

    &__title {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: toRem(20px);
        font-weight: 500;
        text-transform: uppercase;
        color: var(--color-white);
    }

    &__icon, &__value, &__credit {
        display: block;
    }

    &__title, &__value {
        text-transform: uppercase;
    }

    &__icon {
        margin: auto;
    }

    &__value {
        margin-bottom: 5px;
        font-size: toRem(42px);
        font-weight: 700;
        color: var(--heading-color)
    }

    &__credit {
        font-size: toRem(24px);
        font-weight: 600;
        line-height: 140%;
    }

    &__button {
        > span {
            text-transform: none;
        }

        &::after {
            content: none;
        }
    }

    &__features {
        margin-bottom: 30px;
        font-size: toRem(14px);
        line-height: 16px;

        .list__item {
            display: flex;
            align-items: center;
            gap: 5px;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    &__item {

        .price__features-title {
            font-size: 1rem;
            font-weight: 700;
        }



        &_base {
            .svg-icon {
                color: #00F4F4;
            }

            .price__features-title {
                &, .svg-icon {
                    color: #808080;
                }
            }

            .price__credit {
                color: #91A2BB;
            }

            .price__button {
                background: var(--gradient-price-base);
            }
        }

        &_expert {
            .svg-icon, .price__features-title {
                color: #3B7EFF;
            }

            .price__credit {
                color: #2799D3;
            }

            .price__button {
                background: var(--gradient-price-expert);
            }

        }

        &_pro {

            .svg-icon, .price__features-title {
                color: #712FFF;
            }
            .price__credit {
                color: #825AF9;
            }

            .price__button {
                background: var(--gradient-price-pro);
            }

        }

        &_top {

            .svg-icon, .price__features-title {
                color: #F601FB;
            }

            .price__credit {
                color: #E5359B;
            }

            .price__button {
                background: var(--gradient-price-top);
            }
        }

        &_starting {
            //.svg-icon, .price__credit {
            //    color: #A09067;
            //}

            .price__features {
                //.list__item:last-child {
                //    color: #A09067;
                //}
            }

            .price__button {
                //background: linear-gradient(197deg, #FFE193 0%, #A09067 100%);
                background: var(--gradient-price-starting);
            }
        }
    }

    &__tab {
        position: relative;
        margin-bottom: 25px;
        background: var(--nav-bg);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
        overflow: hidden;
        min-height: 81px;

        &::after {
            content: '';
            position: absolute;
            width: 10px;
            height: 100%;
            top: 0;
            right: 0;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 10;
        }

        input {
            position: absolute;
            opacity: 0;
            z-index: -1;

            &:checked + .price__tab-label {
                box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
            }

            &:checked ~ .price__tab-content {
                max-height: 100vh;
                padding: 20px;
            }
        }

        &.price__item_base {
            &::after {
                background-image: url("../images/payment/right-mobile-bg1.svg");
            }
        }
        &.price__item_expert {
            &::after {
                background-image: url("../images/payment/right-mobile-bg2.svg");
            }
        }
        &.price__item_pro {
            &::after {
                background-image: url("../images/payment/right-mobile-bg3.svg");
            }
        }
        &.price__item_top {
            &::after {
                background-image: url("../images/payment/right-mobile-bg4.svg");
            }
        }
        &.price__item_starting {
            &::after {
                background-image: url("../images/payment/right-mobile-bg5.svg");
            }
        }
    }

    &__tab, &__tab-label {
        border-radius: 10px
    }

    &__tab-label {
        position: relative;
        display: flex;

        .price__header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 140px;
            flex: 0 0 140px;
            padding: 0 10px;
            border-radius: 10px 0 0 10px;
        }

        .price__icon {
            margin: 0;
        }

        .price__description {
            padding: 5px 30px 5px 35px;
            flex: 1 1 auto;
        }
    }

    &__tab-content {
        max-height: 0;
        text-align: center;
        background: var(--nav-bg);
        transition: all 0.35s ease-in-out;

        .price__button {
            width: 240px;
        }

        .price__features {
            .list__item {
                justify-content: center;
            }
        }
    }


    &_starting:not(.price_mobile) {
        .price__item_starting {
            .price__header {
                background-image: url(../images/payment/header-bg5.svg);
            }
        }

        .price__features {
            .list__item:last-child {
                text-align: left;
            }
        }
    }

    &_starting {
        display: block;
    }


    &_mobile {

        .price__item {
            margin-bottom: 30px;
        }

        &.price_starting {
            .price__header {
                background-image: url(../images/payment/header-mobile-bg5.svg);
            }
        }

        .price__item_base {
            .price__header {
                background-image: url(../images/payment/header-mobile-bg1.svg);
            }
        }

        .price__item_expert {
            .price__header {
                background-image: url(../images/payment/header-mobile-bg2.svg);
            }
        }

        .price__item_pro {
            .price__header {
                background-image: url(../images/payment/header-mobile-bg3.svg);
            }
        }

        .price__item_top {
            .price__header {
                background-image: url(../images/payment/header-mobile-bg4.svg);
            }
        }

        .price__value {
            font-size: toRem(40px);
        }

        .price__item_starting {
            .price__header {
                background-image: url(../images/payment/header-mobile-bg5.svg);
            }
        }
    }

}


@include create-mq($grid__bp-4xl, "min") {
    .price:not(.price_mobile) {

        .price__item {
            height: 480px;
        }

        .price__features {
            padding: 0 25px;
        }

        .price__body {
            padding: 40px 15px 30px;
        }
    }
}

