.ui-textarea {
    line-height: 1.2;
    background-color: var(--nav-bg);
    border: 1px solid var(--button-color3);
    padding: 15px;
    border-radius: 10px;
    font-size: 1rem;
    color: var(--color-text);

    &_md {
        min-height: 200px;
    }

    &::placeholder {
        color: var(--light-gray);
    }
}
