.article {
    .heading {
        margin-bottom: toRem(24px);
        line-height: 1.4;

        &:not(.heading_center) {
            text-align: left;
        }
    }
}
