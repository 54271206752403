.intro {
    position: relative;
    height: calc(var(--vh, 1vh) * 100);

    &__wrapper {
        position: relative;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        background-position: top;
    }

    &__caption {
        position: absolute;
        bottom: 13%;
        padding: 0 80px;
        //left: 4%;
        //padding-right: 75px;
        //width: 1000px;
        width: 100%;
        line-height: 1.1;
        color: var(--color-white);
        display: flex;
        flex-direction: row-reverse;
        gap: 70px;
        align-items: center;
        justify-content: center;


        p {
            margin: 0;
            font-size: toRem(35px);
            font-weight: 500;
        }

        .button {
            width: 320px;
        }
    }

    &__text {
        display: block;
        //margin-bottom: toRem(90px);
        font-size: toRem(24px);
        font-weight: 400;
        width: toRem(735px);
        font-family: Roboto, sans-serif;
    }

    &__line {
        display: block;
        margin: 10px 0;
        width: 100%;
        height: 3px;
        background: radial-gradient(
                circle at left,
                var(--color-white) 0,
                transparent 100%
        );
    }

    .button_scroll,
    .button_mute {
        position: absolute;
        z-index: 10;
    }

    .button_scroll {
        bottom: 4%;
        left: 50%;
        transform: translateX(-50%);
    }

    .button_mute {
        bottom: 35px;
        left: 30px;
    }
}
@include create-mq($grid__bp-lg - 1, 'max') {
    .intro__caption {
        width: 750px;
        margin-left: calc(50% - 375px);
        padding: 0;
    }
}
@include create-mq($grid__bp-md - 1, 'max') {
    .intro__caption {
        padding-left: 40px;
        padding-right: 40px;
        width: 100%;
        text-align: center;
        left: 0;
        margin-left: 0;

        > *:not(.button_primary) {
            display: none;
        }

        .button_primary {
            width: 100%;
            height: 48px;
            padding: 13px 20px;
            font-size: 1rem;
        }
    }

    .button_scroll {
        display: none;
    }
}

