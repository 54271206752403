.video {

    &_responsive {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        visibility: hidden;
    }

    &_landing {
        visibility: visible;
    }
}
