.contacts {
    .contact {
        padding: toRem(10px) toRem(15px);
        background: var(--body-bg);
        border-radius: 5px;
        box-shadow: 0 10px 25px rgba(136, 136, 136, 0.1);

        &__info {
            position: relative;
            padding-right: 60px;
        }

        &__avatar {
            margin-right: 0;
        }
    }

    &__text {
        margin-top: 5px;
        margin-left: 14px;
        margin-bottom: 5px;
        font-size: toRem(12px);
        color: rgba(126, 126, 126, 0.7);
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
    }

    &__item {
        position: relative;
        padding-right: toRem(10px);

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 5px;
            height: 100%;
            background: var(--gradient);
            border-radius: 5px 0 0 5px;
            visibility: hidden;
            opacity: 0;
            transform: scale(0);
            transform-origin: bottom;
            z-index: 10;
        }

        &:hover {
            padding-left: 0;

            &::after {
                visibility: visible;
                opacity: 1;
                transform: scale(1);
                transition: opacity 0.35s ease, transform 0.4s linear,
                visibility 0.25s ease;
            }
        }
    }

    &__item:not(:last-child) {
        margin-bottom: toRem(10px);
    }
}

@media (hover: hover) {
    .contacts {
        &__text,
        &__item {
            cursor: pointer;
        }
    }
}

@include create-mq($grid__bp-4xl, "min") {
    .contacts {
        &__text {
            font-size: toRem(14px);
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .contacts {
        .contact {
            padding: 10px 11px;
        }

        .contact__message {
            font-size: toRemSm(12px);
        }
    }
}
