.message {
    position: relative;

    &__triangle {
        position: absolute;
        bottom: 0;
        width: 12px;
        height: 20px;
    }

    &__footer {
        margin-top: 5px;
        display: flex;
        align-items: baseline;
    }

    &__date {
        //display: block;
        margin: 0 0 0 auto;
        font-size: toRem(12px);
        text-align: right;
    }

    &__date, &__duration {
        display: block;
        font-size: toRem(12px);
    }

    .button_sound {
        margin-right: 10px;
    }

    &__caption {
        display: flex;
        align-items: center;
    }

    &__icon {
        font-size: toRem(20px);
        font-weight: 500;
        margin-right: 15px;

        .icon {
            margin-right: 8px;
        }
    }

    &__preview {
        flex: 1;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__thumb {
        display: block;
        position: relative;
        width: 280px;
        height: 200px;
        overflow: hidden;

        img, video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
            border-radius: 5px;
        }
    }

    &__title {
        display: block;
        margin-top: 5px;
        margin-bottom: 12px;
        font-size: toRem(18px);
        font-weight: 500;
        text-transform: uppercase;
    }

    &__text {
        &:not(.is-inline-flex) {
            word-break: break-word;
        }

        &.is-inline-flex {
            .message__admin-name {
                flex: 1
            }
        }

    }

    &__information {
        margin-left: 5px;

        .message__subtitle, .message__description {
            display: block;
            font-weight: 700;
        }

        .message__subtitle {
            text-decoration: underline;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        background: rgba(19, 19, 19, 0.3);
        color: var(--color-white);
        border-radius: 5px;
        overflow: hidden;

        .button_video-play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
        }

        .message__icon {
            margin-right: 0;
        }
    }

    &__content {
        margin: auto;
        text-align: center;
    }

    &__button {
        text-transform: capitalize;
    }

    &__audio {
        position: relative;
        display: flex;
        align-items: center;

        .button_audio-play {
            margin-right: 5px;
        }

        .audio {
            display: block;
            flex: 1;
            height: 40px;
        }
    }

    &__admin-name {
        margin-right: 8px;
        float: left;
        color: var(--message-admin-color);
    }

    &_base {
        padding: 10px 20px 10px 15px;
        min-width: 190px;
    }

    &_letter, &_audio {
        padding: 10px 20px;
    }

    &_media {
        padding: 6px;
        margin-bottom: 20px;

        .message__date {
            position: absolute;
            top: 100%;
            right: 0;
        }
    }

    &_letter {
        .message__caption {
            padding-right: 80px;
        }
    }

    .button_link {
        padding: 0;
        background-image: none;
        font-size: toRem(20px);
        color: var(--color-red);

        span {
            margin: 0 5px;
        }
    }

    &_letter-images {
        position: relative;
        min-width: 80%;
        padding: 10px 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        &::before {
            //content: '';
            //position: absolute;
            //top: 0;
            //left: 0;
            //width: 100%;
            //height: 100%;
            //border-radius: 10px 10px 10px 0;
            //border: 1px solid #FAFAFA;
            //background: rgba(39, 39, 39, 0.5);
            //z-index: 0;
            display: none;
        }

        .message__icon {
            line-height: 1;

            .icon {
                margin: -1px;
            }
        }

        .message__text {
            min-height: 60px;
            display: block;
            color: #fff;
        }

        .message__triangle {
            display: none;
        }

        .message__text, .message__footer {
            position: relative;
            //z-index: 11;
        }

        .button_options > span, .button_options > span::after, .button_options > span::before {
            background-color: var(--color-white);
        }

        .message__date, .button_link {
            display: block;
            border-radius: 4px;
            background: var(--color-light);
        }

        .message__date {
            padding: 0 2px;
        }

        .button_link {
            span {
                background-image: linear-gradient(251deg, #EF821E 24.57%, #E8033A 80.45%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
            }
        }

    }
}

@include create-mq($grid__bp-xs, "min") {
    .message {
        &_audio {
            min-width: 280px;
        }

        &__duration {
            margin-left: 40px;
        }
    }
}

@include create-mq($grid__bp-xl, "min") {
    .message {
        &_letter-images {
            //min-width: 340px;
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .message {
        &__date {
            font-size: toRemSm(10px);
        }

        &__icon {
            font-size: 1rem;
            margin-right: 5px;

            .icon {
                margin-right: 0;
            }
        }

        &__thumb {
            display: block;
            position: relative;
            width: 260px;
            height: 180px;
        }

        &_letter, &_audio {
            padding: 8px 15px;
        }

        &_letter {
            .message__caption {
                padding-right: 0;
            }
        }

        &__title {
            font-size: toRemSm(14px);
        }

        &_base:not(.message_letter-images) {
            padding: 5px 10px;
        }

        .button_link {
            font-size: toRemSm(15px);
        }
    }
}
