.grid {
    &_3 {
        grid-template-columns: repeat(3, 1fr);
    }

    &_4 {
        grid-template-columns: repeat(4, 1fr);
    }

    @include create-mq($grid__bp-lg, 'min') {
        display: grid;
        grid-gap: toRem(30px);
    }
}
