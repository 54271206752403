.chat {
    &__header {
        padding: toRem(8px) toRem(16px) toRem(8px) toRem(30px);
        display: flex;
        align-items: center;
        height: 72px;
        background-color: var(--chat-default-bg);
        //border-bottom : 2px solid rgba(197,197,197, .2);

        .contact {
            &__name {
                max-width: 130px;
            }
            //
            //.contact__avatar {
            //    .status {
            //        bottom: -4px;
            //        right: -4px;
            //        left: auto
            //    }

                .status__icon {
                    border: none;
                }
            }
        }
    //}




    &__actions {
        //margin-left: auto;
        //display: flex;
        //align-items: center;
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
    }

    &__close {
        @extend .link;
        position: absolute;
        top: 5px;
        right: 12px;
        z-index: 10;
    }

    &__date {
        font-size: toRem(14px);
    }

    &__container {
        display: flex;
        flex-direction: column;
        background-color: var(--chat-default-bg);
        box-shadow: 0 10px 25px rgba(136, 136, 136, 0.1);
        border-radius: 10px;

    }

    &__wrapper {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 10px;
        background-size: cover;
        //overflow-y: auto;

        &.bg-default {
            background: var(--chat-default-bg);
        }

        &.bg-1 {
            background: radial-gradient(144.49% 119.79% at 76.71% 26.69%, rgba(211, 160, 252, 0.44) 25.39%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #FFD1EA 7.6%, rgba(245, 193, 246, 0.40) 40.2%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(143deg, #FFDBDB -5.98%, #F4ECFF 24.1%, #FFCFEC 36.33%, #FF87B2 50.8%, #FF498B 68.05%, #FF4E8D 79.46%, #FF5AD1 95.22%);
        }

        &.bg-2 {
            background: radial-gradient(180.8% 73.63% at 36.46% 33.89%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 36.96%, rgba(255, 255, 255, 0.00) 86.47%), linear-gradient(180deg, #FFEEF9 4.95%, #DEE3FD 48.96%, #DEFFFF 98.75%);
        }

        &.bg-3 {
            background: radial-gradient(206.66% 98.08% at 73.81% 34.35%, rgba(199, 251, 251, 0.62) 25.39%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAFDFF 7.6%, #FFDCFB 40.2%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(162deg, #F5FFFC 10.68%, #B0F1FF 24.34%, #C3EDFF 35.24%, #CBE8FF 43.37%, #90CAFF 51.75%, #819CFF 64.49%, #E373FF 78.93%, #FFB0F7 99.52%);
        }

        &.bg-4 {
            background: radial-gradient(206.66% 98.08% at 73.81% 34.35%, rgba(251, 249, 199, 0.62) 25.39%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #FAFFDA 7.6%, #FFEDDC 40.2%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(162deg, #E1FAFF 7.25%, #B0FFFF 24.34%, #C3FFF1 35.24%, #D2FFCB 43.37%, #FFFB90 51.75%, #FFD481 64.49%, #FFAE73 78.93%, #FFD1B0 99.52%);
        }

        &.bg-5 {
            background: radial-gradient(97.18% 80.12% at 70.63% 38.09%, rgba(217, 255, 253, 0.82) 25.39%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAFFF6 7.6%, rgba(77, 234, 234, 0.40) 40.2%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(141deg, #EF7EF1 -6.36%, #FFECFA 23.88%, #CFDAFF 36.17%, #87E2FF 50.72%, #CCFFD1 68.06%, #D5FF7B 83.51%, #FFE589 95.37%);
        }

        &.bg-6 {
            background: radial-gradient(97.18% 80.12% at 70.63% 38.09%, rgba(238, 221, 255, 0.82) 25.39%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAE4FF 7.6%, rgba(255, 255, 255, 0.40) 40.2%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(141deg, #DBDEFF -6.36%, #ECEEFF 23.88%, #FFCFD3 36.17%, #FF8787 50.72%, #FF543D 68.06%, #FF4F1B 83.51%, #FF965A 95.37%);
        }
    }

    &__body {
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        flex: 1;
        background: transparent;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--light-gray);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(197, 197, 197, .2);
            border-radius: 3px;
        }
    }

    &__list {
        padding: 0 toRem(20px);
        margin: auto 0 toRem(60px) 0;
        list-style: none;
    }

    &__footer {
        position: relative;
        padding: 0 toRem(8px) toRem(30px);
    }


    &__item {
        position: relative;
        display: flex;
        margin-bottom: toRem(20px);

        .message_base {
            max-width: 85%;
        }

        &.is-start {
            align-items: flex-end;
            justify-content: flex-start;

            .message {
                margin-left: toRem(20px);
                //background: var(--chat-message-bg);
                //color: #1c1c1c;
                border-radius: toRem(10px) toRem(10px) toRem(10px) 0;

                &__triangle {
                    transform: scaleX(-1);
                    left: -12px;
                }

                &::before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
                    right: 100%;
                    bottom: 0;
                    background: inherit;
                    position: absolute;
                    transform: scaleX(-1);
                }

                //
                //&::before {
                //    content: "";
                //    width: 20px;
                //    height: 20px;
                //    clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
                //    right: 100%;
                //    bottom: 0;
                //    background: inherit;
                //    position: absolute;
                //    transform: scaleX(-1);
                //}
                &_color1, &_color2, &_color3, &_color4, &_color6, &_color7 {
                    background: var(--chat-message-bg);
                    color: #292929;

                    .message__triangle {
                        path {
                            fill: var(--chat-message-bg);
                        }
                    }
                }

                &_color5 {
                    background: linear-gradient(94.5deg, #97E0FF 18.71%, #91AFFF 80.41%);
                    color: #000;

                    .message__triangle {
                        path {
                            fill: #97E0FF;
                        }
                    }
                }
            }

            &_letter-images {
                &, &::after {
                    border-radius: 10px 10px 10px 0;
                }
            }
        }

        &.is-end {
            margin: 0 0 toRem(20px) auto;
            justify-content: flex-end;

            .message {
                border-radius: toRem(10px) toRem(10px) 0 toRem(10px);
                margin-right: toRem(20px);
                color: var(--color-black);

                //&::after {
                //    content: "";
                //    width: 20px;
                //    height: 20px;
                //    clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
                //    left: 100%;
                //    bottom: 0;
                //    background: inherit;
                //    position: absolute;
                //}

                &__triangle {
                    right: toRem(-12px);
                }

                &_color1 {
                    background: linear-gradient(180deg, #FF8AD7 9.46%, #E168FF 22.18%, #9584FF 32.74%, #8E7BFF 55.57%, #95BFFE 67.87%, #6FFFF6 79.05%, #54FFFF 100.26%, #7BFFD7 109.46%);
                    background-size: 100% 100vh;
                    background-position: 0 95%;
                    position: relative;

                    &::after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
                        left: 100%;
                        bottom: 0;
                        background: inherit;
                        position: absolute;
                    }

                }

                &_color2 {
                    background: linear-gradient(44deg, #98E6FF -9.91%, #B6FFED 43.92%, #BEFFA7 100%), #EEE;

                    &::after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
                        left: 100%;
                        bottom: 0;
                        background: inherit;
                        position: absolute;
                    }

                }

                &_color2::after,
                &_color3::after,
                &_color7::after,
                &_color8::after {
                    background-attachment: fixed;

                }

                &_color3 {
                    background: linear-gradient(45deg, #FDFF98 -8.88%, #FFA7A7 100%), #EEE;

                    &::after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
                        left: 100%;
                        bottom: 0;
                        background: inherit;
                        position: absolute;
                    }
                }

                &_color4 {
                    background: linear-gradient(180deg, #FF8AD7 0%, #E168FF 100%);

                    &::after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
                        left: 100%;
                        bottom: 0;
                        background: inherit;
                        position: absolute;
                    }

                }

                &_color5 {
                    background: linear-gradient(180deg, #FFB5EA 0%, #E4FFB7 100%);

                    &::after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
                        left: 100%;
                        bottom: 0;
                        background: inherit;
                        position: absolute;
                    }
                }

                &_color6 {
                    background: linear-gradient(180deg, #A6AEFD -16.67%, #6FFFF6 100%);

                    &::after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
                        left: 100%;
                        bottom: 0;
                        background: inherit;
                        position: absolute;
                    }
                }

                &_color7 {
                    background: linear-gradient(44deg, #98FFEC -9.91%, #BEC5FF 43.92%, #FFA7E6 100%), #EEE;

                    &::after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
                        left: 100%;
                        bottom: 0;
                        background: inherit;
                        position: absolute;
                    }
                }

                &_color8 {
                    background: #E4E4E4;

                    &::after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
                        left: 100%;
                        bottom: 0;
                        background: inherit;
                        position: absolute;
                    }
                }


            &_letter-images {
                &, &::before {
                    border-radius: 10px 10px 0 10px;
                }
            }
        }
    }

        &_settings {
            flex-wrap: wrap;

            .button {
                width: 160px;
            }
        }
    }

    &__options {
        position: absolute;
        top: 5px;
        right: -5px;
        color: var(--color-white);
        text-shadow: 1px 1px 1px #000;
        z-index: 12;
    }

    &__message-menu {
        position: absolute;
        bottom: 90%;
        right: toRem(20px);
        z-index: 20;
    }

    &__check-icon {
        position: relative;
        top: 2px;
        margin-left: 5px;
        color: var(--gray);

        &.is-read {
            color: #3DE8FF
        }
    }

    &__input {
        position: relative;
        display: flex;
        align-items: flex-end;
        z-index: 20;
        padding-right: 6px;

        .ui-input {
            flex: 1;
            min-height: toRem(60px);
            height: auto;
            line-height: 1.5;
            padding: 18px 160px 12px 50px;
            background: var(--body-bg);
            border: 0;
            box-shadow: 0 10px 25px rgba(136, 136, 136, 0.25);
            border-radius: 50px;
            word-break: break-word;
            max-height: calc(1.5em * 11); // 10 lines
            overflow-y: auto;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--light-gray);
                border-radius: 3px;
            }

            &::-webkit-scrollbar-track {
                background-color: rgba(197, 197, 197, .2);
                border-radius: 3px;
            }

            .record {
                margin-top: 3px;
            }

        }

        .button_letter {
            margin-right: 6px;
        }
    }

    &__send {
        //padding: 10px 25px;
        width: 110px;
        height: 36px;
        font-size: toRem(14px);
        font-weight: 400;
        color: var(--color-white);
        text-transform: none;
        border-radius: 50px;
    }

    &__attachment {
        position: absolute;
        bottom: 18px;
        left: 78px;
    }

    &__emoji {
        margin-right: 12px;

        .button__icon {
            margin: 0;
        }
    }

    &__buttons {
        position: absolute;
        bottom: 12px;
        right: 12px;
        display: flex;
    }

    &__typing {
        position: absolute;
        transform: translateY(-40px);
        margin-left: 20px;
    }

    &__message-blocked {
        display: block;
        padding-bottom: 10px;
        text-align: center;
        font-weight: 500;

        i {
            margin-right: 5px;
        }
    }

    &__dropdown {
        position: absolute;
        bottom: 70%;
        left: toRem(80px);
    }

    &__preview, &__caption {
        position: absolute;
        bottom: 80%;
        left: 76px;
        right: 16px;
        padding: 14px 20px 24px;
        background: rgba(255, 255, 255, .85);
        box-shadow: 0 10px 25px rgba(136, 136, 136, 0.25);
        border-radius: 5px 5px 0 0;
        font-size: 1.25rem;
        font-weight: 500;
        z-index: 12;
    }

    &__preview_record {
        .progress {
            margin-right: 12px;
        }

        .preview__close {
            top: 8px;
            right: 8px;
        }

        .preview__text {
            margin: 0 auto;
        }
    }

    &__caption {
        .button_close {
            position: absolute;
            top: 15px;
            right: 10px;
        }

        .icon_edit {
            margin-right: 5px;
        }

        &_emoji {
            padding: 20px 35px;
            max-height: 230px;
            overflow-y: auto;
        }
    }

    &__letter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        z-index: 120;

        &_images {

            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            &::after {
                content: '';
                position: absolute;
                background-color: rgba(255, 255, 255, .9);
                backdrop-filter: blur(2.5px);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
            }

            .chat__header {
                position: relative;
                //background-color: transparent;
                z-index: 20;

                .contact__title, .status {
                    padding: 2px 10px;
                    border-radius: 40px;
                    background-color: var(--bg-opacity);
                    backdrop-filter: blur(20px);
                }

                .contact__title {
                    color: var(--color-white);
                }
            }
        }

        //.chat__header {
        //    padding-right: 15px;
        //    background-repeat: no-repeat;
        //    background-size: cover;
        //    background-position: center;
            //
            //&.bg-default {
            //    background-image: url(../images/letter/header1.webp);
            //}
            //
            //&.bg-1 {
            //    background-image: url(../images/letter/header2.webp);
            //}
            //
            //&.bg-2 {
            //    background-image: url(../images/letter/header3.webp);
            //}
            //
            //&.bg-3 {
            //    background-image: url(../images/letter/header4.webp);
            //}
            //
            //&.bg-4 {
            //    background-image: url(../images/letter/header5.webp);
            //
            //    .contact__title {
            //        color: var(--color-black);
            //    }
            //
            //    .button_filled {
            //        border: 1px solid var(--color-black);
            //    }
            //}
            //
            //&.bg-5 {
            //    background-image: url(../images/letter/header6.webp);
            //}
            //
            //.contact__title {
            //    color: var(--color-white);
            //}
        //}

        //.chat__actions {
        //    .button + .button {
        //        margin-left: toRem(24px);
        //    }
        //}
    }

    //&__letter-overlay {
    //    position: absolute;
    //    top: 86px;
    //    left: 0;
    //    right: 0;
    //    bottom: 0;
    //    background: var(--body-bg);
    //    opacity: .85;
    //    z-index: 10;
    //}
    //
    //&__letter-bg {
    //    .list__link {
    //        display: block;
    //        position: relative;
    //
    //        &.is-checked, &:hover {
    //            .list__caption {
    //                display: flex;
    //            }
    //        }
    //
    //        .list__caption {
    //            display: none;
    //            position: absolute;
    //            top: 0;
    //            left: 0;
    //            right: 0;
    //            bottom: 0;
    //            background: rgba(0, 0, 0, .56);
    //        }
    //
    //        img {
    //            width: 100%;
    //        }
    //
    //        .svg-icon {
    //            color: var(--color-red);
    //            margin: auto;
    //        }
    //    }
    //}

    &.is-fixed {
        .chat__container {
            position: fixed;
            top: 0;
            height: calc(var(--vh, 1vh) * 100);
            z-index: 110;
        }
    }

    &.is-absolute {
        position: relative;

        .chat__container {
            position: absolute;
            bottom: 0;
            z-index: 110;
        }
    }

    &_main, &_settings {
        .chat__wrapper {
            > .chat__header {
                position: relative;
            }
        }
    }

    &_support {
        .chat__header {
            background: linear-gradient(221.97deg, #6D2CF7 12.18%, #7058FF 72.46%), #131313;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: toRem(10px);

            .status {
                color: #fff;
            }
        }

        .contact__title {
            color: var(--color-white);
        }

        .contact {
            position: relative;

            &__avatar {
                width: 52px;
                height: 52px;
                background-color: var(--nav-bg);
                border-radius: 5px;
                background-image: url(../images/logo/logo-mob.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 80% 80%;
            }
        }

        .status_badge {
            background: rgba(255, 255, 255, 0.80);
        }

        .chat__animated-icon {
            position: absolute;
            top: -5px;
            right: -10px;

            .wheel {
                animation-name: circle1;
                animation-duration: 10s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                transform-box: fill-box;
                transform-origin: center;
            }

            #wheel1 {
                animation-name: circle2;
            }
        }

        .chat__close {
            padding: 15px 5px;

            > span {
                display: block;
                width: 25px;
                height: 3px;
                background: var(--color-white);
            }
        }

        .chat__wrapper {
            background-image: url(../images/support-chat/light/bg-light.webp);
        }

        .chat__input .ui-input {
            padding-left: 65px;
        }

        .chat__attachment {
            left: 28px;
        }

        .chat__item {
            &.is-start {
                .message:not(.message_simple) {
                    background: var(--chat-message-bg);
                    color: var(--dark-gray2);

                    .message__triangle {
                        path {
                            fill: var(--chat-message-bg);
                        }
                    }
                }
            }

            &.is-end {
                .message:not(.message_simple) {
                    background: linear-gradient(94.4deg, #CCD0FB 35.01%, #C3B6F9 78.46%);
                    color: #000;

                    &::after {
                        background: #C3B6F9;
                    }
                }
            }
        }
    }

    &_settings {
        .chat__wrapper {
            overflow-y: auto;
        }

        .chat__body {
            position: relative;
            padding-left: 15px;
            padding-right: 15px;
            overflow: initial;
        }

        .chat__list {
            padding: 0;
            margin: 30px 0 0;
        }

        .chat__footer {
            padding-bottom: 30px;
            text-align: center;

            .button {
                width: 180px;

                > span {
                    text-transform: none;
                }
            }
        }

        .chat__overlay {
            position: absolute;
            top: 75px;
            left: 0;
            right: 0;
            padding: 35px 15px 15px;
            background: rgba(251, 251, 251, 0.85);
            box-shadow: 0 10px 25px rgba(136, 136, 136, 0.25);
            border-radius: 10px;
            z-index: 100;
        }

        .chat__list-bg {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            gap: toRem(15px)
        }

        .chat__item-caption {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            //transform: translate(-50%, -50%);
            //width: 24px;
            //height: 24px;
            //background: url(../images/icons/check-round.svg) no-repeat center;
            width: 30px;
            height: 30px;
            margin-top: -15px;
            margin-left: -15px;
            background: linear-gradient(221.38deg, #5886FF 16.48%, #D00BF0 93.67%);
            color: var(--color-white);
            border-radius: 50%;
            z-index: 10;

            .svg-icon {
                margin: auto;
            }
        }

        .chat__bg, .chat__color {
            position: relative;
            transition: box-shadow 0.3s ease-in-out;

            &.is-checked {
                .chat__item-caption {
                    display: flex;
                }
            }
        }

        .chat__bg {
            position: relative;
            padding-top: 100%;
            border-radius: 10px;
            background-repeat: no-repeat;
            background-size: cover;
            //box-shadow: 0 10px 20px rgba(19, 19, 19, 0.2);

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(19, 19, 19, 0.2);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                border-radius: 10px;
            }

            &.is-checked {
                &::after {
                    opacity: 1;
                }
            }

            &_default {
                background: var(--chat-default-bg);
            }

            &_1 {
                background: radial-gradient(144.49% 119.79% at 76.71% 26.69%, rgba(211, 160, 252, 0.44) 25.39%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #FFD1EA 7.6%, rgba(245, 193, 246, 0.40) 40.2%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(143deg, #FFDBDB -5.98%, #F4ECFF 24.1%, #FFCFEC 36.33%, #FF87B2 50.8%, #FF498B 68.05%, #FF4E8D 79.46%, #FF5AD1 95.22%);
            }

            &_2 {
                background: radial-gradient(180.8% 73.63% at 36.46% 33.89%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 36.96%, rgba(255, 255, 255, 0.00) 86.47%), linear-gradient(180deg, #FFEEF9 4.95%, #DEE3FD 48.96%, #DEFFFF 98.75%);
            }

            &_3 {
                background: radial-gradient(206.66% 98.08% at 73.81% 34.35%, rgba(199, 251, 251, 0.62) 25.39%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAFDFF 7.6%, #FFDCFB 40.2%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(162deg, #F5FFFC 10.68%, #B0F1FF 24.34%, #C3EDFF 35.24%, #CBE8FF 43.37%, #90CAFF 51.75%, #819CFF 64.49%, #E373FF 78.93%, #FFB0F7 99.52%);
            }

            &_4 {
                background: radial-gradient(206.66% 98.08% at 73.81% 34.35%, rgba(251, 249, 199, 0.62) 25.39%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #FAFFDA 7.6%, #FFEDDC 40.2%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(162deg, #E1FAFF 7.25%, #B0FFFF 24.34%, #C3FFF1 35.24%, #D2FFCB 43.37%, #FFFB90 51.75%, #FFD481 64.49%, #FFAE73 78.93%, #FFD1B0 99.52%);
            }

            &_5 {
                background: radial-gradient(97.18% 80.12% at 70.63% 38.09%, rgba(217, 255, 253, 0.82) 25.39%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAFFF6 7.6%, rgba(77, 234, 234, 0.40) 40.2%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(141deg, #EF7EF1 -6.36%, #FFECFA 23.88%, #CFDAFF 36.17%, #87E2FF 50.72%, #CCFFD1 68.06%, #D5FF7B 83.51%, #FFE589 95.37%);
            }

            &_6 {
                background: radial-gradient(97.18% 80.12% at 70.63% 38.09%, rgba(238, 221, 255, 0.82) 25.39%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAE4FF 7.6%, rgba(255, 255, 255, 0.40) 40.2%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(141deg, #DBDEFF -6.36%, #ECEEFF 23.88%, #FFCFD3 36.17%, #FF8787 50.72%, #FF543D 68.06%, #FF4F1B 83.51%, #FF965A 95.37%);
            }
        }

        .chat__color {
            display: inline-block;
            width: 70px;
            height: 70px;
            flex: 0 0 70px;
            margin-right: 15px;
            margin-bottom: 15px;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
            box-shadow: 0 10px 20px rgba(19, 19, 19, 0.2);
            border-radius: 10px;

            &_1 {
                background:linear-gradient(180deg, #FF8AD7 6.15%, #E168FF 20.46%, #9584FF 37.05%, rgba(142, 123, 255, 0.92) 41.33%, #95BFFE 57.15%, rgba(111, 255, 246, 0.86) 70.68%, #54FFFF 83.06%, #7BFFD7 100%);
            }

            &_2 {
                background: linear-gradient(44deg, #98E6FF -9.91%, #B6FFED 43.92%, #BEFFA7 100%), #EEE;
            }

            &_3 {
                background: linear-gradient(45deg, #FDFF98 -8.88%, #FFA7A7 100%), #EEE;
            }

            &_4 {
                background: linear-gradient(180deg, #FF8AD7 0%, #E168FF 100%);
            }

            &_5 {
                background: linear-gradient(180deg, #FFB5EA 0%, #E4FFB7 100%);
            }

            &_6 {
                background: linear-gradient(180deg, #A6AEFD -16.67%, #6FFFF6 100%);
            }

            &_7 {
                background: linear-gradient(44deg, #98FFEC -9.91%, #BEC5FF 43.92%, #FFA7E6 100%), #EEE;
            }

            &_8 {
                background: linear-gradient(0deg, #E4E4E4 0%, #E4E4E4 100%), #EEE;
            }
        }

        .chat__item, .chat__footer {
            .button {
                font-size: toRem(18px);
            }
        }
    }
}

.chat_mobile {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 150;

    &:not(.chat_support) {
        .chat__header {
            background-color: rgba(255, 255, 255, .8);
            backdrop-filter: blur(20px);
        }

        .chat__input {
            .button_attachment {
                width: toRem(36px);
                height: toRem(36px);

                .button__icon {
                    margin: 0;
                }
            }
        }
    }

    &.chat_support {
        .chat__attachment {
            left: toRem(16px);
        }
    }

    .chat__header {
        position: fixed;
        top: 0;
        left: 0;
        padding: 10px 15px 12px 8px;
        height: 72px;
        width: 100%;
        z-index: 110;
    }

    //.chat__actions {
    //    .button_options {
    //        margin-left: 10px;
    //    }
    //}

    .chat__container {
        height: 100%;
    }

    .chat__body {
        padding-top: 82px;

        &.is-overlay {
            position: relative;
            overflow: hidden;
        }
    }

    .chat__body-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(166, 166, 166, 0.4);
        backdrop-filter: blur(4px);
        z-index: 13;
    }

    .status_badge {
        background: var(--nav-bg);
    }

    .message_letter .message__caption {
        padding-right: 20px;
    }

    .button_favorite {
        width: 44px;
        height: 44px;

        .button__border {
            display: none;
        }
    }

    .button_favorite, .button_back, .button_letter {
        background-color: var(--button-bg1);
    }

    .button_back {
        margin-right: toRem(8px);
    }

    .button_letter {
        width: 44px;
        height: 44px;
        box-shadow: none;
        margin-right: 10px;
    }

    .chat__footer {
        padding: 0;
    }

    .chat__input {
        .ui-input {
            border-radius: 0;
            background: var(--chat-input-bg);
            min-height: 60px;
            padding: 18px 90px 10px 50px;
            background: var(--chat-input-bg);
            box-shadow: 0px -10px 25px rgba(136, 136, 136, 0.15);
            max-height: calc(1.5em * 8); // 7 lines
        }

        .button_send {
            width: 36px;
            height: 36px;

            .button__icon {
                margin: 0;
            }

            //span {
            //    display: block;
            //    line-height: 1
            //}

        }
    }

    .chat__preview, .chat__caption {
        left: 0;
        right: 0;
        bottom: 100%;
        padding: 15px;
    }

    //.chat__letter .chat__actions .button:first-child {
    //    margin-right: 0;
    //}

    .chat__avatar {
        display: none;
    }
}

[contenteditable][data-placeholder] {
    position: relative;
}
[contenteditable][data-placeholder]:empty {
    outline: 0 solid transparent;
}
[contenteditable][data-placeholder]:empty:not(:focus)::before {
    content: attr(data-placeholder);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--placeholder-color)
}
.chat__letter {
    [contenteditable][data-placeholder]:empty:not(:focus)::before {
        top: 20px
    }
}

.no-webp {
    .chat {
        &__wrapper {
            overflow-y: auto;
            &.bg-1 {
                background-image: url(../images/chat/light/bg1.jpg);
            }

            &.bg-2 {
                background-image: url(../images/chat/light/bg2.jpg);
            }

            &.bg-3 {
                background-image: url(../images/chat/light/bg3.jpg);
            }

            &.bg-4 {
                background-image: url(../images/chat/light/bg4.jpg);
            }

            &.bg-5 {
                background-image: url(../images/chat/light/bg5.jpg);
            }

            &.bg-6 {
                background-image: url(../images/chat/light/bg6.jpg);
            }
        }

        &_support {
            .chat__wrapper {
                background-image: url(../images/support-chat/light/bg-light.jpg);
            }
        }

        &_settings {
            .chat__bg {
                &_1 {
                    background-image: url(../images/chat/settings/light/bg1.jpg);
                }

                &_2 {
                    background-image: url(../images/chat/settings/light/bg2.jpg);
                }

                &_3 {
                    background-image: url(../images/chat/settings/light/bg3.jpg);
                }

                &_4 {
                    background-image: url(../images/chat/settings/light/bg4.jpg);
                }

                &_5 {
                    background-image: url(../images/chat/settings/light/bg5.jpg);
                }

                &_6 {
                    background-image: url(../images/chat/settings/light/bg6.jpg);
                }
            }
        }

        //&__letter {
        //    .chat__header {
        //        &.bg-1 {
        //            background-image: url(../images/letter/header2.jpg);
        //        }
        //
        //        &.bg-2 {
        //            background-image: url(../images/letter/header3.jpg);
        //        }
        //
        //        &.bg-3 {
        //            background-image: url(../images/letter/header4.jpg);
        //        }
        //
        //        &.bg-4 {
        //            background-image: url(../images/letter/header5.jpg);
        //        }
        //
        //        &.bg-5 {
        //            background-image: url(../images/letter/header6.jpg);
        //        }
        //    }
        //}
    }
}

@include create-mq($grid__bp-xl - 1, "max") {
    .chat {
        &__letter-bg {
            .list__item {
                margin-bottom: 10px;
            }
        }

        &__letter-overlay {
            top: 80px;
        }

        &__preview_record {
            .preview__container {
                justify-content: flex-start;
            }
        }

        .chat__attachment {
            bottom: 10px;
            left: 8px;
        }

        &.button_round {
            position: relative;
            padding: 4px;
            width: 44px;
            height: 44px;
            background: #FAFAFA;

            .button__icon {
                margin-right: 0;
            }
        }

        .letter {
            padding: 82px 15px 15px;

            .letter__subject {
                font-size: toRem(20px);
            }

            &_view, .letter__message {
                font-size: toRem(18px);
            }
        }

        &_settings {
            .chat__footer {
                padding-bottom: 30px;
            }
        }
    }
}

@include create-mq($grid__bp-sm - 1, 'max') {
    .chat {

        //&__wrapper {
        //    &.bg-1 {
        //        background-image: url(../images/chat/mobile/light/bg1.webp);
        //    }
        //
        //    &.bg-2 {
        //        background-image: url(../images/chat/mobile/light/bg2.webp);
        //    }
        //
        //    &.bg-3 {
        //        background-image: url(../images/chat/mobile/light/bg3.webp);
        //    }
        //
        //    &.bg-4 {
        //        background-image: url(../images/chat/mobile/light/bg4.webp);
        //    }
        //
        //    &.bg-5 {
        //        background-image: url(../images/chat/mobile/light/bg5.webp);
        //    }
        //
        //    &.bg-6 {
        //        background-image: url(../images/chat/mobile/light/bg6.webp);
        //    }
        //}

        &_support {
            .chat__wrapper {
                background-image: url(../images/support-chat/mobile/light/bg-light.jpg);
            }
        }
        &_settings {
            .chat__bg {
                padding-top: 160%;

                //&_1 {
                //    background-image: url(../images/chat/settings/mobile/light/bg1.webp);
                //}
                //
                //&_2 {
                //    background-image: url(../images/chat/settings/mobile/light/bg2.webp);
                //}
                //
                //&_3 {
                //    background-image: url(../images/chat/settings/mobile/light/bg3.webp);
                //}
                //
                //&_4 {
                //    background-image: url(../images/chat/settings/mobile/light/bg4.webp);
                //}
                //
                //&_5 {
                //    background-image: url(../images/chat/settings/mobile/light/bg5.webp);
                //}
                //
                //&_6 {
                //    background-image: url(../images/chat/settings/mobile/light/bg6.webp);
                //}
            }
        }

        //&__letter {
        //    .chat__header {
        //        &.bg-default {
        //            background-image: url(../images/letter/mobile/header1.webp);
        //        }
        //
        //        &.bg-1 {
        //            background-image: url(../images/letter/mobile/header2.webp);
        //        }
        //
        //        &.bg-2 {
        //            background-image: url(../images/letter/mobile/header3.webp);
        //        }
        //
        //        &.bg-3 {
        //            background-image: url(../images/letter/mobile/header4.webp);
        //        }
        //
        //        &.bg-4 {
        //            background-image: url(../images/letter/mobile/header5.webp);
        //        }
        //
        //        &.bg-5 {
        //            background-image: url(../images/letter/mobile/header6.webp);
        //        }
        //    }
        //}
    }
    .no-webp {
        .chat {

            &__wrapper {
                &.bg-1 {
                    background-image: url(../images/chat/mobile/light/bg1.jpg);
                }

                &.bg-2 {
                    background-image: url(../images/chat/mobile/light/bg2.jpg);
                }

                &.bg-3 {
                    background-image: url(../images/chat/mobile/light/bg3.jpg);
                }

                &.bg-4 {
                    background-image: url(../images/chat/mobile/light/bg4.jpg);
                }

                &.bg-5 {
                    background-image: url(../images/chat/mobile/light/bg5.jpg);
                }

                &.bg-6 {
                    background-image: url(../images/chat/mobile/light/bg6.jpg);
                }
            }

            &_support {
                .chat__wrapper {
                    background-image: url(../images/support-chat/mobile/light/bg-light.jpg);
                }
            }
            &_settings {
                .chat__bg {
                    &_1 {
                        background-image: url(../images/chat/settings/mobile/light/bg1.jpg);
                    }

                    &_2 {
                        background-image: url(../images/chat/settings/mobile/light/bg2.jpg);
                    }

                    &_3 {
                        background-image: url(../images/chat/settings/mobile/light/bg3.jpg);
                    }

                    &_4 {
                        background-image: url(../images/chat/settings/mobile/light/bg4.jpg);
                    }

                    &_5 {
                        background-image: url(../images/chat/settings/mobile/light/bg5.jpg);
                    }

                    &_6 {
                        background-image: url(../images/chat/settings/mobile/light/bg6.jpg);
                    }
                }
            }

            //&__letter {
            //    .chat__header {
            //        &.bg-default {
            //            background-image: url(../images/letter/mobile/header1.jpg);
            //        }
            //
            //        &.bg-1 {
            //            background-image: url(../images/letter/mobile/header2.jpg);
            //        }
            //
            //        &.bg-2 {
            //            background-image: url(../images/letter/mobile/header3.jpg);
            //        }
            //
            //        &.bg-3 {
            //            background-image: url(../images/letter/mobile/header4.jpg);
            //        }
            //
            //        &.bg-4 {
            //            background-image: url(../images/letter/mobile/header5.jpg);
            //        }
            //
            //        &.bg-5 {
            //            background-image: url(../images/letter/mobile/header6.jpg);
            //        }
            //    }
            //}
        }
    }
}

@include create-mq($grid__bp-sm, "min") {
    .chat {
        &__item {
            .message_base {
                max-width: 60%;
            }
        }
    }
}

@include create-mq($grid__bp-xl, "min") {
    .chat {

        //&__header {
        //    height: 64px;
        //}
        //
        //&__close {
        //    top: 6px;
        //    right: 8px;
        //
        //    .icon_close {
        //        width: 12px;
        //        height: 12px;
        //    }
        //}

        &__letter-bg {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
        }

        &_support {
            width: 800px;
        }

        &_settings {
            .chat__body {
                padding-left: 50px;
                padding-right: 50px;
            }

            .chat__list {
                margin: 80px 0 0;
            }

            .chat__overlay {
                position: absolute;
                top: 30px;
                left: 30px;
                right: 30px;
            }

        }

        [contenteditable][data-placeholder]:empty:not(:focus)::before {
            content: none;

        }
    }
}

@include create-mq($grid__bp-4xl, "min") {
    .chat {

        //&__close {
        //    top: 15px;
        //    right: 10px;
        //}

        &__header {
            height: 86px;
            padding: 8px 45px 8px 30px;
        }

        &__message-blocked {
            font-size: 1.25rem;
        }

        &_settings {
            .chat__list-bg {
                grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
            }

            .chat__bg {
                width: 170px;
            }
        }
        &__footer {
            .icon_mic-white {
                width: 13px;
                height: 18px;
            }
        }
    }
}

@keyframes circle1 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes circle2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

@media (hover: hover) {
    .chat__color, .chat__bg, .chat__letter-overlay .list__link:hover {
        cursor: pointer;
    }
    .chat__bg:hover {
        box-shadow: 0 10px 20px rgba(19, 19, 19, 0.2);
    }
    .chat__color:not(.is-checked):hover {
        box-shadow: none;
    }
}
@media (max-width: 1279px) {
    .chat {
        &__footer {

            .icon_mic-white {
                min-width: 13px;
                width: 100%;
                height: 18px;
            }
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .chat {

        &__header {
            padding: 8px 30px 8px 20px;
            height: 72px;
        }
        &__close {
            top: 8px;
            right: 8px;
        }

        &__avatar {
            display: none;
        }
        &__date {
            font-size: toRemSm(12px);
        }

        &__list {
            padding: 0 12px;
        }

        &__dropdown {
            left: 40px;
        }

        &__footer {
            padding: 0 8px 20px;
        }

        &__input {
            .ui-input {
                min-height: 35px;
                padding: 5px 90px 5px 45px;
                font-size: toRemSm(16px);

                .record {
                    margin-top: 5px;
                }
            }
        }

        &__footer {
            //padding: 0 8px 20px;

            .chat__send:not(.chat__replay) {
                padding: 0;
                width: 22px;
                height: 22px;
                border-radius: 50%;

                .button__text {
                    display: none;
                }

                .button__icon {
                    margin: 0;
                }

            }
            .icon_mic-white {
                width: 13px;
                height: 18px;
            }

            .icon_envelope,
            .icon_emoji,
            .icon_attachment {
                width: 14px;
                height: 14px;
            }

            .icon_plane {
                width: 14px;
                height: 12px;
            }

            .icon_close {
                width: 12px;
                height: 12px;
            }

            .icon_attachment, .button_letter {
                .button__icon {
                    width: 14px;
                    height: 14px;
                }
            }
        }

        &__attachment {
            bottom: 10px;
            left: 55px;
        }

        &__buttons {
            bottom: 6px;
        }

        &__preview, &__caption {
            left: 50px;
            font-size: toRemSm(14px);
            padding: 10px 14px 24px;
        }

        &__item {
            .message_base {
                max-width: 70%;
            }
        }

        &__message-menu {
            right: 10px;
        }

        &__check-icon {
            width: 1rem;
            height: 1rem;
        }

        &_settings {
            .chat__body {
                padding-left: 20px;
                padding-right: 20px;
            }

            .chat__list {
                margin: 50px 0 0;
            }
        }

    }
}
