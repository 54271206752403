.sidebar {

    &.is-fixed {
        position: fixed;
        top: 20px;
        left: 0;

        .sidebar__nav {
            .nav__container {
                top: -20px;
            }
        }
    }

    &.is-absolute {
        position: absolute;
        bottom: 20px;
        left: 0;

        .sidebar__container, .nav__container  {
            height: 100%;
        }

        .sidebar__promo {
            top: auto
        }
    }

    .button_bordered, .button_primary {

        > span {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 5px;
            padding: 0 25px 0 0;
            border-radius: 0 5px 5px 0;
            font-weight: 600;
        }
    }

    .tooltip_abs {
        top: -5px;
    }

    &__nav-button {
        width: 44px;
        height: 44px;
        border-radius: 0 5px 5px 0;

        .button_menu {
            padding: 0;
            border-radius: 0;
            margin-right: -2px;

            > span:not(.tooltip) {
                width: 24px;

                &,
                &::after,
                &::before {
                    background: var(--gradient)
                }

                &::after {
                    top: -8px;
                    width: 80%;
                }

                &::before {
                    top: 8px;
                }
            }
        }

        &:hover {

            .button_menu {
                > span:not(.tooltip) {
                    &::after {
                        top: -9px;
                    }

                    &::before {
                        top: 9px;
                    }
                }
            }
        }

        .badge {
            position: absolute;
            top: 5px;
            right: 18px;
            z-index: 20;
            background: var(--state-warning);
        }
    }

    &__button {
        position: relative;

        &.button_primary {
            padding: 0;
            .button__text {
                text-transform: none;

                > span {
                    text-transform: uppercase;
                }
            }
        }
    }

    &__promo {
        position: relative;
    }

    &__promo-container {
        position: absolute;
        top: 20px;
        //translate: 0 160px;
        left: 0;
        overflow: hidden;
        user-select: none;
    }

    &__promo-banner {
        border-radius: 5px;
    }

    &__container {
        height: calc(var(--vh, 1vh) * 100);
        background: var(--sidebar-bg);
    }

    &:not(.sidebar_mobile) {
        background: var(--sidebar-color);
        border-radius: 5px 5px 0 0;
        transform-origin: center;
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
        transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1);
        z-index: 110;

        .sidebar__actions,
        .sidebar__nav .nav__container,
        .sidebar__nav-button {
            position: fixed;
        }

        .sidebar__actions,
        .sidebar__nav-button {
            left: 100%;
            width: 140px;
        }

        .sidebar__actions {
            top: 52px;
        }

        .sidebar__button {
            left: 0;
            width: 100%;
            height: 46px;
            text-align: left;
            border-radius: 0 5px 5px 0;
            &:first-of-type {
                margin-bottom: 8px;
            }

            &:last-of-type {
                margin-top: 14px;
            }
        }

        .sidebar__nav {
            .nav__header,
            .nav__container {
                z-index: 120;
            }

            .nav__header {
                top: 0;
            }

            .nav__container {
                left: 100%;
                width: 310px;
            }
        }

        .sidebar__container {
            position: relative;
            width: 250px;
            border-radius: 5px 5px 0 0;
            box-shadow: -2px 10px 15px rgba(0, 0, 0, 0.15);
        }

        &.is-open {
            transform: translate3d(0, 0, 0);

            .sidebar__promo {
                display: none;
            }

            .sidebar__actions,
            .sidebar__nav-button {
                width: 45px;
            }

            .sidebar__nav {

                .nav__title {
                    display: none;
                }

                .nav__container {
                    left: 0;
                }
            }

            .sidebar__button {

                .button__text {
                    display: none;
                }
                .button__icon {
                    margin-right: 0;
                }
            }

            .button_bordered, .button_primary {

                > span {
                    justify-content: center;
                    gap: 0;
                    padding: 0;
                }
            }
        }
    }

    &_mobile {
        .sidebar__actions {
            position: absolute;
            top: 0;
            left: 0;
            padding: 8px 15px;
            height: 100%;
            width: 100%;
            display: flex;

            .logo {
                display: flex;

                &__image {
                    margin: auto;
                }
            }
        }

        .navigation__close {
            left: auto;
            right: 15px;
        }

        .sidebar__nav-button {
            position: absolute;
            top: 8px;
            right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 44px;
            height: 44px;
            border-radius: 5px;
            background: var(--button-nav-bg);
            z-index: 100;

            .badge {
                left: 5px;
            }
        }

        .sidebar__button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: auto;
            width: 44px;
            height: 44px;
            padding: 10px;
            background: var(--nav-header-bg);
            border-radius: 5px 0 0 5px;
            z-index: 10;

            .button__icon {
                margin-right: 0;
            }

            .tooltip {
                top: -10px
            }
        }

        .sidebar__container, .nav__container  {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 110;
        }

        .nav__container {
            background-image: url(../images/modal/hablario.svg);
            background-repeat: no-repeat;
            background-position: 0 0;
        }

        &.is-nav-open {
            .sidebar__nav-button {
                z-index: 110;
            }
        }
    }
}

@include create-mq($grid__bp-2xl, "min") {

    .sidebar:not(.sidebar_mobile) {
        .sidebar__container {
            width: 270px;
        }

        .sidebar__promo,
        .sidebar__actions,
        .sidebar__nav-button {
            width: 190px;
        }

        .sidebar__nav .nav__container {
            width: 370px;
        }
    }
}

@include create-mq($grid__bp-4xl, "min") {

   /* .sidebar:not(.sidebar_mobile) {
        .sidebar__container {
            width: 270px;
        }

        .sidebar__promo,
        .sidebar__actions,
        .sidebar__nav-button {
            width: 190px;
        }

        .sidebar__nav .nav__container {
            width: 370px;
        }

        .sidebar__promo {
            translate: 0 160px;
            height: 585px;
        }

        .sidebar__promo-title {
            height: 60px;
        }

        .sidebar__promo-button {
            bottom: 60px;
        }

        .sidebar__promo-animation {

            .animation__image:nth-child(1) {
                width: 250px;
                height: 63px;
                translate: -125px 530px;
            }
            .animation__coins {
                bottom: -200px;
            }
            .animation__coin {
                scale: .45
            }
        }
    }*/
}

@media (hover: hover) {
    .sidebar__nav-button {
        cursor: pointer;
    }
    .sidebar__promo {
        cursor: pointer;
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {

    .sidebar:not(.sidebar_mobile) {

        .sidebar__container  {
            width: 240px;
        }

        .sidebar__nav .nav__container   {
            width: 280px;
        }

        .sidebar__nav .nav__title   {
           font-size: toRemSm(12px);
        }

        .sidebar__promo,
        .sidebar__actions,
        .sidebar__nav-button {
            width: 146px;
        }

        .sidebar__button,
        .sidebar__nav-button {
            height: 38px;
        }

        .sidebar__actions {
            .button__text {
                font-size: toRemSm(12px);
            }
        }
    }
}
