.members {
    @include create-mq($grid__bp-md - 1, "max") {
        &__item:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    @include create-mq($grid__bp-md, "min") {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px 30px;
    }
    @include create-mq($grid__bp-lg, "min") {
        grid-template-columns: repeat(3, 1fr);
    }
}
