.ui-scroll {
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--light-gray);
        border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(197, 197, 197, .2);
        border-radius: 3px;
    }
}
