.ui-checkbox {
    vertical-align: top;
    margin: 0 3px 0 0;
    width: 18px;
    height: 18px;
}

.ui-checkbox + .label {
    user-select: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0 0 0 30px;
    font-size: toRem(14px);
    line-height: 1.1;
    color: var(--light-gray);
}

.ui-checkbox:not(checked) {
    position: absolute;
    opacity: 0;
}

.ui-checkbox:not(checked) + .label {
    position: relative;
}

.ui-checkbox:not(checked) + .label::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    background-color: var(--color-white);
    border: 1px solid var(--gray);
    transition: all var(--transition-duration) var(--transition-easing);
}

.ui-checkbox:not(checked) + .label::after {
    content: '';
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    background-repeat: no-repeat;
    background-size: contain;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=) center no-repeat;
    background-size: 75%;
}

.ui-checkbox:checked + .label::before {
    background-color: var(--dark-gray);
    background-clip: content-box;
}

.ui-checkbox:checked + .label::after {
    display: block;
}

.ui-checkbox:hover + .label::before {
    border: 1px solid var(--light-gray);
}

.ui-checkbox:disabled + .label,
.ui-checkbox.disabled + .label {
    opacity: 0.5;
    pointer-events: none;
}

.ui-checkbox:disabled + .label::before,
.ui-checkbox.disabled + .label::before {
    border: 1px solid #dcdbdb;
}

.ui-checkbox:disabled + .label::after,
.ui-checkbox.disabled + .label::after {
    display: none;
}
