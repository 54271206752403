.page {
    padding-top: 60px;
    padding-bottom: 80px;

    &__close {
        @extend .modal__close;
    }

    &__actions {
        display: flex;
        align-items: center;

        .button_favorite {
            margin-right: 10px;
        }

        .button_options {
            margin-left: 10px;
        }

        .present-block {
            margin-right: 1rem;
        }

    }

    &__link {
        @extend .text-gradient
    }

    &__header {

        &_search {
            display: flex;
            align-items: center;
            .button__icon {
                margin-right: 0;
                margin-left: 5px;
            }
        }

        &_bg {
            background: linear-gradient(180deg, rgba(19, 19, 19, 0.80) 0%, rgba(19, 19, 19, 0.80) 19.36%, rgba(19, 19, 19, 0.00) 100%);
            backdrop-filter: blur(5px);
            .button_options {
                > span {
                    &,
                    &::after,
                    &::before {
                        background-color: var(--color-white);
                    }
                }
            }
        }
    }

    &__filter {
        margin-left: auto;
        display: flex;
        align-items: center;
        .button_link {
            height: 35px;
            color: var(--color-text)
        }
    }

    &_inner {
        padding-top: 0;
    }

    &_profile, &_account {

        .container {
            @include create-mq($grid__bp-xl, 'min') {
                max-width: $container-width-xl * 1px;
            }
            @include create-mq($grid__bp-4xl, 'min') {
                max-width: $container-width-3xl * 1px;
            }
        }

    }

    &_fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 300;
        background: var(--body-bg);

        .page__header {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            align-items: center;
            padding: 20px 35px;
            background-color: var(--nav-bg);

            &_payment {
                background-color: transparent;
            }
        }

        .page__heading {
            margin-top: 20px;
            margin-bottom: 40px;
            font-size: toRem(35px);
            text-align: center;
        }

        .page__close {
            top: 20px;
            right: 35px;
        }
    }

    &_fixed:not(.page_mobile) {
        .page__container {
            padding-top: 80px;
            height: calc(var(--vh, 1vh)* 100);
            .container {
                margin: auto;
            }
        }
    }

    &_payment:not(.page_mobile) {
        background-image: url("../images/icons/bg-payment.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-color: var(--sidebar-bg);
        margin: 0;
        background-position: 0 0;

        .page__column {
            display: flex;
            gap: 75px;
            align-items: center;
        }

        .page__footer {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 20px 20px 25px;
        }

    }

    &_payment {
        padding-top: 0;
        padding-bottom: 0;

        .page__container {
            display: flex;
            flex-direction: column;
        }

        .page__heading {
            margin-top: 0;
            font-weight: 500;
        }

        .page__footer {
            .icon {
                margin-right: 5px;
            }
        }

        .page__heading, .page__section {
            color: var(--dark-gray2)
        }

        .page__section {
            font-size: toRem(14px);
            text-align: center;
            //margin-top: 40px;


            .page__wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-bottom: 31px;
            }

            .button {
                display: block;
                font-weight: 400;

                .button__text {
                    padding-right: 27px;
                }

                &_service {
                    min-width: 150px;
                    margin: auto;
                    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);

                    span {
                        border-bottom: 1px solid rgba(197,197,197, .5);
                    }
                }
            }
        }
        .page__list-price {
            display: inline-block;

            &_bg {
                padding: 15px 50px 50px;
                margin-top: 15px;
                border-top: 1px solid rgba(197, 197, 197, .3);
                background: var(--nav-bg);
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
            }

            .list__item {
                display: flex;
                align-items: center;
                //margin-bottom: 40px;
                margin-bottom: 20px;
            }

            .list__icon {
                position: relative;
                //margin-right: 30px;
                margin-right: 20px;
                background: var(--nav-bg);
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
                border-radius: 50%;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    width: 26px;
                    height: 26px;
                }
            }

            .list__title {
                flex: 1;
                //padding: 10px 20px;
                padding: 10px 10px;
                background: var(--nav-bg);
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
                font-weight: 700;
                border-radius: 30px;
                text-align: center;
                color: var(--color-text)
            }
        }

        .list_payment {
            display: inline-flex;
            align-items: center;
            margin-left: auto;
            .list__item:not(:last-child) {
                margin-right: 5px;
            }
        }
    }

    &_new-story {

        .container {
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 50px;
        }

        .page__heading {
            @extend .text-gradient;
        }

        .form {

            &__footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;

                .button {
                    & + .button {
                        margin-left: 8px;
                    }
                }
            }

            .slider__delete {
                width: auto
            }
        }

        .slider_portfolio {
            margin-top: 20px;
        }
    }

    &_payment-status {

        .section__heading:not(.text-center) {
            text-align: left;
        }

        .page__footer {
            margin-top: 20px;
            text-align: center;
            font-size: toRem(14px);
        }

        .page__pad, .page__table {
            margin-bottom: 20px;
        }

        .page__button {
            display: block;
            width: 250px;
            margin: 30px auto;
        }

        .page__info {
            text-align: center;
            color: var(--heading-color);
        }
    }

    &_mobile {
        padding-top: 60px;

        &:not(.page_new-story) {
            .container {
                position: relative;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .page__header {
            position: fixed;
            left: 0;
            width: 100%;
            padding: 15px;
            height: 75px;
            display: flex;
            align-items: center;
            z-index: 109;

            &:not(.page__header_bg) {
                box-shadow: 0 10px 15px rgba(246, 246, 246, 0.06);
                backdrop-filter: blur(20px);
            }

            .page__actions {
                margin-left: auto;

                .button_options {
                    margin-left: 20px;
                }

                .present-block {
                    margin-right: 10px;

                    .button_round {

                        > span {
                            background: rgba(0, 0, 0, 0.42);
                        }

                    }
                }
            }

            .button_back, .button_chat {
                background: rgba(0, 0, 0, 0.34);
                backdrop-filter: blur(5px);
            }

            .button_chat {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 132px;
                height: 40px;
                padding-right: 20px;
                padding-left: 20px;
                line-height: 38px;
                z-index: 10;

                > span {
                    font-size: toRem(14px);
                    color: var(--color-white);
                }

                .button__icon {
                    margin-right: 5px;
                }
            }

            .button_back {
                color: var(--color-white);
            }
        }

        .page__filter {
            position: fixed;
            top: 60px;
            left: 0;
            width: 100%;
            padding: 10px;
            justify-content: center;
            flex-wrap: wrap;
            z-index: 100;

            .button_link {
                padding: 8px 10px 8px 15px;
                border-radius: 5px;
                white-space: nowrap;

                & + .button_link {
                    margin-left: 10px;
                }
            }

            .tag, .button_link {
                background: rgba(251, 251, 251, .8);
            }

            .list_tags {
                width: 100%;
                margin-top: 10px;
                padding-bottom: 5px;
                overflow-x: auto;
            }
        }

        &.page_fixed {

            .page__heading {
                margin-bottom: 0;
                margin-top: 0;
                margin-left: 34px;
                font-size: 26px;
            }
        }

        &.page_payment {
            display: flex;
            flex-direction: column;
            min-height: var(--vh);

            .page__container {
                padding: 50px 15px 20px;
                flex: 1;
            }

            .page__footer {
                padding: 10px 20px;
                margin-top: auto;
                text-align: center;
            }

            .page__heading {
                @extend .text-gradient;
            }

            .list_payment {
                margin-top: 10px;
            }
        }
        &.page_account {

            .person {

                .status {
                    position: relative;
                    top: 2px;
                    margin-left: 10px;
                }
            }
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px)  {
    .page {

        &_profile {

            .page__header {

                .button_chat {
                    width: 100%;
                    padding: 0 15px;
                }
            }
        }
    }
}

@include create-mq($grid__bp-xl - 1, "max") {
    .page {

        &_new-story {

            .slider_portfolio {
                margin-top: 20px;

                .hooper {
                    height: 320px;
                }
            }
        }

        &_payment-status {

            .page__footer {

                span {
                    display: block;
                }
            }
        }
    }
}

@include create-mq($grid__bp-sm - 1, "max") {
    .page {

        &_payment-status {

            .section__heading {
                font-size: toRem(25px);
            }

            .page__info, .page__pad .pad__body {
                font-size: toRem(14px);
            }
        }
    }
}

@include create-mq($grid__bp-sm, "min") {
    .page {

        &_new-story {

           .container {
               max-width: 80%;
           }

            .form {

                &__footer {

                    .button {
                        width: 220px;
                    }
                }
            }
        }
    }
}

@include create-mq($grid__bp-lg, "min") {

    .page:not(.page_inner):not(.page_payment):not(.page_static) {
        padding-top: 80px;
    }

    .page:not(.page_inner):not(.page_payment):not(.pb-0) {
        padding-bottom: 120px;
    }
}

@include create-mq($grid__bp-xl, "min") {
    .page {

        &__actions {
            margin: 0 0 0 auto;
        }

        &_gallery {
            .container {
                max-width: 600px;
            }

            .page__header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 20px;
                height: 58px;
                margin-bottom: 10px;

                .button {
                    width: 100px;
                }
            }
        }

        &_static {
            padding-top: 80px;
        }

        &_inner {
           /* padding-right: 245px;*/

          /*  .page__header {
                margin-bottom: toRem(20px);
            }*/
        }

        &_profile, &_account {
         /*   padding-right: 0;*/
            .container {
                padding-right: 0;
                padding-left: 0;
                max-width: 600px;
            }

            .page__header, .page__wrapper {
                height: 64px;
            }

            .page__header {
                width: 100%;
                max-width: 600px;
            }

            /*.page__header, .page__wrapper {
                height: 72px;
            }
            */
            .page__wrapper {
                position: fixed;
                width: 100%;
                max-width: inherit;
                padding: 5px 0;
                display: flex;
                align-items: center;
                background-color: var(--body-bg);
                border-bottom: 2px solid rgba(197, 197, 197, .2);
                z-index: 100;
            }

            &.is-chat-open {
                padding-left: 160px;
                .container {
                    max-width: 1200px;
                }
                .chat {
                    max-width: 430px;

                    &__container {
                        width: 430px;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
               /* .profile__container {
                    flex: 0 0 45vw;
                    max-width: 45vw;
                }
                .page__header {
                    max-width: 45vw;
                }*/
            }
        }

        &_payment {
            .page__container {
                //background-image: url(../images/payment/payment-bg.svg);
            }
        }


        &_account {
            .person {
                .status {
                    position: absolute;
                    bottom: 0;
                    left: 100%;
                    z-index: 10;
                    text-align: center;
                }
            }
        }

        &_new-story {
            .container {
                margin-top: 0;
                max-width: 780px;
            }
            .slider_portfolio {
                .hooper {
                    height: 240px;
                }
            }
        }

        &_payment-status {
            .section__heading {
                font-size: toRem(35px);
            }

            .page__info {
                font-size: toRem(20px);
            }
        }
    }
    .sidebar.is-open ~ .page_profile.is-chat-open, .sidebar.is-nav-open ~ .page_profile.is-chat-open,
    .sidebar.is-open ~ .page_account.is-chat-open, .sidebar.is-nav-open ~ .page_account.is-chat-open {
        padding-left: 290px;

        /*.chat {
           &__container {
              width: 30vw
            }
        };*/
    }
    /*.sidebar.is-open ~ .page_gallery, .sidebar.is-nav-open ~ .page_gallery {
        .gallery {
            grid-template-columns: repeat(3, 1fr);
        }
        .container {
            max-width: 780px;
        }
    }*/
    /*.sidebar.is-open ~ .page_profile:not(.is-chat-open), .sidebar.is-nav-open ~ .page_profile:not(.is-chat-open),
    .sidebar.is-open ~ .page_account:not(.is-chat-open), .sidebar.is-nav-open ~ .page_account:not(.is-chat-open){
        padding-right: 245px;
    }*/
}

@include create-mq($grid__bp-2xl, "min") {
    /*.sidebar.is-open ~ .page_gallery, .sidebar.is-nav-open ~ .page_gallery {
        .container {
            max-width: 900px;
        }
    }*/
}
@include create-mq($grid__bp-3xl, "min") {
    .page {
        /*&_gallery {
            .container {
                max-width: 1600px;
            }
        }*/
       /* &_profile, &_account {

            &.is-chat-open {
                .container {
                    max-width: 1600px;
                }
            }
        }*/

        &_payment {
            /*.page__container {
                padding-top: 87px;
            }*/
            .page__footer {
                .page__section {
                    position: absolute;
                    bottom: 25px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
    /*.sidebar.is-open ~ .page_gallery, .sidebar.is-nav-open ~ .page_gallery {
        .container {
            max-width: 1080px;
        }
    }*/
}

@include create-mq($grid__bp-4xl, "min") {
    .page {
        &_gallery {
          /*  padding-bottom: toRem(90px);*/
            .container {
                max-width: 800px;
            }

            .page__header {
                height: 80px;

                .button {
                    width: 130px;
                }
            }
        }

        &_profile, &_account {

           /* .page__header {
                max-width: 1200px;
                .logo {
                    top: toRem(20px);
                }
            }*/

            .container {
                max-width: 870px;
            }

            .page__header, .page__wrapper {
                height: 86px;
            }

            .page__header {
                max-width: 870px;
            }

            .page__wrapper {
                padding-left: 15px;
                padding-right: 15px;
            }

            &.is-chat-open {
                padding-left: 200px;

                .container {
                    max-width: 1600px;
                }

                /*.logo {
                    margin-left: 15px;
                }*/

               /* .page__header {
                    max-width: 900px;
                }*/

                /*.profile__container {
                    flex: 0 0 900px;
                    max-width: 900px;
                }*/

                .chat {
                    max-width: 670px;

                    &__container {
                        width: 670px;
                    }
                }
            }
        }

        &_payment {
            /*.page__container {
                padding-top: 174px;
            }*/
            .page__heading {
                margin-bottom: 60px;
            }
            .price {
                //margin-bottom: 60px;
            }

            .page__list-price {

                .list__icon {
                    width: 80px;
                    height: 80px;
                    flex: 0 0 80px;

                    .icon {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
    /*.sidebar.is-open ~ .page_gallery, .sidebar.is-nav-open ~ .page_gallery {
        .container {
            max-width: 1200px;
        }
    }*/

   /* .sidebar.is-open ~ .page_inner:not(.is-chat-open), .sidebar.is-nav-open ~ .page_inner:not(.is-chat-open) {
        padding-right: 0;
    }*/
    .sidebar.is-open ~ .page_profile.is-chat-open, .sidebar.is-nav-open ~ .page_profile.is-chat-open,
    .sidebar.is-open ~ .page_account.is-chat-open, .sidebar.is-nav-open ~ .page_account.is-chat-open {
        padding-left: 340px;

       /* .chat {
            &__container {
                width: 670px;
            }
        }*/

       /* .profile__container {
            flex: 0 0 900px;
            max-width: 900px;
        }

        .page__header {
            max-width: 900px;
        }*/
    }
}

@include create-mq($grid__bp-5xl, "min") {
    .page {
       /* &_gallery {
            padding-right: 0;
        }*/

        &_profile, &_account {
            &.is-chat-open {
                padding-left: 0;
            }
        }
    }

    /*.sidebar.is-open ~ .page_inner, .sidebar.is-nav-open ~ .page_inner {
        padding-left: 0;
    }*/
    /*.sidebar.is-open ~ .page_gallery, .sidebar.is-nav-open ~ .page_gallery {
        .gallery {
            grid-template-columns: repeat(4, 1fr);
        }

        .container {
            max-width: 1600px;
        }
    }*/
    /*.sidebar.is-open ~ .page_profile.is-chat-open, .sidebar.is-nav-open ~ .page_profile.is-chat-open,
    .sidebar.is-open ~ .page_account.is-chat-open, .sidebar.is-nav-open ~ .page_account.is-chat-open {
        padding-right: 0;
    }*/
}
