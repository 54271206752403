@charset "UTF-8";
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Black.woff2") format("woff2"), url("../fonts/Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-BlackItalic.woff2") format("woff2"), url("../fonts/Roboto-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.woff2") format("woff2"), url("../fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Italic.woff2") format("woff2"), url("../fonts/Roboto-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-ThinItalic.woff2") format("woff2"), url("../fonts/Roboto-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Thin.woff2") format("woff2"), url("../fonts/Roboto-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-BoldItalic.woff2") format("woff2"), url("../fonts/Roboto-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-MediumItalic.woff2") format("woff2"), url("../fonts/Roboto-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-LightItalic.woff2") format("woff2"), url("../fonts/Roboto-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
:root {
  --vh: 1vh;
  --color-white: #fff;
  --color-light: #fafafa;
  --color-light1: #fbfbfb;
  --color-light2: #ebe1ff;
  --color-light3: #f6f6f6;
  --color-red: #e52a62;
  --color-green: #2BA33E;
  --color-light-green: #3AC624;
  --color-blue: #4d8dec;
  --color-orange: #ff8754;
  --dark-gray: #131313;
  --dark-gray2: #1B1B1B;
  --dark-gray3: #1F1F1F;
  --light-gray: #C5C5C5;
  --light-gray1: #D9D9D9;
  --gray: #7e7e7e;
  --gray1: #757575;
  --gray2: #989898;
  --color-black: #000;
  --color-date: #262626;
  --gradient: linear-gradient(227deg, #FF63F9 1.25%, #766DE6 44.67%, #00DCDC 94.77%);
  --gradient-reverse: linear-gradient(-227deg, #00DCDC 5.23%, #766DE6 55.33%, #FF63F9 98.75%);
  --gradient-text: linear-gradient(221deg, #5886FF 16.48%, #D00BF0 93.67%);
  --gradient-price-base:linear-gradient(259deg, #1BA8F7 -5.37%, #00DBDB 82.72%);
  --gradient-price-expert:linear-gradient(259deg, #5F32E0 -5.37%, #3A94FE 82.72%);
  --gradient-price-pro:linear-gradient(259deg, #C323EB -5.37%, #5939D8 82.72%);
  --gradient-price-top:linear-gradient(259deg, #E04285 -5.37%, #AD07D7 82.72%);
  --gradient-price-starting:linear-gradient(264deg, #FF54D9 -6.48%, #5E78FF 44.88%, #00DBDB 102.04%);
  --state-success: var(--color-green);
  --state-online: #00FC29;
  --state-info: var(--color-blue);
  --state-warning: var(--color-orange);
  --state-danger: var(--color-red);
  --body-bg: var(--color-white);
  --color-text: var(--gray);
  --heading-font-weight: 700;
  --font-size: 1rem;
  --font-size-xs: .625rem;
  --font-size-s: .75rem;
  --font-size-sm: .875rem;
  --font-size-md: var(--font-size);
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --line-height: 1.4;
  --font-base: "Roboto", sans-serif;
  --heading-color: var(--dark-gray);
  --heading-font-family: var(--font-base);
  --heading-line-height: 1.2;
  --transition-duration: 0.35s;
  --transition-easing: cubic-bezier(0.5, 0, 0.5, 1);
}

.mode-light {
  --body-bg: var(--color-white);
  --nav-bg: var(--color-white);
  --nav-header-bg: var(--color-light1);
  --sidebar-bg: var(--color-light1);
  --sidebar-color: var(--color-light);
  --snotify-bg: rgba(41, 27, 27, 0.60);
  --btn-snotify: #ffffff;
  --card-bg: var(--color-light3);
  --color-text: var(--gray);
  --heading-color: var(--dark-gray);
  --chat-default-bg: var(--color-white);
  --chat-message-bg: var(--color-light);
  --chat-input-bg: var(--color-white);
  --letter-message-color: #1F1F1F;
  --letter-subject-color: #4A4A4A;
  --button-bg1: var(--color-light1);
  --button-bg2: var(--color-white);
  --button-bg3: #302F2F;
  --button-color1: var(--dark-gray);
  --button-color2: var(--gray);
  --button-nav-bg: #f8f8f8;
  --button-color3: var(--light-gray);
  --drobdown-hover-bg: #EEEEEE;
  --icon-box-bg: var(--color-light1);
  --media-bg: var(--color-light2);
  --message-admin-color: var(--gray1);
  --profile-title: var(--gray);
  --footer-color: var(--color-black);
  --color-date: var(--color-white);
}
.mode-light .is-dark {
  display: none;
}
.mode-light .is-light {
  display: initial;
}
.mode-light .chat__letter .chat__header.bg-1 .contact__title, .mode-light .chat__letter .chat__header.bg-2 .contact__title, .mode-light .chat__letter .chat__header.bg-3 .contact__title {
  color: var(--color-black);
}
.mode-light .chat__letter .chat__header.bg-1 .button_filled, .mode-light .chat__letter .chat__header.bg-2 .button_filled, .mode-light .chat__letter .chat__header.bg-3 .button_filled {
  border: 1px solid var(--color-black);
}

.mode-dark {
  --body-bg: var(--dark-gray);
  --nav-header-bg: #272727;
  --nav-bg: #272727;
  --sidebar-bg: #272727;
  --button-nav-bg: #272727;
  --sidebar-color: var(--dark-gray);
  --snotify-bg: rgba(61, 61, 61, .56);
  --btn-snotify: #fff;
  --card-bg: #272727;
  --color-text: var(--color-white);
  --heading-color: var(--color-white);
  --chat-default-bg: var(--dark-gray);
  --chat-message-bg: #262626;
  --chat-input-bg: #262626;
  --letter-message-color: #FAFAFA;
  --letter-subject-color: #E1E1E1;
  --button-bg1: #272727;
  --button-bg2: var(--dark-gray);
  --button-bg3: var(--light-gray);
  --button-color1: var(--color-white);
  --button-color2: var(--color-white);
  --button-color3: var(--dark-gray);
  --drobdown-hover-bg: var(--gray);
  --icon-box-bg: transparent;
  --media-bg: var(--gray);
  --message-admin-color: var(--gray1);
  --profile-title: var(--gray2);
  --footer-color: var(--color-white);
  --color-date: var(--dark-gray);
}
.mode-dark .is-light {
  display: none;
}
.mode-dark .is-dark {
  display: initial;
}
.mode-dark .dropdown__menu::before {
  background-image: url(../images/icons/corner-dark.svg);
}
.mode-dark .icon_letter-white {
  background-image: url(../images/icons/letter-dark.svg);
}
.mode-dark .icon_letter-open {
  background-image: url(../images/icons/letter-dark-open.svg);
}
.mode-dark .chat .chat__check-icon:not(.is-read) {
  color: var(--light-gray);
}
.mode-dark .chat__item.is-start .chat__check-icon {
  color: var(--light-gray);
}
.mode-dark .chat__item.is-start .message {
  background: var(--chat-message-bg);
  color: var(--light-gray);
  background-size: cover;
  background-repeat: no-repeat;
}
.mode-dark .chat__item.is-start .message .message__triangle path {
  fill: var(--chat-message-bg);
}
.mode-dark .chat__item.is-end .message {
  color: var(--color-white);
}
.mode-dark .chat__item.is-end .message_color2 {
  background: linear-gradient(44deg, #1C708B -9.91%, #028766 43.92%, #47972B 100%), #EEE;
}
.mode-dark .chat__item.is-end .message_color3 {
  background: linear-gradient(45deg, #8E9122 -8.88%, #971C1C 100%), #EEE;
}
.mode-dark .chat__item.is-end .message_color4 {
  background: linear-gradient(180deg, #A21271 0%, #752B87 100%);
}
.mode-dark .chat__item.is-end .message_color5 {
  background: linear-gradient(180deg, #B88012 0%, #497600 100%);
}
.mode-dark .chat__item.is-end .message_color6 {
  background: linear-gradient(180deg, #181D48 0%, #198F88 100%);
}
.mode-dark .chat__item.is-end .message_color7 {
  background: linear-gradient(44deg, #00AB8C -9.91%, #3B49BA 49.51%, #A50076 100%), #EEE;
}
.mode-dark .chat__item.is-end .message_color8 {
  background: #21242C;
}
.mode-dark .chat__wrapper.bg-1 {
  background: linear-gradient(180deg, #9A0066 0%, #3C1986 56.43%, #176399 100%);
}
.mode-dark .chat__wrapper.bg-2 {
  background: linear-gradient(180deg, #4C3556 0%, #14101A 55.41%, #233235 100%);
}
.mode-dark .chat__wrapper.bg-3 {
  background: linear-gradient(180deg, #4F0362 0%, #0F1733 64.17%, #29222F 100%);
}
.mode-dark .chat__wrapper.bg-4 {
  background: linear-gradient(180deg, #806400 0%, #33230F 55.77%, #132210 100%);
}
.mode-dark .chat__wrapper.bg-5 {
  background: linear-gradient(180deg, #036251 0%, #0F2633 64.17%, #29222F 100%);
}
.mode-dark .chat__wrapper.bg-6 {
  background: linear-gradient(180deg, #80001F 0%, #330F2D 64.17%, #2F222B 100%);
}
.mode-dark .chat_support .chat__wrapper {
  background-image: url(../images/support-chat/dark/bg-dark.webp);
}
.mode-dark .chat_support .chat__item.is-start .message:not(.message_simple) {
  color: var(--color-white);
}
.mode-dark .chat_support .chat__item.is-end .message {
  color: #000;
}
.mode-dark .chat_support .chat__item.is-end .button {
  color: var(--color-text);
}
.mode-dark .chat_support .contact__avatar {
  background-image: url(../images/support-chat/support-logo-dark.svg);
}
.mode-dark .chat_settings .chat__color_1 {
  background: linear-gradient(180deg, #FF8AD7 6.15%, #E168FF 20.46%, #9584FF 37.05%, rgba(142, 123, 255, 0.92) 41.33%, #95BFFE 57.15%, rgba(111, 255, 246, 0.86) 70.68%, #54FFFF 83.06%, #7BFFD7 100%);
}
.mode-dark .chat_settings .chat__color_2 {
  background: linear-gradient(44deg, #98E6FF -9.91%, #B6FFED 43.92%, #BEFFA7 100%), #EEE;
}
.mode-dark .chat_settings .chat__color_3 {
  background: linear-gradient(45deg, #8E9122 -8.88%, #971C1C 100%), #EEE;
}
.mode-dark .chat_settings .chat__color_4 {
  background: linear-gradient(180deg, #A21271 0%, #752B87 100%);
}
.mode-dark .chat_settings .chat__color_5 {
  background: linear-gradient(180deg, #B88012 0%, #497600 100%);
}
.mode-dark .chat_settings .chat__color_6 {
  background: linear-gradient(180deg, #181D48 0%, #198F88 100%);
}
.mode-dark .chat_settings .chat__color_7 {
  background: linear-gradient(44deg, #00AB8C -9.91%, #3B49BA 49.51%, #A50076 100%), #EEE;
}
.mode-dark .chat_settings .chat__color_8 {
  background: linear-gradient(0deg, #21242C 0%, #21242C 100%), #EEE;
}
.mode-dark .chat_settings .chat__bg_default {
  background: var(--chat-default-bg);
}
.mode-dark .chat_settings .chat__bg_1 {
  background: linear-gradient(180deg, #9A0066 0%, #3C1986 56.43%, #176399 100%);
}
.mode-dark .chat_settings .chat__bg_2 {
  background: linear-gradient(180deg, #4C3556 0%, #14101A 55.41%, #233235 100%);
}
.mode-dark .chat_settings .chat__bg_3 {
  background: linear-gradient(180deg, #4F0362 0%, #0F1733 64.17%, #29222F 100%);
}
.mode-dark .chat_settings .chat__bg_4 {
  background: linear-gradient(180deg, #806400 0%, #33230F 55.77%, #132210 100%);
}
.mode-dark .chat_settings .chat__bg_5 {
  background: linear-gradient(180deg, #036251 0%, #0F2633 64.17%, #29222F 100%);
}
.mode-dark .chat_settings .chat__bg_6 {
  background: linear-gradient(180deg, #80001F 0%, #330F2D 64.17%, #2F222B 100%);
}
.mode-dark .chat__preview, .mode-dark .chat__caption {
  background: rgba(38, 38, 38, 0.85);
}
.mode-dark .chat_settings .chat__overlay {
  background: rgba(19, 19, 19, 0.65);
}
.mode-dark .chat_mobile:not(.chat_support) .chat__header {
  background: rgba(19, 19, 19, 0.8);
}
.mode-dark .chat_mobile:not(.chat_support) .chat__input {
  box-shadow: 0 -10px 25px #000000;
}
.mode-dark .panel::after {
  background: linear-gradient(0deg, #272727 34.29%, rgba(255, 255, 255, 0) 100%);
}
.mode-dark .panel__handler::before {
  background: #000;
  border: 1px solid #131313;
}
.mode-dark .tabs__link {
  background: linear-gradient(357.97deg, rgba(19, 19, 19, 0.9) 2.22%, rgba(19, 19, 19, 0) 98.82%);
}
.mode-dark .page_payment .page__heading, .mode-dark .page_payment .page__section {
  color: var(--color-white);
}
.mode-dark .page_payment .page__list-price_bg {
  background: none;
}
.mode-dark .page_mobile .page__filter .tag, .mode-dark .page_mobile .page__filter .button_link {
  background: rgba(39, 39, 39, 0.8);
}
.mode-dark .ui-radio_payment:checked + .label, .mode-dark .ui-radio_payment:not(checked) + .label:hover {
  background: #262626;
}
.mode-dark .status_badge {
  background: rgba(39, 39, 39, 0.1);
}
.mode-dark .header_mobile {
  background: rgba(19, 19, 19, 0.8);
  backdrop-filter: blur(5px);
  box-shadow: none;
}
.mode-dark .button_back {
  background: #272727;
}
.mode-dark .button_like.is-active {
  background-image: url(../images/icons/heart-white.svg);
}
.mode-dark .letter__textarea {
  color: #f2f2f2;
}
.mode-dark .letter__footer .chat__dropdown .dropdown__list::after, .mode-dark .letter__footer .chat__dropdown .dropdown__list::before {
  content: none;
}
.mode-dark .letter .button_rounded span {
  color: #fff;
}
.mode-dark .images__body::after, .mode-dark .chat__letter_images::after {
  background: rgba(26, 26, 26, 0.9);
}
.mode-dark .button_white {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}
.mode-dark .message_letter-images::before {
  border: 2px solid #262626;
  background: rgba(74, 74, 74, 0.5);
}
.mode-dark .message_letter-images .message__date, .mode-dark .message_letter-images .button_link {
  background: #262626;
}
.mode-dark .message_letter-images .button_link {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
}

.no-webp .mode-dark .chat_support .chat__wrapper {
  background-image: url(../images/support-chat/dark/bg-dark.jpg);
}

@media screen and (max-width: 575px) {
  .mode-dark .chat_support .chat__wrapper {
    background-image: url(../images/support-chat/mobile/dark/bg-dark.webp);
  }

  .no-webp .mode-dark .chat_support .chat__wrapper {
    background-image: url(../images/support-chat/mobile/dark/bg-dark.jpg);
  }
}
:root {
  line-height: 1.15;
  text-size-adjust: 100%;
  -webkit-overflow-scrolling: auto;
  -webkit-touch-callout: none;
}

body {
  margin: 0;
  font-smooth: antialiased;
  text-rendering: optimizeLegibility;
}

html,
body {
  min-height: 100%;
}

body * {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  outline: none;
}

* {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

img,
a {
  -webkit-touch-callout: none;
  user-select: none;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img,
picture,
video {
  max-width: 100%;
  height: auto;
  border: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  line-height: 1.15;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

svg:not(:root) {
  overflow: hidden;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
}

body {
  font-size: var(--font-size);
  font-family: var(--font-base);
  font-weight: 400;
  line-height: var(--line-height);
  background-color: var(--body-bg);
  color: var(--color-text);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  line-height: var(--heading-line-height);
  color: var(--heading-color);
}

h1 {
  font-size: 5.75rem;
}

h2 {
  font-size: 3.75rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.25rem;
}

h5,
h6 {
  font-size: 1rem;
}

@media screen and (min-width: 1280px) {
  html {
    font-size: 87.5%;
  }
}
/* only between 1920 and 1280 1rem = 14px */
@media screen and (min-width: 1920px) {
  html {
    font-size: 100%;
  }
}
@media print {
  *, *::before, *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
}
html,
body {
  overflow-x: hidden;
}

.is-sticky-footer {
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
}
.is-sticky-footer .main {
  flex: 1;
}

.main {
  position: relative;
}
.main p,
.main blockquote {
  margin: 0 0 1.25rem;
}

html.is-loading #app * {
  opacity: 0;
  transition: none !important;
  overflow: hidden;
}

.landing_welcome .header__actions {
  display: flex;
  align-items: center;
}
.landing_welcome .header_dark .button_sign {
  display: block;
}
.landing_welcome .header .navbar {
  flex: initial;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding-right: 10px;
  padding-left: 10px;
}
@media screen and (min-width: 1280px) {
  .container {
    max-width: 1200px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.d-flex {
  display: flex;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
@media screen and (min-width: 1280px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.row > .col,
.row > [class*=col-] {
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}
@media screen and (min-width: 1280px) {
  .row > .col,
.row > [class*=col-] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row-flush {
  margin-left: 0;
  margin-right: 0;
}
.row-flush > .col,
.row-flush > [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}

.row-start {
  justify-content: flex-start;
}

.row-center {
  justify-content: center;
}

.row-end {
  justify-content: flex-end;
}

.row-around {
  justify-content: space-around;
}

.row-between {
  justify-content: space-between;
}

.row-align-center {
  align-items: center;
}

@media screen and (min-width: 428px) {
  .row-xs-start {
    justify-content: flex-start;
  }

  .row-xs-center {
    justify-content: center;
  }

  .row-xs-end {
    justify-content: flex-end;
  }

  .row-xs-around {
    justify-content: space-around;
  }

  .row-xs-between {
    justify-content: space-between;
  }

  .row-xs-align-center {
    align-items: center;
  }
}
@media screen and (min-width: 576px) {
  .row-sm-start {
    justify-content: flex-start;
  }

  .row-sm-center {
    justify-content: center;
  }

  .row-sm-end {
    justify-content: flex-end;
  }

  .row-sm-around {
    justify-content: space-around;
  }

  .row-sm-between {
    justify-content: space-between;
  }

  .row-sm-align-center {
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .row-md-start {
    justify-content: flex-start;
  }

  .row-md-center {
    justify-content: center;
  }

  .row-md-end {
    justify-content: flex-end;
  }

  .row-md-around {
    justify-content: space-around;
  }

  .row-md-between {
    justify-content: space-between;
  }

  .row-md-align-center {
    align-items: center;
  }
}
@media screen and (min-width: 1024px) {
  .row-lg-start {
    justify-content: flex-start;
  }

  .row-lg-center {
    justify-content: center;
  }

  .row-lg-end {
    justify-content: flex-end;
  }

  .row-lg-around {
    justify-content: space-around;
  }

  .row-lg-between {
    justify-content: space-between;
  }

  .row-lg-align-center {
    align-items: center;
  }
}
@media screen and (min-width: 1280px) {
  .row-xl-start {
    justify-content: flex-start;
  }

  .row-xl-center {
    justify-content: center;
  }

  .row-xl-end {
    justify-content: flex-end;
  }

  .row-xl-around {
    justify-content: space-around;
  }

  .row-xl-between {
    justify-content: space-between;
  }

  .row-xl-align-center {
    align-items: center;
  }
}
@media screen and (min-width: 1440px) {
  .row-2xl-start {
    justify-content: flex-start;
  }

  .row-2xl-center {
    justify-content: center;
  }

  .row-2xl-end {
    justify-content: flex-end;
  }

  .row-2xl-around {
    justify-content: space-around;
  }

  .row-2xl-between {
    justify-content: space-between;
  }

  .row-2xl-align-center {
    align-items: center;
  }
}
.offset-0 {
  margin-left: 0;
}

.order-1 {
  order: 1;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.order-2 {
  order: 2;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.order-3 {
  order: 3;
}

.offset-3 {
  margin-left: 25%;
}

.order-4 {
  order: 4;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.order-5 {
  order: 5;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.order-6 {
  order: 6;
}

.offset-6 {
  margin-left: 50%;
}

.order-7 {
  order: 7;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.order-8 {
  order: 8;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.order-9 {
  order: 9;
}

.offset-9 {
  margin-left: 75%;
}

.order-10 {
  order: 10;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.order-11 {
  order: 11;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.order-12 {
  order: 12;
}

.offset-12 {
  margin-left: 100%;
}

@media screen and (min-width: 428px) {
  .offset-xs-0 {
    margin-left: 0;
  }

  .order-xs-1 {
    order: 1;
  }

  .offset-xs-1 {
    margin-left: 8.3333333333%;
  }

  .order-xs-2 {
    order: 2;
  }

  .offset-xs-2 {
    margin-left: 16.6666666667%;
  }

  .order-xs-3 {
    order: 3;
  }

  .offset-xs-3 {
    margin-left: 25%;
  }

  .order-xs-4 {
    order: 4;
  }

  .offset-xs-4 {
    margin-left: 33.3333333333%;
  }

  .order-xs-5 {
    order: 5;
  }

  .offset-xs-5 {
    margin-left: 41.6666666667%;
  }

  .order-xs-6 {
    order: 6;
  }

  .offset-xs-6 {
    margin-left: 50%;
  }

  .order-xs-7 {
    order: 7;
  }

  .offset-xs-7 {
    margin-left: 58.3333333333%;
  }

  .order-xs-8 {
    order: 8;
  }

  .offset-xs-8 {
    margin-left: 66.6666666667%;
  }

  .order-xs-9 {
    order: 9;
  }

  .offset-xs-9 {
    margin-left: 75%;
  }

  .order-xs-10 {
    order: 10;
  }

  .offset-xs-10 {
    margin-left: 83.3333333333%;
  }

  .order-xs-11 {
    order: 11;
  }

  .offset-xs-11 {
    margin-left: 91.6666666667%;
  }

  .order-xs-12 {
    order: 12;
  }

  .offset-xs-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 576px) {
  .offset-sm-0 {
    margin-left: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .order-sm-2 {
    order: 2;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .order-sm-3 {
    order: 3;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .order-sm-4 {
    order: 4;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .order-sm-5 {
    order: 5;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .order-sm-6 {
    order: 6;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .order-sm-7 {
    order: 7;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .order-sm-8 {
    order: 8;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .order-sm-9 {
    order: 9;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .order-sm-10 {
    order: 10;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .order-sm-11 {
    order: 11;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 768px) {
  .offset-md-0 {
    margin-left: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .order-md-2 {
    order: 2;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .order-md-3 {
    order: 3;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .order-md-4 {
    order: 4;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .order-md-5 {
    order: 5;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .order-md-6 {
    order: 6;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .order-md-7 {
    order: 7;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .order-md-8 {
    order: 8;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .order-md-9 {
    order: 9;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .order-md-10 {
    order: 10;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .order-md-11 {
    order: 11;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .offset-lg-0 {
    margin-left: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .order-lg-2 {
    order: 2;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .order-lg-3 {
    order: 3;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .order-lg-4 {
    order: 4;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .order-lg-5 {
    order: 5;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .order-lg-6 {
    order: 6;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .order-lg-7 {
    order: 7;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .order-lg-8 {
    order: 8;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .order-lg-9 {
    order: 9;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .order-lg-10 {
    order: 10;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .order-lg-11 {
    order: 11;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .offset-xl-0 {
    margin-left: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .order-xl-2 {
    order: 2;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .order-xl-3 {
    order: 3;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .order-xl-4 {
    order: 4;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .order-xl-5 {
    order: 5;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .order-xl-6 {
    order: 6;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .order-xl-7 {
    order: 7;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .order-xl-8 {
    order: 8;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .order-xl-9 {
    order: 9;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .order-xl-10 {
    order: 10;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .order-xl-11 {
    order: 11;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1440px) {
  .offset-2xl-0 {
    margin-left: 0;
  }

  .order-2xl-1 {
    order: 1;
  }

  .offset-2xl-1 {
    margin-left: 8.3333333333%;
  }

  .order-2xl-2 {
    order: 2;
  }

  .offset-2xl-2 {
    margin-left: 16.6666666667%;
  }

  .order-2xl-3 {
    order: 3;
  }

  .offset-2xl-3 {
    margin-left: 25%;
  }

  .order-2xl-4 {
    order: 4;
  }

  .offset-2xl-4 {
    margin-left: 33.3333333333%;
  }

  .order-2xl-5 {
    order: 5;
  }

  .offset-2xl-5 {
    margin-left: 41.6666666667%;
  }

  .order-2xl-6 {
    order: 6;
  }

  .offset-2xl-6 {
    margin-left: 50%;
  }

  .order-2xl-7 {
    order: 7;
  }

  .offset-2xl-7 {
    margin-left: 58.3333333333%;
  }

  .order-2xl-8 {
    order: 8;
  }

  .offset-2xl-8 {
    margin-left: 66.6666666667%;
  }

  .order-2xl-9 {
    order: 9;
  }

  .offset-2xl-9 {
    margin-left: 75%;
  }

  .order-2xl-10 {
    order: 10;
  }

  .offset-2xl-10 {
    margin-left: 83.3333333333%;
  }

  .order-2xl-11 {
    order: 11;
  }

  .offset-2xl-11 {
    margin-left: 91.6666666667%;
  }

  .order-2xl-12 {
    order: 12;
  }

  .offset-2xl-12 {
    margin-left: 100%;
  }
}
.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media screen and (min-width: 428px) {
  .col-xs-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xs-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xs-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xs-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xs-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xs-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xs-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 1440px) {
  .col-2xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-2xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-2xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-2xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-2xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-2xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-2xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-2xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-2xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-2xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-2xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-2xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.hidden {
  display: none !important;
}

@media screen and (max-width: 427px) {
  .hidden-xs-down {
    display: none !important;
  }
}
@media screen and (min-width: 428px) {
  .hidden-xs-up {
    display: none !important;
  }
}
@media screen and (max-width: 575px) {
  .hidden-sm-down {
    display: none !important;
  }
}
@media screen and (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .hidden-md-down {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}
@media screen and (max-width: 1023px) {
  .hidden-lg-down {
    display: none !important;
  }
}
@media screen and (min-width: 1024px) {
  .hidden-lg-up {
    display: none !important;
  }
}
@media screen and (max-width: 1279px) {
  .hidden-xl-down {
    display: none !important;
  }
}
@media screen and (min-width: 1280px) {
  .hidden-xl-up {
    display: none !important;
  }
}
@media screen and (max-width: 1439px) {
  .hidden-2xl-down {
    display: none !important;
  }
}
@media screen and (min-width: 1440px) {
  .hidden-2xl-up {
    display: none !important;
  }
}
@media screen and (min-width: 576px) {
  .double-row {
    column-count: 2;
    column-gap: 20px;
  }
}
.icon {
  display: inline-block;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.icon_fb,
.icon_in,
.icon_yb {
  width: 50px;
  height: 50px;
}

.icon_fb {
  background-image: url(../images/icons/fb.svg);
}

.icon_in {
  background-image: url(../images/icons/in.svg);
}

.icon_yb {
  background-image: url(../images/icons/yb.svg);
}

.icon_phone,
.icon_marker,
.icon_mail,
.icon_view,
.icon_eye,
.icon_favorite,
.icon_favorite-check,
.icon_money,
.icon_bell,
.icon_bell-red,
.icon_block-user,
.icon_chat-bubble-gradient,
.icon_chat-bubble-light,
.icon_chat-bubble-bordered,
.icon_dark,
.icon_light,
.icon_headset,
.icon_sound-off,
.icon_sound-on,
.icon_search,
.icon_search-white,
.icon_setting,
.icon_settings,
.icon_subscribe,
.icon_envelope,
.icon_subscribe-on,
.icon_plus,
.icon_question,
.icon_info,
.icon_account,
.icon_block-user,
.icon_unsubscribe,
.icon_complain,
.icon_tariff,
.icon_logout,
.icon_stories,
.icon_emoji,
.icon_delete,
.icon_theme,
.icon_unlock,
.icon_edit,
.icon_chat,
.icon_box,
.icon_warning,
.icon_letter-full,
.icon_image,
.icon_mic,
.icon_video,
.icon_camera,
.icon_secured,
.icon_heart-white,
.icon_attachment-white,
.icon_crown,
.icon_star,
.icon_cristal,
.icon_image-fill {
  width: 24px;
  height: 24px;
}

.icon_present {
  width: 34px;
  height: 38px;
}

.icon_letter-white,
.icon_letter-dark {
  width: 43px;
  height: 30px;
}

.icon_image-fill {
  background-image: url(../images/icons/image.svg);
}

.icon_buy-credits {
  width: 55px;
  height: 55px;
  background-image: url(../images/icons/buy-credits.svg);
}

.icon_attachment-white {
  background-image: url(../images/icons/attachment-white.svg);
}

.icon_present {
  background-image: url(../images/icons/present.svg);
}

.icon_crown {
  background-image: url(../images/icons/crown.svg);
}

.icon_star {
  background-image: url(../images/icons/star.svg);
}

.icon_heart-white {
  background-image: url(../images/icons/heart-white.svg);
}

.icon_cristal {
  background-image: url(../images/icons/cristal.svg);
}

.icon_secured {
  background-image: url(../images/icons/secured.svg);
}

.icon_image {
  background-image: url(../images/icons/image-upload.svg);
}

.icon_mic {
  background-image: url(../images/icons/mic.svg);
}

.icon_mic-white {
  background-image: url(../images/icons/mic-white.svg);
}

.icon_video {
  background-image: url(../images/icons/video.svg);
}

.icon_camera {
  background-image: url(../images/icons/camera.svg);
}

.icon_warning {
  background-image: url(../images/icons/warning.svg);
}

.icon_chat {
  background-image: url(../images/icons/chat.svg);
}

.icon_box {
  background-image: url(../images/icons/box-open.svg);
}

.icon_letter-full {
  background-image: url(../images/icons/letter-full.svg);
}

.icon_edit {
  background-image: url(../images/icons/edit.svg);
}

.icon_unlock {
  background-image: url(../images/icons/unlock.svg);
}

.icon_theme {
  background-image: url(../images/icons/theme.svg);
}

.icon_setting {
  background-image: url(../images/icons/setting.svg);
}

.icon_delete {
  background-image: url(../images/icons/delete.svg);
}

.icon_stories {
  background-image: url(../images/icons/stories.svg);
}

.icon_emoji {
  background-image: url(../images/icons/emoji.svg);
}

.icon_unsubscribe {
  background-image: url(../images/icons/unsubscribe.svg);
}

.icon_block-user {
  background-image: url(../images/icons/block-user.svg);
}

.icon_complain {
  background-image: url(../images/icons/complain.svg);
}

.icon_settings {
  background-image: url(../images/icons/settings.svg);
}

.icon_account {
  background-image: url(../images/icons/account.svg);
}

.icon_tariff {
  background-image: url(../images/icons/tariff.svg);
}

.icon_logout {
  background-image: url(../images/icons/logout.svg);
}

.icon_info {
  background-image: url(../images/icons/info.svg);
}

.icon_question {
  background-image: url(../images/icons/question.svg);
}

.icon_dark {
  background-image: url(../images/icons/dark.svg);
}

.icon_search {
  background-image: url(../images/icons/search.svg);
}

.icon_search-white {
  background-image: url(../images/icons/search-white.svg);
}

.icon_sound-off {
  background-image: url(../images/icons/sound-off.svg);
}

.icon_sound-on {
  background-image: url(../images/icons/sound-on.svg);
}

.icon_subscribe {
  background-image: url(../images/icons/subscribe.svg);
}

.icon_subscribe-on {
  background-image: url(../images/icons/subscribe-on.svg);
}

.icon_plus {
  background-image: url(../images/icons/plus.svg);
}

.icon_headset {
  background-image: url(../images/icons/headset.svg);
}

.icon_light {
  background-image: url(../images/icons/light.svg);
}

.icon_chat-bubble-gradient {
  background-image: url(../images/icons/chat-bubble-gradient.svg);
}

.icon_chat-bubble-light {
  background-image: url(../images/icons/chat-bubble-light.svg);
}

.icon_chat-bubble-bordered {
  background-image: url(../images/icons/chat-bubble-bordered.svg);
}

.icon_block-user {
  background-image: url(../images/icons/block-user.svg);
}

.icon_money {
  background-image: url(../images/icons/money.svg);
}

.icon_bell {
  background-image: url(../images/icons/bell.svg);
}

.icon_bell-red {
  background-image: url(../images/icons/bell-red.svg);
}

.icon_phone {
  background-image: url(../images/icons/phone.svg);
}

.icon_marker {
  background-image: url(../images/icons/marker.svg);
}

.icon_mail {
  background-image: url(../images/icons/mail.svg);
}

.icon_close {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  text-align: center;
  background-image: url(../images/icons/close.svg);
  flex-shrink: 0;
  margin: 0 auto;
  display: block !important;
}

.icon_eye {
  background-image: url(../images/icons/eye.svg);
}

.icon_envelope {
  background-image: url(../images/icons/envelope.svg);
}

.icon_favorite {
  background-image: url(../images/icons/favorite.svg);
}

.icon_favorite-check {
  background-image: url(../images/icons/favorite-check.svg);
}

.icon_more {
  width: 18px;
  height: 8px;
  background-image: url(../images/icons/arrow-right.svg);
}

.icon_calendar {
  width: 16px;
  height: 16px;
  background-image: url(../images/icons/calendar.svg);
}

.icon_like {
  width: 17px;
  height: 16px;
  background-image: url(../images/icons/like.svg);
}

.icon_user {
  width: 17px;
  height: 16px;
  background-image: url(../images/icons/user.svg);
}

.icon_new {
  width: 44px;
  height: 30px;
  background-image: url(../images/icons/new.svg);
}

.icon_view {
  width: 20px;
  height: 20px;
  background-image: url(../images/icons/view.svg);
}

.icon_history {
  width: 8px;
  height: 17px;
  background-image: url(../images/icons/history.svg);
}

.icon_location {
  width: 10px;
  height: 14px;
  background-image: url(../images/icons/location.svg);
}

.icon_send {
  width: 36px;
  height: 36px;
  background-image: url(../images/icons/send.svg);
}

.icon_lock {
  width: 36px;
  height: 36px;
  background-image: url(../images/icons/lock.svg);
}

.icon_plane {
  width: 16px;
  height: 16px;
  background-image: url(../images/icons/plane.svg);
}

.icon_attachment {
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/attachment.svg);
}

.icon_download {
  width: 16px;
  height: 18px;
  background-image: url(../images/icons/download.svg);
}

.icon_letter {
  width: 44px;
  height: 28px;
  background-image: url(../images/icons/letter.svg);
}

.icon_letter-white {
  border-radius: 5px;
  background-image: url(../images/icons/letter-white.svg);
}

.icon_letter-dark {
  border-radius: 5px;
  background-image: url(../images/icons/letter-dark.svg);
}

.icon_letter-open {
  width: 44px;
  height: 44px;
  background-image: url(../images/icons/letter-open.svg);
}

.icon_letter-dark-open {
  width: 44px;
  height: 44px;
  background-image: url(../images/icons/letter-dark-open.svg);
}

.icon_background {
  width: 25px;
  height: 20px;
  background-image: url(../images/icons/image-bg.svg);
}

.icon_dollar {
  width: 60px;
  height: 60px;
  background-image: url(../images/icons/dollar.svg);
}

.icon_choose {
  width: 14px;
  height: 22px;
  background-image: url(../images/icons/choose.svg);
}

.icon_back {
  width: 14px;
  height: 14px;
  background-image: url(../images/icons/back-arrow.svg);
}

.icon_camera-white {
  width: 20px;
  height: 16px;
  background-image: url(../images/icons/camera-white.svg);
}

.icon_video-white {
  width: 28px;
  height: 16px;
  background-image: url(../images/icons/video-white.svg);
}

.icon_copy {
  width: 12px;
  height: 16px;
  background-image: url(../images/icons/copy.svg);
}

.icon_sound {
  width: 28px;
  height: 16px;
  background-image: url(../images/icons/sound.svg);
}

.icon_zodiac {
  width: 28px;
  height: 28px;
}

.icon_gifts_tr {
  background-image: url(../images/icons/gifts/tr.svg);
  height: 1px;
  width: 238px;
  margin: 0 auto;
}

.icon_crypto {
  background-image: url(../images/icons/gifts/crypto.svg);
  height: 23px;
  width: 25px;
}

@media (max-width: 1920px) {
  .icon_gifts_tr {
    width: 203px;
  }
}
@media (max-width: 1280px) {
  /*.icon_zodiac {
      width: toRemSm(22px);
      height: toRemSm(22px);
  }
  .icon_letter {
      width: toRemSm(28px);
      height: toRemSm(18px);
  }
  .icon_letter-open {
      width: toRemSm(28px);
      height: toRemSm(28px);
  }*/
}
.icon_aquarius {
  background-image: url(../images/icons/zodiac/01-aquarius.svg);
}

.icon_pisces {
  background-image: url(../images/icons/zodiac/02-pisces.svg);
}

.icon_aries {
  background-image: url(../images/icons/zodiac/03-aries.svg);
}

.icon_taurus {
  background-image: url(../images/icons/zodiac/04-taurus.svg);
}

.icon_gemini {
  background-image: url(../images/icons/zodiac/05-gemini.svg);
}

.icon_leo {
  background-image: url(../images/icons/zodiac/06-leo.svg);
}

.icon_cancer {
  background-image: url(../images/icons/zodiac/07-cancer.svg);
}

.icon_virgo {
  background-image: url(../images/icons/zodiac/08-virgo.svg);
}

.icon_libra {
  background-image: url(../images/icons/zodiac/09-libra.svg);
}

.icon_scorpio {
  background-image: url(../images/icons/zodiac/10-scorpio.svg);
}

.icon_sagittarius {
  background-image: url(../images/icons/zodiac/11-sagittarius.svg);
}

.icon_capricorn {
  background-image: url(../images/icons/zodiac/12-capricorn.svg);
}

.icon_hobbies {
  width: 34px;
  height: 34px;
}

.travel {
  background-image: url(../images/icons/hobbies/travel.svg);
}

.shopping {
  background-image: url(../images/icons/hobbies/shopping.svg);
}

.camping {
  background-image: url(../images/icons/hobbies/camping.svg);
}

.reading {
  background-image: url(../images/icons/hobbies/reading.svg);
}

.music {
  background-image: url(../images/icons/hobbies/music.svg);
}

.movies {
  background-image: url(../images/icons/hobbies/movies.svg);
}

.sailing {
  background-image: url(../images/icons/hobbies/sailing.svg);
}

.natural {
  background-image: url(../images/icons/hobbies/natural.svg);
}

.dancing {
  background-image: url(../images/icons/hobbies/dancing.svg);
}

.biking {
  background-image: url(../images/icons/hobbies/biking.svg);
}

.cars {
  background-image: url(../images/icons/hobbies/cars.svg);
}

.cooking {
  background-image: url(../images/icons/hobbies/cooking.svg);
}

.art {
  background-image: url(../images/icons/hobbies/art.svg);
}

.party {
  background-image: url(../images/icons/hobbies/party.svg);
}

.fashion {
  background-image: url(../images/icons/hobbies/fashion.svg);
}

.sport {
  background-image: url(../images/icons/hobbies/sport.svg);
}

.diving {
  background-image: url(../images/icons/hobbies/diving.svg);
}

.yoga {
  background-image: url(../images/icons/hobbies/yoga.svg);
}

.animals {
  background-image: url(../images/icons/hobbies/animals.svg);
}

@media (hover: hover) {
  .icon_close {
    cursor: pointer;
  }
}
.icon_aws {
  background-image: url(../images/icons/protection/aws.svg);
}

.icon_sectigo {
  background-image: url(../images/icons/protection/sectigo.svg);
}

.icon_dmca {
  background-image: url(../images/icons/protection/dmca.svg);
}

.icon_safe-labeling {
  background-image: url(../images/icons/protection/safe-labeling.svg);
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .icon_phone,
.icon_marker,
.icon_mail,
.icon_view,
.icon_eye,
.icon_favorite,
.icon_favorite-check,
.icon_money,
.icon_bell,
.icon_bell-red,
.icon_block-user,
.icon_chat-bubble-gradient,
.icon_chat-bubble-light,
.icon_chat-bubble-bordered,
.icon_dark,
.icon_light,
.icon_headset,
.icon_sound-off,
.icon_sound-on,
.icon_search,
.icon_search-white,
.icon_setting,
.icon_settings,
.icon_subscribe,
.icon_envelope,
.icon_subscribe-on,
.icon_plus,
.icon_question,
.icon_info,
.icon_account,
.icon_block-user,
.icon_unsubscribe,
.icon_complain,
.icon_tariff,
.icon_logout,
.icon_stories,
.icon_emoji,
.icon_delete,
.icon_theme,
.icon_unlock,
.icon_edit,
.icon_chat,
.icon_box,
.icon_warning,
.icon_letter-full,
.icon_image,
.icon_mic,
.icon_mic-white,
.icon_video,
.icon_camera,
.icon_secured,
.icon_heart-white,
.icon_attachment-white,
.icon_crown,
.icon_star,
.icon_cristal {
    width: 20px;
    height: 20px;
  }

  .icon_zodiac {
    width: 22px;
    height: 22px;
  }

  .icon_letter {
    width: 28px;
    height: 18px;
  }

  .icon_letter-open {
    width: 28px;
    height: 28px;
  }
}
.triangle {
  border-style: solid;
  height: 0;
  width: 0;
  --triangle-size: 0.5rem;
}
.triangle_sm {
  --triangle-size: 0.5rem ;
}
.triangle_md {
  --triangle-size: 2rem ;
}
.triangle_lg {
  --triangle-size: 4rem ;
}
.triangle_t {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #d1d5db rgba(0, 0, 0, 0);
  border-width: 0 var(--triangle-size) var(--triangle-size) var(--triangle-size);
}
.triangle_r {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #d1d5db;
  border-width: var(--triangle-size) 0 var(--triangle-size) 1rem;
}
.triangle_b {
  border-color: #d1d5db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-width: var(--triangle-size) var(--triangle-size) 0 var(--triangle-size);
}
.triangle_l {
  border-color: rgba(0, 0, 0, 0) #d1d5db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-width: var(--triangle-size) 1rem var(--triangle-size) 0;
}
.triangle_tr {
  border-color: rgba(0, 0, 0, 0) #d1d5db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-width: 0 var(--triangle-size) var(--triangle-size) 0;
}
.triangle_br {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #d1d5db rgba(0, 0, 0, 0);
  border-width: 0 0 var(--triangle-size) var(--triangle-size);
}
.triangle_bl {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #d1d5db;
  border-width: var(--triangle-size) 0 0 var(--triangle-size);
}
.triangle_tl {
  border-color: #d1d5db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-width: var(--triangle-size) var(--triangle-size) 0 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
}
.loader:not(.loader_local) {
  position: fixed;
  z-index: 50000;
  background: white;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.loader_local {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.loader_local .loader__spinner {
  width: 20px;
  height: 20px;
}
.loader_local .loader__spinner::before, .loader_local .loader__spinner::after {
  width: 20px;
  height: 20px;
  animation: rotate-local 1.5s cubic-bezier(0.4, 0, 1, 1) infinite;
}
.loader_local .loader__spinner::after {
  background: var(--color-light2);
  animation-delay: 0.85s;
}
.loader__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 120px;
  font-size: 1.25rem;
  font-weight: 500;
  z-index: 10;
}
.loader__spinner {
  position: relative;
  width: 50px;
  height: 50px;
}
.loader__spinner::before, .loader__spinner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation: rotate 1.5s cubic-bezier(0.4, 0, 1, 1) infinite;
}
.loader__spinner::before {
  background: var(--gradient);
}
.loader__spinner::after {
  background: var(--color-light3);
  animation-delay: 0.85s;
}

@keyframes rotate {
  0%, 100% {
    transform: translate(25px, 0);
  }
  25% {
    transform: scale(0.25);
  }
  50% {
    transform: translate(-25px, 0);
    z-index: 1;
  }
  75% {
    transform: scale(1);
  }
}
@keyframes rotate-local {
  0%, 100% {
    transform: translate(10px, 0);
  }
  25% {
    transform: scale(0.25);
  }
  50% {
    transform: translate(-10px, 0);
    z-index: 1;
  }
  75% {
    transform: scale(1);
  }
}
.fib, .fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.fi {
  position: relative;
  display: inline-block;
  width: 1.333333em;
  line-height: 1em;
}
.fi:before {
  content: " ";
}
.fi.fis {
  width: 1em;
}

.fi-xx {
  background-image: url(../images/flags/4x3/xx.svg);
}
.fi-xx.fis {
  background-image: url(../images/flags/1x1/xx.svg);
}

.fi-ad {
  background-image: url(../images/flags/4x3/ad.svg);
}
.fi-ad.fis {
  background-image: url(../images/flags/1x1/ad.svg);
}

.fi-ae {
  background-image: url(../images/flags/4x3/ae.svg);
}
.fi-ae.fis {
  background-image: url(../images/flags/1x1/ae.svg);
}

.fi-af {
  background-image: url(../images/flags/4x3/af.svg);
}
.fi-af.fis {
  background-image: url(../images/flags/1x1/af.svg);
}

.fi-ag {
  background-image: url(../images/flags/4x3/ag.svg);
}
.fi-ag.fis {
  background-image: url(../images/flags/1x1/ag.svg);
}

.fi-ai {
  background-image: url(../images/flags/4x3/ai.svg);
}
.fi-ai.fis {
  background-image: url(../images/flags/1x1/ai.svg);
}

.fi-al {
  background-image: url(../images/flags/4x3/al.svg);
}
.fi-al.fis {
  background-image: url(../images/flags/1x1/al.svg);
}

.fi-am {
  background-image: url(../images/flags/4x3/am.svg);
}
.fi-am.fis {
  background-image: url(../images/flags/1x1/am.svg);
}

.fi-ao {
  background-image: url(../images/flags/4x3/ao.svg);
}
.fi-ao.fis {
  background-image: url(../images/flags/1x1/ao.svg);
}

.fi-aq {
  background-image: url(../images/flags/4x3/aq.svg);
}
.fi-aq.fis {
  background-image: url(../images/flags/1x1/aq.svg);
}

.fi-ar {
  background-image: url(../images/flags/4x3/ar.svg);
}
.fi-ar.fis {
  background-image: url(../images/flags/1x1/ar.svg);
}

.fi-as {
  background-image: url(../images/flags/4x3/as.svg);
}
.fi-as.fis {
  background-image: url(../images/flags/1x1/as.svg);
}

.fi-at {
  background-image: url(../images/flags/4x3/at.svg);
}
.fi-at.fis {
  background-image: url(../images/flags/1x1/at.svg);
}

.fi-au {
  background-image: url(../images/flags/4x3/au.svg);
}
.fi-au.fis {
  background-image: url(../images/flags/1x1/au.svg);
}

.fi-aw {
  background-image: url(../images/flags/4x3/aw.svg);
}
.fi-aw.fis {
  background-image: url(../images/flags/1x1/aw.svg);
}

.fi-ax {
  background-image: url(../images/flags/4x3/ax.svg);
}
.fi-ax.fis {
  background-image: url(../images/flags/1x1/ax.svg);
}

.fi-az {
  background-image: url(../images/flags/4x3/az.svg);
}
.fi-az.fis {
  background-image: url(../images/flags/1x1/az.svg);
}

.fi-ba {
  background-image: url(../images/flags/4x3/ba.svg);
}
.fi-ba.fis {
  background-image: url(../images/flags/1x1/ba.svg);
}

.fi-bb {
  background-image: url(../images/flags/4x3/bb.svg);
}
.fi-bb.fis {
  background-image: url(../images/flags/1x1/bb.svg);
}

.fi-bd {
  background-image: url(../images/flags/4x3/bd.svg);
}
.fi-bd.fis {
  background-image: url(../images/flags/1x1/bd.svg);
}

.fi-be {
  background-image: url(../images/flags/4x3/be.svg);
}
.fi-be.fis {
  background-image: url(../images/flags/1x1/be.svg);
}

.fi-bf {
  background-image: url(../images/flags/4x3/bf.svg);
}
.fi-bf.fis {
  background-image: url(../images/flags/1x1/bf.svg);
}

.fi-bg {
  background-image: url(../images/flags/4x3/bg.svg);
}
.fi-bg.fis {
  background-image: url(../images/flags/1x1/bg.svg);
}

.fi-bh {
  background-image: url(../images/flags/4x3/bh.svg);
}
.fi-bh.fis {
  background-image: url(../images/flags/1x1/bh.svg);
}

.fi-bi {
  background-image: url(../images/flags/4x3/bi.svg);
}
.fi-bi.fis {
  background-image: url(../images/flags/1x1/bi.svg);
}

.fi-bj {
  background-image: url(../images/flags/4x3/bj.svg);
}
.fi-bj.fis {
  background-image: url(../images/flags/1x1/bj.svg);
}

.fi-bl {
  background-image: url(../images/flags/4x3/bl.svg);
}
.fi-bl.fis {
  background-image: url(../images/flags/1x1/bl.svg);
}

.fi-bm {
  background-image: url(../images/flags/4x3/bm.svg);
}
.fi-bm.fis {
  background-image: url(../images/flags/1x1/bm.svg);
}

.fi-bn {
  background-image: url(../images/flags/4x3/bn.svg);
}
.fi-bn.fis {
  background-image: url(../images/flags/1x1/bn.svg);
}

.fi-bo {
  background-image: url(../images/flags/4x3/bo.svg);
}
.fi-bo.fis {
  background-image: url(../images/flags/1x1/bo.svg);
}

.fi-bq {
  background-image: url(../images/flags/4x3/bq.svg);
}
.fi-bq.fis {
  background-image: url(../images/flags/1x1/bq.svg);
}

.fi-br {
  background-image: url(../images/flags/4x3/br.svg);
}
.fi-br.fis {
  background-image: url(../images/flags/1x1/br.svg);
}

.fi-bs {
  background-image: url(../images/flags/4x3/bs.svg);
}
.fi-bs.fis {
  background-image: url(../images/flags/1x1/bs.svg);
}

.fi-bt {
  background-image: url(../images/flags/4x3/bt.svg);
}
.fi-bt.fis {
  background-image: url(../images/flags/1x1/bt.svg);
}

.fi-bv {
  background-image: url(../images/flags/4x3/bv.svg);
}
.fi-bv.fis {
  background-image: url(../images/flags/1x1/bv.svg);
}

.fi-bw {
  background-image: url(../images/flags/4x3/bw.svg);
}
.fi-bw.fis {
  background-image: url(../images/flags/1x1/bw.svg);
}

.fi-by {
  background-image: url(../images/flags/4x3/by.svg);
}
.fi-by.fis {
  background-image: url(../images/flags/1x1/by.svg);
}

.fi-bz {
  background-image: url(../images/flags/4x3/bz.svg);
}
.fi-bz.fis {
  background-image: url(../images/flags/1x1/bz.svg);
}

.fi-ca {
  background-image: url(../images/flags/4x3/ca.svg);
}
.fi-ca.fis {
  background-image: url(../images/flags/1x1/ca.svg);
}

.fi-cc {
  background-image: url(../images/flags/4x3/cc.svg);
}
.fi-cc.fis {
  background-image: url(../images/flags/1x1/cc.svg);
}

.fi-cd {
  background-image: url(../images/flags/4x3/cd.svg);
}
.fi-cd.fis {
  background-image: url(../images/flags/1x1/cd.svg);
}

.fi-cf {
  background-image: url(../images/flags/4x3/cf.svg);
}
.fi-cf.fis {
  background-image: url(../images/flags/1x1/cf.svg);
}

.fi-cg {
  background-image: url(../images/flags/4x3/cg.svg);
}
.fi-cg.fis {
  background-image: url(../images/flags/1x1/cg.svg);
}

.fi-ch {
  background-image: url(../images/flags/4x3/ch.svg);
}
.fi-ch.fis {
  background-image: url(../images/flags/1x1/ch.svg);
}

.fi-ci {
  background-image: url(../images/flags/4x3/ci.svg);
}
.fi-ci.fis {
  background-image: url(../images/flags/1x1/ci.svg);
}

.fi-ck {
  background-image: url(../images/flags/4x3/ck.svg);
}
.fi-ck.fis {
  background-image: url(../images/flags/1x1/ck.svg);
}

.fi-cl {
  background-image: url(../images/flags/4x3/cl.svg);
}
.fi-cl.fis {
  background-image: url(../images/flags/1x1/cl.svg);
}

.fi-cm {
  background-image: url(../images/flags/4x3/cm.svg);
}
.fi-cm.fis {
  background-image: url(../images/flags/1x1/cm.svg);
}

.fi-cn {
  background-image: url(../images/flags/4x3/cn.svg);
}
.fi-cn.fis {
  background-image: url(../images/flags/1x1/cn.svg);
}

.fi-co {
  background-image: url(../images/flags/4x3/co.svg);
}
.fi-co.fis {
  background-image: url(../images/flags/1x1/co.svg);
}

.fi-cr {
  background-image: url(../images/flags/4x3/cr.svg);
}
.fi-cr.fis {
  background-image: url(../images/flags/1x1/cr.svg);
}

.fi-cu {
  background-image: url(../images/flags/4x3/cu.svg);
}
.fi-cu.fis {
  background-image: url(../images/flags/1x1/cu.svg);
}

.fi-cv {
  background-image: url(../images/flags/4x3/cv.svg);
}
.fi-cv.fis {
  background-image: url(../images/flags/1x1/cv.svg);
}

.fi-cw {
  background-image: url(../images/flags/4x3/cw.svg);
}
.fi-cw.fis {
  background-image: url(../images/flags/1x1/cw.svg);
}

.fi-cx {
  background-image: url(../images/flags/4x3/cx.svg);
}
.fi-cx.fis {
  background-image: url(../images/flags/1x1/cx.svg);
}

.fi-cy {
  background-image: url(../images/flags/4x3/cy.svg);
}
.fi-cy.fis {
  background-image: url(../images/flags/1x1/cy.svg);
}

.fi-cz {
  background-image: url(../images/flags/4x3/cz.svg);
}
.fi-cz.fis {
  background-image: url(../images/flags/1x1/cz.svg);
}

.fi-de {
  background-image: url(../images/flags/4x3/de.svg);
}
.fi-de.fis {
  background-image: url(../images/flags/1x1/de.svg);
}

.fi-dj {
  background-image: url(../images/flags/4x3/dj.svg);
}
.fi-dj.fis {
  background-image: url(../images/flags/1x1/dj.svg);
}

.fi-dk {
  background-image: url(../images/flags/4x3/dk.svg);
}
.fi-dk.fis {
  background-image: url(../images/flags/1x1/dk.svg);
}

.fi-dm {
  background-image: url(../images/flags/4x3/dm.svg);
}
.fi-dm.fis {
  background-image: url(../images/flags/1x1/dm.svg);
}

.fi-do {
  background-image: url(../images/flags/4x3/do.svg);
}
.fi-do.fis {
  background-image: url(../images/flags/1x1/do.svg);
}

.fi-dz {
  background-image: url(../images/flags/4x3/dz.svg);
}
.fi-dz.fis {
  background-image: url(../images/flags/1x1/dz.svg);
}

.fi-ec {
  background-image: url(../images/flags/4x3/ec.svg);
}
.fi-ec.fis {
  background-image: url(../images/flags/1x1/ec.svg);
}

.fi-ee {
  background-image: url(../images/flags/4x3/ee.svg);
}
.fi-ee.fis {
  background-image: url(../images/flags/1x1/ee.svg);
}

.fi-eg {
  background-image: url(../images/flags/4x3/eg.svg);
}
.fi-eg.fis {
  background-image: url(../images/flags/1x1/eg.svg);
}

.fi-eh {
  background-image: url(../images/flags/4x3/eh.svg);
}
.fi-eh.fis {
  background-image: url(../images/flags/1x1/eh.svg);
}

.fi-er {
  background-image: url(../images/flags/4x3/er.svg);
}
.fi-er.fis {
  background-image: url(../images/flags/1x1/er.svg);
}

.fi-es {
  background-image: url(../images/flags/4x3/es.svg);
}
.fi-es.fis {
  background-image: url(../images/flags/1x1/es.svg);
}

.fi-et {
  background-image: url(../images/flags/4x3/et.svg);
}
.fi-et.fis {
  background-image: url(../images/flags/1x1/et.svg);
}

.fi-fi {
  background-image: url(../images/flags/4x3/fi.svg);
}
.fi-fi.fis {
  background-image: url(../images/flags/1x1/fi.svg);
}

.fi-fj {
  background-image: url(../images/flags/4x3/fj.svg);
}
.fi-fj.fis {
  background-image: url(../images/flags/1x1/fj.svg);
}

.fi-fk {
  background-image: url(../images/flags/4x3/fk.svg);
}
.fi-fk.fis {
  background-image: url(../images/flags/1x1/fk.svg);
}

.fi-fm {
  background-image: url(../images/flags/4x3/fm.svg);
}
.fi-fm.fis {
  background-image: url(../images/flags/1x1/fm.svg);
}

.fi-fo {
  background-image: url(../images/flags/4x3/fo.svg);
}
.fi-fo.fis {
  background-image: url(../images/flags/1x1/fo.svg);
}

.fi-fr {
  background-image: url(../images/flags/4x3/fr.svg);
}
.fi-fr.fis {
  background-image: url(../images/flags/1x1/fr.svg);
}

.fi-ga {
  background-image: url(../images/flags/4x3/ga.svg);
}
.fi-ga.fis {
  background-image: url(../images/flags/1x1/ga.svg);
}

.fi-gb {
  background-image: url(../images/flags/4x3/gb.svg);
}
.fi-gb.fis {
  background-image: url(../images/flags/1x1/gb.svg);
}

.fi-gd {
  background-image: url(../images/flags/4x3/gd.svg);
}
.fi-gd.fis {
  background-image: url(../images/flags/1x1/gd.svg);
}

.fi-ge {
  background-image: url(../images/flags/4x3/ge.svg);
}
.fi-ge.fis {
  background-image: url(../images/flags/1x1/ge.svg);
}

.fi-gf {
  background-image: url(../images/flags/4x3/gf.svg);
}
.fi-gf.fis {
  background-image: url(../images/flags/1x1/gf.svg);
}

.fi-gg {
  background-image: url(../images/flags/4x3/gg.svg);
}
.fi-gg.fis {
  background-image: url(../images/flags/1x1/gg.svg);
}

.fi-gh {
  background-image: url(../images/flags/4x3/gh.svg);
}
.fi-gh.fis {
  background-image: url(../images/flags/1x1/gh.svg);
}

.fi-gi {
  background-image: url(../images/flags/4x3/gi.svg);
}
.fi-gi.fis {
  background-image: url(../images/flags/1x1/gi.svg);
}

.fi-gl {
  background-image: url(../images/flags/4x3/gl.svg);
}
.fi-gl.fis {
  background-image: url(../images/flags/1x1/gl.svg);
}

.fi-gm {
  background-image: url(../images/flags/4x3/gm.svg);
}
.fi-gm.fis {
  background-image: url(../images/flags/1x1/gm.svg);
}

.fi-gn {
  background-image: url(../images/flags/4x3/gn.svg);
}
.fi-gn.fis {
  background-image: url(../images/flags/1x1/gn.svg);
}

.fi-gp {
  background-image: url(../images/flags/4x3/gp.svg);
}
.fi-gp.fis {
  background-image: url(../images/flags/1x1/gp.svg);
}

.fi-gq {
  background-image: url(../images/flags/4x3/gq.svg);
}
.fi-gq.fis {
  background-image: url(../images/flags/1x1/gq.svg);
}

.fi-gr {
  background-image: url(../images/flags/4x3/gr.svg);
}
.fi-gr.fis {
  background-image: url(../images/flags/1x1/gr.svg);
}

.fi-gs {
  background-image: url(../images/flags/4x3/gs.svg);
}
.fi-gs.fis {
  background-image: url(../images/flags/1x1/gs.svg);
}

.fi-gt {
  background-image: url(../images/flags/4x3/gt.svg);
}
.fi-gt.fis {
  background-image: url(../images/flags/1x1/gt.svg);
}

.fi-gu {
  background-image: url(../images/flags/4x3/gu.svg);
}
.fi-gu.fis {
  background-image: url(../images/flags/1x1/gu.svg);
}

.fi-gw {
  background-image: url(../images/flags/4x3/gw.svg);
}
.fi-gw.fis {
  background-image: url(../images/flags/1x1/gw.svg);
}

.fi-gy {
  background-image: url(../images/flags/4x3/gy.svg);
}
.fi-gy.fis {
  background-image: url(../images/flags/1x1/gy.svg);
}

.fi-hk {
  background-image: url(../images/flags/4x3/hk.svg);
}
.fi-hk.fis {
  background-image: url(../images/flags/1x1/hk.svg);
}

.fi-hm {
  background-image: url(../images/flags/4x3/hm.svg);
}
.fi-hm.fis {
  background-image: url(../images/flags/1x1/hm.svg);
}

.fi-hn {
  background-image: url(../images/flags/4x3/hn.svg);
}
.fi-hn.fis {
  background-image: url(../images/flags/1x1/hn.svg);
}

.fi-hr {
  background-image: url(../images/flags/4x3/hr.svg);
}
.fi-hr.fis {
  background-image: url(../images/flags/1x1/hr.svg);
}

.fi-ht {
  background-image: url(../images/flags/4x3/ht.svg);
}
.fi-ht.fis {
  background-image: url(../images/flags/1x1/ht.svg);
}

.fi-hu {
  background-image: url(../images/flags/4x3/hu.svg);
}
.fi-hu.fis {
  background-image: url(../images/flags/1x1/hu.svg);
}

.fi-id {
  background-image: url(../images/flags/4x3/id.svg);
}
.fi-id.fis {
  background-image: url(../images/flags/1x1/id.svg);
}

.fi-ie {
  background-image: url(../images/flags/4x3/ie.svg);
}
.fi-ie.fis {
  background-image: url(../images/flags/1x1/ie.svg);
}

.fi-il {
  background-image: url(../images/flags/4x3/il.svg);
}
.fi-il.fis {
  background-image: url(../images/flags/1x1/il.svg);
}

.fi-im {
  background-image: url(../images/flags/4x3/im.svg);
}
.fi-im.fis {
  background-image: url(../images/flags/1x1/im.svg);
}

.fi-in {
  background-image: url(../images/flags/4x3/in.svg);
}
.fi-in.fis {
  background-image: url(../images/flags/1x1/in.svg);
}

.fi-io {
  background-image: url(../images/flags/4x3/io.svg);
}
.fi-io.fis {
  background-image: url(../images/flags/1x1/io.svg);
}

.fi-iq {
  background-image: url(../images/flags/4x3/iq.svg);
}
.fi-iq.fis {
  background-image: url(../images/flags/1x1/iq.svg);
}

.fi-ir {
  background-image: url(../images/flags/4x3/ir.svg);
}
.fi-ir.fis {
  background-image: url(../images/flags/1x1/ir.svg);
}

.fi-is {
  background-image: url(../images/flags/4x3/is.svg);
}
.fi-is.fis {
  background-image: url(../images/flags/1x1/is.svg);
}

.fi-it {
  background-image: url(../images/flags/4x3/it.svg);
}
.fi-it.fis {
  background-image: url(../images/flags/1x1/it.svg);
}

.fi-je {
  background-image: url(../images/flags/4x3/je.svg);
}
.fi-je.fis {
  background-image: url(../images/flags/1x1/je.svg);
}

.fi-jm {
  background-image: url(../images/flags/4x3/jm.svg);
}
.fi-jm.fis {
  background-image: url(../images/flags/1x1/jm.svg);
}

.fi-jo {
  background-image: url(../images/flags/4x3/jo.svg);
}
.fi-jo.fis {
  background-image: url(../images/flags/1x1/jo.svg);
}

.fi-jp {
  background-image: url(../images/flags/4x3/jp.svg);
}
.fi-jp.fis {
  background-image: url(../images/flags/1x1/jp.svg);
}

.fi-ke {
  background-image: url(../images/flags/4x3/ke.svg);
}
.fi-ke.fis {
  background-image: url(../images/flags/1x1/ke.svg);
}

.fi-kg {
  background-image: url(../images/flags/4x3/kg.svg);
}
.fi-kg.fis {
  background-image: url(../images/flags/1x1/kg.svg);
}

.fi-kh {
  background-image: url(../images/flags/4x3/kh.svg);
}
.fi-kh.fis {
  background-image: url(../images/flags/1x1/kh.svg);
}

.fi-ki {
  background-image: url(../images/flags/4x3/ki.svg);
}
.fi-ki.fis {
  background-image: url(../images/flags/1x1/ki.svg);
}

.fi-km {
  background-image: url(../images/flags/4x3/km.svg);
}
.fi-km.fis {
  background-image: url(../images/flags/1x1/km.svg);
}

.fi-kn {
  background-image: url(../images/flags/4x3/kn.svg);
}
.fi-kn.fis {
  background-image: url(../images/flags/1x1/kn.svg);
}

.fi-kp {
  background-image: url(../images/flags/4x3/kp.svg);
}
.fi-kp.fis {
  background-image: url(../images/flags/1x1/kp.svg);
}

.fi-kr {
  background-image: url(../images/flags/4x3/kr.svg);
}
.fi-kr.fis {
  background-image: url(../images/flags/1x1/kr.svg);
}

.fi-kw {
  background-image: url(../images/flags/4x3/kw.svg);
}
.fi-kw.fis {
  background-image: url(../images/flags/1x1/kw.svg);
}

.fi-ky {
  background-image: url(../images/flags/4x3/ky.svg);
}
.fi-ky.fis {
  background-image: url(../images/flags/1x1/ky.svg);
}

.fi-kz {
  background-image: url(../images/flags/4x3/kz.svg);
}
.fi-kz.fis {
  background-image: url(../images/flags/1x1/kz.svg);
}

.fi-la {
  background-image: url(../images/flags/4x3/la.svg);
}
.fi-la.fis {
  background-image: url(../images/flags/1x1/la.svg);
}

.fi-lb {
  background-image: url(../images/flags/4x3/lb.svg);
}
.fi-lb.fis {
  background-image: url(../images/flags/1x1/lb.svg);
}

.fi-lc {
  background-image: url(../images/flags/4x3/lc.svg);
}
.fi-lc.fis {
  background-image: url(../images/flags/1x1/lc.svg);
}

.fi-li {
  background-image: url(../images/flags/4x3/li.svg);
}
.fi-li.fis {
  background-image: url(../images/flags/1x1/li.svg);
}

.fi-lk {
  background-image: url(../images/flags/4x3/lk.svg);
}
.fi-lk.fis {
  background-image: url(../images/flags/1x1/lk.svg);
}

.fi-lr {
  background-image: url(../images/flags/4x3/lr.svg);
}
.fi-lr.fis {
  background-image: url(../images/flags/1x1/lr.svg);
}

.fi-ls {
  background-image: url(../images/flags/4x3/ls.svg);
}
.fi-ls.fis {
  background-image: url(../images/flags/1x1/ls.svg);
}

.fi-lt {
  background-image: url(../images/flags/4x3/lt.svg);
}
.fi-lt.fis {
  background-image: url(../images/flags/1x1/lt.svg);
}

.fi-lu {
  background-image: url(../images/flags/4x3/lu.svg);
}
.fi-lu.fis {
  background-image: url(../images/flags/1x1/lu.svg);
}

.fi-lv {
  background-image: url(../images/flags/4x3/lv.svg);
}
.fi-lv.fis {
  background-image: url(../images/flags/1x1/lv.svg);
}

.fi-ly {
  background-image: url(../images/flags/4x3/ly.svg);
}
.fi-ly.fis {
  background-image: url(../images/flags/1x1/ly.svg);
}

.fi-ma {
  background-image: url(../images/flags/4x3/ma.svg);
}
.fi-ma.fis {
  background-image: url(../images/flags/1x1/ma.svg);
}

.fi-mc {
  background-image: url(../images/flags/4x3/mc.svg);
}
.fi-mc.fis {
  background-image: url(../images/flags/1x1/mc.svg);
}

.fi-md {
  background-image: url(../images/flags/4x3/md.svg);
}
.fi-md.fis {
  background-image: url(../images/flags/1x1/md.svg);
}

.fi-me {
  background-image: url(../images/flags/4x3/me.svg);
}
.fi-me.fis {
  background-image: url(../images/flags/1x1/me.svg);
}

.fi-mf {
  background-image: url(../images/flags/4x3/mf.svg);
}
.fi-mf.fis {
  background-image: url(../images/flags/1x1/mf.svg);
}

.fi-mg {
  background-image: url(../images/flags/4x3/mg.svg);
}
.fi-mg.fis {
  background-image: url(../images/flags/1x1/mg.svg);
}

.fi-mh {
  background-image: url(../images/flags/4x3/mh.svg);
}
.fi-mh.fis {
  background-image: url(../images/flags/1x1/mh.svg);
}

.fi-mk {
  background-image: url(../images/flags/4x3/mk.svg);
}
.fi-mk.fis {
  background-image: url(../images/flags/1x1/mk.svg);
}

.fi-ml {
  background-image: url(../images/flags/4x3/ml.svg);
}
.fi-ml.fis {
  background-image: url(../images/flags/1x1/ml.svg);
}

.fi-mm {
  background-image: url(../images/flags/4x3/mm.svg);
}
.fi-mm.fis {
  background-image: url(../images/flags/1x1/mm.svg);
}

.fi-mn {
  background-image: url(../images/flags/4x3/mn.svg);
}
.fi-mn.fis {
  background-image: url(../images/flags/1x1/mn.svg);
}

.fi-mo {
  background-image: url(../images/flags/4x3/mo.svg);
}
.fi-mo.fis {
  background-image: url(../images/flags/1x1/mo.svg);
}

.fi-mp {
  background-image: url(../images/flags/4x3/mp.svg);
}
.fi-mp.fis {
  background-image: url(../images/flags/1x1/mp.svg);
}

.fi-mq {
  background-image: url(../images/flags/4x3/mq.svg);
}
.fi-mq.fis {
  background-image: url(../images/flags/1x1/mq.svg);
}

.fi-mr {
  background-image: url(../images/flags/4x3/mr.svg);
}
.fi-mr.fis {
  background-image: url(../images/flags/1x1/mr.svg);
}

.fi-ms {
  background-image: url(../images/flags/4x3/ms.svg);
}
.fi-ms.fis {
  background-image: url(../images/flags/1x1/ms.svg);
}

.fi-mt {
  background-image: url(../images/flags/4x3/mt.svg);
}
.fi-mt.fis {
  background-image: url(../images/flags/1x1/mt.svg);
}

.fi-mu {
  background-image: url(../images/flags/4x3/mu.svg);
}
.fi-mu.fis {
  background-image: url(../images/flags/1x1/mu.svg);
}

.fi-mv {
  background-image: url(../images/flags/4x3/mv.svg);
}
.fi-mv.fis {
  background-image: url(../images/flags/1x1/mv.svg);
}

.fi-mw {
  background-image: url(../images/flags/4x3/mw.svg);
}
.fi-mw.fis {
  background-image: url(../images/flags/1x1/mw.svg);
}

.fi-mx {
  background-image: url(../images/flags/4x3/mx.svg);
}
.fi-mx.fis {
  background-image: url(../images/flags/1x1/mx.svg);
}

.fi-my {
  background-image: url(../images/flags/4x3/my.svg);
}
.fi-my.fis {
  background-image: url(../images/flags/1x1/my.svg);
}

.fi-mz {
  background-image: url(../images/flags/4x3/mz.svg);
}
.fi-mz.fis {
  background-image: url(../images/flags/1x1/mz.svg);
}

.fi-na {
  background-image: url(../images/flags/4x3/na.svg);
}
.fi-na.fis {
  background-image: url(../images/flags/1x1/na.svg);
}

.fi-nc {
  background-image: url(../images/flags/4x3/nc.svg);
}
.fi-nc.fis {
  background-image: url(../images/flags/1x1/nc.svg);
}

.fi-ne {
  background-image: url(../images/flags/4x3/ne.svg);
}
.fi-ne.fis {
  background-image: url(../images/flags/1x1/ne.svg);
}

.fi-nf {
  background-image: url(../images/flags/4x3/nf.svg);
}
.fi-nf.fis {
  background-image: url(../images/flags/1x1/nf.svg);
}

.fi-ng {
  background-image: url(../images/flags/4x3/ng.svg);
}
.fi-ng.fis {
  background-image: url(../images/flags/1x1/ng.svg);
}

.fi-ni {
  background-image: url(../images/flags/4x3/ni.svg);
}
.fi-ni.fis {
  background-image: url(../images/flags/1x1/ni.svg);
}

.fi-nl {
  background-image: url(../images/flags/4x3/nl.svg);
}
.fi-nl.fis {
  background-image: url(../images/flags/1x1/nl.svg);
}

.fi-no {
  background-image: url(../images/flags/4x3/no.svg);
}
.fi-no.fis {
  background-image: url(../images/flags/1x1/no.svg);
}

.fi-np {
  background-image: url(../images/flags/4x3/np.svg);
}
.fi-np.fis {
  background-image: url(../images/flags/1x1/np.svg);
}

.fi-nr {
  background-image: url(../images/flags/4x3/nr.svg);
}
.fi-nr.fis {
  background-image: url(../images/flags/1x1/nr.svg);
}

.fi-nu {
  background-image: url(../images/flags/4x3/nu.svg);
}
.fi-nu.fis {
  background-image: url(../images/flags/1x1/nu.svg);
}

.fi-nz {
  background-image: url(../images/flags/4x3/nz.svg);
}
.fi-nz.fis {
  background-image: url(../images/flags/1x1/nz.svg);
}

.fi-om {
  background-image: url(../images/flags/4x3/om.svg);
}
.fi-om.fis {
  background-image: url(../images/flags/1x1/om.svg);
}

.fi-pa {
  background-image: url(../images/flags/4x3/pa.svg);
}
.fi-pa.fis {
  background-image: url(../images/flags/1x1/pa.svg);
}

.fi-pe {
  background-image: url(../images/flags/4x3/pe.svg);
}
.fi-pe.fis {
  background-image: url(../images/flags/1x1/pe.svg);
}

.fi-pf {
  background-image: url(../images/flags/4x3/pf.svg);
}
.fi-pf.fis {
  background-image: url(../images/flags/1x1/pf.svg);
}

.fi-pg {
  background-image: url(../images/flags/4x3/pg.svg);
}
.fi-pg.fis {
  background-image: url(../images/flags/1x1/pg.svg);
}

.fi-ph {
  background-image: url(../images/flags/4x3/ph.svg);
}
.fi-ph.fis {
  background-image: url(../images/flags/1x1/ph.svg);
}

.fi-pk {
  background-image: url(../images/flags/4x3/pk.svg);
}
.fi-pk.fis {
  background-image: url(../images/flags/1x1/pk.svg);
}

.fi-pl {
  background-image: url(../images/flags/4x3/pl.svg);
}
.fi-pl.fis {
  background-image: url(../images/flags/1x1/pl.svg);
}

.fi-pm {
  background-image: url(../images/flags/4x3/pm.svg);
}
.fi-pm.fis {
  background-image: url(../images/flags/1x1/pm.svg);
}

.fi-pn {
  background-image: url(../images/flags/4x3/pn.svg);
}
.fi-pn.fis {
  background-image: url(../images/flags/1x1/pn.svg);
}

.fi-pr {
  background-image: url(../images/flags/4x3/pr.svg);
}
.fi-pr.fis {
  background-image: url(../images/flags/1x1/pr.svg);
}

.fi-ps {
  background-image: url(../images/flags/4x3/ps.svg);
}
.fi-ps.fis {
  background-image: url(../images/flags/1x1/ps.svg);
}

.fi-pt {
  background-image: url(../images/flags/4x3/pt.svg);
}
.fi-pt.fis {
  background-image: url(../images/flags/1x1/pt.svg);
}

.fi-pw {
  background-image: url(../images/flags/4x3/pw.svg);
}
.fi-pw.fis {
  background-image: url(../images/flags/1x1/pw.svg);
}

.fi-py {
  background-image: url(../images/flags/4x3/py.svg);
}
.fi-py.fis {
  background-image: url(../images/flags/1x1/py.svg);
}

.fi-qa {
  background-image: url(../images/flags/4x3/qa.svg);
}
.fi-qa.fis {
  background-image: url(../images/flags/1x1/qa.svg);
}

.fi-re {
  background-image: url(../images/flags/4x3/re.svg);
}
.fi-re.fis {
  background-image: url(../images/flags/1x1/re.svg);
}

.fi-ro {
  background-image: url(../images/flags/4x3/ro.svg);
}
.fi-ro.fis {
  background-image: url(../images/flags/1x1/ro.svg);
}

.fi-rs {
  background-image: url(../images/flags/4x3/rs.svg);
}
.fi-rs.fis {
  background-image: url(../images/flags/1x1/rs.svg);
}

.fi-ru {
  background-image: url(../images/flags/4x3/ru.svg);
}
.fi-ru.fis {
  background-image: url(../images/flags/1x1/ru.svg);
}

.fi-rw {
  background-image: url(../images/flags/4x3/rw.svg);
}
.fi-rw.fis {
  background-image: url(../images/flags/1x1/rw.svg);
}

.fi-sa {
  background-image: url(../images/flags/4x3/sa.svg);
}
.fi-sa.fis {
  background-image: url(../images/flags/1x1/sa.svg);
}

.fi-sb {
  background-image: url(../images/flags/4x3/sb.svg);
}
.fi-sb.fis {
  background-image: url(../images/flags/1x1/sb.svg);
}

.fi-sc {
  background-image: url(../images/flags/4x3/sc.svg);
}
.fi-sc.fis {
  background-image: url(../images/flags/1x1/sc.svg);
}

.fi-sd {
  background-image: url(../images/flags/4x3/sd.svg);
}
.fi-sd.fis {
  background-image: url(../images/flags/1x1/sd.svg);
}

.fi-se {
  background-image: url(../images/flags/4x3/se.svg);
}
.fi-se.fis {
  background-image: url(../images/flags/1x1/se.svg);
}

.fi-sg {
  background-image: url(../images/flags/4x3/sg.svg);
}
.fi-sg.fis {
  background-image: url(../images/flags/1x1/sg.svg);
}

.fi-sh {
  background-image: url(../images/flags/4x3/sh.svg);
}
.fi-sh.fis {
  background-image: url(../images/flags/1x1/sh.svg);
}

.fi-si {
  background-image: url(../images/flags/4x3/si.svg);
}
.fi-si.fis {
  background-image: url(../images/flags/1x1/si.svg);
}

.fi-sj {
  background-image: url(../images/flags/4x3/sj.svg);
}
.fi-sj.fis {
  background-image: url(../images/flags/1x1/sj.svg);
}

.fi-sk {
  background-image: url(../images/flags/4x3/sk.svg);
}
.fi-sk.fis {
  background-image: url(../images/flags/1x1/sk.svg);
}

.fi-sl {
  background-image: url(../images/flags/4x3/sl.svg);
}
.fi-sl.fis {
  background-image: url(../images/flags/1x1/sl.svg);
}

.fi-sm {
  background-image: url(../images/flags/4x3/sm.svg);
}
.fi-sm.fis {
  background-image: url(../images/flags/1x1/sm.svg);
}

.fi-sn {
  background-image: url(../images/flags/4x3/sn.svg);
}
.fi-sn.fis {
  background-image: url(../images/flags/1x1/sn.svg);
}

.fi-so {
  background-image: url(../images/flags/4x3/so.svg);
}
.fi-so.fis {
  background-image: url(../images/flags/1x1/so.svg);
}

.fi-sr {
  background-image: url(../images/flags/4x3/sr.svg);
}
.fi-sr.fis {
  background-image: url(../images/flags/1x1/sr.svg);
}

.fi-ss {
  background-image: url(../images/flags/4x3/ss.svg);
}
.fi-ss.fis {
  background-image: url(../images/flags/1x1/ss.svg);
}

.fi-st {
  background-image: url(../images/flags/4x3/st.svg);
}
.fi-st.fis {
  background-image: url(../images/flags/1x1/st.svg);
}

.fi-sv {
  background-image: url(../images/flags/4x3/sv.svg);
}
.fi-sv.fis {
  background-image: url(../images/flags/1x1/sv.svg);
}

.fi-sx {
  background-image: url(../images/flags/4x3/sx.svg);
}
.fi-sx.fis {
  background-image: url(../images/flags/1x1/sx.svg);
}

.fi-sy {
  background-image: url(../images/flags/4x3/sy.svg);
}
.fi-sy.fis {
  background-image: url(../images/flags/1x1/sy.svg);
}

.fi-sz {
  background-image: url(../images/flags/4x3/sz.svg);
}
.fi-sz.fis {
  background-image: url(../images/flags/1x1/sz.svg);
}

.fi-tc {
  background-image: url(../images/flags/4x3/tc.svg);
}
.fi-tc.fis {
  background-image: url(../images/flags/1x1/tc.svg);
}

.fi-td {
  background-image: url(../images/flags/4x3/td.svg);
}
.fi-td.fis {
  background-image: url(../images/flags/1x1/td.svg);
}

.fi-tf {
  background-image: url(../images/flags/4x3/tf.svg);
}
.fi-tf.fis {
  background-image: url(../images/flags/1x1/tf.svg);
}

.fi-tg {
  background-image: url(../images/flags/4x3/tg.svg);
}
.fi-tg.fis {
  background-image: url(../images/flags/1x1/tg.svg);
}

.fi-th {
  background-image: url(../images/flags/4x3/th.svg);
}
.fi-th.fis {
  background-image: url(../images/flags/1x1/th.svg);
}

.fi-tj {
  background-image: url(../images/flags/4x3/tj.svg);
}
.fi-tj.fis {
  background-image: url(../images/flags/1x1/tj.svg);
}

.fi-tk {
  background-image: url(../images/flags/4x3/tk.svg);
}
.fi-tk.fis {
  background-image: url(../images/flags/1x1/tk.svg);
}

.fi-tl {
  background-image: url(../images/flags/4x3/tl.svg);
}
.fi-tl.fis {
  background-image: url(../images/flags/1x1/tl.svg);
}

.fi-tm {
  background-image: url(../images/flags/4x3/tm.svg);
}
.fi-tm.fis {
  background-image: url(../images/flags/1x1/tm.svg);
}

.fi-tn {
  background-image: url(../images/flags/4x3/tn.svg);
}
.fi-tn.fis {
  background-image: url(../images/flags/1x1/tn.svg);
}

.fi-to {
  background-image: url(../images/flags/4x3/to.svg);
}
.fi-to.fis {
  background-image: url(../images/flags/1x1/to.svg);
}

.fi-tr {
  background-image: url(../images/flags/4x3/tr.svg);
}
.fi-tr.fis {
  background-image: url(../images/flags/1x1/tr.svg);
}

.fi-tt {
  background-image: url(../images/flags/4x3/tt.svg);
}
.fi-tt.fis {
  background-image: url(../images/flags/1x1/tt.svg);
}

.fi-tv {
  background-image: url(../images/flags/4x3/tv.svg);
}
.fi-tv.fis {
  background-image: url(../images/flags/1x1/tv.svg);
}

.fi-tw {
  background-image: url(../images/flags/4x3/tw.svg);
}
.fi-tw.fis {
  background-image: url(../images/flags/1x1/tw.svg);
}

.fi-tz {
  background-image: url(../images/flags/4x3/tz.svg);
}
.fi-tz.fis {
  background-image: url(../images/flags/1x1/tz.svg);
}

.fi-ua {
  background-image: url(../images/flags/4x3/ua.svg);
}
.fi-ua.fis {
  background-image: url(../images/flags/1x1/ua.svg);
}

.fi-ug {
  background-image: url(../images/flags/4x3/ug.svg);
}
.fi-ug.fis {
  background-image: url(../images/flags/1x1/ug.svg);
}

.fi-um {
  background-image: url(../images/flags/4x3/um.svg);
}
.fi-um.fis {
  background-image: url(../images/flags/1x1/um.svg);
}

.fi-us {
  background-image: url(../images/flags/4x3/us.svg);
}
.fi-us.fis {
  background-image: url(../images/flags/1x1/us.svg);
}

.fi-uy {
  background-image: url(../images/flags/4x3/uy.svg);
}
.fi-uy.fis {
  background-image: url(../images/flags/1x1/uy.svg);
}

.fi-uz {
  background-image: url(../images/flags/4x3/uz.svg);
}
.fi-uz.fis {
  background-image: url(../images/flags/1x1/uz.svg);
}

.fi-va {
  background-image: url(../images/flags/4x3/va.svg);
}
.fi-va.fis {
  background-image: url(../images/flags/1x1/va.svg);
}

.fi-vc {
  background-image: url(../images/flags/4x3/vc.svg);
}
.fi-vc.fis {
  background-image: url(../images/flags/1x1/vc.svg);
}

.fi-ve {
  background-image: url(../images/flags/4x3/ve.svg);
}
.fi-ve.fis {
  background-image: url(../images/flags/1x1/ve.svg);
}

.fi-vg {
  background-image: url(../images/flags/4x3/vg.svg);
}
.fi-vg.fis {
  background-image: url(../images/flags/1x1/vg.svg);
}

.fi-vi {
  background-image: url(../images/flags/4x3/vi.svg);
}
.fi-vi.fis {
  background-image: url(../images/flags/1x1/vi.svg);
}

.fi-vn {
  background-image: url(../images/flags/4x3/vn.svg);
}
.fi-vn.fis {
  background-image: url(../images/flags/1x1/vn.svg);
}

.fi-vu {
  background-image: url(../images/flags/4x3/vu.svg);
}
.fi-vu.fis {
  background-image: url(../images/flags/1x1/vu.svg);
}

.fi-wf {
  background-image: url(../images/flags/4x3/wf.svg);
}
.fi-wf.fis {
  background-image: url(../images/flags/1x1/wf.svg);
}

.fi-ws {
  background-image: url(../images/flags/4x3/ws.svg);
}
.fi-ws.fis {
  background-image: url(../images/flags/1x1/ws.svg);
}

.fi-ye {
  background-image: url(../images/flags/4x3/ye.svg);
}
.fi-ye.fis {
  background-image: url(../images/flags/1x1/ye.svg);
}

.fi-yt {
  background-image: url(../images/flags/4x3/yt.svg);
}
.fi-yt.fis {
  background-image: url(../images/flags/1x1/yt.svg);
}

.fi-za {
  background-image: url(../images/flags/4x3/za.svg);
}
.fi-za.fis {
  background-image: url(../images/flags/1x1/za.svg);
}

.fi-zm {
  background-image: url(../images/flags/4x3/zm.svg);
}
.fi-zm.fis {
  background-image: url(../images/flags/1x1/zm.svg);
}

.fi-zw {
  background-image: url(../images/flags/4x3/zw.svg);
}
.fi-zw.fis {
  background-image: url(../images/flags/1x1/zw.svg);
}

.fi-ac {
  background-image: url(../images/flags/4x3/ac.svg);
}
.fi-ac.fis {
  background-image: url(../images/flags/1x1/ac.svg);
}

.fi-cefta {
  background-image: url(../images/flags/4x3/cefta.svg);
}
.fi-cefta.fis {
  background-image: url(../images/flags/1x1/cefta.svg);
}

.fi-cp {
  background-image: url(../images/flags/4x3/cp.svg);
}
.fi-cp.fis {
  background-image: url(../images/flags/1x1/cp.svg);
}

.fi-dg {
  background-image: url(../images/flags/4x3/dg.svg);
}
.fi-dg.fis {
  background-image: url(../images/flags/1x1/dg.svg);
}

.fi-ea {
  background-image: url(../images/flags/4x3/ea.svg);
}
.fi-ea.fis {
  background-image: url(../images/flags/1x1/ea.svg);
}

.fi-es-ct {
  background-image: url(../images/flags/4x3/es-ct.svg);
}
.fi-es-ct.fis {
  background-image: url(../images/flags/1x1/es-ct.svg);
}

.fi-es-ga {
  background-image: url(../images/flags/4x3/es-ga.svg);
}
.fi-es-ga.fis {
  background-image: url(../images/flags/1x1/es-ga.svg);
}

.fi-es-pv {
  background-image: url(../images/flags/4x3/es-pv.svg);
}
.fi-es-pv.fis {
  background-image: url(../images/flags/1x1/es-pv.svg);
}

.fi-eu {
  background-image: url(../images/flags/4x3/eu.svg);
}
.fi-eu.fis {
  background-image: url(../images/flags/1x1/eu.svg);
}

.fi-gb-eng {
  background-image: url(../images/flags/4x3/gb-eng.svg);
}
.fi-gb-eng.fis {
  background-image: url(../images/flags/1x1/gb-eng.svg);
}

.fi-gb-nir {
  background-image: url(../images/flags/4x3/gb-nir.svg);
}
.fi-gb-nir.fis {
  background-image: url(../images/flags/1x1/gb-nir.svg);
}

.fi-gb-sct {
  background-image: url(../images/flags/4x3/gb-sct.svg);
}
.fi-gb-sct.fis {
  background-image: url(../images/flags/1x1/gb-sct.svg);
}

.fi-gb-wls {
  background-image: url(../images/flags/4x3/gb-wls.svg);
}
.fi-gb-wls.fis {
  background-image: url(../images/flags/1x1/gb-wls.svg);
}

.fi-ic {
  background-image: url(../images/flags/4x3/ic.svg);
}
.fi-ic.fis {
  background-image: url(../images/flags/1x1/ic.svg);
}

.fi-ta {
  background-image: url(../images/flags/4x3/ta.svg);
}
.fi-ta.fis {
  background-image: url(../images/flags/1x1/ta.svg);
}

.fi-un {
  background-image: url(../images/flags/4x3/un.svg);
}
.fi-un.fis {
  background-image: url(../images/flags/1x1/un.svg);
}

.fi-xk {
  background-image: url(../images/flags/4x3/xk.svg);
}
.fi-xk.fis {
  background-image: url(../images/flags/1x1/xk.svg);
}

.grid_3, .list_features {
  grid-template-columns: repeat(3, 1fr);
}
.grid_4, .statistic__list {
  grid-template-columns: repeat(4, 1fr);
}
@media screen and (min-width: 1024px) {
  .grid, .statistic__list, .list_features {
    display: grid;
    grid-gap: 1.875rem;
  }
}

.logo {
  transition: opacity var(--transition-duration) var(--transition-easing);
}
.logo:hover {
  opacity: 0.9;
}
.logo__image {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 42px;
  background-repeat: no-repeat;
  background-image: url(../images/logo/logo-mob.svg);
  background-size: contain;
}
@media screen and (min-width: 768px) {
  .logo__image {
    width: 98px;
    height: 24px;
    background-image: url(../images/logo/logo.svg);
  }
}
@media screen and (min-width: 1920px) {
  .logo__image {
    width: 140px;
    height: 34px;
  }
}

.form__input {
  position: relative;
}
.form__buttons .button + .button {
  margin-left: 10px;
}
.form__heading, .form__sub-heading {
  line-height: 1.1;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}
.form__heading {
  margin: 0 0 10px;
  font-size: 2.5rem;
}
.form__sub-heading {
  margin: 0 0 20px;
  font-size: 1.25rem;
  background-image: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.form__row {
  margin-bottom: 15px;
}
.form__row-wrapper {
  position: relative;
}
.form__icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 1;
}
.form__label {
  font-size: 0.875rem;
  color: var(--gray);
}
.form__link {
  background-image: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.form__footer .form__link {
  font-weight: 500;
}
.form__footer.is-flex {
  justify-content: space-between;
  align-items: center;
}
.form__footer.is-flex .button + .button {
  margin-left: 5px;
}
.form_horizontal .ui-input,
.form_horizontal .ui-textarea,
.form_horizontal .ui-select,
.form_horizontal .custom-select__option_selected,
.form_horizontal .button,
.form_horizontal .form__text,
.form_horizontal .form__error {
  width: 100%;
}
.form_horizontal .form__label {
  display: block;
  margin-bottom: 5px;
}
.form_horizontal .form__text {
  display: block;
  margin-top: 20px;
  text-align: center;
  color: var(--light-gray);
}
.form_horizontal .is-flex {
  justify-content: space-between;
}
.form_horizontal .is-flex .ui-select, .form_horizontal .is-flex .custom-select__option_selected,
.form_horizontal .is-flex .custom-radio {
  flex: 1;
}
.form_horizontal .is-flex .ui-select:not(:last-child), .form_horizontal .is-flex .custom-select__option_selected:not(:last-child),
.form_horizontal .is-flex .custom-radio:not(:last-child) {
  margin-right: 10px;
}
.form_horizontal .is-flex .form__link {
  margin-left: 5px;
}
.form .is-error .ui-input, .form .is-error .ui-textarea, .form .is-error .ui-select, .form .is-error .custom-select__option_selected {
  border-color: var(--color-red);
}
.form .form__error {
  display: block;
  margin-top: 5px;
  color: var(--color-red);
}

@media (hover: hover) {
  .form__link:hover {
    cursor: pointer;
  }
}
.link, .chat__close, .modal_tariffs .form__link, .modal__close, .page__close, .button_close {
  transition: opacity var(--transition-duration) var(--transition-easing);
}
.link:hover, .chat__close:hover, .modal_tariffs .form__link:hover, .modal__close:hover, .page__close:hover, .button_close:hover {
  opacity: 0.85;
}

@media (hover: hover) {
  .link, .chat__close, .modal_tariffs .form__link, .modal__close, .page__close, .button_close {
    cursor: pointer;
  }
}
.list, .price, .tabs, .pagination, .social, .dropdown__menu, .menu {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1;
}
.list_payment, .list_tags {
  display: inline-flex;
}
.list_payment .list__item:not(:last-child), .list_tags .list__item:not(:last-child) {
  margin-right: 5px;
}
.list_partners .list__item:first-child {
  margin-bottom: 1rem;
}
.list_features .list__item {
  position: relative;
  text-align: center;
}
.list_features .list__item .animation {
  position: absolute;
  z-index: 10;
}
.list_page .list__item {
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(197, 197, 197, 0.4);
}
.list_page .list__link {
  display: block;
  padding: 16px 0;
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--heading-color);
  text-decoration: none;
}
.list_sort {
  display: flex;
  align-items: center;
}
.list_sort .list__item:not(:last-child) {
  margin-right: 5px;
}
.list_sort .list__item:not(:last-child) .list__link::after {
  content: "/";
  margin-left: 5px;
}
.list_sort .list__item.is-active .list__link {
  color: var(--dark-gray);
}
.list_sort .list__link {
  display: block;
  font-weight: 500;
  color: var(--gray);
}
.list_emoji {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}
.list_hobbies {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.list_help, .list_marked {
  margin-top: 10px;
  margin-bottom: 20px;
  list-style: none;
  line-height: 1.8;
  text-align: left;
}
.list_help li, .list_marked li {
  position: relative;
  padding: 0 0 0 25px;
}
.list_help li::after, .list_marked li::after {
  content: "";
  position: absolute;
  display: block;
  top: 5px;
  left: 0;
  width: 18px;
  height: 22px;
  background: url(../images/icons/triangle-gradient.svg) no-repeat center center;
  background-size: contain;
}
.list_help .list__link, .list_marked .list__link {
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 1279px) {
  .list_features .media__content {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .list_features .list__item .animation {
    transform: rotate(90deg);
  }
  .list_features .list__item:nth-child(1) .animation {
    right: 0;
  }
  .list_features .list__item:nth-child(2) .animation {
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  .list_features .list__item:nth-child(1) .animation {
    right: -100px;
  }
  .list_features .list__item:nth-child(2) .animation {
    left: -100px;
  }
}
@media screen and (min-width: 1024px) {
  .list_payment .list__icon {
    width: 40px;
  }

  .list_features .list__item .animation {
    top: -22px;
    right: 0;
    margin-right: -140px;
  }
  .list_features .list__item:nth-child(2) .animation {
    top: 15%;
  }

  .list_page .list__link {
    padding: 20px 0;
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 768px) {
  .list_emoji {
    grid-template-columns: repeat(8, 1fr);
    gap: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .list_payment .list__icon {
    width: 50px;
  }
  .list_help {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
@media (min-width: 1280px) and (max-width: 1679px) {
  .list_emoji {
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }
}
.title, .icon-box__title, .section__sub-heading, .section__title, .heading {
  margin: 0;
  font-weight: 500;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: var(--color-text);
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

.table_page:not(.table_modal) {
  position: relative;
}
.table_page:not(.table_modal)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: -webkit-linear-gradient(275deg, #17AAFC 0%, #CB77FF 100%);
  background: -o-linear-gradient(275deg, #17AAFC 0%, #CB77FF 100%);
  background: linear-gradient(175deg, #17AAFC 0%, #CB77FF 100%);
}
.table_page thead th, .table_page tbody td {
  padding: 0.3125rem 1.25rem;
  text-align: left;
}
.table_page thead {
  border-radius: 5px;
  background: rgba(51, 41, 68, 0.1);
  color: var(--heading-color);
}
.table_page .title, .table_page .icon-box__title, .table_page .section__sub-heading, .table_page .section__title, .table_page .heading {
  text-align: center;
  color: var(--heading-color);
  white-space: nowrap;
}

@media screen and (max-width: 575px) {
  .table_page:not(.table_responsive) tbody td {
    font-size: 0.75rem;
    padding: 0.3125rem 0.625rem;
  }
}
@media screen and (max-width: 767px) {
  .table_responsive {
    padding-bottom: 30px;
  }
  .table_responsive, .table_responsive thead, .table_responsive tbody, .table_responsive tr {
    display: block;
  }
  .table_responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .table_responsive tr {
    margin: 0 0 1rem 0;
  }
  .table_responsive.table_page td, .table_responsive td {
    display: flex;
    justify-content: space-between;
    margin: 0.5em 0;
    border: none;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: right;
  }
  .table_responsive .table__cell::before {
    position: absolute;
    left: 10px;
    width: 45%;
    padding-left: 10px;
    padding-right: 10px;
    background: rgba(51, 41, 68, 0.1);
    color: var(--heading-color);
    white-space: nowrap;
    font-weight: 600;
    text-align: left;
  }
  .table_responsive .table__date::before {
    content: "Data:";
  }
  .table_responsive .table__description::before {
    content: "Description:";
  }
  .table_responsive .table__credit::before {
    content: "Credit:";
  }
  .table_responsive .table__amount::before {
    content: "Amount:";
  }
}
@media screen and (min-width: 1280px) {
  .table_page thead th, .table_page tbody td {
    padding: 0.3125rem 1.25rem;
  }
  .table_page thead {
    font-size: 1.25rem;
  }
  .table_page tbody {
    font-size: 1.125rem;
  }
  .table_page .title, .table_page .icon-box__title, .table_page .section__sub-heading, .table_page .section__title, .table_page .heading {
    font-size: 1.5625rem;
  }
}
.tooltip {
  display: inline-block;
  position: relative;
  line-height: 1;
}
.tooltip__content {
  width: fit-content;
  color: #fff;
}
.tooltip_secondary .tooltip__content {
  display: block;
  padding: 1px 3px;
  min-width: 20px;
  height: 20px;
  line-height: 18px;
  font-weight: 500;
  font-size: 0.75rem;
  border-radius: 50%;
  background: var(--gradient);
  text-align: center;
}
.tooltip_primary .tooltip__content {
  padding: 1px 4px;
  font-size: 0.625rem;
  background-color: var(--color-light-green);
  border-radius: 0.5rem;
}
.tooltip_abs {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.heading {
  margin: 0;
  font-size: 2.75rem;
  text-transform: capitalize;
}
@media screen and (min-width: 1024px) {
  .heading {
    font-size: 3.75rem;
    text-align: center;
  }
}

.ui-scroll::-webkit-scrollbar, .panel__content::-webkit-scrollbar, .custom-select__options::-webkit-scrollbar {
  width: 6px;
}
.ui-scroll::-webkit-scrollbar-thumb, .panel__content::-webkit-scrollbar-thumb, .custom-select__options::-webkit-scrollbar-thumb {
  background-color: var(--light-gray);
  border-radius: 3px;
}
.ui-scroll::-webkit-scrollbar-track, .panel__content::-webkit-scrollbar-track, .custom-select__options::-webkit-scrollbar-track {
  background-color: rgba(197, 197, 197, 0.2);
  border-radius: 3px;
}

.ui-input {
  padding-left: 15px;
  height: 40px;
  padding-right: 15px;
  line-height: 1.2;
  background-color: var(--nav-bg);
  border: 1px solid var(--button-color3);
  border-radius: 10px;
  font-size: 1rem;
  color: var(--color-text);
}
.ui-input::placeholder {
  color: var(--light-gray);
}

.file-input {
  position: relative;
  vertical-align: middle;
}
.file-input > input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 1.4375rem;
  direction: ltr;
  cursor: pointer;
  opacity: 0;
}
.file-input .ui-input {
  display: block;
  padding: 8px 15px;
  white-space: nowrap;
}

.ui-textarea {
  line-height: 1.2;
  background-color: var(--nav-bg);
  border: 1px solid var(--button-color3);
  padding: 15px;
  border-radius: 10px;
  font-size: 1rem;
  color: var(--color-text);
}
.ui-textarea_md {
  min-height: 200px;
}
.ui-textarea::placeholder {
  color: var(--light-gray);
}

.ui-select, .custom-select__option_selected {
  position: relative;
  background-color: var(--nav-bg);
  border: 1px solid var(--button-color3);
  border-radius: 10px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 1 7 7 7-7' stroke='%23C5C5C5' stroke-width='1.3'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) center;
  padding-left: 1rem;
  padding-right: 2rem;
  height: 40px;
  font-size: 1rem;
  line-height: 1.1;
  color: var(--light-gray);
}
.ui-select::placeholder, .custom-select__option_selected::placeholder {
  color: var(--light-gray);
}

@media (hover: hover) {
  .ui-select, .custom-select__option_selected,
.custom-select {
    cursor: pointer;
  }
}
.ui-radio {
  vertical-align: middle;
  margin: 0 3px 0 0;
  width: 20px;
  height: 20px;
}

.ui-radio + .label {
  user-select: none;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  line-height: 20px;
}

.ui-radio:not(checked) {
  position: absolute;
  opacity: 0;
}

.ui-radio:not(checked) + .label {
  position: relative;
  padding: 0 0 0 25px;
  color: var(--gray);
  font-family: var(--font-base);
}

.ui-radio:not(checked) + .label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: var(--color-white);
  border: 1px solid var(--gray);
  border-radius: 50%;
}

.ui-radio:not(checked) + .label::after {
  content: "";
  position: absolute;
  display: none;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  background: var(--gradient);
  border-radius: 50%;
}

.ui-radio:checked + .label {
  color: var(--dark-gray);
}

.ui-radio:checked + .label::after {
  display: block;
}

.ui-radio:hover + .label::before {
  border: 1px solid var(--dark-gray);
}

.ui-radio_payment:not(checked) + .label {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: var(--nav-bg);
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.25s ease-in-out;
}
.ui-radio_payment:not(checked) + .label:hover {
  filter: none;
  background: #F5F4F4;
}
.ui-radio_payment:not(checked) + .label::after {
  top: 24px;
  left: 22px;
}
.ui-radio_payment:not(checked) + .label::before {
  top: 22px;
  left: 20px;
  border-color: #A7A1A1;
}
.ui-radio_payment:checked + .label {
  background: #F5F4F4;
}
.ui-radio_payment + .label > .label__title {
  padding: 0 0 0 25px;
  color: var(--button-color1);
}
.ui-radio_payment + .label > .label__subtitle {
  margin-left: auto;
}
.ui-radio_payment + .label > .label__subtitle img {
  margin-left: 5px;
}

@media screen and (max-width: 1279px) {
  .ui-radio_payment:not(checked) + .label, .ui-radio_payment + .label > .label__title, .ui-radio_payment + .label > .label__subtitle {
    display: block;
  }

  .ui-radio_payment:not(checked) + .label {
    height: auto;
    padding: 10px;
  }

  .ui-radio_payment + .label > .label__title {
    margin: 10px 0;
    padding-left: 40px;
  }
}
.ui-checkbox {
  vertical-align: top;
  margin: 0 3px 0 0;
  width: 18px;
  height: 18px;
}

.ui-checkbox + .label {
  user-select: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0 0 0 30px;
  font-size: 0.875rem;
  line-height: 1.1;
  color: var(--light-gray);
}

.ui-checkbox:not(checked) {
  position: absolute;
  opacity: 0;
}

.ui-checkbox:not(checked) + .label {
  position: relative;
}

.ui-checkbox:not(checked) + .label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  background-color: var(--color-white);
  border: 1px solid var(--gray);
  transition: all var(--transition-duration) var(--transition-easing);
}

.ui-checkbox:not(checked) + .label::after {
  content: "";
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=) center no-repeat;
  background-size: 75%;
}

.ui-checkbox:checked + .label::before {
  background-color: var(--dark-gray);
  background-clip: content-box;
}

.ui-checkbox:checked + .label::after {
  display: block;
}

.ui-checkbox:hover + .label::before {
  border: 1px solid var(--light-gray);
}

.ui-checkbox:disabled + .label,
.ui-checkbox.disabled + .label {
  opacity: 0.5;
  pointer-events: none;
}

.ui-checkbox:disabled + .label::before,
.ui-checkbox.disabled + .label::before {
  border: 1px solid #dcdbdb;
}

.ui-checkbox:disabled + .label::after,
.ui-checkbox.disabled + .label::after {
  display: none;
}

.ui-switcher {
  display: flex;
  align-items: center;
}
.ui-switcher__box {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 0 0 0 auto;
}
.ui-switcher .icon {
  margin-right: 5px;
  flex: 0 0 1.5rem;
}
.ui-switcher__title {
  font-weight: 500;
  line-height: 1;
  margin-right: 10px;
}
.ui-switcher input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.ui-switcher input[type=checkbox]:checked + .ui-switcher__label {
  background-color: var(--state-danger);
}
.ui-switcher input[type=checkbox]:checked + .ui-switcher__label::after {
  left: calc(100% - 25px);
  transform: translateX(calc(-100% + 24px));
}
.ui-switcher input[type=checkbox]:disabled + .ui-switcher__label, .ui-switcher input[type=checkbox].is-disabled + .ui-switcher__label {
  opacity: 0.5;
  pointer-events: none;
  background-color: #b7b7b7;
}
.ui-switcher .ui-switcher__label {
  display: inline-block;
  position: relative;
  text-indent: -9999px;
  width: 26px;
  height: 14px;
  background-color: var(--state-info);
  border-radius: 10px;
  transition: background-color 0.2s ease-in;
}
.ui-switcher .ui-switcher__label::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
}

@media (hover: hover) {
  .ui-switcher__label:hover {
    cursor: pointer;
  }
}
.ui-range_bg {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  height: 44px;
  padding: 12px 20px;
  box-shadow: 0 10px 20px rgba(19, 19, 19, 0.2);
  background: var(--nav-bg);
  border-radius: 5px;
}
.ui-range:focus {
  outline: none;
}
.ui-range_sm {
  width: 200px;
}
.ui-range__input {
  appearance: none;
  height: 2px;
  background: #c5c5c5;
  background-image: linear-gradient(221.38deg, #5886FF 16.48%, #D00BF0 93.67%);
  background-size: 50% 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
  width: 100%;
}
.ui-range__input:focus {
  outline: none;
}
.ui-range__input::-webkit-slider-thumb {
  appearance: none;
  height: 20px;
  width: 20px;
  background: linear-gradient(221.38deg, #5886FF 16.48%, #D00BF0 93.67%);
  border-radius: 50%;
}
.ui-range__input::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.ui-range__input__label {
  display: block;
  text-align: center;
}
.ui-range_double {
  position: relative;
  margin-top: 20px;
  height: 2px;
  background: #c5c5c5;
  border-radius: 5px;
}
.ui-range_double .ui-range__input {
  position: absolute;
  width: 100%;
  height: 1px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  top: 50%;
  transform: translateY(-50%);
}
.ui-range_double .ui-range__input::-webkit-slider-thumb {
  pointer-events: auto;
  -webkit-appearance: none;
  cursor: pointer;
  margin-bottom: 1px;
}
.ui-range_double .ui-range__input::-moz-range-thumb {
  pointer-events: auto;
  -moz-appearance: none;
  cursor: pointer;
}
.ui-range_double .ui-range__track {
  height: 100%;
  position: absolute;
  border-radius: 5px;
  background: linear-gradient(221.38deg, #5886FF 16.48%, #D00BF0 93.67%);
}

@media (hover: hover) {
  .ui-range__input {
    cursor: pointer;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .ui-range_bg {
    height: 34px;
    padding: 8px 10px;
  }
}
.custom-select {
  position: relative;
  user-select: none;
}
.custom-select__option {
  display: flex;
  align-items: center;
}
.custom-select__option_selected {
  position: relative;
}
.custom-select__options {
  position: absolute;
  top: 105%;
  left: 0;
  right: 0;
  max-height: 150px;
  overflow-y: auto;
  background-color: var(--nav-bg);
  border: 1px solid var(--button-color3);
  border-radius: 10px;
  z-index: 100;
  color: var(--light-gray);
}
.custom-select__options .custom-select__option {
  padding: 0.5rem 1rem;
}
.custom-select__options .custom-select__option:hover {
  background-color: var(--chat-message-bg);
}
.custom-select .icon {
  margin-right: 5px;
}

.custom-radio {
  position: relative;
  user-select: none;
}
.custom-radio__input {
  position: relative;
  display: flex;
}
.custom-radio__icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
.custom-radio__label {
  margin: auto;
  font-size: 1rem;
  line-height: 1.1;
  color: var(--light-gray);
}
.custom-radio input[type=radio] {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.custom-radio input[type=radio]:checked + .custom-radio__input {
  border-color: var(--gray);
}
.custom-radio input[type=radio]:checked + .custom-radio__input::after {
  content: "✓";
  color: var(--state-success);
  position: absolute;
  top: 10px;
}

@media (hover: hover) {
  .custom-radio {
    cursor: pointer;
  }
}
.custom-checkbox {
  position: relative;
  display: inline-block;
}
.custom-checkbox__input {
  position: absolute;
  top: 0;
  opacity: 0;
}
.custom-checkbox__input:checked + .custom-checkbox__label {
  border-color: #D00BF0;
}
.custom-checkbox__label {
  position: relative;
  user-select: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid transparent;
}

.button {
  margin: 0;
  display: inline-block;
  background-color: transparent;
  box-shadow: none;
  border: none;
  vertical-align: top;
  touch-action: manipulation;
  appearance: none;
  user-select: none;
  font-family: var(--font-base);
  font-size: var(--font-size);
  font-weight: 600;
  backface-visibility: hidden;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
}
.button:disabled, .button.disabled {
  box-shadow: none;
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.button__icon {
  position: relative;
}
.button__icon:not(.icon) {
  display: inline-block;
  vertical-align: middle;
}
.button__border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.button_xs {
  height: 38px;
}
.button_xs:not(.button_bordered), .button_xs.button_bordered > span {
  padding: 10px 12px;
}
.button_xs.button_bordered > span {
  padding: 10px 12px;
}
.button_sm {
  height: 44px;
}
.button_sm:not(.button_bordered), .button_sm.button_bordered > span {
  padding-right: 25px;
  padding-left: 25px;
  line-height: 42px;
}
.button_md {
  height: 48px;
}
.button_md:not(.button_bordered), .button_md.button_bordered > span {
  padding-right: 50px;
  padding-left: 50px;
  line-height: 44px;
}
.button_lg {
  height: 70px;
}
.button_lg:not(.button_bordered), .button_lg.button_bordered > span {
  padding: 13px 50px;
  font-size: 1.875rem;
}
.button_light, .button_primary, .button_top, .button_bordered, .button_border, .button_white, .button_payment {
  transition: box-shadow var(--transition-duration) var(--transition-easing);
}
.button_light:hover, .button_primary:hover, .button_top:hover, .button_bordered:hover, .button_border:hover, .button_white:hover, .button_payment:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.button_light:active, .button_primary:active, .button_top:active, .button_bordered:active, .button_border:active, .button_white:active, .button_payment:active {
  box-shadow: none;
}
.button_light, .button_primary {
  text-transform: uppercase;
}
.button_light, .button_white {
  background: var(--body-bg);
  color: var(--button-color1);
}
.button_white {
  box-shadow: 0 10px 20px rgba(19, 19, 19, 0.2);
}
.button_white > span {
  text-transform: none;
  display: inline-block;
}
.button_link {
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.35s opacity ease;
  white-space: nowrap;
}
.button_link:hover {
  opacity: 0.85;
}
.button_social {
  height: 2.125rem;
  line-height: 2rem;
  text-transform: capitalize;
  background: #1877f2;
  padding: 0 1rem;
  font-weight: 700;
  font-size: 0.875rem;
  color: var(--color-white);
  transition: opacity 0.35s ease;
}
.button_social:hover {
  opacity: 0.85;
}
.button_social:hover .icon_like {
  animation: swing 1s ease-in-out;
}
.button_primary {
  position: relative;
  overflow: hidden;
  background: var(--gradient);
  text-transform: uppercase;
  color: var(--color-white);
}
.button_primary::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient-reverse);
  transition: opacity 1s var(--transition-easing);
}
.button_primary:hover::after {
  opacity: 1;
}
.button_primary span:not(.tooltip) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  z-index: 1;
}
.button_full {
  background-color: var(--body-bg);
  transition: background-color var(--transition-duration) var(--transition-easing);
}
.button_full:hover {
  background-color: rgba(255, 255, 255, 0.56);
}
.button_filled {
  padding: 0.25rem;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}
.button_danger {
  border: 1px solid var(--state-danger);
  color: var(--state-danger);
  white-space: nowrap;
}
.button_favorite .button__icon {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url(../images/icons/favorite.svg);
}
.button_favorite.is-active .button__icon {
  background-image: url(../images/icons/favorite-check.svg);
}
.button_favorite.button_round {
  width: 3.125rem;
  height: 3.125rem;
  padding: 4px;
}
.button_favorite.button_round:active, .button_favorite.button_round.is-active {
  background-color: var(--body-bg);
}
.button_gradient {
  position: relative;
  background-color: transparent;
  background-clip: content-box, border-box;
  background-origin: border-box;
  transition: background-color var(--transition-duration) var(--transition-easing);
}
.button_gradient.button_round > span {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.button_rounded, .button_rounded.button_bordered > span {
  border-radius: 1.875rem;
}
.button_round {
  border-radius: 50%;
}
.button_round.button_primary::after {
  border-radius: 50%;
}
.button_round.button_bordered span {
  border-radius: 50%;
}
.button_bordered {
  background: var(--gradient);
  padding: 1px;
}
.button_bordered > span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 5px;
  background: var(--body-bg);
  border-radius: 4px;
}
.button_round_payment {
  margin-left: -27px;
  height: 55px;
  width: 55px;
}
.button_bg {
  padding: 5px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
}
.button_border {
  background: var(--nav-bg);
  border: 1px solid rgba(197, 197, 197, 0.4);
}
.button_payment {
  padding: 0.625rem 0.75rem;
  background: var(--nav-bg);
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}
.button_icon, .button_letter, .button_options, .button_mute, .button_collapse, .button_like, .button_emoji, .button_attachment, .button_play, .button_edit, .button_back, .button_video-play {
  padding: 0;
  line-height: 0;
}

.button_back {
  width: 35px;
  height: 35px;
  padding: 10px;
  color: var(--button-color1);
}

.button_menu {
  padding: 10px;
}
.button_menu > span:not(.tooltip) {
  display: block;
  position: relative;
  width: 20px;
  height: 2px;
}
.button_menu > span:not(.tooltip), .button_menu > span:not(.tooltip)::after, .button_menu > span:not(.tooltip)::before {
  background-color: var(--button-color1);
  border-radius: 2px;
}
.button_menu > span:not(.tooltip)::after, .button_menu > span:not(.tooltip)::before {
  content: "";
  position: absolute;
  right: 0;
  width: 100%;
  height: 2px;
  transition: top var(--transition-duration) var(--transition-easing);
}
.button_menu > span:not(.tooltip)::before {
  top: -5px;
  width: 80%;
}
.button_menu > span:not(.tooltip)::after {
  top: 5px;
  width: 60%;
}
.button_menu:hover > span::before {
  top: -7px;
}
.button_menu:hover > span::after {
  top: 7px;
}

.button_options {
  position: relative;
  width: 20px;
  height: 24px;
  line-height: 0;
  text-align: left;
}
.button_options > span, .button_options > span::after, .button_options > span::before {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin-top: 2px;
  background-color: var(--button-color2);
}
.button_options > span::after, .button_options > span::before {
  content: "";
  position: absolute;
  left: 0;
}
.button_options > span::before {
  top: 0;
}
.button_options > span::after {
  bottom: 0;
}

.button_scroll {
  display: block;
  position: relative;
  padding-top: 50px;
  width: 2px;
  background: rgba(255, 255, 255, 0.35);
  color: var(--color-white);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 2.5rem;
  text-transform: none;
  text-align: center;
  transition: opacity 0.25s ease-in;
}
.button_scroll:hover {
  opacity: 0.85;
}
.button_scroll::after {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 30px;
  background: var(--color-white);
  animation: elasticus 3s cubic-bezier(0, 0, 0.35, 0.1) infinite;
}
.button_scroll .button__text {
  position: absolute;
  top: -40px;
  left: -15px;
}
.button_scroll .button__arrow {
  display: block;
  margin: 0 0 auto -5px;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
}
.button_scroll .button__arrow::after {
  content: "";
  display: block;
  margin: 0;
  padding: 0;
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(135deg);
}

.button_mute {
  position: relative;
  color: var(--color-white);
  transition: opacity var(--transition-duration) var(--transition-easing);
}
.button_mute::after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--state-danger);
  transform: translateY(-50%) rotate(-45deg);
}
.button_mute .button__icon {
  width: 32px;
  height: 32px;
}
.button_mute.is-muted {
  opacity: 0.56;
}
.button_mute.is-muted::after {
  content: "";
}

.button_top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  line-height: 28px;
  border: 0;
  border-radius: 50%;
  background-image: var(--gradient);
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: visibility var(--transition-duration) var(--transition-easing), opacity var(--transition-duration) var(--transition-easing);
  z-index: 100;
  color: #fff;
}
.button_top .button__icon {
  width: 24px;
  height: 24px;
}
.button_top.is-visible {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.button_collapse {
  display: block;
  width: 70px;
  height: 35px;
  margin: auto;
  border: 1px solid rgba(234, 234, 234, 0.7);
  border-radius: 5px;
  color: var(--color-text);
}
.button_collapse .button__icon {
  display: block;
  margin: auto;
}
.button_collapse.is-collapsed .button__icon {
  rotate: 180deg;
}

.button_bookmark {
  height: 60px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 1.25rem;
  font-weight: 500;
  background: var(--button-bg1);
  border-radius: 5px 5px 0 0;
  cursor: default;
  color: var(--profile-title);
}
.button_bookmark .button__icon {
  margin-right: 5px;
}

.button_like {
  width: 24px;
  height: 24px;
  background-image: url(../images/icons/heart.svg);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: default;
}
.button_like.is-active {
  background-image: url(../images/icons/heart-hover.svg);
}

.button_letter {
  width: 60px;
  height: 60px;
  border-radius: 20px;
  display: flex;
  background: var(--button-bg2);
  box-shadow: 0 10px 25px rgba(136, 136, 136, 0.25);
}
.button_letter .button__icon {
  margin: auto;
}

.button_chat span {
  text-transform: none;
}

.button_video-play {
  position: relative;
}
.button_video-play .button__icon {
  border: 2px solid var(--color-white);
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.button_audio-play {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--button-bg3);
  cursor: pointer;
  overflow: hidden;
  color: var(--state-success);
}
.button_audio-play.is-paid {
  color: var(--state-danger);
}
.button_audio-play .button__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button_audio-play .button__icon_stop {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background: #FF4A4A;
}
.button_audio-play.is-play .button__icon_pause {
  display: none;
}
.button_audio-play.is-pause .button__icon_play {
  display: none;
}
.button_audio-play.is-stop .button__icon_play, .button_audio-play.is-stop .button__icon_pause {
  display: none;
}

.button_sound {
  padding: 0 6px;
  height: 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.button_sound .button__icon {
  width: 15px;
  height: 13px;
  margin: 0;
  flex: 0 0 15px;
}
.button_sound .button__circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--gradient);
}
.button_sound .button__wave {
  display: none;
  align-content: center;
  gap: 3px;
  margin-left: 3px;
}
.button_sound.is-animated .button__wave {
  display: flex;
}
.button_sound.is-animated .button__circle {
  animation: wave-animation 2s infinite ease-out;
  opacity: 0;
}
.button_sound.is-animated .button__circle:nth-child(2) {
  animation-delay: 0.65s;
}
.button_sound.is-animated .button__circle:nth-child(3) {
  animation-delay: 1.25s;
}
@media screen and (min-width: 1280px) {
  .button_sound {
    height: 23px;
  }
  .button_sound .button__icon {
    width: 20px;
    height: 17px;
    flex: 0 0 20px;
  }
}

@keyframes wave-animation {
  0% {
    transform: scale(0);
    opacity: 0.85;
  }
  50% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.button_edit {
  padding: 5px;
  background: rgba(197, 197, 197, 0.4);
  border-radius: 5px;
}

.button_presentation {
  position: relative;
}
.button_presentation .button__icon {
  position: absolute;
  right: -4px;
  top: -4px;
  padding: 1px;
  width: 50px;
  height: 50px;
  background: var(--gradient);
  z-index: 10;
}
.button_presentation .button__icon, .button_presentation .button__icon span {
  border-radius: 50%;
}
.button_presentation .button__icon span {
  width: 100%;
  height: 100%;
  display: flex;
  background: var(--body-bg);
}
.button_presentation .button__icon span .icon {
  margin: auto;
}

.button_attachment input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  margin: 0;
  direction: ltr;
  cursor: pointer;
  opacity: 0;
}

.button_send, .button_attachment {
  transition: none;
}

@keyframes elasticus {
  0% {
    transform: translate3d(0, -20px, 0) scaleY(2);
    opacity: 0;
  }
  50% {
    transform: translate3d(0, 10px, 0) scaleY(0.5);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0) scaleY(1);
    opacity: 0;
  }
}
@media (hover: hover) {
  .button:not(.button_like):not(.button_bookmark):not(.button_bg):hover {
    cursor: pointer;
  }
  .button_favorite.button_round:hover {
    background-color: var(--body-bg);
  }
}
@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 10deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .button_favorite.button_round {
    padding: 2px;
  }
  .button_favorite.button_round, .button_favorite.button_round .button__border {
    width: 30px;
    height: 30px;
  }
  .button_favorite.button_round .button__icon {
    width: 16px;
    height: 16px;
  }
  .button_letter {
    width: 34px;
    height: 34px;
  }
  .button_collapse {
    width: 45px;
    height: 25px;
  }
  .button_edit {
    padding: 2px;
  }
  .button_options {
    position: relative;
    width: 18px;
    height: 18px;
  }
  .button_bookmark {
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 0.8571428571rem;
  }
  .button_bookmark .icon {
    width: 14px;
    height: 14px;
  }
  .button_like {
    width: 18px;
    height: 18px;
  }
  .button_presentation .button__icon {
    width: 34px;
    height: 34px;
  }
  .button_delete {
    top: 10px;
    right: 10px;
  }
  .button_sm:not(.price__button) {
    height: 30px;
  }
  .button_sm:not(.price__button):not(.button_bordered), .button_sm:not(.price__button).button_bordered > span {
    padding-right: 15px;
    padding-left: 15px;
    line-height: 28px;
    font-size: 12px;
  }
  .button_md {
    height: 38px;
  }
  .button_md:not(.button_bordered), .button_md.button_bordered > span {
    padding-right: 25px;
    padding-left: 25px;
    line-height: 36px;
  }
  .button_lg {
    height: 50px;
  }
  .button_lg:not(.button_bordered), .button_lg.button_bordered > span {
    padding: 8px 25px;
    font-size: 1.4285714286rem;
  }
}
.navbar__menu {
  display: flex;
  align-items: center;
}
.navbar__menu .menu__item:not(:last-child) {
  padding-right: 15px;
}
.navbar__menu .menu__link {
  position: relative;
  padding: 0 10px;
  font-weight: 700;
  line-height: 1.1;
  color: var(--gray);
  text-transform: uppercase;
}
.navbar__menu .menu__link::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  transform: scale(0);
  transform-origin: center;
  width: 100%;
  height: 3px;
  border-radius: 2px;
  transition: transform 0.35s ease-in-out;
  background: linear-gradient(250.44deg, #5886ff 33.27%, #d00bf0 102.24%);
}
.navbar__menu .menu__link:hover {
  color: var(--dark-gray);
}
.navbar__menu .menu__link:hover::after {
  transform: scale(1);
}

@media screen and (max-width: 1023px) {
  .navbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    padding: 0 30px;
    display: flex;
    opacity: 0;
    visibility: hidden;
    flex-direction: column;
    overflow-y: auto;
    transition: opacity 0.56s ease-in-out;
    z-index: 100;
  }
  .navbar:not(.is-active) {
    pointer-events: none;
  }
  .navbar::after {
    content: " ";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    opacity: 0;
    z-index: 99;
    pointer-events: none;
    transition: opacity 0.35s linear;
    background: rgba(19, 19, 19, 0.75);
    backdrop-filter: blur(10px);
  }
  .navbar.is-active {
    visibility: visible;
    opacity: 1;
  }
  .navbar.is-active::after {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar.is-active .menu__item {
    opacity: 1;
    transform: translateY(0);
  }
  .navbar.is-active .menu__item:nth-child(1) {
    transition-delay: 0.1s;
  }
  .navbar.is-active .menu__item:nth-child(2) {
    transition-delay: 0.2s;
  }
  .navbar.is-active .menu__item:nth-child(3) {
    transition-delay: 0.3s;
  }
  .navbar.is-active .menu__item:nth-child(4) {
    transition-delay: 0.4s;
  }
  .navbar.is-active .menu__item:nth-child(5) {
    transition-delay: 0.5s;
  }
  .navbar.is-active .menu__item:nth-child(6) {
    transition-delay: 0.6s;
  }
  .navbar .menu {
    position: relative;
    z-index: 999;
    flex-direction: column;
    text-align: center;
    margin: auto;
  }
  .navbar .menu__item {
    display: block;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.56s ease-in-out, opacity 0.35s ease-out 0.1s;
  }
  .navbar .menu__item:not(:last-child) {
    margin: 0 0 40px;
    padding-right: 0;
  }
  .navbar .menu__link {
    font-size: 2rem;
  }
  .navbar .menu__link, .navbar .menu__link:hover, .navbar .menu__link:active {
    color: var(--color-white);
  }
}
.menu__link {
  text-decoration: none;
  transition: color var(--transition-duration) var(--transition-easing);
}
.menu_contact {
  margin-bottom: 40px;
  color: var(--dark-gray);
}
.menu_contact .menu__item {
  display: flex;
  line-height: 1.1;
}
.menu_contact .menu__item:not(:last-child) {
  margin-bottom: 20px;
}
.menu_contact .menu__item .icon {
  flex: 0 0 1.5rem;
  margin-right: 20px;
}
.menu_contact .menu__link {
  color: var(--dark-gray);
}
.menu_contact .menu__link:hover {
  color: var(--gray);
}
.menu_contact .icon_phone {
  position: relative;
  top: -2px;
}
.menu_contact .address {
  line-height: 1.4;
}
.menu_settings .menu__item:not(:last-child) {
  margin-bottom: 30px;
}
.menu_settings .menu__item_parent {
  overflow: hidden;
}
.menu_settings .menu__item_parent > .menu__link {
  position: relative;
  z-index: 1;
}
.menu_settings .menu__item_parent > .menu__link::before {
  content: "";
  width: 8px;
  height: 6px;
  margin-right: 5px;
  background-image: url(../images/icons/triangle.svg);
  transition: transform 0.35s ease;
  transform: rotate(180deg);
}
.menu_settings .menu__item_parent.is-active > .menu__link::before {
  transform: none;
}
.menu_settings .menu__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-easing);
}
.menu_settings .menu__link .tooltip {
  top: -5px;
}
.menu_settings .menu__link .icon {
  margin-left: 1rem;
}
.menu_settings .menu__link:hover {
  color: var(--light-gray);
}
.menu_sub .menu__item:not(:last-child) {
  margin-bottom: 15px;
}
.menu_sub .menu__link {
  color: var(--light-gray);
}
.menu_sub .menu__link:hover {
  color: var(--color-text);
}
.menu_sub-settings .menu__item:not(:last-child) {
  margin-bottom: 20px;
}
.menu_upload {
  display: flex;
  justify-content: space-around;
}
.menu_upload input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  direction: ltr;
  opacity: 0;
}
.menu_upload .menu__item {
  position: relative;
}
.menu_upload .icon_image,
.menu_upload .icon_mic,
.menu_upload .icon_video,
.menu_upload .icon_camera {
  width: 28px;
  height: 28px;
}

@media (hover: hover) {
  .menu__title:hover, .menu__link {
    cursor: pointer;
  }
}
@media screen and (min-width: 1280px) {
  .menu_upload {
    display: block;
  }
  .menu_upload .menu__link {
    display: flex;
    align-items: center;
  }
  .menu_upload .icon_image,
.menu_upload .icon_mic,
.menu_upload .icon_video,
.menu_upload .icon_camera {
    width: 18px;
    height: 18px;
    margin-left: auto;
  }
}
.dropdown {
  position: relative;
  z-index: 100;
}
.dropdown__menu {
  position: absolute;
  min-width: auto;
  padding: 20px 0 20px;
  background: var(--nav-bg);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 110;
}
.dropdown__menu::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-image: url(../images/icons/corner.svg);
}
.dropdown__item {
  padding-left: 30px;
  padding-right: 30px;
  white-space: nowrap;
  transition: background-color 0.25s ease-out;
}
.dropdown__item:not(:last-child) .dropdown__link {
  border-bottom: 1px solid rgba(197, 197, 197, 0.3);
}
.dropdown__item:hover {
  background-color: var(--drobdown-hover-bg);
}
.dropdown__item:hover .dropdown__link {
  border-color: transparent;
}
.dropdown__item .is-flex {
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown__item .is-flex .dropdown__title {
  flex: 1;
}
.dropdown__item .is-flex .dropdown__text {
  margin-left: 50px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown__label {
  margin-right: 10px;
}
.dropdown__link {
  display: block;
  padding: 0.625rem 0;
  font-weight: 500;
}
.dropdown__link .icon {
  margin-right: 5px;
}
.dropdown__text {
  color: #C5C5C5;
}
.dropdown_right .dropdown__menu {
  top: calc(100% + 30px);
  right: 20px;
}
.dropdown_right .dropdown__menu::before {
  top: -15px;
  right: 0;
}
.dropdown_bottom .dropdown__menu {
  bottom: calc(100% + 30px);
  left: 20px;
}
.dropdown_bottom .dropdown__menu::before {
  bottom: -15px;
  left: 0;
  transform: rotate(180deg);
}
.dropdown_content .dropdown__menu {
  padding: 20px 15px 25px;
}
.dropdown_content .dropdown__menu .title, .dropdown_content .dropdown__menu .icon-box__title, .dropdown_content .dropdown__menu .section__sub-heading, .dropdown_content .dropdown__menu .section__title, .dropdown_content .dropdown__menu .heading {
  display: block;
  margin-bottom: 8px;
}
.dropdown_content .dropdown__menu .ui-input {
  width: 100%;
}
.dropdown_content .dropdown__menu .button {
  display: block;
  width: 160px;
  margin: 24px auto 0;
}
.dropdown_content .dropdown__menu .is-flex {
  align-items: center;
  gap: 12px;
}
.dropdown_contact .dropdown__menu, .dropdown_donate .dropdown__menu {
  top: calc(100% + 20px);
}
.dropdown_contact .dropdown__menu {
  min-width: 300px;
}

@media screen and (max-width: 575px) {
  .dropdown_contact, .dropdown_donate {
    position: static;
  }
  .dropdown_contact .dropdown__menu, .dropdown_donate .dropdown__menu {
    top: 100%;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  .dropdown_contact .dropdown__menu::before, .dropdown_donate .dropdown__menu::before {
    content: none;
  }
  .dropdown_contact .dropdown__menu {
    min-width: 250px;
  }
}
@media screen and (max-width: 1279px) {
  .dropdown_icon .dropdown__label {
    display: none;
  }
}
@media screen and (min-width: 576px) {
  .dropdown_contact {
    transform: translateX(-70px);
  }
  .dropdown_donate {
    transform: translateX(-40px);
  }
}
@media screen and (min-width: 1280px) {
  .dropdown_contact {
    transform: translateX(-150px);
  }
  .dropdown_donate {
    transform: translateX(-80px);
  }

  .dropdown_icon .dropdown__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1920px) {
  .dropdown_lg .dropdown__menu {
    width: 290px;
  }
  .dropdown_md .dropdown__menu {
    min-width: 240px;
  }
  .dropdown_sm .dropdown__menu {
    min-width: 200px;
  }
  .dropdown_right .dropdown__menu {
    min-width: 240px;
  }
  .dropdown_content .dropdown__menu {
    min-width: 280px;
  }
}
@media (hover: hover) {
  .dropdown__link:hover {
    cursor: pointer;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .dropdown__menu {
    padding: 14px 0 14px;
  }
  .dropdown__item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .dropdown__link {
    font-size: 1rem;
  }
  .dropdown_contact {
    transform: translateX(-120px);
  }
  .dropdown_donate {
    transform: translateX(-65px);
  }
}
.nav__header .button_menu {
  margin-right: 8px;
}
.nav__container {
  padding: 50px 50px 60px;
}

@media screen and (min-width: 1280px) {
  .nav__container {
    padding: 1.875rem;
  }
  .nav__menu-sub.menu_sub-settings {
    width: 70%;
  }
}
@media screen and (min-width: 2560px) {
  .nav__container {
    padding: 50px;
  }
}
.navigation {
  height: calc(var(--vh, 1vh) * 100);
  background: var(--nav-bg);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px 0 0 10px;
  overflow-y: auto;
}
.navigation__close {
  position: absolute;
  top: 15px;
  left: 15px;
  display: block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  z-index: 10;
}
.navigation__close::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 2px;
  background: var(--gradient);
}
.navigation__menu:not(:last-of-type) {
  padding-bottom: 15px;
  border-bottom: 2px solid rgba(197, 197, 197, 0.15);
}
.navigation__menu:not(:first-of-type) {
  padding-top: 20px;
}
.navigation__menu .menu__item {
  position: relative;
}
.navigation__menu .menu__item .badge {
  position: absolute;
  top: -4px;
  right: -6px;
  z-index: 20;
}
.navigation__menu-sub {
  margin-top: 20px;
  margin-left: auto;
}
.navigation__menu-sub .ui-switcher {
  justify-content: flex-end;
}

@media screen and (min-width: 1280px) {
  .navigation {
    background-image: url(../images/navigation/bg.png);
    background-repeat: no-repeat;
    background-position: 0 0;
  }
  .navigation__menu-sub {
    margin-top: 30px;
  }
  .navigation .menu_sub-settings {
    width: 180px;
  }
}
.poster {
  position: relative;
}
.poster::after {
  content: "";
  display: block;
}
.poster__media, .poster__overlay {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}
.poster__overlay {
  display: flex;
}
.poster__holder {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.poster__title {
  margin: auto;
  font-size: 12vh;
  color: #fff;
  text-transform: uppercase;
}
.poster_horizontal::after {
  padding-bottom: 56.25%;
}
.poster_personal {
  aspect-ratio: 380/530;
}
.poster_member::after {
  padding-bottom: 97.28%;
}
.poster_member .poster__media {
  border-radius: 10px 10px 0 0;
}
.poster_gallery::after {
  padding-bottom: 162%;
}
.poster_gallery .poster__media_holder {
  object-fit: contain;
  object-position: 60%;
}

@media (hover: hover) {
  .poster_member .poster__media {
    cursor: pointer;
  }
}
@media screen and (max-width: 575px) {
  .poster_personal .poster__holder {
    position: absolute;
    bottom: 50px;
  }
}
@media screen and (min-width: 576px) {
  .poster_personal {
    aspect-ratio: 16/9;
  }
}
@media screen and (min-width: 1280px) {
  .poster_gallery::after {
    padding-bottom: 53.5%;
  }
  .poster_personal {
    aspect-ratio: 250/336;
  }
}
.address__title {
  font-weight: 700;
}
.address__title, .address__text {
  display: block;
}

:root {
  --avatar-size-xs: 40px;
  --avatar-size-sm: 50px;
  --avatar-size-md: 72px;
  --avatar-size-lg: 80px;
  --avatar-size-xl: 100px;
}

.avatar {
  display: block;
  position: relative;
  background-size: cover;
  overflow: hidden;
  border-radius: 5px;
  object-fit: cover;
  object-position: top;
}
.avatar_xs {
  width: var(--avatar-size-xs);
  height: var(--avatar-size-xs);
}
.avatar_sm {
  width: var(--avatar-size-sm);
  height: var(--avatar-size-sm);
}
.avatar_md {
  width: var(--avatar-size-md);
  height: var(--avatar-size-md);
}
.avatar_lg {
  width: var(--avatar-size-lg);
  height: var(--avatar-size-lg);
}
.avatar_xl {
  width: var(--avatar-size-xl);
  height: var(--avatar-size-xl);
}
.avatar_holder {
  position: relative;
  padding: 0;
  background: rgba(197, 197, 197, 0.2);
  overflow: hidden;
}
.avatar_holder .avatar__image {
  position: absolute;
  top: 0.3125rem;
  left: 50%;
  transform: translateX(-50%);
}

.media__icon {
  display: inline-block;
  padding: 15px 20px 23px;
  background: #ebe1ff;
  border-radius: 25px 25px 25px 0;
}
.media__title {
  margin-top: 60px;
  margin-bottom: 30px;
  font-size: 2rem;
  line-height: 1.1;
  text-transform: uppercase;
}
.media__content {
  line-height: 1.4;
}

@media screen and (min-width: 1024px) {
  .media__icon {
    padding: 0;
    background: none;
    border-radius: 0;
  }
}
.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 3.625rem 1.6875rem 1.5rem;
  background: linear-gradient(360deg, #131313 -25%, rgba(19, 19, 19, 0) 100%);
  color: var(--color-white);
  z-index: 5;
}

.person__name {
  font-weight: 700;
  line-height: 1;
}
.person__age_inline::before {
  content: ",";
  margin-right: 5px;
}
.person__avatar {
  flex: 1;
  background: var(--body-bg);
  padding: 5px;
}
.person__date .icon {
  position: relative;
  top: -2px;
  margin-right: 8px;
}
.person__icon {
  display: block;
  background: none;
}
.person__location {
  display: block;
}
.person__location .icon {
  margin-right: 5px;
  width: 12px;
}
.person_card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.person_card .person__info {
  position: relative;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.person_card .person__info .person__location {
  margin-top: 5px;
  max-width: 100%;
  flex-basis: 100%;
  font-size: 0.8571428571rem;
}
.person_card .person__icon {
  margin-left: 5px;
}
.person_card .person__name,
.person_card .person__age {
  font-size: 1rem;
}
.person_card .person__name {
  white-space: nowrap;
  max-width: 130px;
}
.person_card .person__age {
  color: var(--color-white);
}

@media screen and (min-width: 1920px) {
  .person .person__name,
.person .person__age {
    font-size: 1.25rem;
  }
  .person .person__info .person__location {
    font-size: 1rem;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .person_card .icon_zodiac {
    width: 18px;
    height: 18px;
  }
  .person_card .person__name, .person_card .person__age {
    font-size: 1rem;
  }
  .person_card .person_inline .person__info {
    font-size: 1.4285714286rem;
  }
  .person_card .person__info .person__location {
    font-size: 0.8571428571rem;
  }
}
.card__col {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card__icon {
  display: inline-block;
  position: absolute;
  z-index: 10;
  line-height: 1;
  top: 10px;
}
.card__icon_new {
  left: 20px;
}
.card__icon_view {
  padding: 5px;
  right: 20px;
  background: rgba(19, 19, 19, 0.3);
  border-radius: 2px;
  display: flex;
  align-items: center;
}
.card_member {
  background: var(--body-bg);
  box-shadow: 0 10px 25px rgba(126, 126, 126, 0.08);
  border-radius: 10px;
}
.card_member .card__meta {
  display: flex;
  justify-content: space-between;
}
.card_member .card__body {
  padding: 10px 25px 25px;
}
.card_member .card__title {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1.125rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card_member .card__buttons {
  margin: 0 0 0 auto;
}
.card_member .card__buttons .button + .button {
  margin-left: 10px;
}
.card_member .person__name, .card_member .person__age {
  display: block;
}
.card_member .person_age {
  font-size: 0.75rem;
  color: rgba(126, 126, 126, 0.8);
}
.card_member .person__avatar {
  margin-top: -35px;
  margin-right: 12px;
  position: relative;
  z-index: 12;
}
.card_member .person__name {
  margin-bottom: 5px;
  max-width: 130px;
}
@media screen and (max-width: 767px) {
  .card_member .button_more {
    font-size: 0.875rem;
  }
  .card_member .button_social,
.card_member .person__date {
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 768px) {
  .card_member .card__body {
    padding: 13px 10px 20px;
  }
  .card_member .card__title {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1280px) {
  .card_member .card__body {
    padding: 13px 20px 30px;
  }
}
.card_gallery {
  position: relative;
}
.card_gallery .card__media, .card_gallery .card__holder {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.card_gallery .card__media_holder, .card_gallery .card__holder_holder {
  object-fit: contain;
}
.card_gallery:not(.card_mobile) .card__caption {
  padding: 58px 27px 24px;
}
.card_gallery:not(.card_mobile) .card__media, .card_gallery:not(.card_mobile) .card__holder {
  border-radius: 10px;
}
.card_gallery .person_card .status {
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 1;
  font-size: 0.875rem;
  background: rgba(0, 0, 0, 0.35);
}
.card_gallery .card__footer {
  margin-top: 10px;
  margin-bottom: 34px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 26px;
}
.card_gallery .card__photo {
  position: relative;
  overflow: hidden;
  padding-bottom: 71%;
}
.card_gallery .card__photo .card__media, .card_gallery .card__photo .card__holder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.card_gallery.card_mobile .card__caption {
  padding: 45% 20px 24px;
}
.card_gallery.card_mobile .person_card .status {
  top: -24px;
}
.card_gallery.card_mobile .person_card .person__name, .card_gallery.card_mobile .person_card .person__age {
  font-size: 1.25rem;
}
.card_gallery.card_mobile .person_card .person__info .person__location {
  font-size: 1rem;
}
.card_single .card__buttons {
  text-align: right;
}
.card_single .poster::after {
  padding-bottom: 46.75%;
}
.card_single .poster__media {
  border-radius: 10px 10px 0 0;
}
.card.is-blocked {
  overflow: hidden;
}
.card.is-blocked * {
  pointer-events: none;
}
.card.is-blocked .card__media {
  filter: blur(10px);
}
.card_personal .card__content p {
  margin: 0;
}

@media (hover: hover) {
  .card_person {
    transition: box-shadow 0.35s ease-in-out;
  }
  .card_person:hover {
    box-shadow: 0 5px 15px rgba(126, 126, 126, 0.08);
  }
}
@media screen and (max-width: 1279px) {
  .card_gallery .poster__media {
    border-radius: 0;
  }
}
@media screen and (min-width: 1280px) {
  .card_gallery:not(.card_mobile) .card__caption {
    padding: 8px 20px 20px;
    border-radius: 0 0 10px 10px;
  }
  .card_gallery:not(.card_mobile) .button_favorite {
    width: 85px;
    height: 28px;
    font-size: 0.7142857143rem;
  }
  .card_gallery .person_card .status {
    top: -20px;
    font-size: 0.8571428571rem;
  }
}
@media screen and (min-width: 1680px) {
  .card_gallery:not(.card_mobile) .card__caption {
    padding: 8px 30px 30px;
  }
}
@media screen and (min-width: 1920px) {
  .card_gallery:not(.card_mobile) .card__caption {
    padding: 8px 32px 30px;
  }
  .card_gallery:not(.card_mobile) .button_favorite {
    width: 130px;
    height: 38px;
    font-size: 1rem;
  }
  .card_gallery:not(.card_mobile) .person_card .status {
    font-size: 0.875rem;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .card_gallery:not(.card_mobile) .icon_new {
    width: 32px;
    height: 23px;
  }
}
.social {
  display: flex;
  line-height: 1;
}
.social__link {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  transition: opacity var(--transition-duration) var(--transition-easing);
}
.social__link:hover {
  opacity: 0.85;
}

.social_light .social__link {
  color: #fff;
}

.header {
  padding: 0.75rem 0.9375rem;
}
.header__container {
  display: flex;
  align-items: center;
}
.header .content {
  display: contents;
}
.header:not(.header_mobile) .header__container {
  justify-content: space-between;
}
.header_dark {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(51, 41, 68, 0) 100%);
}
.header_dark .menu__link, .header_dark .menu__link:hover {
  color: var(--color-white);
}
.header_dark .button_link {
  color: #fff;
}
.header_dark .button_sign {
  display: none;
}
.header_dark .button_menu span,
.header_dark .button_menu span::after,
.header_dark .button_menu span::before {
  background-color: var(--color-white);
}
.header_flex {
  display: flex;
  align-items: center;
}
.header_shadow {
  box-shadow: 0 5px 20px rgba(197, 197, 197, 0.1);
}
.header_light {
  background-color: var(--color-white);
}
.header_light .logo {
  color: #332944;
}
.header_light .logo__image.is-light {
  display: none;
}
.header_light .button_link {
  color: var(--dark-gray);
  transition: color 0.25s ease;
}
.header_light .button_link:hover {
  color: var(--gray);
}
.header_light .button_sign {
  display: inline-block;
}
.header_fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 110;
}
.header_fixed ~ div > .page_static {
  padding-top: 120px;
}
.header_mobile {
  min-height: 60px;
  padding: 8px 15px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(20px);
}

@media screen and (max-width: 1279px) {
  .header .button_link .button__text {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .header__actions {
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .header_dark .logo__image {
    background-image: url(../images/logo/logo-white.svg);
  }
}
@media screen and (min-width: 1024px) {
  .header:not(.header_mobile) {
    padding: 20px 80px;
  }
  .header:not(.header_mobile) .content {
    max-width: 1920px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .header:not(.header_mobile) .button_menu {
    display: none;
  }
  .header:not(.header_mobile) .navbar {
    margin: 0 auto;
  }
}
@media screen and (min-width: 1280px) {
  .header_fixed ~ div > .page_static {
    padding-top: 160px;
  }
}
@media screen and (min-width: 1440px) {
  .header {
    padding: 20px 50px 18px;
  }
}
.sidebar.is-fixed {
  position: fixed;
  top: 20px;
  left: 0;
}
.sidebar.is-fixed .sidebar__nav .nav__container {
  top: -20px;
}
.sidebar.is-absolute {
  position: absolute;
  bottom: 20px;
  left: 0;
}
.sidebar.is-absolute .sidebar__container, .sidebar.is-absolute .nav__container {
  height: 100%;
}
.sidebar.is-absolute .sidebar__promo {
  top: auto;
}
.sidebar .button_bordered > span, .sidebar .button_primary > span {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  padding: 0 25px 0 0;
  border-radius: 0 5px 5px 0;
  font-weight: 600;
}
.sidebar .tooltip_abs {
  top: -5px;
}
.sidebar__nav-button {
  width: 44px;
  height: 44px;
  border-radius: 0 5px 5px 0;
}
.sidebar__nav-button .button_menu {
  padding: 0;
  border-radius: 0;
  margin-right: -2px;
}
.sidebar__nav-button .button_menu > span:not(.tooltip) {
  width: 24px;
}
.sidebar__nav-button .button_menu > span:not(.tooltip), .sidebar__nav-button .button_menu > span:not(.tooltip)::after, .sidebar__nav-button .button_menu > span:not(.tooltip)::before {
  background: var(--gradient);
}
.sidebar__nav-button .button_menu > span:not(.tooltip)::after {
  top: -8px;
  width: 80%;
}
.sidebar__nav-button .button_menu > span:not(.tooltip)::before {
  top: 8px;
}
.sidebar__nav-button:hover .button_menu > span:not(.tooltip)::after {
  top: -9px;
}
.sidebar__nav-button:hover .button_menu > span:not(.tooltip)::before {
  top: 9px;
}
.sidebar__nav-button .badge {
  position: absolute;
  top: 5px;
  right: 18px;
  z-index: 20;
  background: var(--state-warning);
}
.sidebar__button {
  position: relative;
}
.sidebar__button.button_primary {
  padding: 0;
}
.sidebar__button.button_primary .button__text {
  text-transform: none;
}
.sidebar__button.button_primary .button__text > span {
  text-transform: uppercase;
}
.sidebar__promo {
  position: relative;
}
.sidebar__promo-container {
  position: absolute;
  top: 20px;
  left: 0;
  overflow: hidden;
  user-select: none;
}
.sidebar__promo-banner {
  border-radius: 5px;
}
.sidebar__container {
  height: calc(var(--vh, 1vh) * 100);
  background: var(--sidebar-bg);
}
.sidebar:not(.sidebar_mobile) {
  background: var(--sidebar-color);
  border-radius: 5px 5px 0 0;
  transform-origin: center;
  transform: translate3d(-100%, 0, 0);
  visibility: visible;
  transition: all 0.25s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 110;
}
.sidebar:not(.sidebar_mobile) .sidebar__actions,
.sidebar:not(.sidebar_mobile) .sidebar__nav .nav__container,
.sidebar:not(.sidebar_mobile) .sidebar__nav-button {
  position: fixed;
}
.sidebar:not(.sidebar_mobile) .sidebar__actions,
.sidebar:not(.sidebar_mobile) .sidebar__nav-button {
  left: 100%;
  width: 140px;
}
.sidebar:not(.sidebar_mobile) .sidebar__actions {
  top: 52px;
}
.sidebar:not(.sidebar_mobile) .sidebar__button {
  left: 0;
  width: 100%;
  height: 46px;
  text-align: left;
  border-radius: 0 5px 5px 0;
}
.sidebar:not(.sidebar_mobile) .sidebar__button:first-of-type {
  margin-bottom: 8px;
}
.sidebar:not(.sidebar_mobile) .sidebar__button:last-of-type {
  margin-top: 14px;
}
.sidebar:not(.sidebar_mobile) .sidebar__nav .nav__header,
.sidebar:not(.sidebar_mobile) .sidebar__nav .nav__container {
  z-index: 120;
}
.sidebar:not(.sidebar_mobile) .sidebar__nav .nav__header {
  top: 0;
}
.sidebar:not(.sidebar_mobile) .sidebar__nav .nav__container {
  left: 100%;
  width: 310px;
}
.sidebar:not(.sidebar_mobile) .sidebar__container {
  position: relative;
  width: 250px;
  border-radius: 5px 5px 0 0;
  box-shadow: -2px 10px 15px rgba(0, 0, 0, 0.15);
}
.sidebar:not(.sidebar_mobile).is-open {
  transform: translate3d(0, 0, 0);
}
.sidebar:not(.sidebar_mobile).is-open .sidebar__promo {
  display: none;
}
.sidebar:not(.sidebar_mobile).is-open .sidebar__actions,
.sidebar:not(.sidebar_mobile).is-open .sidebar__nav-button {
  width: 45px;
}
.sidebar:not(.sidebar_mobile).is-open .sidebar__nav .nav__title {
  display: none;
}
.sidebar:not(.sidebar_mobile).is-open .sidebar__nav .nav__container {
  left: 0;
}
.sidebar:not(.sidebar_mobile).is-open .sidebar__button .button__text {
  display: none;
}
.sidebar:not(.sidebar_mobile).is-open .sidebar__button .button__icon {
  margin-right: 0;
}
.sidebar:not(.sidebar_mobile).is-open .button_bordered > span, .sidebar:not(.sidebar_mobile).is-open .button_primary > span {
  justify-content: center;
  gap: 0;
  padding: 0;
}
.sidebar_mobile .sidebar__actions {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 15px;
  height: 100%;
  width: 100%;
  display: flex;
}
.sidebar_mobile .sidebar__actions .logo {
  display: flex;
}
.sidebar_mobile .sidebar__actions .logo__image {
  margin: auto;
}
.sidebar_mobile .navigation__close {
  left: auto;
  right: 15px;
}
.sidebar_mobile .sidebar__nav-button {
  position: absolute;
  top: 8px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 5px;
  background: var(--button-nav-bg);
  z-index: 100;
}
.sidebar_mobile .sidebar__nav-button .badge {
  left: 5px;
}
.sidebar_mobile .sidebar__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 44px;
  height: 44px;
  padding: 10px;
  background: var(--nav-header-bg);
  border-radius: 5px 0 0 5px;
  z-index: 10;
}
.sidebar_mobile .sidebar__button .button__icon {
  margin-right: 0;
}
.sidebar_mobile .sidebar__button .tooltip {
  top: -10px;
}
.sidebar_mobile .sidebar__container, .sidebar_mobile .nav__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 110;
}
.sidebar_mobile .nav__container {
  background-image: url(../images/modal/hablario.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
}
.sidebar_mobile.is-nav-open .sidebar__nav-button {
  z-index: 110;
}

@media screen and (min-width: 1440px) {
  .sidebar:not(.sidebar_mobile) .sidebar__container {
    width: 270px;
  }
  .sidebar:not(.sidebar_mobile) .sidebar__promo,
.sidebar:not(.sidebar_mobile) .sidebar__actions,
.sidebar:not(.sidebar_mobile) .sidebar__nav-button {
    width: 190px;
  }
  .sidebar:not(.sidebar_mobile) .sidebar__nav .nav__container {
    width: 370px;
  }
}
@media screen and (min-width: 1920px) {
  /* .sidebar:not(.sidebar_mobile) {
       .sidebar__container {
           width: 270px;
       }

       .sidebar__promo,
       .sidebar__actions,
       .sidebar__nav-button {
           width: 190px;
       }

       .sidebar__nav .nav__container {
           width: 370px;
       }

       .sidebar__promo {
           translate: 0 160px;
           height: 585px;
       }

       .sidebar__promo-title {
           height: 60px;
       }

       .sidebar__promo-button {
           bottom: 60px;
       }

       .sidebar__promo-animation {

           .animation__image:nth-child(1) {
               width: 250px;
               height: 63px;
               translate: -125px 530px;
           }
           .animation__coins {
               bottom: -200px;
           }
           .animation__coin {
               scale: .45
           }
       }
   }*/
}
@media (hover: hover) {
  .sidebar__nav-button {
    cursor: pointer;
  }

  .sidebar__promo {
    cursor: pointer;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .sidebar:not(.sidebar_mobile) .sidebar__container {
    width: 240px;
  }
  .sidebar:not(.sidebar_mobile) .sidebar__nav .nav__container {
    width: 280px;
  }
  .sidebar:not(.sidebar_mobile) .sidebar__nav .nav__title {
    font-size: 0.8571428571rem;
  }
  .sidebar:not(.sidebar_mobile) .sidebar__promo,
.sidebar:not(.sidebar_mobile) .sidebar__actions,
.sidebar:not(.sidebar_mobile) .sidebar__nav-button {
    width: 146px;
  }
  .sidebar:not(.sidebar_mobile) .sidebar__button,
.sidebar:not(.sidebar_mobile) .sidebar__nav-button {
    height: 38px;
  }
  .sidebar:not(.sidebar_mobile) .sidebar__actions .button__text {
    font-size: 0.8571428571rem;
  }
}
.page {
  padding-top: 60px;
  padding-bottom: 80px;
}
.page__actions {
  display: flex;
  align-items: center;
}
.page__actions .button_favorite {
  margin-right: 10px;
}
.page__actions .button_options {
  margin-left: 10px;
}
.page__actions .present-block {
  margin-right: 1rem;
}
.page__header_search {
  display: flex;
  align-items: center;
}
.page__header_search .button__icon {
  margin-right: 0;
  margin-left: 5px;
}
.page__header_bg {
  background: linear-gradient(180deg, rgba(19, 19, 19, 0.8) 0%, rgba(19, 19, 19, 0.8) 19.36%, rgba(19, 19, 19, 0) 100%);
  backdrop-filter: blur(5px);
}
.page__header_bg .button_options > span, .page__header_bg .button_options > span::after, .page__header_bg .button_options > span::before {
  background-color: var(--color-white);
}
.page__filter {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.page__filter .button_link {
  height: 35px;
  color: var(--color-text);
}
.page_inner {
  padding-top: 0;
}
@media screen and (min-width: 1280px) {
  .page_profile .container, .page_account .container {
    max-width: 780px;
  }
}
@media screen and (min-width: 1920px) {
  .page_profile .container, .page_account .container {
    max-width: 1200px;
  }
}
.page_fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 300;
  background: var(--body-bg);
}
.page_fixed .page__header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 35px;
  background-color: var(--nav-bg);
}
.page_fixed .page__header_payment {
  background-color: transparent;
}
.page_fixed .page__heading {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 2.1875rem;
  text-align: center;
}
.page_fixed .page__close {
  top: 20px;
  right: 35px;
}
.page_fixed:not(.page_mobile) .page__container {
  padding-top: 80px;
  height: calc(var(--vh, 1vh)* 100);
}
.page_fixed:not(.page_mobile) .page__container .container {
  margin: auto;
}
.page_payment:not(.page_mobile) {
  background-image: url("../images/icons/bg-payment.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: var(--sidebar-bg);
  margin: 0;
  background-position: 0 0;
}
.page_payment:not(.page_mobile) .page__column {
  display: flex;
  gap: 75px;
  align-items: center;
}
.page_payment:not(.page_mobile) .page__footer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 20px 25px;
}
.page_payment {
  padding-top: 0;
  padding-bottom: 0;
}
.page_payment .page__container {
  display: flex;
  flex-direction: column;
}
.page_payment .page__heading {
  margin-top: 0;
  font-weight: 500;
}
.page_payment .page__footer .icon {
  margin-right: 5px;
}
.page_payment .page__heading, .page_payment .page__section {
  color: var(--dark-gray2);
}
.page_payment .page__section {
  font-size: 0.875rem;
  text-align: center;
}
.page_payment .page__section .page__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 31px;
}
.page_payment .page__section .button {
  display: block;
  font-weight: 400;
}
.page_payment .page__section .button .button__text {
  padding-right: 27px;
}
.page_payment .page__section .button_service {
  min-width: 150px;
  margin: auto;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
}
.page_payment .page__section .button_service span {
  border-bottom: 1px solid rgba(197, 197, 197, 0.5);
}
.page_payment .page__list-price {
  display: inline-block;
}
.page_payment .page__list-price_bg {
  padding: 15px 50px 50px;
  margin-top: 15px;
  border-top: 1px solid rgba(197, 197, 197, 0.3);
  background: var(--nav-bg);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.page_payment .page__list-price .list__item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.page_payment .page__list-price .list__icon {
  position: relative;
  margin-right: 20px;
  background: var(--nav-bg);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_payment .page__list-price .list__icon .icon {
  width: 26px;
  height: 26px;
}
.page_payment .page__list-price .list__title {
  flex: 1;
  padding: 10px 10px;
  background: var(--nav-bg);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  font-weight: 700;
  border-radius: 30px;
  text-align: center;
  color: var(--color-text);
}
.page_payment .list_payment {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
}
.page_payment .list_payment .list__item:not(:last-child) {
  margin-right: 5px;
}
.page_new-story .container {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 50px;
}
.page_new-story .form__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.page_new-story .form__footer .button + .button {
  margin-left: 8px;
}
.page_new-story .form .slider__delete {
  width: auto;
}
.page_new-story .slider_portfolio {
  margin-top: 20px;
}
.page_payment-status .section__heading:not(.text-center) {
  text-align: left;
}
.page_payment-status .page__footer {
  margin-top: 20px;
  text-align: center;
  font-size: 0.875rem;
}
.page_payment-status .page__pad, .page_payment-status .page__table {
  margin-bottom: 20px;
}
.page_payment-status .page__button {
  display: block;
  width: 250px;
  margin: 30px auto;
}
.page_payment-status .page__info {
  text-align: center;
  color: var(--heading-color);
}
.page_mobile {
  padding-top: 60px;
}
.page_mobile:not(.page_new-story) .container {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
.page_mobile .page__header {
  position: fixed;
  left: 0;
  width: 100%;
  padding: 15px;
  height: 75px;
  display: flex;
  align-items: center;
  z-index: 109;
}
.page_mobile .page__header:not(.page__header_bg) {
  box-shadow: 0 10px 15px rgba(246, 246, 246, 0.06);
  backdrop-filter: blur(20px);
}
.page_mobile .page__header .page__actions {
  margin-left: auto;
}
.page_mobile .page__header .page__actions .button_options {
  margin-left: 20px;
}
.page_mobile .page__header .page__actions .present-block {
  margin-right: 10px;
}
.page_mobile .page__header .page__actions .present-block .button_round > span {
  background: rgba(0, 0, 0, 0.42);
}
.page_mobile .page__header .button_back, .page_mobile .page__header .button_chat {
  background: rgba(0, 0, 0, 0.34);
  backdrop-filter: blur(5px);
}
.page_mobile .page__header .button_chat {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 132px;
  height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  line-height: 38px;
  z-index: 10;
}
.page_mobile .page__header .button_chat > span {
  font-size: 0.875rem;
  color: var(--color-white);
}
.page_mobile .page__header .button_chat .button__icon {
  margin-right: 5px;
}
.page_mobile .page__header .button_back {
  color: var(--color-white);
}
.page_mobile .page__filter {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  padding: 10px;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 100;
}
.page_mobile .page__filter .button_link {
  padding: 8px 10px 8px 15px;
  border-radius: 5px;
  white-space: nowrap;
}
.page_mobile .page__filter .button_link + .button_link {
  margin-left: 10px;
}
.page_mobile .page__filter .tag, .page_mobile .page__filter .button_link {
  background: rgba(251, 251, 251, 0.8);
}
.page_mobile .page__filter .list_tags {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 5px;
  overflow-x: auto;
}
.page_mobile.page_fixed .page__heading {
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 34px;
  font-size: 26px;
}
.page_mobile.page_payment {
  display: flex;
  flex-direction: column;
  min-height: var(--vh);
}
.page_mobile.page_payment .page__container {
  padding: 50px 15px 20px;
  flex: 1;
}
.page_mobile.page_payment .page__footer {
  padding: 10px 20px;
  margin-top: auto;
  text-align: center;
}
.page_mobile.page_payment .list_payment {
  margin-top: 10px;
}
.page_mobile.page_account .person .status {
  position: relative;
  top: 2px;
  margin-left: 10px;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .page_profile .page__header .button_chat {
    width: 100%;
    padding: 0 15px;
  }
}
@media screen and (max-width: 1279px) {
  .page_new-story .slider_portfolio {
    margin-top: 20px;
  }
  .page_new-story .slider_portfolio .hooper {
    height: 320px;
  }
  .page_payment-status .page__footer span {
    display: block;
  }
}
@media screen and (max-width: 575px) {
  .page_payment-status .section__heading {
    font-size: 1.5625rem;
  }
  .page_payment-status .page__info, .page_payment-status .page__pad .pad__body {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 576px) {
  .page_new-story .container {
    max-width: 80%;
  }
  .page_new-story .form__footer .button {
    width: 220px;
  }
}
@media screen and (min-width: 1024px) {
  .page:not(.page_inner):not(.page_payment):not(.page_static) {
    padding-top: 80px;
  }

  .page:not(.page_inner):not(.page_payment):not(.pb-0) {
    padding-bottom: 120px;
  }
}
@media screen and (min-width: 1280px) {
  .page__actions {
    margin: 0 0 0 auto;
  }
  .page_gallery .container {
    max-width: 600px;
  }
  .page_gallery .page__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    height: 58px;
    margin-bottom: 10px;
  }
  .page_gallery .page__header .button {
    width: 100px;
  }
  .page_static {
    padding-top: 80px;
  }
  .page_inner {
    /* padding-right: 245px;*/
    /*  .page__header {
          margin-bottom: toRem(20px);
      }*/
  }
  .page_profile, .page_account {
    /*   padding-right: 0;*/
    /*.page__header, .page__wrapper {
        height: 72px;
    }
    */
  }
  .page_profile .container, .page_account .container {
    padding-right: 0;
    padding-left: 0;
    max-width: 600px;
  }
  .page_profile .page__header, .page_profile .page__wrapper, .page_account .page__header, .page_account .page__wrapper {
    height: 64px;
  }
  .page_profile .page__header, .page_account .page__header {
    width: 100%;
    max-width: 600px;
  }
  .page_profile .page__wrapper, .page_account .page__wrapper {
    position: fixed;
    width: 100%;
    max-width: inherit;
    padding: 5px 0;
    display: flex;
    align-items: center;
    background-color: var(--body-bg);
    border-bottom: 2px solid rgba(197, 197, 197, 0.2);
    z-index: 100;
  }
  .page_profile.is-chat-open, .page_account.is-chat-open {
    padding-left: 160px;
    /* .profile__container {
         flex: 0 0 45vw;
         max-width: 45vw;
     }
     .page__header {
         max-width: 45vw;
     }*/
  }
  .page_profile.is-chat-open .container, .page_account.is-chat-open .container {
    max-width: 1200px;
  }
  .page_profile.is-chat-open .chat, .page_account.is-chat-open .chat {
    max-width: 430px;
  }
  .page_profile.is-chat-open .chat__container, .page_account.is-chat-open .chat__container {
    width: 430px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .page_account .person .status {
    position: absolute;
    bottom: 0;
    left: 100%;
    z-index: 10;
    text-align: center;
  }
  .page_new-story .container {
    margin-top: 0;
    max-width: 780px;
  }
  .page_new-story .slider_portfolio .hooper {
    height: 240px;
  }
  .page_payment-status .section__heading {
    font-size: 2.1875rem;
  }
  .page_payment-status .page__info {
    font-size: 1.25rem;
  }

  .sidebar.is-open ~ .page_profile.is-chat-open, .sidebar.is-nav-open ~ .page_profile.is-chat-open,
.sidebar.is-open ~ .page_account.is-chat-open, .sidebar.is-nav-open ~ .page_account.is-chat-open {
    padding-left: 290px;
    /*.chat {
       &__container {
          width: 30vw
        }
    };*/
  }

  /*.sidebar.is-open ~ .page_gallery, .sidebar.is-nav-open ~ .page_gallery {
      .gallery {
          grid-template-columns: repeat(3, 1fr);
      }
      .container {
          max-width: 780px;
      }
  }*/
  /*.sidebar.is-open ~ .page_profile:not(.is-chat-open), .sidebar.is-nav-open ~ .page_profile:not(.is-chat-open),
  .sidebar.is-open ~ .page_account:not(.is-chat-open), .sidebar.is-nav-open ~ .page_account:not(.is-chat-open){
      padding-right: 245px;
  }*/
}
@media screen and (min-width: 1440px) {
  /*.sidebar.is-open ~ .page_gallery, .sidebar.is-nav-open ~ .page_gallery {
      .container {
          max-width: 900px;
      }
  }*/
}
@media screen and (min-width: 1680px) {
  .page {
    /*&_gallery {
        .container {
            max-width: 1600px;
        }
    }*/
    /* &_profile, &_account {

         &.is-chat-open {
             .container {
                 max-width: 1600px;
             }
         }
     }*/
  }
  .page_payment {
    /*.page__container {
        padding-top: 87px;
    }*/
  }
  .page_payment .page__footer .page__section {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

  /*.sidebar.is-open ~ .page_gallery, .sidebar.is-nav-open ~ .page_gallery {
      .container {
          max-width: 1080px;
      }
  }*/
}
@media screen and (min-width: 1920px) {
  .page_gallery {
    /*  padding-bottom: toRem(90px);*/
  }
  .page_gallery .container {
    max-width: 800px;
  }
  .page_gallery .page__header {
    height: 80px;
  }
  .page_gallery .page__header .button {
    width: 130px;
  }
  .page_profile, .page_account {
    /* .page__header {
         max-width: 1200px;
         .logo {
             top: toRem(20px);
         }
     }*/
  }
  .page_profile .container, .page_account .container {
    max-width: 870px;
  }
  .page_profile .page__header, .page_profile .page__wrapper, .page_account .page__header, .page_account .page__wrapper {
    height: 86px;
  }
  .page_profile .page__header, .page_account .page__header {
    max-width: 870px;
  }
  .page_profile .page__wrapper, .page_account .page__wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .page_profile.is-chat-open, .page_account.is-chat-open {
    padding-left: 200px;
    /*.logo {
        margin-left: 15px;
    }*/
    /* .page__header {
         max-width: 900px;
     }*/
    /*.profile__container {
        flex: 0 0 900px;
        max-width: 900px;
    }*/
  }
  .page_profile.is-chat-open .container, .page_account.is-chat-open .container {
    max-width: 1600px;
  }
  .page_profile.is-chat-open .chat, .page_account.is-chat-open .chat {
    max-width: 670px;
  }
  .page_profile.is-chat-open .chat__container, .page_account.is-chat-open .chat__container {
    width: 670px;
  }
  .page_payment {
    /*.page__container {
        padding-top: 174px;
    }*/
  }
  .page_payment .page__heading {
    margin-bottom: 60px;
  }
  .page_payment .page__list-price .list__icon {
    width: 80px;
    height: 80px;
    flex: 0 0 80px;
  }
  .page_payment .page__list-price .list__icon .icon {
    width: 40px;
    height: 40px;
  }

  /*.sidebar.is-open ~ .page_gallery, .sidebar.is-nav-open ~ .page_gallery {
      .container {
          max-width: 1200px;
      }
  }*/
  /* .sidebar.is-open ~ .page_inner:not(.is-chat-open), .sidebar.is-nav-open ~ .page_inner:not(.is-chat-open) {
       padding-right: 0;
   }*/
  .sidebar.is-open ~ .page_profile.is-chat-open, .sidebar.is-nav-open ~ .page_profile.is-chat-open,
.sidebar.is-open ~ .page_account.is-chat-open, .sidebar.is-nav-open ~ .page_account.is-chat-open {
    padding-left: 340px;
    /* .chat {
         &__container {
             width: 670px;
         }
     }*/
    /* .profile__container {
         flex: 0 0 900px;
         max-width: 900px;
     }

     .page__header {
         max-width: 900px;
     }*/
  }
}
@media screen and (min-width: 2560px) {
  .page {
    /* &_gallery {
         padding-right: 0;
     }*/
  }
  .page_profile.is-chat-open, .page_account.is-chat-open {
    padding-left: 0;
  }

  /*.sidebar.is-open ~ .page_inner, .sidebar.is-nav-open ~ .page_inner {
      padding-left: 0;
  }*/
  /*.sidebar.is-open ~ .page_gallery, .sidebar.is-nav-open ~ .page_gallery {
      .gallery {
          grid-template-columns: repeat(4, 1fr);
      }

      .container {
          max-width: 1600px;
      }
  }*/
  /*.sidebar.is-open ~ .page_profile.is-chat-open, .sidebar.is-nav-open ~ .page_profile.is-chat-open,
  .sidebar.is-open ~ .page_account.is-chat-open, .sidebar.is-nav-open ~ .page_account.is-chat-open {
      padding-right: 0;
  }*/
}
.slider__container {
  position: relative;
}
.slider__item {
  position: relative;
  overflow: hidden;
  display: block;
  user-select: none;
}
.slider__button {
  display: inline-block;
  padding: 0;
  position: absolute;
  top: 50%;
  height: 45px;
  line-height: 0;
  font-size: 0;
  background: rgba(255, 255, 255, 0.75);
  transform: translateY(-50%);
  transition: opacity 0.25s ease;
  z-index: 10;
}
.slider__button .button__icon {
  width: 18px;
  margin: 0;
  transform: rotate(90deg);
  transition: transform 0.35s ease-in-out;
}
.slider__button_dark {
  width: 28px;
  background: rgba(19, 19, 19, 0.5);
  border-radius: 0 5px 5px 0;
  color: rgba(255, 255, 255, 0.5);
}
.slider__button_prev {
  left: 0;
  border-radius: 0 5px 5px 0;
}
.slider__button_next {
  right: 0;
  border-radius: 5px 0 0 5px;
}
.slider__button_next .button__icon {
  transform: rotate(-90deg);
  transform-origin: initial;
}
.slider__button:hover {
  opacity: 0.85;
}
.slider__button:hover_prev .button__icon {
  transform: rotate(90deg) translateY(5px);
}
.slider__button:hover_next .button__icon {
  transform: rotate(-90deg) translateY(5px);
}
.slider__delete {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
}
.slider .hooper .hooper-pagination {
  padding: 0;
}
.slider .hooper .hooper-indicators {
  line-height: 1;
}
.slider .hooper .hooper-indicators li .hooper-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #C5C5C5;
}
.slider .hooper .hooper-indicators li .hooper-indicator:hover, .slider .hooper .hooper-indicators li .hooper-indicator.is-active {
  background: var(--gradient);
}
.slider .hooper .hooper-slide {
  transform: translate3d(0, 0, 0);
}
.slider_promo .slider__holder, .slider_portfolio .slider__holder {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.slider_promo .button_video-play, .slider_portfolio .button_video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.slider_promo .hooper {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: auto;
}
.slider_promo .hooper::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  /*16:9*/
}
.slider_promo .hooper .hooper-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.slider_promo .slider__item {
  height: 100%;
}
.slider_promo .slider__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 0 0 20px 20px;
}
.slider_promo .slider__holder {
  bottom: -20px;
}
.slider_promo .slider__caption {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 20px;
  right: 20px;
}
.slider_promo .slider__caption .button {
  margin: 0 0 0 auto;
}
.slider_promo .slider__caption .profile__credits {
  display: inline-block;
  margin-top: 20px;
}
.slider_thumbs {
  margin-top: 25px;
  height: 140px;
}
.slider_thumbs .hooper {
  height: 100%;
}
.slider_thumbs .slider__item {
  margin-right: 15px;
}
.slider_thumbs .button_close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px;
}
.slider_portfolio .slider__media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  aspect-ratio: 1/1;
  border-radius: 10px;
}
.slider_portfolio .slide__item {
  position: relative;
}
.slider_portfolio .slider__delete {
  top: 10px;
  right: 20px;
}
.slider_portfolio .slider__button {
  width: 25px;
}
.slider_portfolio .upload {
  width: 100%;
  height: 100%;
}
.slider_portfolio + .slider_portfolio {
  margin-top: 30px;
}
.slider_content {
  padding-top: 40px;
}
.slider_content .hooper {
  height: auto;
  min-height: 160px;
}
.slider_gallery .hooper, .slider_gallery .slider__item {
  height: calc((var(--vh, 1vh) * 100) - 60px);
}
.slider_gallery .gallery__item, .slider_gallery .card_gallery {
  position: relative;
  width: 100%;
  height: 100%;
}
.slider_gallery .card__media {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1279px) {
  .slider_promo .slider__holder {
    bottom: 20vh;
  }
  .slider_promo .slider__delete {
    top: 90px;
  }
  .slider_promo .hooper .hooper-pagination {
    bottom: 60px;
  }
  .slider_promo .hooper::before {
    padding-top: 80vh;
  }
  .slider_portfolio .slider__holder {
    bottom: 0;
  }
  .slider_portfolio .slider__delete {
    top: 25px;
  }
  .slider_portfolio {
    margin-top: -20px;
  }
  .slider_portfolio .hooper {
    height: 480px;
  }
  .slider_portfolio .slider__item {
    width: 100%;
  }
  .slider_portfolio .slider__item, .slider_portfolio img, .slider_portfolio video {
    border-radius: 0 0 20px 20px;
  }
  .slider_portfolio .hooper .hooper-pagination {
    width: 100%;
    padding: 40px 0 20px;
    justify-content: center;
    border-radius: 0 0 20px 20px;
    background: linear-gradient(360deg, #131313 10.74%, rgba(0, 0, 0, 0) 66.94%);
  }
}
@media screen and (max-width: 767px) {
  .profile_account .slider_promo .upload:not(.upload_simple) .upload__label {
    top: 30vh;
    bottom: auto;
  }
}
@media screen and (min-width: 1280px) {
  .slider_promo .slider__caption {
    text-align: right;
  }
  .slider_portfolio .hooper {
    height: 170px;
  }
  .slider_portfolio .slider__item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .slider_portfolio .slider__holder {
    bottom: -50px;
  }
}
@media screen and (min-width: 1920px) {
  .slider__button {
    height: 70px;
  }
  .slider__button_dark {
    width: 46px;
  }
  .slider__button .button__icon {
    width: 28px;
  }
  .slider_portfolio .hooper {
    height: 270px;
  }
  .slider_portfolio .slider__button {
    width: 35px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .slider_promo .slider__holder {
    bottom: 0;
    width: 300px;
    height: 300px;
  }
  .slider_portfolio .slider__holder {
    bottom: 0;
  }
}
.mockup {
  position: relative;
  overflow: hidden;
}
.mockup .video_landing {
  clip-path: polygon(1% 1%, 99% 0%, 99% 99%, 0% 99%);
}
.mockup_mobile {
  width: 100%;
  padding-top: 188%;
  margin: auto;
}
.mockup_desktop {
  display: none;
  top: 10px;
  left: 10px;
  width: 606px;
  height: 346px;
}
@media screen and (min-width: 1024px) {
  .mockup_mobile {
    display: none;
  }
  .mockup_desktop {
    display: block;
  }
}

@media screen and (min-width: 428px) {
  .mockup_mobile {
    width: 340px;
    height: 630px;
    padding-top: 0;
  }
}
@media screen and (min-width: 1920px) {
  .mockup_desktop {
    width: 1006px;
    height: 558px;
    left: -60px;
  }
}
@media screen and (min-width: 2560px) {
  .mockup_desktop {
    left: 10px;
  }
}
.intro {
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
}
.intro__wrapper {
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  background-position: top;
}
.intro__caption {
  position: absolute;
  bottom: 13%;
  padding: 0 80px;
  width: 100%;
  line-height: 1.1;
  color: var(--color-white);
  display: flex;
  flex-direction: row-reverse;
  gap: 70px;
  align-items: center;
  justify-content: center;
}
.intro__caption p {
  margin: 0;
  font-size: 2.1875rem;
  font-weight: 500;
}
.intro__caption .button {
  width: 320px;
}
.intro__text {
  display: block;
  font-size: 1.5rem;
  font-weight: 400;
  width: 45.9375rem;
  font-family: Roboto, sans-serif;
}
.intro__line {
  display: block;
  margin: 10px 0;
  width: 100%;
  height: 3px;
  background: radial-gradient(circle at left, var(--color-white) 0, transparent 100%);
}
.intro .button_scroll,
.intro .button_mute {
  position: absolute;
  z-index: 10;
}
.intro .button_scroll {
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
}
.intro .button_mute {
  bottom: 35px;
  left: 30px;
}

@media screen and (max-width: 1023px) {
  .intro__caption {
    width: 750px;
    margin-left: calc(50% - 375px);
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .intro__caption {
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    text-align: center;
    left: 0;
    margin-left: 0;
  }
  .intro__caption > *:not(.button_primary) {
    display: none;
  }
  .intro__caption .button_primary {
    width: 100%;
    height: 48px;
    padding: 13px 20px;
    font-size: 1rem;
  }

  .button_scroll {
    display: none;
  }
}
.carousel {
  position: absolute;
  width: 492px;
  height: 492px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
  /*&__slide_1 {
      top: 111px;
      left: 111px;
  }*/
}
.carousel__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 30;
}
.carousel__slide {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* z-index: 30;
   width: 492px;
   position: absolute;
   display: block;*/
}

@media (min-width: 430px) and (max-width: 1279px) {
  .carousel {
    width: 246px;
    height: 246px;
    /*  &__slide {
          width: 246px;
      }

      &__slide_1 {
          top: 56px;
          left: 56px;
      }*/
  }
}
@media (max-width: 429px) {
  .carousel {
    width: 246px;
    height: 246px;
    /* &__slide {
         width: 246px;
     }

     &__slide_1 {
         top: 56px;
         left: 56px;
     }*/
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .carousel {
    width: 369px;
    height: 369px;
    /* &__slide {
         width: 369px;
         display: block;
     }

     &__slide_1 {
         top: 84px;
         left: 84px;
     }*/
  }
}
.post__heading {
  line-height: 1.1;
}
.post__poster video,
.post__poster audio {
  width: 100%;
  max-width: 100%;
}
.post__poster_locked {
  position: relative;
  overflow: hidden;
}
.post__poster_locked .poster__media {
  filter: blur(15px);
}
.post__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  border-radius: 10px;
  z-index: 10;
}
.post__overlay-content {
  margin: auto;
  text-align: center;
}
.post__overlay-content, .post__overlay-content .title, .post__overlay-content .icon-box__title, .post__overlay-content .section__sub-heading, .post__overlay-content .section__title, .post__overlay-content .heading {
  color: var(--color-white);
}
.post__overlay-content .title, .post__overlay-content .icon-box__title, .post__overlay-content .section__sub-heading, .post__overlay-content .section__title, .post__overlay-content .heading {
  margin: 5px 0 20px;
  text-transform: uppercase;
}
.post__overlay-content .sub-title {
  display: block;
  margin-top: 0.625rem;
  font-weight: 500;
}
.post__overlay-content .button {
  height: 40px;
  padding-left: 25px;
  padding-right: 25px;
  line-height: 38px;
  font-size: 1.125rem;
  text-transform: none;
}
.post__category {
  text-transform: uppercase;
}
.post__category::before {
  content: "/";
  padding-left: 10px;
  padding-right: 10px;
}
.post__body {
  line-height: 1.4;
}
.post__content {
  display: flex;
}
.post__button {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}
.post__icon {
  margin-right: 10px;
  position: relative;
  top: -2px;
}
.post__comment {
  display: flex;
  word-break: break-word;
}
.post__comment .avatar {
  margin-right: 15px;
}
.post__input, .post__textarea {
  width: 100%;
}
.post__input .form__buttons {
  position: absolute;
  top: 5px;
  right: 14px;
  display: flex;
  align-items: center;
  z-index: 10;
}
.post__textarea {
  appearance: none;
  flex: 1;
  height: 50px;
  padding: 16px 90px 2px 30px;
  background: var(--body-bg);
  color: var(--color-text);
  box-shadow: 0 10px 25px rgba(136, 136, 136, 0.1);
  border-radius: 50px;
  border: 0;
  resize: none;
}
.post__comments .title, .post__comments .icon-box__title, .post__comments .section__sub-heading, .post__comments .section__title, .post__comments .heading {
  margin-top: 1.125rem;
  margin-bottom: 1.25rem;
}
.post__comment-author, .post__comment-date {
  display: block;
  margin-bottom: 5px;
}
.post__comment-author {
  font-weight: 500;
}
.post__comment-date {
  font-size: 0.875rem;
}
.post__comments-media {
  display: flex;
  margin: 1rem 0;
}
.post__comments-media:not(:last-child) {
  border-bottom: 2px solid rgba(197, 197, 197, 0.2);
}
.post__comments-body {
  flex: 1;
  margin-left: 10px;
}
.post__editor {
  position: relative;
}
.post__editor .form__buttons {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.post__editor .form__buttons .button + .button {
  margin-left: 10px;
}
.post_single .post__poster {
  margin-top: 30px;
}
.post_blog .post__heading, .post_stories .post__heading {
  font-size: 2rem;
  line-height: 1.1;
}
.post_blog .post__heading, .post_stories .post__heading {
  margin-top: 20px;
}
.post_blog .post__meta-data, .post_stories .post__meta-data {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  background-color: var(--nav-bg);
  padding-right: 20px;
  z-index: 10;
}
.post_blog .post__meta, .post_stories .post__meta {
  position: relative;
}
.post_blog .post__meta::after, .post_stories .post__meta::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(197, 197, 197, 0.6);
}
.post_stories {
  position: relative;
}
.post_stories .poster::after {
  padding-bottom: 60.24%;
}
.post_stories .post__body {
  position: relative;
  padding-bottom: 30px;
}
.post_stories .post__info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.875rem;
  line-height: 1;
}
.post_stories .post__content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.post_stories .post__short-text {
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.post_stories .post__view {
  margin-left: 20px;
  display: inherit;
  align-items: center;
}
.post_stories .post__poster {
  margin-bottom: 20px;
}
.post_stories .post__form-title {
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: var(--color-text);
}
.post_stories .post__like {
  margin-left: 10px;
}
.post_stories .post__less {
  position: absolute;
  bottom: 10px;
  right: 0;
  z-index: 10;
}
.post_stories .post__less .button__icon {
  color: var(--color-text);
  transform: rotate(180deg);
}
.post_stories .button_edit {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 10;
}
.post_stories .slider_portfolio {
  margin: 20px 0;
}
.post_blog .button_more {
  margin-top: 20px;
}
.post_blog .post__poster {
  margin-bottom: 40px;
}
.post_blog .post__date {
  margin-right: 20px;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .post__overlay-content .button {
    height: 30px;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 28px;
    font-size: 0.8571428571rem;
  }
  .post__overlay-content .title, .post__overlay-content .icon-box__title, .post__overlay-content .section__sub-heading, .post__overlay-content .section__title, .post__overlay-content .heading {
    font-size: 1rem;
  }
  .post_stories {
    font-size: 0.7142857143rem;
  }
  .post_stories .post__heading {
    font-size: 1.4285714286rem;
  }
  .post_stories .post__form-title, .post_stories .post__comments-title {
    font-size: 0.8571428571rem;
  }
  .post_stories .post__textarea {
    height: 30px;
    padding: 10px 90px 5px 20px;
    font-size: 0.7142857143rem;
  }
  .post_stories .avatar {
    margin-top: 0;
    width: 30px;
    height: 30px;
  }
  .post_stories .post__comment-date, .post_stories .post__info, .post_stories .button_link, .post_stories .post__meta-data {
    font-size: 0.7142857143rem;
  }
  .post_stories .icon_send {
    width: 30px;
    height: 30px;
  }
  .post_stories .post__input .form__buttons {
    top: 0;
  }
}
@media screen and (max-width: 767px) {
  .post_blog .post__meta {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid rgba(197, 197, 197, 0.6);
  }
  .post_blog .post__meta::after {
    content: none;
  }
  .post_blog .post__meta .post__view {
    margin: 0 0 0 auto;
  }
  .post_blog .post__meta-data {
    display: flex;
    padding-right: 0;
  }

  .post_stories .post__poster {
    margin-right: -15px;
    margin-left: -15px;
  }
  .post_stories .post__media, .post_stories .post__overlay {
    border-radius: 0;
  }
  .post_stories .post__content {
    flex-direction: column;
  }
  .post_stories .post__info {
    margin-bottom: 12px;
    order: -1;
  }
  .post_stories .post__short-text {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 575px) {
  .post_stories .post__poster::after {
    padding-bottom: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .post__editor .form__footer {
    display: flex;
    align-items: center;
  }
  .post__editor .form__buttons {
    margin: 0 0 0 auto;
  }
  .post_stories .post__info {
    max-width: 8.125rem;
    flex: 0 0 8.125rem;
    margin-left: 2.5rem;
  }
  .post_stories .post__short {
    position: relative;
    top: 0.3125rem;
    display: flex;
  }
  .post_stories .post__short .button_link {
    margin-left: 0.625rem;
  }
}
.section__content-title, .section__title-promo, .section__title {
  margin-top: 0;
}
.section__sub-heading, .section__content-title, .section__title-promo {
  font-size: 1.625rem;
}
.section__sub-heading {
  line-height: 1.1;
}
.section__title {
  font-size: 1.125rem;
  line-height: 1.4;
  color: var(--gray);
}
.section_contact .section__heading, .section_about .section__heading, .section_promo .section__heading {
  text-transform: uppercase;
}
.section_contact, .section_install, .section_features, .section_service {
  padding-bottom: 60px;
}
.section_about {
  padding-top: 35px;
  padding-bottom: 10px;
}
.section_about .section__sub-heading {
  margin: 0;
  text-transform: uppercase;
  line-height: 1.1;
}
.section_about .section__heading {
  margin-bottom: 40px;
}
.section_about .section__title {
  margin-bottom: 20px;
  line-height: 1.4;
}
.section_confidence {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: var(--color-white);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}
.section_confidence .container {
  max-width: 100%;
}
.section_confidence .heading {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 135%;
  text-transform: uppercase;
}
.section_confidence .list_confidence {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 3.75rem;
  width: 100%;
  gap: 40px;
}
.section_confidence .list__item,
.section_confidence .list__item_confidence {
  position: relative;
  display: flex;
  width: 504px;
  padding: 37px 42px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 40px;
  background: #FFF;
  box-shadow: 0 4px 62px 0 rgba(11, 40, 115, 0.06);
  transform: scale(0.1);
}
.section_confidence .list__item:nth-child(1)::before,
.section_confidence .list__item_confidence:nth-child(1)::before {
  content: url("../images/landing/flicker1.svg");
  position: absolute;
  left: -60px;
  top: -30px;
}
.section_confidence .list__item:nth-child(1)::after,
.section_confidence .list__item_confidence:nth-child(1)::after {
  content: url("../images/landing/flicker2.svg");
  position: absolute;
  right: -60px;
  bottom: -60px;
}
.section_confidence .list__item:nth-child(2)::after,
.section_confidence .list__item_confidence:nth-child(2)::after {
  content: url("../images/landing/flicker3.svg");
  position: absolute;
  top: -31px;
  right: 0;
}
.section_confidence .list__item:nth-child(3)::after,
.section_confidence .list__item_confidence:nth-child(3)::after {
  content: url("../images/landing/flicker4.svg");
  position: absolute;
  bottom: -49px;
  right: -60px;
}
.section_confidence .list__item .icon_user,
.section_confidence .list__item .icon_heart,
.section_confidence .list__item .icon_member,
.section_confidence .list__item_confidence .icon_user,
.section_confidence .list__item_confidence .icon_heart,
.section_confidence .list__item_confidence .icon_member {
  width: 100px;
  height: 100px;
  display: block;
}
.section_confidence .list__item .icon_user,
.section_confidence .list__item_confidence .icon_user {
  background-image: url("../images/landing/icon_user.svg");
}
.section_confidence .list__item .icon_heart,
.section_confidence .list__item_confidence .icon_heart {
  background-image: url("../images/landing/icon_heart.svg");
}
.section_confidence .list__item .icon_member,
.section_confidence .list__item_confidence .icon_member {
  background-image: url("../images/landing/icon_member.svg");
}
.section_confidence .list__item .title, .section_confidence .list__item .icon-box__title, .section_confidence .list__item .heading, .section_confidence .list__item .section__sub-heading, .section_confidence .list__item .section__title,
.section_confidence .list__item .content,
.section_confidence .list__item_confidence .title,
.section_confidence .list__item_confidence .icon-box__title,
.section_confidence .list__item_confidence .heading,
.section_confidence .list__item_confidence .section__sub-heading,
.section_confidence .list__item_confidence .section__title,
.section_confidence .list__item_confidence .content {
  color: #131313;
  text-align: center;
}
.section_confidence .list__item .title, .section_confidence .list__item .icon-box__title, .section_confidence .list__item .heading, .section_confidence .list__item .section__sub-heading, .section_confidence .list__item .section__title,
.section_confidence .list__item_confidence .title,
.section_confidence .list__item_confidence .icon-box__title,
.section_confidence .list__item_confidence .heading,
.section_confidence .list__item_confidence .section__sub-heading,
.section_confidence .list__item_confidence .section__title {
  font-size: 24px;
  font-weight: 700;
}
.section_confidence .list__item .content,
.section_confidence .list__item_confidence .content {
  font-size: 20px;
  line-height: normal;
}
.section_advantages {
  padding-top: 0;
  background-color: var(--color-white);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: auto;
}
.section_advantages .container {
  max-width: 100%;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
}
.section_advantages .heading {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
}
.section_advantages .is-flex {
  align-items: center;
  padding: 30px 0;
  gap: 252px;
  justify-content: center;
}
.section_advantages .list_advantages {
  display: flex;
  flex-direction: column;
  gap: 28px;
  position: relative;
  z-index: 50;
}
.section_advantages .list_advantages::before, .section_advantages .picture::before {
  content: "";
  background: linear-gradient(229deg, rgba(255, 94, 239, 0.7) 2.25%, rgba(92, 99, 252, 0.7) 47.27%, rgba(0, 215, 215, 0.7) 98.75%);
  width: 584px;
  height: 584px;
  border-radius: 584px;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: bg-gradient 3s infinite ease-in-out;
  background-size: 600%;
  filter: blur(100px);
}
@keyframes bg-gradient {
  0% {
    background-position: 80% 0;
  }
  50% {
    background-position: 20% 100%;
  }
  100% {
    background-position: 80% 0;
  }
}
.section_advantages .list__item {
  width: 621px;
  height: 203px;
  flex-shrink: 0;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 4px 62px 0 rgba(11, 40, 115, 0.06);
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
}
.section_advantages .wrapper {
  padding: 28px 0 44px 90px;
}
.section_advantages .wrapper .title, .section_advantages .wrapper .icon-box__title, .section_advantages .wrapper .heading, .section_advantages .wrapper .section__sub-heading, .section_advantages .wrapper .section__title {
  color: #131313;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
}
.section_advantages .list__item:nth-child(1)::before,
.section_advantages .list__item:nth-child(2)::before,
.section_advantages .list__item:nth-child(3)::before {
  position: absolute;
  background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
  background-clip: text;
  color: transparent;
  width: 35px;
  height: 80px;
  display: block;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  left: calc(90px - 35px - 15px);
  top: 0;
}
.section_advantages .list__item:nth-child(1)::before {
  content: "1";
}
.section_advantages .list__item:nth-child(2)::before {
  content: "2";
}
.section_advantages .list__item:nth-child(3)::before {
  content: "3";
}
.section_advantages .content {
  color: #131313;
  font-size: 20px;
  font-style: normal;
  line-height: 1.4;
  display: block;
  width: 450px;
}
.section_advantages .button_lg {
  width: 260px;
  align-self: center;
}
.section_advantages .picture {
  position: relative;
  max-width: 715px;
}
.section_advantages .ellipse1 {
  background: radial-gradient(125.83% 68.45% at 49.07% 50%, rgba(255, 94, 239, 0.6) 0%, rgba(92, 99, 252, 0.22) 46.66%, rgba(0, 215, 215, 0) 100%);
  border-radius: 715px;
  height: 715px;
  width: 715px;
  z-index: 10;
}
.section_advantages .ellipse2,
.section_advantages .ellipse3 {
  position: absolute;
  display: block;
}
.section_advantages .ellipse2 {
  z-index: 10;
  width: 649px;
  height: 649px;
  border-radius: 649px;
  background: linear-gradient(229deg, rgba(255, 94, 239, 0.7) 2.25%, rgba(92, 99, 252, 0.7) 47.27%, rgba(0, 215, 215, 0.7) 98.75%);
  top: 33px;
  left: 33px;
}
.section_advantages .ellipse3 {
  z-index: 20;
  width: 575px;
  height: 575px;
  border-radius: 575px;
  background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
  top: 70px;
  left: 70px;
}
.section_advantages .gifts,
.section_advantages .calls,
.section_advantages .bonuses1,
.section_advantages .bonuses2,
.section_advantages .messages,
.section_advantages .messages2,
.section_advantages .voice,
.section_advantages .video,
.section_advantages .private_contents,
.section_advantages .mystories {
  position: absolute;
  display: block;
  z-index: 60;
}
.section_advantages .gifts {
  content: url("../images/landing/landing-slider/gifts.svg");
  top: 40%;
  right: -33px;
}
.section_advantages .calls {
  content: url("../images/landing/landing-slider/calls.svg");
  top: 35%;
  right: 60px;
  width: 79px;
  height: 50px;
}
.section_advantages .bonuses1 {
  content: url("../images/landing/landing-slider/bonuses.svg");
  top: 60%;
  left: 33px;
  width: 52px;
  height: 52px;
}
.section_advantages .bonuses2 {
  content: url("../images/landing/landing-slider/bonuses2.svg");
  left: 50%;
  bottom: 33px;
  width: 57px;
  height: 57px;
}
.section_advantages .messages {
  content: url("../images/landing/landing-slider/messages.svg");
  top: 100px;
  left: 100px;
  width: 109px;
  height: 109px;
}
.section_advantages .messages2 {
  content: url("../images/landing/landing-slider/messages2.svg");
  top: 0;
  left: 50%;
  width: 67px;
  height: 67px;
}
.section_advantages .voice {
  content: url("../images/landing/landing-slider/voice.svg");
  top: 65%;
  left: 115px;
  width: 68px;
  height: 68px;
}
.section_advantages .video {
  content: url("../images/landing/landing-slider/video.svg");
  top: 45%;
  left: 60px;
  width: 66px;
  height: 66px;
}
.section_advantages .mystories {
  content: url("../images/landing/landing-slider/mystories.svg");
  top: 70%;
  right: 0;
  width: 169px;
  height: 50px;
}
.section_advantages .private_contents {
  content: url("../images/landing/landing-slider/private_contents.svg");
  left: 60%;
  top: 66px;
  width: 77px;
  height: 77px;
}
.section_participate {
  background: var(--gradient);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}
.section_participate .container {
  max-width: 100%;
  padding: 0;
  position: relative;
  z-index: 50;
}
.section_participate .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.section_participate .text {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-top: 110px;
  padding-right: 236px;
}
.section_participate .text::after {
  content: url("../images/landing/confetti_1.svg");
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  animation: coin-fall 6s linear;
  transition: transform 1s;
  transform-style: preserve-3d;
  background-repeat: no-repeat;
}
.section_participate .text::before {
  content: url("../images/landing/confetti_2.svg");
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  animation: coin-fall 6s linear;
  transition: transform 1s;
  transform-style: preserve-3d;
  background-repeat: no-repeat;
}
.section_participate .media__image {
  background-repeat: no-repeat;
  margin-top: 80px;
  z-index: 20;
}
.section_participate .media__video {
  border-radius: 55px;
}
.section_participate .media__video_desktop {
  display: block;
}
.section_participate .media__video_mobile {
  display: none;
}
.section_participate .body {
  display: flex;
  flex-direction: column;
  max-width: 609px;
  gap: 60px;
}
.section_participate .button_lg {
  max-width: 323px;
  height: 70px;
  background-color: var(--color-white);
}
.section_participate .button_lg > span {
  background: var(--gradient-reverse);
  background-clip: text;
  color: transparent;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.35;
}
.section_participate .title, .section_participate .icon-box__title, .section_participate .heading, .section_participate .section__sub-heading, .section_participate .section__title,
.section_participate .content {
  color: var(--color-white);
}
.section_participate .title, .section_participate .icon-box__title, .section_participate .heading, .section_participate .section__sub-heading, .section_participate .section__title {
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
}
.section_participate .content {
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.section_participate .message {
  display: flex;
  flex-direction: column;
  margin-top: 174px;
  margin-left: -60px;
  z-index: 50;
}
.section_participate .message__body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 97px;
  border-radius: 10px 10px 10px 10px;
  background-color: rgba(225, 225, 225, 0.2);
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 21px 10px 21px 50px;
  align-items: center;
  justify-content: space-between;
}
.section_participate .message__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-white);
  line-height: 1.4;
}
.section_participate .message__title {
  color: var(--color-white);
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  text-transform: none;
}
.section_participate .message__content {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}
.section_participate .message__picture {
  width: 71px;
  height: 71px;
}
.section_participate .message__image {
  background-size: cover;
  object-fit: cover;
}
.section_features {
  padding-top: 50px;
  background-image: url(../images/landing/bg-mob.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.section_features .animation {
  display: block;
  overflow: hidden;
}
.section_install {
  padding-top: 0;
  overflow: hidden;
}
.section_install .section__title {
  margin-top: 38px;
  margin-bottom: 30px;
}
.section_install .section__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.section_install .section__buttons .button_primary {
  width: 100%;
  margin-top: 30px;
}
.section_install .section__buttons .svg-icon {
  margin-right: 14px;
}
.section_promo {
  padding-top: 90px;
  padding-bottom: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.section_promo .section__promo {
  position: relative;
  z-index: 30;
}
.section_promo .section__heading {
  margin-bottom: 30px;
  line-height: 1.3;
  color: var(--color-white);
}
.section_about .section__heading, .section_contact .section__heading, .section_meet .section__heading {
  text-align: left;
}
.section_contact {
  padding-top: 60px;
}
.section_contact .container {
  background-image: url(../images/modal/hablario.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 620px;
  height: 540px;
}
.section_contact .section__heading {
  margin-bottom: 40px;
  text-transform: uppercase;
}
.section_contact .section__sub-heading {
  margin-top: 60px;
  margin-bottom: 30px;
  font-size: 2rem;
}
.section_contact .ui-textarea {
  min-height: 160px;
}
.section_contact .form__row {
  margin-bottom: 20px;
}
.section_contact .form__label {
  margin: 0;
}
.section_contact .form .button {
  margin-top: 20px;
}
.section_service .section__description {
  text-align: left;
  width: 735px;
}
.section_service .section__wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 25px;
}
.section_service .section__content {
  width: 553px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.7;
  color: #1F1F1F;
}
.section_service .section__link a {
  display: block;
  text-transform: uppercase;
  color: #131313;
  font-size: 20px;
  font-weight: 400;
}
.section_service .section__images {
  display: block;
  width: 907px;
  min-width: 907px;
  margin-top: -200px;
  background-repeat: no-repeat;
  background-size: contain;
}
.section_statistic .section__title-promo {
  text-align: center;
  width: 830px;
  margin-left: calc(50% - 415px);
  padding-bottom: 92px;
}
.section_meet {
  padding-top: 80px;
}
.section_meet .wrapper {
  display: flex;
  flex-direction: row;
  gap: 100px;
}
.section_meet .section__heading,
.section_meet .section__content {
  width: 570px;
}
.section_meet .section__heading {
  padding-bottom: 40px;
}
.section_meet .section__content p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #7E7E7E;
}
.section_meet .section__picture {
  width: 568px;
  min-width: 568px;
  margin-top: -184px;
  background-repeat: no-repeat;
  background-size: contain;
}
.section .image {
  border-radius: 10px;
}
.section_faq .section__heading {
  margin-bottom: 50px;
}
.section_faq .section__title {
  font-size: 1.5rem;
}
.section_blog .section__heading {
  margin-bottom: 12px;
}
.section_blog .pagination {
  margin-top: 40px;
}
.section_members .section__heading, .section_page .section__heading {
  margin-bottom: 50px;
}
.section_members {
  padding-bottom: 50px;
}
.section_page {
  line-height: 1.8;
}
.section_page h1:first-child, .section_page h2:first-child, .section_page h3:first-child, .section_page h4:first-child, .section_page h5:first-child, .section_page h6:first-child {
  margin-top: 0;
}
.section_page h2, .section_page h3 {
  margin-top: 20px;
  margin-bottom: 30px;
}
.section_page h2 {
  font-size: 2rem;
}
.section_page p {
  margin-bottom: 25px;
}
.section_page .link, .section_page .chat__close, .section_page .modal_tariffs .form__link, .modal_tariffs .section_page .form__link, .section_page .modal__close, .section_page .page__close, .section_page .button_close {
  color: var(--color-text);
  text-decoration: underline;
}
.section_page .list, .section_page .price, .section_page .tabs, .section_page .pagination, .section_page .menu, .section_page .dropdown__menu, .section_page .social {
  line-height: 1.8;
}

.no-webp .section_promo {
  background-image: url(../images/landing/bg-section1_mob.png);
}

.webp .section_promo {
  background-image: url(../images/landing/bg-section1_mob.webp);
}

@media screen and (max-width: 1279px) {
  .section_promo {
    text-align: center;
  }
  .section_promo .button_light {
    min-width: 290px;
  }
}
@media screen and (max-width: 767px) {
  .section_contact, .section_install, .section_about {
    padding-left: 25px;
    padding-right: 25px;
  }
  .section_promo {
    padding-left: 10px;
    padding-right: 10px;
  }
  .section_service .section__heading,
.section_service .section__description {
    text-align: left;
  }
  .section_statistic .statistic__item:not(:last-child) {
    margin-bottom: 60px;
  }
  .section_meet .image {
    margin-bottom: 40px;
    aspect-ratio: 16/9;
    width: 100%;
    object-fit: cover;
    object-position: top center;
  }
}
@media screen and (max-width: 374px) {
  .section_contact, .section_install, .section_about {
    padding-left: 0;
    padding-right: 0;
  }
  .section_promo {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .section_install .section__buttons .button_primary {
    margin-top: 0;
    width: initial;
  }
  .section_meet .section__column {
    position: relative;
    min-height: 495px;
  }
  .section_meet .section__column .image {
    position: absolute;
    width: calc(100% - 15px);
    top: 0;
    right: calc(-100% + 15px);
    z-index: 1;
    margin-bottom: 0;
  }
  .section_blog .post {
    margin-top: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .section__sub-heading, .section__content-title, .section__title-promo {
    font-size: 2rem;
  }
  .section__title {
    font-size: 1.25rem;
  }
  .section_contact, .section_install, .section_features, .section_meet {
    padding-bottom: 120px;
  }
  .section_service {
    padding-bottom: 142px;
    display: flex;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(100px);
  }
  .section_service .container {
    max-width: 100%;
    padding: 100px 170px 0 266px;
  }
  .section_service .section__heading {
    font-size: 72px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: left;
  }
  .section_service .section__description {
    background-image: var(--gradient-reverse);
    margin-bottom: 35px;
    font-size: 2rem;
    margin-top: 35px;
  }
  .section_about .section__heading {
    margin-bottom: 50px;
  }
  .section_about .section__title {
    margin-bottom: 30px;
  }
  .section_install .section__buttons {
    justify-content: flex-start;
  }
  .section_contact, .section_meet {
    padding-top: 120px;
  }
  .section_contact .section__sub-heading {
    margin-top: 100px;
  }
  .section_contact .form__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .section_contact .form__footer .button {
    margin-top: 0;
    width: 160px;
  }
  .section_features {
    padding-top: 8.5rem;
    background-image: url(../images/landing/bg.svg);
    background-position: 55% 25%;
  }
  .section_service .section__content-title {
    width: 80%;
  }
  .section_faq .section__title {
    font-size: 2rem;
  }
  .section_blog .list_sort {
    justify-content: center;
  }
}
@media (min-width: 429px) and (max-width: 1279px) {
  .section_confidence {
    padding: 40px 20px;
  }
  .section_confidence .heading {
    font-size: 1.5rem;
    text-align: center;
    width: 326px;
    margin: 0 auto;
  }
  .section_confidence .list_confidence {
    flex-direction: column;
    padding-top: 1.625rem;
    gap: 26px;
  }
  .section_confidence .list__item,
.section_confidence .list__item_confidence {
    width: 389px;
    padding: 20px 40px;
    gap: 16px;
    transform: scale(0.1);
  }
  .section_confidence .list__item:nth-child(2)::after,
.section_confidence .list__item_confidence:nth-child(2)::after {
    content: url("../images/landing/flicker3.svg");
    position: absolute;
    bottom: -31px;
    left: 0;
    right: 75%;
    transform: rotate(180deg);
  }
  .section_confidence .list__item .icon_user,
.section_confidence .list__item .icon_heart,
.section_confidence .list__item .icon_member,
.section_confidence .list__item_confidence .icon_user,
.section_confidence .list__item_confidence .icon_heart,
.section_confidence .list__item_confidence .icon_member {
    width: 50px;
    height: 50px;
  }
  .section_confidence .list__item .title, .section_confidence .list__item .icon-box__title, .section_confidence .list__item .heading, .section_confidence .list__item .section__sub-heading, .section_confidence .list__item .section__title,
.section_confidence .list__item_confidence .title,
.section_confidence .list__item_confidence .icon-box__title,
.section_confidence .list__item_confidence .heading,
.section_confidence .list__item_confidence .section__sub-heading,
.section_confidence .list__item_confidence .section__title {
    font-size: 20px;
  }
  .section_confidence .list__item .content,
.section_confidence .list__item_confidence .content {
    font-size: 16px;
  }
  .section_advantages {
    padding-top: 40px;
  }
  .section_advantages .container {
    padding-bottom: 40px;
  }
  .section_advantages .heading {
    font-size: 1.5rem;
    text-align: center;
    width: 326px;
    margin: 0 auto;
  }
  .section_advantages .is-flex {
    flex-direction: column;
    padding: 30px 0;
    gap: 20px;
  }
  .section_advantages .list_advantages {
    gap: 16px;
    order: 2;
  }
  .section_advantages .list_advantages::before, .section_advantages .picture::before {
    content: "";
    width: 357px;
    height: 357px;
    border-radius: 357px;
  }
  .section_advantages .list__item {
    width: 390px;
    height: 130px;
    border-radius: 30px;
    box-shadow: 0 3px 20px 0 rgba(11, 40, 115, 0.06);
    gap: 4px;
  }
  .section_advantages .wrapper {
    padding: 12px 17px 26px 56px;
  }
  .section_advantages .wrapper .title, .section_advantages .wrapper .icon-box__title, .section_advantages .wrapper .heading, .section_advantages .wrapper .section__sub-heading, .section_advantages .wrapper .section__title {
    font-size: 20px;
  }
  .section_advantages .list__item:nth-child(1)::before,
.section_advantages .list__item:nth-child(2)::before,
.section_advantages .list__item:nth-child(3)::before {
    width: 26px;
    height: 60px;
    font-size: 48px;
    left: calc(56px - 18px - 13px);
  }
  .section_advantages .content {
    font-size: 16px;
    width: 316px;
  }
  .section_advantages .button_lg {
    width: 390px;
    align-self: center;
  }
  .section_advantages .picture {
    max-width: 358px;
  }
  .section_advantages .ellipse1 {
    border-radius: 358px;
    height: 358px;
    width: 358px;
  }
  .section_advantages .ellipse2 {
    width: 325px;
    height: 325px;
    border-radius: 325px;
    top: 16px;
    left: 16px;
  }
  .section_advantages .ellipse3 {
    width: 288px;
    height: 288px;
    border-radius: 288px;
    top: 35px;
    left: 35px;
  }
  .section_advantages .gifts {
    top: 40%;
    right: -56px;
  }
  .section_advantages .calls {
    content: url("../images/landing/landing-slider/calls.svg");
    top: 35%;
    right: 35px;
    width: 40px;
    height: 25px;
  }
  .section_advantages .bonuses1 {
    content: url("../images/landing/landing-slider/bonuses.svg");
    top: 60%;
    left: 16px;
    width: 36px;
    height: 36px;
  }
  .section_advantages .bonuses2 {
    content: url("../images/landing/landing-slider/bonuses2.svg");
    left: 50%;
    bottom: 16px;
    width: 38px;
    height: 38px;
  }
  .section_advantages .messages {
    content: url("../images/landing/landing-slider/messages.svg");
    top: 56px;
    left: 56px;
    width: 55px;
    height: 55px;
  }
  .section_advantages .messages2 {
    content: url("../images/landing/landing-slider/messages2.svg");
    top: 0;
    left: 50%;
    width: 34px;
    height: 34px;
  }
  .section_advantages .voice {
    content: url("../images/landing/landing-slider/voice.svg");
    top: 65%;
    left: 56px;
    width: 40px;
    height: 40px;
  }
  .section_advantages .video {
    content: url("../images/landing/landing-slider/video.svg");
    top: 45%;
    left: 35px;
    width: 33px;
    height: 33px;
  }
  .section_advantages .mystories {
    content: url("../images/landing/landing-slider/mystories.svg");
    top: 70%;
    right: 0;
    width: 85px;
    height: 25px;
  }
  .section_advantages .private_contents {
    content: url("../images/landing/landing-slider/private_contents.svg");
    left: 60%;
    top: 35px;
    width: 38px;
    height: 38px;
  }
  .section_participate {
    background-image: none;
    margin: 0 auto;
  }
  .section_participate::before {
    content: "";
  }
  .section_participate::after {
    content: "";
  }
  .section_participate .container {
    z-index: 10;
  }
  .section_participate .wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    gap: 40px;
  }
  .section_participate .text {
    gap: 32px;
    padding-top: 82px;
    padding-bottom: 82px;
    padding-right: 0;
    order: 2;
    background: var(--gradient);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 10;
  }
  .section_participate .text::after {
    content: url("../images/landing/confetti_1.svg");
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    animation: coin-fall_mobile 6s infinite;
    transition: transform 1s;
    transform-style: preserve-3d;
    background-repeat: no-repeat;
  }
  .section_participate .text::before {
    content: "";
  }
  .section_participate .media__image {
    background-repeat: no-repeat;
    margin-top: 0;
  }
  .section_participate .media__video {
    border-radius: 55px;
    object-fit: cover;
  }
  .section_participate .media__video_desktop {
    display: none;
  }
  .section_participate .media__video_mobile {
    display: block;
  }
  .section_participate .body {
    width: 330px;
    gap: 32px;
    margin: 0 auto;
  }
  .section_participate .button_lg {
    width: 390px;
    height: 60px;
    margin: 0 auto;
    align-items: center;
  }
  .section_participate .button_lg > span {
    font-size: 24px;
  }
  .section_participate .title, .section_participate .icon-box__title, .section_participate .heading, .section_participate .section__sub-heading, .section_participate .section__title {
    font-size: 32px;
    text-align: center;
  }
  .section_participate .content {
    font-size: 24px;
    gap: 8px;
    margin: 0 auto;
    text-align: left;
  }
  .section_participate .message {
    display: none;
  }
  .section_service {
    padding-bottom: 0;
  }
  .section_service .container {
    padding: 40px 20px 0 20px;
  }
  .section_service .section__heading {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  }
  .section_service .section__description {
    text-align: center;
    width: 359px;
    font-size: 20px;
    margin: 12px auto;
  }
  .section_service .section__wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .section_service .section__content {
    width: 390px;
    font-size: 16px;
    order: 1;
  }
  .section_service .section__link a {
    font-size: 20px;
    padding-top: 20px;
  }
  .section_service .section__images {
    display: block;
    width: 430px;
    min-width: 430px;
    margin-top: 0;
    order: 0;
  }
  .section_statistic .section__title-promo {
    width: 390px;
    margin-left: calc(50% - 195px);
    font-size: 16px;
    padding-bottom: 24px;
  }
  .section_meet {
    padding-top: 77px;
    padding-bottom: 20px;
  }
  .section_meet .wrapper {
    gap: 0;
    flex-direction: column;
    align-items: center;
  }
  .section_meet .section__heading {
    font-size: 24px;
  }
  .section_meet .section__heading,
.section_meet .section__content {
    width: 390px;
  }
  .section_meet .section__content {
    font-size: 16px;
    order: 1;
  }
  .section_meet .section__heading {
    padding-bottom: 0;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: center;
    position: relative;
  }
  .section_meet .section__heading::before {
    content: url("../images/landing/about/stars.svg");
    position: absolute;
    width: 95px;
    height: 14px;
    display: block;
    top: -50px;
    left: calc(50% - 47px);
    z-index: 50;
  }
  .section_meet .section__picture {
    width: 390px;
    min-width: 390px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    order: 0;
  }
}
@media (max-width: 429px) {
  .section_confidence {
    padding: 40px 20px;
  }
  .section_confidence .heading {
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .section_confidence .list_confidence {
    flex-direction: column;
    padding-top: 1.625rem;
    gap: 26px;
  }
  .section_confidence .list__item,
.section_confidence .list__item_confidence {
    width: 100%;
    padding: 20px 40px;
    gap: 16px;
    transform: scale(0.1);
  }
  .section_confidence .list__item:nth-child(2)::after,
.section_confidence .list__item_confidence:nth-child(2)::after {
    content: url("../images/landing/flicker3.svg");
    position: absolute;
    bottom: -31px;
    left: 0;
    right: 75%;
    transform: rotate(180deg);
  }
  .section_confidence .list__item .icon_user,
.section_confidence .list__item .icon_heart,
.section_confidence .list__item .icon_member,
.section_confidence .list__item_confidence .icon_user,
.section_confidence .list__item_confidence .icon_heart,
.section_confidence .list__item_confidence .icon_member {
    width: 50px;
    height: 50px;
  }
  .section_confidence .list__item .title, .section_confidence .list__item .icon-box__title, .section_confidence .list__item .heading, .section_confidence .list__item .section__sub-heading, .section_confidence .list__item .section__title,
.section_confidence .list__item_confidence .title,
.section_confidence .list__item_confidence .icon-box__title,
.section_confidence .list__item_confidence .heading,
.section_confidence .list__item_confidence .section__sub-heading,
.section_confidence .list__item_confidence .section__title {
    font-size: 20px;
  }
  .section_confidence .list__item .content,
.section_confidence .list__item_confidence .content {
    font-size: 16px;
  }
  .section_advantages {
    padding-top: 40px;
  }
  .section_advantages .container {
    padding-bottom: 40px;
  }
  .section_advantages .heading {
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .section_advantages .is-flex {
    flex-direction: column;
    padding: 30px 0;
    gap: 20px;
  }
  .section_advantages .list_advantages {
    gap: 16px;
    order: 2;
  }
  .section_advantages .list_advantages::before, .section_advantages .picture::before {
    content: "";
    width: 357px;
    height: 357px;
    border-radius: 357px;
  }
  .section_advantages .list__item {
    width: 100%;
    height: auto;
    border-radius: 30px;
    box-shadow: 0 3px 20px 0 rgba(11, 40, 115, 0.06);
    gap: 4px;
  }
  .section_advantages .wrapper {
    padding: 12px 17px 26px 56px;
  }
  .section_advantages .wrapper .title, .section_advantages .wrapper .icon-box__title, .section_advantages .wrapper .heading, .section_advantages .wrapper .section__sub-heading, .section_advantages .wrapper .section__title {
    font-size: 20px;
  }
  .section_advantages .list__item:nth-child(1)::before,
.section_advantages .list__item:nth-child(2)::before,
.section_advantages .list__item:nth-child(3)::before {
    width: 26px;
    height: 60px;
    font-size: 48px;
    left: calc(56px - 18px - 13px);
  }
  .section_advantages .content {
    font-size: 16px;
    width: fit-content;
  }
  .section_advantages .button_lg {
    align-self: center;
    width: 343px;
  }
  .section_advantages .picture {
    max-width: 358px;
  }
  .section_advantages .ellipse1 {
    border-radius: 358px;
    height: 358px;
    width: 358px;
  }
  .section_advantages .ellipse2 {
    width: 325px;
    height: 325px;
    border-radius: 325px;
    top: 16px;
    left: 16px;
  }
  .section_advantages .ellipse3 {
    width: 288px;
    height: 288px;
    border-radius: 288px;
    top: 35px;
    left: 35px;
  }
  .section_advantages .gifts {
    top: 40%;
    right: -56px;
  }
  .section_advantages .calls {
    content: url("../images/landing/landing-slider/calls.svg");
    top: 35%;
    right: 35px;
    width: 40px;
    height: 25px;
  }
  .section_advantages .bonuses1 {
    content: url("../images/landing/landing-slider/bonuses.svg");
    top: 60%;
    left: 16px;
    width: 36px;
    height: 36px;
  }
  .section_advantages .bonuses2 {
    content: url("../images/landing/landing-slider/bonuses2.svg");
    left: 50%;
    bottom: 16px;
    width: 38px;
    height: 38px;
  }
  .section_advantages .messages {
    content: url("../images/landing/landing-slider/messages.svg");
    top: 56px;
    left: 56px;
    width: 55px;
    height: 55px;
  }
  .section_advantages .messages2 {
    content: url("../images/landing/landing-slider/messages2.svg");
    top: 0;
    left: 50%;
    width: 34px;
    height: 34px;
  }
  .section_advantages .voice {
    content: url("../images/landing/landing-slider/voice.svg");
    top: 65%;
    left: 56px;
    width: 40px;
    height: 40px;
  }
  .section_advantages .video {
    content: url("../images/landing/landing-slider/video.svg");
    top: 45%;
    left: 35px;
    width: 33px;
    height: 33px;
  }
  .section_advantages .mystories {
    content: url("../images/landing/landing-slider/mystories.svg");
    top: 70%;
    right: 0;
    width: 85px;
    height: 25px;
  }
  .section_advantages .private_contents {
    content: url("../images/landing/landing-slider/private_contents.svg");
    left: 60%;
    top: 35px;
    width: 38px;
    height: 38px;
  }
  .section_participate {
    background-image: none;
    margin: 0 auto;
  }
  .section_participate .wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    gap: 40px;
  }
  .section_participate .text {
    gap: 32px;
    padding-top: 82px;
    padding-bottom: 82px;
    padding-right: 0;
    order: 2;
    background: var(--gradient);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 20;
  }
  .section_participate .text::after {
    content: url("../images/landing/confetti_1.svg");
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    animation: coin-fall_mobile 3s infinite;
    transition: transform 1s;
    transform-style: preserve-3d;
    background-repeat: no-repeat;
  }
  .section_participate .text::before {
    content: "";
  }
  .section_participate .media__image {
    margin-top: 0;
  }
  .section_participate .media__video_desktop {
    display: none;
  }
  .section_participate .media__video_mobile {
    display: block;
  }
  .section_participate .body {
    max-width: 330px;
    gap: 32px;
    margin: 0 auto;
  }
  .section_participate .button_lg {
    height: 60px;
    margin: 0 auto;
    align-items: center;
  }
  .section_participate .button_lg > span {
    font-size: 24px;
  }
  .section_participate .title, .section_participate .icon-box__title, .section_participate .heading, .section_participate .section__sub-heading, .section_participate .section__title {
    font-size: 32px;
    text-align: center;
  }
  .section_participate .content {
    font-size: 24px;
    gap: 8px;
    margin: 0 auto;
    text-align: left;
    padding-left: 10px;
  }
  .section_participate .message {
    display: none;
  }
  .section_service {
    padding-bottom: 0;
  }
  .section_service .container {
    padding: 40px 20px 0 20px;
  }
  .section_service .section__heading {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
  }
  .section_service .section__description {
    text-align: center;
    width: 100%;
    font-size: 20px;
    margin: 12px auto;
  }
  .section_service .section__wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .section_service .section__content {
    width: 100%;
    font-size: 16px;
    order: 1;
    padding: 10px;
  }
  .section_service .section__link a {
    font-size: 20px;
    padding-top: 20px;
  }
  .section_service .section__images {
    display: block;
    width: auto;
    min-width: auto;
    margin-top: 0;
    order: 0;
  }
  .section_statistic .section__title-promo {
    width: 100%;
    margin-left: 0;
    font-size: 16px;
    padding-bottom: 24px;
  }
  .section_meet {
    padding-top: 77px;
    padding-bottom: 20px;
  }
  .section_meet .wrapper {
    gap: 0;
    flex-direction: column;
    align-items: center;
  }
  .section_meet .section__heading {
    font-size: 24px;
  }
  .section_meet .section__heading,
.section_meet .section__content {
    width: 100%;
  }
  .section_meet .section__content {
    font-size: 16px;
    order: 1;
  }
  .section_meet .section__heading {
    padding-bottom: 0;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: center;
    position: relative;
  }
  .section_meet .section__heading::before {
    content: url("../images/landing/about/stars.svg");
    position: absolute;
    width: 95px;
    height: 14px;
    display: block;
    top: -50px;
    left: calc(50% - 47px);
    z-index: 50;
  }
  .section_meet .section__picture {
    width: auto;
    min-width: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    order: 0;
  }
}
@media (min-width: 1279px) and (max-width: 1919px) {
  .no-webp .section_promo {
    background-image: url(../images/landing/bg-section1.png);
  }

  .webp .section_promo {
    background-image: url(../images/landing/bg-section1.webp);
  }

  .section_about {
    padding-top: 135px;
  }
  .section_about .carousel {
    width: 95%;
  }
  .section_confidence {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .section_confidence .heading {
    font-size: 28px;
  }
  .section_confidence .list_confidence {
    gap: 14px;
    padding-top: 2.5rem;
  }
  .section_confidence .list__item,
.section_confidence .list__item_confidence {
    width: 378px;
    padding: 28px 50px;
    border-radius: 30px;
    box-shadow: 0 2px 36px 0 rgba(11, 40, 115, 0.08);
  }
  .section_confidence .list__item .icon_user,
.section_confidence .list__item .icon_heart,
.section_confidence .list__item .icon_member,
.section_confidence .list__item_confidence .icon_user,
.section_confidence .list__item_confidence .icon_heart,
.section_confidence .list__item_confidence .icon_member {
    width: 74px;
    height: 74px;
  }
  .section_confidence .list__item .title, .section_confidence .list__item .icon-box__title, .section_confidence .list__item .heading, .section_confidence .list__item .section__sub-heading, .section_confidence .list__item .section__title,
.section_confidence .list__item_confidence .title,
.section_confidence .list__item_confidence .icon-box__title,
.section_confidence .list__item_confidence .heading,
.section_confidence .list__item_confidence .section__sub-heading,
.section_confidence .list__item_confidence .section__title {
    font-size: 18px;
  }
  .section_confidence .list__item .content,
.section_confidence .list__item_confidence .content {
    font-size: 16px;
  }
  .section_advantages .container {
    padding-top: 60px;
    padding-bottom: 47px;
  }
  .section_advantages .heading {
    font-size: 2rem;
  }
  .section_advantages .is-flex {
    gap: 76px;
  }
  .section_advantages .list_advantages {
    gap: 20px;
  }
  .section_advantages .list_advantages::before, .section_advantages .picture::before {
    content: "";
    width: 430px;
    height: 430px;
    border-radius: 430px;
  }
  .section_advantages .list__item {
    width: 466px;
    height: 152px;
    display: block;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 44px 0 rgba(11, 40, 115, 0.06);
    gap: 4px;
  }
  .section_advantages .wrapper {
    padding: 20px 0 34px 65px;
  }
  .section_advantages .wrapper .title, .section_advantages .wrapper .icon-box__title, .section_advantages .wrapper .heading, .section_advantages .wrapper .section__sub-heading, .section_advantages .wrapper .section__title {
    font-size: 24px;
  }
  .section_advantages .list__item:nth-child(1)::before,
.section_advantages .list__item:nth-child(2)::before,
.section_advantages .list__item:nth-child(3)::before {
    width: 26px;
    height: 62px;
    font-size: 44px;
    left: calc(65px - 27px - 13px);
  }
  .section_advantages .content {
    font-size: 16px;
    width: 358px;
  }
  .section_advantages .picture {
    position: relative;
    max-width: 536px;
  }
  .section_advantages .ellipse1 {
    border-radius: 536px;
    height: 536px;
    width: 536px;
  }
  .section_advantages .ellipse2 {
    width: 487px;
    height: 487px;
    border-radius: 487px;
    top: 25px;
    left: 25px;
  }
  .section_advantages .ellipse3 {
    width: 431px;
    height: 431px;
    border-radius: 431px;
    top: 52px;
    left: 52px;
  }
  .section_advantages .gifts,
.section_advantages .calls,
.section_advantages .bonuses1,
.section_advantages .bonuses2,
.section_advantages .messages,
.section_advantages .messages2,
.section_advantages .voice,
.section_advantages .video,
.section_advantages .private_contents,
.section_advantages .mystories {
    position: absolute;
    display: block;
    z-index: 60;
  }
  .section_advantages .gifts {
    content: url("../images/landing/landing-slider/gifts.svg");
    top: 40%;
    right: -33px;
  }
  .section_advantages .calls {
    content: url("../images/landing/landing-slider/calls.svg");
    top: 35%;
    right: 60px;
    width: 59px;
    height: 37px;
  }
  .section_advantages .bonuses1 {
    content: url("../images/landing/landing-slider/bonuses.svg");
    top: 60%;
    left: 33px;
    width: 39px;
    height: 39px;
  }
  .section_advantages .bonuses2 {
    content: url("../images/landing/landing-slider/bonuses2.svg");
    left: 50%;
    bottom: 33px;
    width: 43px;
    height: 43px;
  }
  .section_advantages .messages {
    content: url("../images/landing/landing-slider/messages.svg");
    top: 100px;
    left: 100px;
    width: 82px;
    height: 82px;
  }
  .section_advantages .messages2 {
    content: url("../images/landing/landing-slider/messages2.svg");
    top: 0;
    left: 50%;
    width: 50px;
    height: 50px;
  }
  .section_advantages .voice {
    content: url("../images/landing/landing-slider/voice.svg");
    top: 65%;
    left: 115px;
    width: 50px;
    height: 50px;
  }
  .section_advantages .video {
    content: url("../images/landing/landing-slider/video.svg");
    top: 45%;
    left: 60px;
    width: 50px;
    height: 50px;
  }
  .section_advantages .mystories {
    content: url("../images/landing/landing-slider/mystories.svg");
    top: 70%;
    right: 0;
    width: 127px;
    height: 38px;
  }
  .section_advantages .private_contents {
    content: url("../images/landing/landing-slider/private_contents.svg");
    left: 60%;
    top: 66px;
    width: 57px;
    height: 57px;
  }
  .section_participate {
    background: var(--gradient);
  }
  .section_participate .text {
    gap: 40px;
    padding-top: 100px;
    padding-right: 115px;
  }
  .section_participate .text::after {
    content: url("../images/landing/confetti_1.svg");
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    animation: coin-fall_mobile 6s infinite;
    transition: transform 1s;
    transform-style: preserve-3d;
    background-repeat: no-repeat;
    opacity: 0;
  }
  .section_participate .text::before {
    content: url("../images/landing/confetti_2.svg");
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    animation: coin-fall_mobile 6s infinite;
    transition: transform 1s;
    transform-style: preserve-3d;
    background-repeat: no-repeat;
    opacity: 0;
  }
  .section_participate .media__image {
    background-repeat: no-repeat;
    margin-top: 60px;
  }
  .section_participate .media__video {
    border-radius: 55px;
  }
  .section_participate .media__video_desktop {
    display: block;
  }
  .section_participate .media__video_mobile {
    display: none;
  }
  .section_participate .body {
    width: 406px;
    gap: 40px;
  }
  .section_participate .button_lg {
    width: 240px;
    height: 56px;
  }
  .section_participate .button_lg > span {
    font-size: 20px;
  }
  .section_participate .title, .section_participate .icon-box__title, .section_participate .heading, .section_participate .section__sub-heading, .section_participate .section__title {
    font-size: 40px;
  }
  .section_participate .content {
    font-size: 24px;
    gap: 12px;
  }
  .section_participate .message {
    margin-top: 131px;
    margin-left: -60px;
  }
  .section_participate .message__body {
    width: 255px;
    height: 72px;
    border-radius: 7px;
    background-color: rgba(225, 225, 225, 0.2);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 15px 10px 15px 37px;
    fill: rgba(225, 225, 225, 0.2);
    backdrop-filter: blur(7.5px);
  }
  .section_participate .message__title {
    font-size: 15px;
  }
  .section_participate .message__content {
    font-size: 12px;
  }
  .section_participate .message__picture {
    width: 54px;
    height: 54px;
  }
  .section_service .container {
    padding: 70px 55px 0 90px;
  }
  .section_service .section__heading {
    font-size: 48px;
  }
  .section_service .section__description {
    text-align: left;
    width: 550px;
    font-size: 18px;
  }
  .section_service .section__content {
    width: 432px;
    font-size: 1rem;
  }
  .section_service .section__content p, .section_service .section__content ul li {
    font-size: 1rem;
  }
  .section_service .section__link a {
    font-size: 16px;
  }
  .section_service .section__images {
    display: block;
    width: 681px;
    min-width: 681px;
    margin-top: -200px;
  }
  .section_statistic .section__title-promo {
    width: 623px;
    margin-left: calc(50% - 312px);
    padding-bottom: 40px;
  }
  .section_meet {
    padding-top: 40px;
  }
  .section_meet .wrapper {
    gap: 75px;
    justify-content: center;
  }
  .section_meet .section__heading {
    font-size: 48px;
    margin-left: calc(50% - 428px - 37px);
  }
  .section_meet .section__heading,
.section_meet .section__content {
    width: 428px;
  }
  .section_meet .section__heading {
    padding-bottom: 22px;
  }
  .section_meet .section__picture {
    width: 426px;
    min-width: 426px;
    margin-top: -138px;
  }
  .section_install .section__title {
    width: 60%;
  }
  .section_promo {
    padding-bottom: 92px;
    padding-top: 80px;
  }
  .section_promo .section__heading {
    text-align: left;
  }
  .section_promo .container {
    display: flex;
  }
  .section_promo .section__promo {
    width: 50%;
  }
  .section_promo .animation {
    position: relative;
    width: 50%;
    height: 100%;
  }
  .section_contact .form_horizontal {
    width: 82%;
  }
  .section_contact .ui-input,
.section_contact .ui-textarea {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 1920px) {
  .section_confidence {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: var(--color-white);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
  }
  .section_confidence .container {
    max-width: 100%;
  }
  .section_confidence .heading {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 135%;
    text-transform: uppercase;
  }
  .section_confidence .list_confidence {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 3.75rem;
    width: 100%;
    gap: 40px;
  }
  .section_confidence .list__item,
.section_confidence .list__item_confidence {
    display: flex;
    width: 504px;
    padding: 37px 42px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 40px;
    background: #FFF;
    box-shadow: 0 4px 62px 0 rgba(11, 40, 115, 0.06);
    transform: scale(0.1);
  }
  .section_confidence .list__item:nth-child(1)::before,
.section_confidence .list__item_confidence:nth-child(1)::before {
    content: url("../images/landing/flicker1.svg");
    position: absolute;
    left: -60px;
    top: -30px;
  }
  .section_confidence .list__item:nth-child(1)::after,
.section_confidence .list__item_confidence:nth-child(1)::after {
    content: url("../images/landing/flicker2.svg");
    position: absolute;
    right: -60px;
    bottom: -60px;
  }
  .section_confidence .list__item:nth-child(2)::after,
.section_confidence .list__item_confidence:nth-child(2)::after {
    content: url("../images/landing/flicker3.svg");
    position: absolute;
    top: -31px;
    right: 0;
  }
  .section_confidence .list__item:nth-child(3)::after,
.section_confidence .list__item_confidence:nth-child(3)::after {
    content: url("../images/landing/flicker4.svg");
    position: absolute;
    bottom: -49px;
    right: -60px;
  }
  .section_confidence .list__item .icon_user,
.section_confidence .list__item .icon_heart,
.section_confidence .list__item .icon_member,
.section_confidence .list__item_confidence .icon_user,
.section_confidence .list__item_confidence .icon_heart,
.section_confidence .list__item_confidence .icon_member {
    width: 100px;
    height: 100px;
    display: block;
  }
  .section_confidence .list__item .icon_user,
.section_confidence .list__item_confidence .icon_user {
    background-image: url("../images/landing/icon_user.svg");
  }
  .section_confidence .list__item .icon_heart,
.section_confidence .list__item_confidence .icon_heart {
    background-image: url("../images/landing/icon_heart.svg");
  }
  .section_confidence .list__item .icon_member,
.section_confidence .list__item_confidence .icon_member {
    background-image: url("../images/landing/icon_member.svg");
  }
  .section_confidence .list__item .title, .section_confidence .list__item .icon-box__title, .section_confidence .list__item .heading, .section_confidence .list__item .section__sub-heading, .section_confidence .list__item .section__title,
.section_confidence .list__item .content,
.section_confidence .list__item_confidence .title,
.section_confidence .list__item_confidence .icon-box__title,
.section_confidence .list__item_confidence .heading,
.section_confidence .list__item_confidence .section__sub-heading,
.section_confidence .list__item_confidence .section__title,
.section_confidence .list__item_confidence .content {
    color: #131313;
    text-align: center;
  }
  .section_confidence .list__item .title, .section_confidence .list__item .icon-box__title, .section_confidence .list__item .heading, .section_confidence .list__item .section__sub-heading, .section_confidence .list__item .section__title,
.section_confidence .list__item_confidence .title,
.section_confidence .list__item_confidence .icon-box__title,
.section_confidence .list__item_confidence .heading,
.section_confidence .list__item_confidence .section__sub-heading,
.section_confidence .list__item_confidence .section__title {
    font-size: 24px;
    font-weight: 700;
  }
  .section_confidence .list__item .content,
.section_confidence .list__item_confidence .content {
    font-size: 20px;
    line-height: normal;
  }
  .section_advantages {
    padding-top: 0;
    background-color: var(--color-white);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: auto;
  }
  .section_advantages .container {
    max-width: 100%;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
  }
  .section_advantages .heading {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
  }
  .section_advantages .is-flex {
    align-items: center;
    padding: 30px 0;
    gap: 252px;
    justify-content: center;
  }
  .section_advantages .list_advantages {
    display: flex;
    flex-direction: column;
    gap: 28px;
    position: relative;
    z-index: 50;
  }
  .section_advantages .list_advantages::before, .section_advantages .picture::before {
    content: "";
    background: linear-gradient(229deg, rgba(255, 94, 239, 0.7) 2.25%, rgba(92, 99, 252, 0.7) 47.27%, rgba(0, 215, 215, 0.7) 98.75%);
    width: 584px;
    height: 584px;
    border-radius: 584px;
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: bg-gradient 3s infinite ease-in-out;
    background-size: 600%;
    filter: blur(100px);
  }
  @keyframes bg-gradient {
    0% {
      background-position: 80% 0;
    }
    50% {
      background-position: 20% 100%;
    }
    100% {
      background-position: 80% 0;
    }
  }
  .section_advantages .list__item {
    width: 621px;
    height: 203px;
    flex-shrink: 0;
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 62px 0 rgba(11, 40, 115, 0.06);
    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;
  }
  .section_advantages .wrapper {
    padding: 28px 0 44px 90px;
  }
  .section_advantages .wrapper .title, .section_advantages .wrapper .icon-box__title, .section_advantages .wrapper .heading, .section_advantages .wrapper .section__sub-heading, .section_advantages .wrapper .section__title {
    color: #131313;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4;
  }
  .section_advantages .list__item:nth-child(1)::before,
.section_advantages .list__item:nth-child(2)::before,
.section_advantages .list__item:nth-child(3)::before {
    position: absolute;
    background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
    background-clip: text;
    color: transparent;
    width: 35px;
    height: 80px;
    display: block;
    font-size: 60px;
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    left: calc(90px - 35px - 15px);
    top: 0;
  }
  .section_advantages .list__item:nth-child(1)::before {
    content: "1";
  }
  .section_advantages .list__item:nth-child(2)::before {
    content: "2";
  }
  .section_advantages .list__item:nth-child(3)::before {
    content: "3";
  }
  .section_advantages .content {
    color: #131313;
    font-size: 20px;
    font-style: normal;
    line-height: 1.4;
    display: block;
    width: 450px;
  }
  .section_advantages .button_lg {
    width: 260px;
    align-self: center;
  }
  .section_advantages .picture {
    position: relative;
    max-width: 715px;
  }
  .section_advantages .ellipse1 {
    background: radial-gradient(125.83% 68.45% at 49.07% 50%, rgba(255, 94, 239, 0.6) 0%, rgba(92, 99, 252, 0.22) 46.66%, rgba(0, 215, 215, 0) 100%);
    border-radius: 715px;
    height: 715px;
    width: 715px;
    z-index: 10;
  }
  .section_advantages .ellipse2,
.section_advantages .ellipse3,
.section_advantages .slide {
    position: absolute;
    display: block;
  }
  .section_advantages .ellipse2 {
    z-index: 10;
    width: 649px;
    height: 649px;
    border-radius: 649px;
    background: linear-gradient(229deg, rgba(255, 94, 239, 0.7) 2.25%, rgba(92, 99, 252, 0.7) 47.27%, rgba(0, 215, 215, 0.7) 98.75%);
    top: 33px;
    left: 33px;
  }
  .section_advantages .ellipse3 {
    z-index: 20;
    width: 575px;
    height: 575px;
    border-radius: 575px;
    background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
    top: 70px;
    left: 70px;
  }
  .section_advantages .slide {
    z-index: 30;
    width: 492px;
    height: 492px;
  }
  .section_advantages .slide_1 {
    top: 111px;
    left: 111px;
  }
  .section_advantages .gifts,
.section_advantages .calls,
.section_advantages .bonuses1,
.section_advantages .bonuses2,
.section_advantages .messages,
.section_advantages .messages2,
.section_advantages .voice,
.section_advantages .video,
.section_advantages .private_contents,
.section_advantages .mystories {
    position: absolute;
    display: block;
    z-index: 60;
  }
  .section_advantages .gifts {
    content: url("../images/landing/landing-slider/gifts.svg");
    top: 40%;
    right: -33px;
  }
  .section_advantages .calls {
    content: url("../images/landing/landing-slider/calls.svg");
    top: 35%;
    right: 60px;
    width: 79px;
    height: 50px;
  }
  .section_advantages .bonuses1 {
    content: url("../images/landing/landing-slider/bonuses.svg");
    top: 60%;
    left: 33px;
    width: 52px;
    height: 52px;
  }
  .section_advantages .bonuses2 {
    content: url("../images/landing/landing-slider/bonuses2.svg");
    left: 50%;
    bottom: 33px;
    width: 57px;
    height: 57px;
  }
  .section_advantages .messages {
    content: url("../images/landing/landing-slider/messages.svg");
    top: 100px;
    left: 100px;
    width: 109px;
    height: 109px;
  }
  .section_advantages .messages2 {
    content: url("../images/landing/landing-slider/messages2.svg");
    top: 0;
    left: 50%;
    width: 67px;
    height: 67px;
  }
  .section_advantages .voice {
    content: url("../images/landing/landing-slider/voice.svg");
    top: 65%;
    left: 115px;
    width: 68px;
    height: 68px;
  }
  .section_advantages .video {
    content: url("../images/landing/landing-slider/video.svg");
    top: 45%;
    left: 60px;
    width: 66px;
    height: 66px;
  }
  .section_advantages .mystories {
    content: url("../images/landing/landing-slider/mystories.svg");
    top: 70%;
    right: 0;
    width: 169px;
    height: 50px;
  }
  .section_advantages .private_contents {
    content: url("../images/landing/landing-slider/private_contents.svg");
    left: 60%;
    top: 66px;
    width: 77px;
    height: 77px;
  }
  .section_participate {
    background: var(--gradient);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .section_participate .container {
    max-width: 100%;
    position: relative;
    z-index: 50;
  }
  .section_participate .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .section_participate .text {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding-top: 110px;
    padding-right: 236px;
  }
  .section_participate .text::after {
    content: url("../images/landing/confetti_1.svg");
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    animation: coin-fall 6s infinite;
    transition: transform 1s;
    transform-style: preserve-3d;
    background-repeat: no-repeat;
    opacity: 0;
  }
  .section_participate .text::before {
    content: url("../images/landing/confetti_2.svg");
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    animation: coin-fall 6s infinite;
    transition: transform 1s;
    transform-style: preserve-3d;
    background-repeat: no-repeat;
    opacity: 0;
  }
  .section_participate .media__image {
    background-repeat: no-repeat;
    margin-top: 80px;
    z-index: 20;
  }
  .section_participate .media__video {
    border-radius: 65px;
  }
  .section_participate .media__video_desktop {
    display: block;
  }
  .section_participate .media__video_mobile {
    display: none;
  }
  .section_participate .body {
    display: flex;
    flex-direction: column;
    width: 609px;
    gap: 60px;
  }
  .section_participate .button_lg {
    width: 323px;
    height: 70px;
    background-color: var(--color-white);
  }
  .section_participate .button_lg > span {
    background: var(--gradient-reverse);
    background-clip: text;
    color: transparent;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.35;
  }
  .section_participate .title, .section_participate .icon-box__title, .section_participate .heading, .section_participate .section__sub-heading, .section_participate .section__title,
.section_participate .content {
    color: var(--color-white);
  }
  .section_participate .title, .section_participate .icon-box__title, .section_participate .heading, .section_participate .section__sub-heading, .section_participate .section__title {
    font-size: 60px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .section_participate .content {
    text-align: left;
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .section_participate .message {
    display: flex;
    flex-direction: column;
    margin-top: 174px;
    margin-left: -60px;
    z-index: 50;
  }
  .section_participate .message__body {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 97px;
    border-radius: 10px 10px 10px 10px;
    background-color: rgba(225, 225, 225, 0.2);
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 21px 10px 21px 50px;
    align-items: center;
    justify-content: space-between;
  }
  .section_participate .message__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-white);
    line-height: 1.4;
  }
  .section_participate .message__title {
    color: var(--color-white);
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: none;
  }
  .section_participate .message__content {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
  .section_participate .message__picture {
    width: 71px;
    height: 71px;
  }
  .section_participate .message__image {
    background-size: cover;
    object-fit: cover;
  }
}
.article .heading {
  margin-bottom: 1.5rem;
  line-height: 1.4;
}
.article .heading:not(.heading_center) {
  text-align: left;
}

.pagination {
  display: flex;
  justify-content: center;
}
.pagination__item:not(:last-child) {
  margin-right: 5px;
}
.pagination__button {
  display: block;
  width: 36px;
  height: 36px;
  line-height: 35px;
  border-radius: 5px;
  background: var(--body-bg);
  border: 1px solid var(--light-gray);
  font-size: 0.875rem;
  text-align: center;
  text-decoration: none;
  color: var(--color-black);
  transition: background-color 0.35s linear;
}
.pagination__button.is-active:not(.no-active), .pagination__button:active:not(.no-active), .pagination__button:hover:not(.no-active) {
  background-color: var(--gray);
  border-color: var(--gray);
  color: var(--color-white);
}
.pagination__button_nav {
  color: #000;
}
.pagination__button_nav:disabled, .pagination__button_nav.is-disabled {
  opacity: 0.45;
  pointer-events: none;
}
@media screen and (min-width: 1280px) {
  .pagination__button {
    font-size: 1rem;
  }
}

@media (hover: hover) {
  .pagination__button:not(.no-active) {
    cursor: pointer;
  }
}
.statistic {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding-top: 4.375rem;
  padding-bottom: 3.4375rem;
  background-image: url(../images/about/bg-section2_mob.png);
  background-repeat: no-repeat;
}
.statistic::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/about/bg2_mob.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.statistic__item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.statistic__number, .statistic__title {
  display: block;
}
.statistic__number {
  display: block;
  font-size: 3.625rem;
  font-weight: 700;
  letter-spacing: -0.03em;
  color: var(--color-white);
}
.statistic__title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.1;
  color: rgba(255, 255, 255, 0.7);
}
@media screen and (min-width: 768px) {
  .statistic {
    background-image: url(../images/about/bg-section2.png);
  }
  .statistic::after {
    background-image: url(../images/about/bg2.svg);
  }
}
@media screen and (min-width: 1280px) {
  .statistic {
    padding-bottom: 5.625rem;
  }
}

.is-animated .animation__scale {
  transform: scale(1);
  z-index: 50;
}
.is-animated .animation__scale_1 {
  transition: all 1s ease;
}
.is-animated .animation__scale_2 {
  transition: all 2s ease;
}
.is-animated .animation__scale_3 {
  transition: all 3s ease;
}
.is-animated .animation__rotate_right {
  animation-name: rotate-right;
  animation-duration: 10s;
  animation-timing-function: linear;
  border-radius: 50%;
  transition: all 3s ease-in;
}
.is-animated .animation__rotate_left {
  animation-name: rotate-left;
  animation-duration: 10s;
  animation-timing-function: linear;
  border-radius: 50%;
  transition: all 6s ease-in;
}
@keyframes rotate-right {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate-left {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.is-animated .animation__scale_gifts {
  animation: fadeInFromNone 1s ease 1s forwards;
  opacity: 0;
}
.is-animated .animation__scale_video {
  animation: fadeInFromNone 1s ease 1.5s forwards;
  opacity: 0;
}
.is-animated .animation__scale_voice {
  animation: fadeInFromNone 1s ease 2s forwards;
  opacity: 0;
}
.is-animated .animation__scale_messages1 {
  animation: fadeInFromNone 1s ease 2.5s forwards;
  opacity: 0;
}
.is-animated .animation__scale_messages2 {
  animation: fadeInFromNone 1s ease 3s forwards;
  opacity: 0;
}
.is-animated .animation__scale_bonuses1 {
  animation: fadeInFromNone 1s ease 3.5s forwards;
  opacity: 0;
}
.is-animated .animation__scale_bonuses2 {
  animation: fadeInFromNone 2s ease 4s forwards;
  opacity: 0;
}
.is-animated .animation__scale_video {
  animation: fadeInFromNone 2s ease 4.5s forwards;
  opacity: 0;
}
.is-animated .animation__scale_mystories {
  animation: fadeInFromNone 2s ease 5s forwards;
  opacity: 0;
}
.is-animated .animation__scale_calls {
  animation: fadeInFromNone 2s ease 5.5s forwards;
  opacity: 0;
}
.is-animated .animation__scale_private_contents {
  animation: fadeInFromNone 2s ease 6s forwards;
  opacity: 0;
}
@keyframes fadeInFromNone {
  0% {
    scale: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}
.is-animated .animation__image {
  opacity: 1;
  transform: translateX(0);
}
.is-animated .animation__image:nth-child(2) {
  transition-delay: 0.25s;
}
.is-animated .animation__image:nth-child(3) {
  transition-delay: 0.45s;
}
.is-animated .animation__image:nth-child(4) {
  transition-delay: 0.8s;
}
.is-animated .animation__coins_left .animation__coin:nth-child(1) {
  animation: coin-fall 2s linear;
  animation-delay: 0s;
}
.is-animated .animation__coins_left .animation__coin:nth-child(2) {
  animation: coin-fall 2.2s linear;
  animation-delay: 0.45s;
}
.is-animated .animation__coins_left .animation__coin:nth-child(3) {
  animation: coin-fall 2.4s linear;
  animation-delay: 0.56s;
}
.is-animated .animation__coins_left .animation__coin:nth-child(4) {
  animation: coin-fall 2.6s linear;
  animation-delay: 0.86s;
}
.is-animated .animation__coins_left .animation__coin:nth-child(5) {
  animation: coin-fall 2s linear;
  animation-delay: 1.2s;
}
.is-animated .animation__coins_left .animation__coin:nth-child(6) {
  animation: coin-fall 2.5s linear;
  animation-delay: 1.65s;
}
.is-animated .animation__coins_left .animation__coin:nth-child(7) {
  animation: coin-fall 3s linear;
  animation-delay: 2.25s;
}
.is-animated .animation__coins_left .animation__coin:nth-child(8) {
  animation: coin-fall 2.56s linear;
  animation-delay: 3s;
}
.is-animated .animation__coins_left .animation__coin:nth-child(9) {
  animation: coin-fall 3s linear;
  animation-delay: 4.25s;
}
.is-animated .animation__coins_right .animation__coin:nth-child(1) {
  animation: coin-fall 3.5s ease-in-out infinite;
  animation-delay: 5.2s;
}
.is-animated .animation__coins_right .animation__coin:nth-child(2) {
  animation: coin-fall 3s ease-in-out infinite;
  animation-delay: 6s;
}
.is-animated .animation__coins_right .animation__coin:nth-child(3) {
  animation: coin-fall 2.8s ease-in-out infinite;
  animation-delay: 6.85s;
}
.is-animated .animation__coins_right .animation__coin:nth-child(4) {
  animation: coin-fall 2.6s ease-in-out infinite;
  animation-delay: 7.5s;
}
.is-animated .animation__coins_right .animation__coin:nth-child(5) {
  animation: coin-fall 2.4s ease-in-out infinite;
  animation-delay: 7.8s;
}
.is-animated .animation__coins_right .animation__coin:nth-child(6) {
  animation: coin-fall 2.7s ease-in-out infinite;
  animation-delay: 8.2s;
}
.is-animated .animation__coins_right .animation__coin:nth-child(7) {
  animation: coin-fall 3.2s ease-in-out infinite;
  animation-delay: 8.56s;
}
.is-animated .animation__coins_right .animation__coin:nth-child(8) {
  animation: coin-fall 2.9s ease-in-out infinite;
  animation-delay: 8.86s;
}
.is-animated .animation__coins_right .animation__coin:nth-child(9) {
  animation: coin-fall 2.3s ease-in-out infinite;
  animation-delay: 9s;
}
.is-animated .animation__wrapper {
  animation: slide-out 1s linear forwards;
}
.is-animated .animation_2 .animation__wrapper {
  animation-delay: 1s;
}
.is-animated .animation__message {
  opacity: 0;
}
.is-animated .animation__message_1 {
  animation: fadeOutFromNone 1s ease 2.5s forwards;
  opacity: 0;
}
.is-animated .animation__message_2 {
  animation: fadeOutFromNone 1s ease 2s forwards;
  opacity: 0;
}
.is-animated .animation__message_3 {
  animation: fadeOutFromNone 1s ease 1.5s forwards;
  opacity: 0;
}
.is-animated .animation__message_4 {
  animation: fadeOutFromNone 1s ease 1s forwards;
  opacity: 0;
}
@keyframes fadeOutFromNone {
  0% {
    scale: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}

@media screen and (max-width: 1279px) {
  .animation__image:not(:nth-child(1)) {
    display: none;
  }
  .animation__image:nth-child(1) {
    margin-top: 0;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .animation__coins_left {
    left: 0;
  }
  .animation__coins_right {
    right: 0;
  }
}
@keyframes coin-fall {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(400px);
  }
}
@keyframes coin-fall_mobile {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100px);
  }
}
@keyframes bgFadeIn {
  from {
    background-size: 0%;
  }
  to {
    background-size: 100%;
  }
}
.no-webp .animation__coin:nth-child(1) {
  background-image: url(../images/landing/coin1.svg);
}
.no-webp .animation__coin:nth-child(2) {
  background-image: url(../images/landing/coin2.svg);
}
.no-webp .animation__coin:nth-child(3) {
  background-image: url(../images/landing/coin3.svg);
}
.no-webp .animation__coin:nth-child(4) {
  background-image: url(../images/landing/coin4.svg);
}
.no-webp .animation__coin:nth-child(5) {
  background-image: url(../images/landing/coin5.svg);
}
.no-webp .animation__coin:nth-child(6) {
  background-image: url(../images/landing/coin6.svg);
}
.no-webp .animation__coin:nth-child(7) {
  background-image: url(../images/landing/coin7.svg);
}
.no-webp .animation__coin:nth-child(8) {
  background-image: url(../images/landing/coin8.svg);
}
.no-webp .animation__coin:nth-child(9) {
  background-image: url(../images/landing/coin9.svg);
}
.no-webp .animation__image:nth-child(1) {
  background-image: url(../images/landing/coins.svg);
}
.no-webp .animation__image:nth-child(2) {
  background-image: url(../images/landing/phone1.png);
}
.no-webp .animation__image:nth-child(3) {
  background-image: url(../images/landing/phone2.png);
}
.no-webp .animation__image:nth-child(4) {
  background-image: url(../images/landing/phone3.png);
}

.webp .animation__coin:nth-child(1) {
  background-image: url(../images/landing/coin1.webp);
}
.webp .animation__coin:nth-child(2) {
  background-image: url(../images/landing/coin2.webp);
}
.webp .animation__coin:nth-child(3) {
  background-image: url(../images/landing/coin3.webp);
}
.webp .animation__coin:nth-child(4) {
  background-image: url(../images/landing/coin4.webp);
}
.webp .animation__coin:nth-child(5) {
  background-image: url(../images/landing/coin5.webp);
}
.webp .animation__coin:nth-child(6) {
  background-image: url(../images/landing/coin6.webp);
}
.webp .animation__coin:nth-child(7) {
  background-image: url(../images/landing/coin7.webp);
}
.webp .animation__coin:nth-child(8) {
  background-image: url(../images/landing/coin8.webp);
}
.webp .animation__coin:nth-child(9) {
  background-image: url(../images/landing/coin9.webp);
}
.webp .animation__image:nth-child(1) {
  background-image: url(../images/landing/coins.webp);
}
.webp .animation__image:nth-child(2) {
  background-image: url(../images/landing/phone1.webp);
}
.webp .animation__image:nth-child(3) {
  background-image: url(../images/landing/phone2.webp);
}
.webp .animation__image:nth-child(4) {
  background-image: url(../images/landing/phone3.webp);
}

@keyframes slide-out {
  from {
    width: 100%;
  }
  to {
    width: 1px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .animation__image:nth-child(1) {
    margin-top: 200px;
  }
}
.copyright {
  text-align: center;
}

.footer {
  background-color: initial;
  font-size: 0.875rem;
  color: var(--footer-color);
}
.footer .container {
  margin: 0 auto;
}
.footer__menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}
.footer__menu .menu__link {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
  color: var(--footer-color);
}
.footer__section {
  display: flex;
  flex-direction: row;
  gap: 80px;
  justify-content: space-between;
}
.footer__section_menu {
  padding-top: 76px;
  padding-bottom: 56px;
}
.footer__section_protection {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  padding: 0 10px 24px 10px;
  align-items: center;
}
.footer__section_protection .icon_aws {
  width: 86px;
  height: 116px;
}
.footer__section_protection .icon_safe-labeling {
  width: 149px;
  height: 53px;
}
.footer__section_protection .icon_dmca {
  width: 122px;
  height: 50px;
}
.footer__section_protection .icon_sectigo {
  width: 149px;
  height: 51px;
}
.footer__section_copyright {
  padding-top: 30px;
  border-top: 1px solid var(--footer-color);
  margin: 0 auto;
}
.footer__section_copyright .menu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}
.footer__section_copyright .menu .menu__link {
  color: var(--footer-color);
}
.footer__section_copyright .flex_copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 35px;
  gap: 15px;
  flex-wrap: wrap;
}
.footer__section_copyright .flex_copyright .sectigo_secured_logo {
  width: 100px;
  height: 39px;
}
.footer__section .app {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer__section .contacts {
  min-width: 193px;
}
.footer__section .icon_marker,
.footer__section .icon_letter-full {
  display: block;
  width: 20px;
  height: 20px;
}
.footer__section .email, .footer__section .city {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.footer__section .city span,
.footer__section .text,
.footer__section .email a {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}
.footer__section .email a {
  color: var(--footer-color);
  text-decoration: none;
  font-style: normal;
}
.footer__copyright {
  font-size: 0.75rem;
  text-align: center;
}
.footer__heading {
  margin: 0;
  font-size: inherit;
  color: var(--footer-color);
}
.footer__title {
  margin: 0;
  font-size: 1rem;
  line-height: 1.2;
  color: var(--footer-color);
}
.footer .social {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.footer .social_light {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.footer .social__item {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.footer .payment {
  display: flex;
  flex-direction: column;
  min-width: 193px;
  gap: 12px;
}
.footer .list_payment {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  gap: 12px 16px;
}
.footer .list__item {
  width: 30px;
  height: 30px;
  display: block;
}

@media screen and (max-width: 1023px) {
  .footer {
    text-align: center;
  }
  .footer .footer__title {
    display: none;
  }
  .footer .list_partners,
.footer .footer__menu {
    display: flex;
  }
  .footer .footer__menu,
.footer .social,
.footer .list_partners,
.footer .list_payment {
    justify-content: center;
  }
  .footer .list_payment {
    margin-bottom: 20px;
  }
  .footer .footer__copyright {
    padding-top: 0;
  }
  .footer .footer__icon {
    margin-bottom: 0;
    width: 142px;
  }
}
@media (max-width: 1279px) {
  .footer {
    font-size: 1rem;
  }
  .footer__menu {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }
  .footer__menu .menu__link {
    color: var(--footer-color);
  }
  .footer__section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
  }
  .footer__section_menu {
    padding-top: 36px;
    padding-bottom: 24px;
  }
  .footer__section_copyright {
    padding-top: 0;
    border-top: 1px solid #131313;
    margin: 0 auto;
  }
  .footer__section_copyright .menu {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 24px 0;
  }
  .footer__section .app {
    flex-direction: row;
    align-items: center;
    gap: 16px;
    justify-content: center;
    order: 0;
    padding: 0 10px;
  }
  .footer__section .contacts {
    max-width: 336px;
    text-align: left;
    order: 1;
  }
  .footer__section .social {
    order: 2;
  }
  .footer__section .links {
    order: 3;
  }
  .footer__section .payment {
    order: 4;
  }
  .footer__section .icon_marker,
.footer__section .icon_letter-full {
    display: block;
    width: 20px;
    height: 20px;
  }
  .footer__section .email, .footer__section .city {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  .footer__section .city span,
.footer__section .text,
.footer__section .email a {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  }
  .footer__section .email a {
    color: var(--footer-color);
    text-decoration: none;
    font-style: normal;
  }
  .footer__copyright {
    font-size: 0.75rem;
    text-align: center;
  }
  .footer__heading {
    display: inline;
    margin: 0;
    font-size: inherit;
    color: var(--footer-color);
  }
  .footer__title {
    display: none;
  }
  .footer .social {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .footer .social_light {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .footer .social__item {
    display: inline-block;
    width: 30px;
    height: 30px;
  }
  .footer .payment {
    display: flex;
    flex-direction: column;
    min-width: 193px;
    gap: 12px;
  }
  .footer .list_payment {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 16px;
  }
  .footer .list__item {
    width: 30px;
    height: 30px;
    display: block;
  }
}
@media screen and (max-width: 1279px) and (max-width: 1023px) {
  .footer {
    text-align: center;
  }
  .footer .footer__title {
    display: none;
  }
  .footer .list_partners,
.footer .footer__menu {
    display: flex;
  }
  .footer .footer__menu,
.footer .social,
.footer .list_partners,
.footer .list_payment {
    justify-content: center;
  }
  .footer .list_payment {
    margin-bottom: 20px;
  }
  .footer .footer__copyright {
    padding-top: 0;
  }
  .footer .footer__icon {
    margin-bottom: 0;
    width: 142px;
  }
}
.modal {
  display: none;
}
.modal__heading {
  color: var(--button-color2);
  text-align: center;
}
.modal__title {
  font-weight: 500;
  font-size: 2rem;
  margin: 0;
}
.modal__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(19, 19, 19, 0.75);
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
}
.modal__container {
  transform: scale(0);
  will-change: transform;
  transition: 0.56s transform ease;
  background: var(--body-bg);
  color: var(--color-text);
  overflow-y: auto;
  z-index: 1020;
}
@media screen and (min-width: 1024px) {
  .modal__container {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }
}
.modal__close, .page__close {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10;
}
.modal.is-active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1010;
  display: flex;
  flex-direction: column;
}
.modal.is-active .modal__container {
  transform: scale(1);
}
.modal.is-active .modal__bg {
  opacity: 1;
  pointer-events: auto;
}
@media screen and (min-width: 768px) {
  .modal.is-active.modal_sidebar .modal__container {
    transform: translateX(0);
  }
}
@media screen and (max-width: 767px) {
  .modal_lg:not(.modal_sidebar) .modal__container, .modal_sm:not(.modal_sidebar) .modal__container, .modal_md:not(.modal_sidebar) .modal__container {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    border-radius: 0;
  }
  .modal_lg:not(.modal_sidebar) .modal__container_contactus, .modal_sm:not(.modal_sidebar) .modal__container_contactus, .modal_md:not(.modal_sidebar) .modal__container_contactus {
    width: auto;
    height: auto;
    margin: auto 20px;
    border-radius: 10px;
    background-image: url("../images/modal/hablario.svg");
    background-repeat: no-repeat;
    background-position: left -140px top -86px;
    top: 0;
    left: 0;
    box-shadow: 0 4px 32px 0 rgba(19, 19, 19, 0.12);
  }
  .modal_lg.modal_account .modal__container, .modal_lg.modal_tariffs .modal__container, .modal_sm.modal_account .modal__container, .modal_sm.modal_tariffs .modal__container, .modal_md.modal_account .modal__container, .modal_md.modal_tariffs .modal__container {
    padding: 40px 25px 60px;
  }
}
@media screen and (min-width: 768px) {
  .modal_lg:not(.modal_sidebar) .modal__container, .modal_sm:not(.modal_sidebar) .modal__container, .modal_md:not(.modal_sidebar) .modal__container {
    margin: auto;
    border-radius: 10px;
  }
  .modal_lg:not(.modal_sidebar) .modal__container_contactus, .modal_sm:not(.modal_sidebar) .modal__container_contactus, .modal_md:not(.modal_sidebar) .modal__container_contactus {
    padding: 65px 125px;
    background-image: url("../images/modal/hablario.svg");
    background-repeat: no-repeat;
    background-position: left -140px top -86px;
    top: 0;
    left: 0;
    box-shadow: 0 4px 32px 0 rgba(19, 19, 19, 0.12);
  }
}
.modal_sm .form__footer, .modal_md .form__footer {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .modal_md .modal__container {
    padding: 45px 20px;
    width: 700px;
  }
}
@media screen and (min-width: 1920px) {
  .modal_md .modal__container {
    padding: 95px 60px;
  }
}
.modal_notify {
  display: block;
}
.modal_notify.is-active {
  top: 60px;
  height: auto;
}
.modal_notify.is-active .modal__container {
  transform: translateY(0);
}
.modal_notify .modal__container {
  padding: 15px 20px;
  box-shadow: none;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.3);
  transform: translateY(-100%);
  transition: transform 0.85s cubic-bezier(0.19, 1, 0.22, 1);
}
.modal_notify .modal__body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--color-white);
}
.modal_notify .modal__body .button_collapse {
  width: auto;
  height: auto;
  padding: 7px 25px;
  margin: 0 0 0 20px;
  color: var(--color-white);
  border-color: var(--color-white);
}
.modal_notify .modal__body .button_collapse:hover {
  opacity: 0.85;
}
.modal_notify .modal__message:first-letter {
  text-transform: uppercase;
}
@media screen and (min-width: 1280px) {
  .modal_notify.is-active {
    top: 72px;
  }
  .modal_notify .modal__body {
    justify-content: center;
  }
}
@media screen and (max-width: 1279px) {
  .modal_notify .is-desktop {
    display: none;
  }
}
.modal_sm .modal__container {
  width: auto;
  padding: 45px 20px 20px;
}
@media screen and (min-width: 576px) {
  .modal_sm .modal__container {
    padding: 45px 50px;
  }
  .modal_sm .form__footer {
    margin-top: 40px;
  }
}
@media screen and (min-width: 768px) {
  .modal_lg .modal__container {
    width: 80vw;
    height: auto;
  }
}
.modal_account .modal__title {
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 400;
}
.modal_account .modal__heading {
  font-size: 1.25rem;
  text-align: center;
}
.modal_account .ui-textarea {
  resize: none;
  min-height: 240px;
}
@media screen and (min-width: 768px) {
  .modal_account .form__footer {
    text-align: right;
  }
  .modal_account .form__footer .button {
    width: auto;
  }
}
.modal_interests .modal__container {
  padding-top: 25px;
  padding-bottom: 30px;
}
.modal_interests .modal__body {
  text-align: center;
}
.modal_interests .modal__heading {
  margin-bottom: 30px;
}
.modal_interests .list_hobbies {
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
}
.modal_interests .modal__footer {
  margin-top: 40px;
  text-align: center;
}
.modal_interests .list_hobbies {
  width: 90%;
  margin: auto;
}
@media screen and (min-width: 768px) {
  .modal_interests .list_hobbies {
    width: 50%;
  }
  .modal_interests .modal__footer {
    margin-top: 2.5rem;
    text-align: center;
  }
}
@media screen and (min-width: 1280px) {
  .modal_interests .modal__container {
    width: 748px;
  }
  .modal_interests .list_hobbies {
    width: 60%;
  }
}
.modal_about .form, .modal_search .form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
}
.modal_about .form__footer, .modal_search .form__footer {
  grid-column: 1/3;
}
@media screen and (min-width: 768px) {
  .modal_about .modal__container, .modal_search .modal__container {
    width: auto;
  }
}
@media screen and (min-width: 1280px) {
  .modal_about .form, .modal_search .form {
    grid-template-columns: repeat(3, 270px);
  }
  .modal_about .form__footer, .modal_search .form__footer {
    grid-column: 1/4;
  }
  .modal_about .form__footer .button, .modal_search .form__footer .button {
    width: 270px;
  }
}
.modal_install .modal__container {
  padding: 40px 20px 60px;
}
.modal_install .is-desktop {
  display: none;
}
@media screen and (min-width: 1024px) {
  .modal_install .is-mobile {
    display: none;
  }
  .modal_install .is-desktop {
    display: block;
  }
}
.modal_sidebar .modal__container {
  display: flex;
  padding: 10% 5% 20%;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
}
.modal_sidebar .modal__container .form {
  margin: auto;
}
.modal_sidebar .modal__container .form .button + .button {
  margin-top: 10px;
}
.modal_sidebar .modal__container .form .button + .button .button__text {
  color: var(--color-text);
}
@media screen and (min-width: 1024px) {
  .modal_sidebar {
    display: block;
  }
  .modal_sidebar .modal__container {
    position: fixed;
    right: 0;
    padding: 2%;
    width: 55vw;
    border-radius: 10px 0 0 10px;
    transform: translateX(100%);
    background-image: url(../images/modal/hablario.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
  }
  .modal_sidebar .modal__container .form {
    width: 370px;
  }
}
@media screen and (min-width: 1280px) {
  .modal_sidebar .modal__container {
    width: 39vw;
  }
}
.modal_support-chat .modal__container {
  padding: 0;
  background: none;
  border-radius: 10px;
}
.modal_tariffs .modal__container {
  padding-bottom: 30px;
}
.modal_tariffs .modal__header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal_tariffs .modal__title {
  margin-bottom: 0;
  font-size: 2rem;
}
.modal_tariffs .modal__label {
  font-size: 1.25rem;
  font-weight: 700;
}
.modal_tariffs .form__footer {
  padding-top: 15vh;
  font-size: 0.875rem;
  text-align: center;
}
.modal_tariffs .form__footer .icon {
  margin-right: 10px;
}
.modal_tariffs .form__text {
  display: block;
  margin-bottom: 14px;
}
.modal_tariffs .form__link {
  margin-right: 10px;
}
.modal_tariffs .form__button {
  margin-top: 30px;
  text-align: center;
}
.modal_tariffs .form__button .button_presentation > span {
  padding: 0 55px 0 35px;
}
.modal_tariffs .form__button .button_presentation .button__icon {
  background: none;
  right: -4px;
  top: -7px;
}
.modal_payment .modal__container {
  padding: 42px 25px 25px;
}
.modal_payment .modal__title {
  margin-bottom: 10px;
  font-size: 1.875rem;
  text-align: center;
}
.modal_payment .modal__body, .modal_payment .modal__header {
  position: relative;
}
.modal_payment .modal__body::after, .modal_payment .modal__header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--gradient);
}
.modal_payment .modal__body {
  padding: 20px 0;
}
.modal_payment .modal__body::after {
  bottom: 0;
}
.modal_payment .modal__footer {
  padding: 20px 0;
  text-align: center;
  font-size: 0.875rem;
}
.modal_payment .modal__footer .list_payment {
  margin-top: 10px;
}
.modal_payment .form__section {
  margin-top: 5px;
  display: flex;
}
.modal_payment .form__section .button_payment {
  margin-right: 10px;
}
.modal_payment-table .modal__header {
  padding-bottom: 20px;
}
.modal_payment-table .modal__header .heading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.875rem;
  white-space: nowrap;
}
.modal_payment-table .modal__header .heading .icon {
  margin-left: 5px;
}
.modal_gifts .modal__container {
  background-image: url("../images/icons/gifts/bg.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
}
.modal_gifts .modal__header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 50px 46px 0;
  gap: 11px;
  align-self: flex-start;
  white-space: nowrap;
}
.modal_gifts .modal__title {
  font-weight: 400;
}
.modal_new-story .modal__container {
  padding: 30px 40px 28px;
}
.modal_new-story .form__label {
  font-weight: 500;
}
.modal_new-story .form__col {
  margin-bottom: 10px;
}
@media screen and (min-width: 1280px) {
  .modal_new-story .form__col {
    width: 310px;
    margin-right: 34px;
    margin-bottom: 0;
  }
}
.modal_new-story .form__footer {
  padding-top: 5vh;
  text-align: center;
}
.modal_new-story .form .ui-textarea {
  min-height: 70px;
}
.modal_new-story .form .is-inline-flex {
  align-items: flex-end;
}
@media screen and (max-width: 1279px) {
  .modal_new-story .form .is-inline-flex {
    display: block;
  }
}
.modal_complain .modal__container {
  padding: 30px 38px;
}
.modal_complain .modal__title {
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center;
}
.modal_complain .form .ui-textarea {
  min-height: 200px;
}
.modal_complain .form__footer .button {
  width: 100%;
}
.modal_block-user .modal__container {
  padding: 30px 20px;
}
.modal_block-user .modal__header {
  position: relative;
}
.modal_block-user .modal__header::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--gradient);
}
.modal_block-user .modal__title {
  font-size: 1.75rem;
  margin-bottom: 10px;
  text-align: center;
  color: var(--color-red);
}
.modal_block-user .modal__title .icon {
  width: 32px;
  height: 32px;
}
.modal_block-user .modal__body {
  font-size: 1.25rem;
  text-align: center;
}
.modal_block-user .modal__footer {
  margin-top: 10vh;
  display: flex;
  justify-content: center;
}
.modal_block-user .modal__footer .button + .button {
  margin-left: 20px;
}
.modal_search .modal__header {
  display: flex;
  align-items: center;
}
.modal_search .button_link {
  margin-left: auto;
  color: var(--color-text);
}
.modal_search .button_link .button__icon {
  margin-left: 8px;
}
.modal_search .button__link-mobile {
  display: none;
}
.modal_search .form__col {
  display: flex;
  margin-bottom: 5px;
}
.modal_search .form__col .ui-input + .ui-input {
  margin-left: 20px;
}
.modal_search .form__separator {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--light-gray);
}
.modal_search .form__separator .form__label {
  display: block;
  margin-bottom: 2px;
  color: #C4C4C4;
}
.modal_search .form .ui-input[type=number] {
  appearance: none;
  pointer-events: none;
}
.modal_search .form .ui-input[type=number]:disabled {
  color: var(--color-text);
}
.modal_search .form .ui-input[type=number]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
.modal_services .modal__container {
  padding: 40px;
}
.modal_services .modal__title {
  margin: 0 0 20px;
  font-size: 2.5rem;
  text-align: center;
}
.modal_services .modal__subtitle {
  margin: 0 0 20px;
  font-size: 1.625rem;
}
.modal_services .modal__footer {
  text-align: center;
}
.modal_services .link, .modal_services .chat__close, .modal_services .button_close, .modal_services .modal__close, .modal_services .page__close, .modal_services .modal_tariffs .form__link, .modal_tariffs .modal_services .form__link {
  color: var(--color-text);
  text-decoration: underline;
}
.modal_services .centered {
  display: block;
  margin: 1.875rem auto;
  text-align: center;
}
.modal_services .is-mobile {
  display: block;
}
.modal_services .is-desktop {
  display: none;
}
.modal_services p {
  margin-bottom: 10px;
}
.modal_cropper {
  /*  margin: 10px auto !important;*/
}
.modal_cropper .modal__close, .modal_cropper .page__close {
  top: 0;
  right: 0;
}
.modal_cropper .modal__container {
  /*width: 300px;*/
  margin: auto;
  padding: 20px;
}
.modal_promo {
  padding: 20px;
  overflow-y: auto;
}
.modal_promo .modal__container {
  max-width: 80vw;
  width: 440px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 5px;
  box-shadow: none;
  background-color: transparent;
}
.modal_promo .modal__close, .modal_promo .page__close {
  top: 10px;
  right: 10px;
  width: 28px;
  height: 28px;
  line-height: 20px;
  padding: 5px;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #3a3a3a;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .modal_tariffs .modal__container, .modal_payment .modal__container {
    display: flex;
    flex-direction: column;
  }
  .modal_tariffs .modal__container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .modal_tariffs .form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .modal_tariffs .form__footer {
    margin-top: auto;
  }
  .modal_tariffs .modal__header {
    margin-bottom: 50px;
  }
  .modal_payment .modal__body {
    flex: 1;
  }
  .modal_payment .modal__header .heading {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 1279px) {
  .modal_search .button_link {
    display: none;
  }
  .modal_search .button_link-mobile {
    display: block;
    margin-bottom: 10px;
  }
  .modal_search .form_primary .form__row:nth-child(3) {
    grid-area: 1/2;
  }
  .modal_search .form_primary .form__row:nth-child(2) {
    grid-column: 1/3;
  }
  .modal_search .form_secondary .form__row:nth-child(5), .modal_search .form_secondary .form__row:nth-child(6) {
    grid-column: 1/3;
  }
  .modal_about .form__row:nth-last-child(2) {
    grid-column: 1/3;
  }
  .modal_payment .modal__footer .text {
    display: block;
  }
  .modal_gifts .modal__header {
    padding-left: calc(50% - 345px);
    gap: 55px;
  }
  .modal_gifts.modal_lg .modal__container {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    border-radius: 0;
  }
}
@media screen and (min-width: 1024px) {
  .modal_promo .modal__container {
    width: 340px;
  }
}
@media screen and (min-width: 1280px) {
  .modal_support-chat .modal__container {
    min-height: 90vh;
    margin: auto;
  }
  .modal_support-chat .chat, .modal_support-chat .chat__container {
    height: 100%;
  }
  .modal_payment .modal__container {
    width: 860px;
    padding: 62px 60px 50px;
  }
  .modal_payment .modal__body {
    padding: 46px 80px 54px;
  }
  .modal_payment .modal__footer {
    display: flex;
    padding-top: 25px;
    padding-bottom: 0;
  }
  .modal_payment .modal__footer .list_payment {
    margin-left: auto;
    margin-top: 0;
  }
  .modal_payment-table .modal__body {
    padding: 46px 0 54px;
  }
  .modal_payment-table .modal__footer {
    display: block;
  }
  .modal_new-story .form__footer .button {
    min-width: 270px;
  }
  .modal_search .modal__container {
    width: auto;
    padding: 80px 60px;
    background-image: url("../images/modal/hablario.svg");
    background-repeat: no-repeat;
  }
  .modal_complain .modal__container {
    width: 430px;
  }
  .modal_services .is-mobile {
    display: none;
  }
  .modal_services .is-desktop {
    display: block;
  }
  .modal_cropper .modal__container {
    /*width: 600px;*/
  }
  .modal_promo .modal__container {
    max-width: none;
    width: 1137px;
    height: 973px;
    padding-bottom: 0;
  }
  .modal_promo .modal__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
  .modal_gifts .modal__container {
    width: 1170px;
    max-height: 90vh;
    overflow-x: hidden;
  }
}
@media screen and (min-width: 1680px) {
  .modal_search .modal__container {
    padding: 120px 150px 200px;
  }
  .modal_services .modal__container {
    padding: 80px 100px;
  }
  .modal_services .modal__title {
    font-size: 3.75rem;
    margin: 0 0 50px;
  }
  .modal_services .modal__subtitle {
    font-size: 2rem;
    margin-bottom: 32px;
  }
  .modal_promo .modal__container {
    width: 1140px;
    height: 980px;
  }
}
@media screen and (min-width: 1920px) {
  .modal_support-chat .modal__container {
    max-width: 830px;
    min-height: 74vh;
  }
  .modal_new-story .modal__container {
    max-width: 1090px;
  }
}
@media screen and (max-width: 576px) {
  .modal_gifts .modal__header {
    padding-left: calc(50% - 170px);
  }
}
@media (min-width: 577px) and (max-width: 968px) {
  .modal_gifts .modal__header {
    padding-left: calc(50% - 227px);
  }
}
@media (min-width: 1280px) and (max-width: 1920px) {
  .modal_gifts .modal__header {
    padding-left: calc(50% - 345px);
    gap: 55px;
  }
  .modal_tariffs .form__button .button_presentation > span {
    padding: 0 35px 0 25px;
  }
  .modal_tariffs .form__button .button_presentation .icon_buy-credits {
    width: 45px;
    height: 45px;
  }
}
.video_responsive {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  visibility: hidden;
}
.video_landing {
  visibility: visible;
}

.preview__container {
  display: flex;
  justify-content: flex-end;
}
.preview__message {
  display: block;
}
.preview__text {
  display: block;
  color: var(--color-danger);
}
.preview__media:not(.preview__media_audio) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.preview__item {
  position: relative;
}
.preview__item:not(.preview__item_audio) {
  max-width: 160px;
  max-height: 160px;
}
.preview .message__audio {
  width: 200px;
}
.preview__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px;
  background: #DADADA;
  border-radius: 5px;
  z-index: 10;
}

.gallery__stop {
  position: absolute;
  visibility: hidden;
  height: 500px;
}
.gallery__stop#gallery_top {
  top: 0;
}
.gallery__stop#gallery_end {
  bottom: 0;
}

@media (min-width: 576px) and (max-width: 1278px) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .gallery .card_gallery {
    height: 100%;
  }
}
.panel {
  position: relative;
}
.panel::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 10;
  background: linear-gradient(0deg, #ffffff 34.29%, rgba(255, 255, 255, 0) 100%);
}
.panel__tabs {
  margin: 0.625rem 0;
}
.panel__content {
  margin: 0 8px 0 10px;
  padding-bottom: 80px;
  overflow-x: hidden;
  overflow-y: auto;
}
.panel__handler {
  width: 100%;
  height: 0.3125rem;
  padding: 0;
  position: relative;
  cursor: row-resize;
  z-index: 110;
}
.panel__handler::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  border: 1px solid #e7e7e7;
  border-bottom: none;
  margin: 0 auto;
}
.panel_contacts {
  height: 300px;
}
.panel_contacts .panel__content {
  height: calc(100% - 55px);
}
.panel_chat {
  height: calc(100% - 300px);
}
.panel_chat .panel__content {
  height: calc(100% - 80px);
}
.panel_chat .panel__header {
  background: var(--body-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 8px 10px 0;
  text-align: center;
}
.panel_chat .panel__title {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--heading-color);
}
.panel_chat .panel__title .icon_plus {
  width: 16px;
  height: 16px;
}
.panel_mobile .panel__tabs {
  padding-left: 50px;
  padding-right: 60px;
}
.panel_mobile .button_back {
  position: absolute;
  top: 1px;
  left: 0.625rem;
}
.panel_mobile .panel__content {
  height: calc((var(--vh, 1vh) * 100) - 62px);
  overflow-y: auto;
}

@media (max-height: 982px) {
  .panel_contacts {
    height: 200px;
  }
  .panel_chat {
    height: calc(100% - 200px);
  }
}
.tabs {
  display: flex;
}
.tabs__tab {
  flex: 1;
}
.tabs__link {
  position: relative;
  display: block;
  padding: 8px;
  text-align: center;
  font-size: 0.75rem;
  white-space: nowrap;
  color: var(--light-gray);
  background: linear-gradient(357.97deg, rgba(255, 255, 255, 0.9) 2.22%, rgba(255, 255, 255, 0) 98.82%);
  transition: color var(--transition-duration) var(--transition-easing);
}
.tabs__link:hover, .tabs__link:active, .tabs__link.is-active {
  background: var(--body-bg);
  font-weight: 500;
  color: var(--button-color1);
}
.tabs__link:hover .icon, .tabs__link:active .icon, .tabs__link.is-active .icon {
  opacity: 1;
}
.tabs__link .icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  opacity: 0.5;
}

@media (hover: hover) {
  .tabs__link {
    cursor: pointer;
  }
}
@media screen and (min-width: 576px) {
  .tabs__link {
    padding: 12px;
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 767px) {
  .members__item:not(:last-child) {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 768px) {
  .members {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px 30px;
  }
}
@media screen and (min-width: 1024px) {
  .members {
    grid-template-columns: repeat(3, 1fr);
  }
}

.contacts .contact {
  padding: 0.625rem 0.9375rem;
  background: var(--body-bg);
  border-radius: 5px;
  box-shadow: 0 10px 25px rgba(136, 136, 136, 0.1);
}
.contacts .contact__info {
  position: relative;
  padding-right: 60px;
}
.contacts .contact__avatar {
  margin-right: 0;
}
.contacts__text {
  margin-top: 5px;
  margin-left: 14px;
  margin-bottom: 5px;
  font-size: 0.75rem;
  color: rgba(126, 126, 126, 0.7);
}
.contacts__icon {
  display: inline-block;
  vertical-align: middle;
}
.contacts__item {
  position: relative;
  padding-right: 0.625rem;
}
.contacts__item::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  height: 100%;
  background: var(--gradient);
  border-radius: 5px 0 0 5px;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transform-origin: bottom;
  z-index: 10;
}
.contacts__item:hover {
  padding-left: 0;
}
.contacts__item:hover::after {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.35s ease, transform 0.4s linear, visibility 0.25s ease;
}
.contacts__item:not(:last-child) {
  margin-bottom: 0.625rem;
}

@media (hover: hover) {
  .contacts__text, .contacts__item {
    cursor: pointer;
  }
}
@media screen and (min-width: 1920px) {
  .contacts__text {
    font-size: 0.875rem;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .contacts .contact {
    padding: 10px 11px;
  }
  .contacts .contact__message {
    font-size: 0.8571428571rem;
  }
}
.contact {
  position: relative;
  display: flex;
  align-items: center;
}
.contact__info {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  align-items: flex-start;
}
.contact__avatar {
  position: relative;
  margin-right: 5px;
}
.contact__avatar .status {
  position: absolute;
  bottom: -2px;
  left: 2px;
  z-index: 10;
}
.contact__title {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.contact__name {
  max-width: 98px;
  font-weight: 700;
}
.contact__age {
  position: relative;
}
.contact__age::before {
  content: ",";
  margin-right: 5px;
}
.contact__name, .contact__message {
  white-space: nowrap;
}
.contact__message {
  max-width: 100%;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--heading-color);
}
.contact .status__icon {
  border: 2px solid var(--color-white);
}
.contact .tooltip, .contact .date {
  position: absolute;
}
.contact .tooltip {
  top: 0;
  right: 4px;
}
.contact .date {
  top: 5px;
  right: 68px;
  font-size: 0.75rem;
  text-align: right;
}
.contact .date_old {
  color: #C5C5C5;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .contact__avatar .avatar {
    width: 40px;
    height: 40px;
  }
  .contact__avatar .tooltip {
    top: 5px;
  }
}
.description__item {
  display: flex;
  align-items: center;
}
.description__item:not(:last-child) {
  margin-bottom: 5px;
}
.description__key, .description__value {
  display: inline-block;
}
.description__key {
  padding: 5px 10px;
  margin-right: 8px;
  min-width: 80px;
  font-size: 0.875rem;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  white-space: nowrap;
}
.description__value {
  font-weight: 500;
  font-size: 0.875rem;
  white-space: nowrap;
  line-height: 140%;
}
.description__value .icon {
  margin-right: 5px;
}
.description_tariffs .description__item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: rgba(197, 197, 197, 0.15);
  border-radius: 5px;
  font-size: 1.25rem;
}
.description_tariffs .description__item:not(:last-child) {
  margin-bottom: 0.9375rem;
}
.description_tariffs .description__item .icon {
  margin-right: 10px;
}
.description_tariffs .description__credit {
  margin: 0 0 0 auto;
  font-weight: 700;
}
.description_personal .description__item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.description_personal .description__item:nth-child(-n+4) .description__key {
  background: rgba(255, 99, 249, 0.15);
}
.description_personal .description__item:nth-child(n+5):nth-child(-n+8) .description__key {
  background: rgba(118, 109, 255, 0.15);
}
.description_personal .description__item:nth-child(n+9):nth-child(-n+12) .description__key {
  background: rgba(0, 220, 220, 0.15);
}
.description_personal .description__key {
  min-width: 84px;
  margin-right: 20px;
  font-size: 0.875rem;
  font-weight: 400;
  background: rgba(197, 197, 197, 0.15);
}
.description_personal .description__value {
  font-weight: 700;
  font-size: 1rem;
  color: var(--heading-color);
}
.description_personal.description_first .description__item .description__key {
  background: rgba(255, 99, 249, 0.15);
}
.description_personal.description_second .description__item .description__key {
  background: rgba(118, 109, 255, 0.15);
}
.description_personal.description_third .description__item .description__key {
  background: rgba(0, 220, 220, 0.15);
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .description__key {
    max-width: 60px;
    font-size: 0.7142857143rem;
  }
  .description__value {
    font-size: 0.8571428571rem;
  }
  .description_tariffs .description__item {
    font-size: 0.8571428571rem;
  }
  .description_personal .description__key {
    min-width: 64px;
    margin-right: 10px;
    font-size: 0.8571428571rem;
  }
  .description_personal .description__value {
    font-size: 0.8571428571rem;
  }
}
.status {
  user-select: none;
}
.status, .status__icon {
  display: inline-block;
  vertical-align: baseline;
  line-height: 1;
}
.status_online .status__icon {
  background-color: var(--state-online);
}
.status_online .status__icon {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 6px;
}
.status_offline .status__icon {
  background-color: var(--state-danger);
}
.status_badge {
  display: flex;
  align-items: center;
  padding: 0.125rem 0.375rem;
  background: rgba(24, 23, 23, 0.39);
  border-radius: 1.25rem;
}
.status_badge .status__icon {
  margin-right: 5px;
}
.status_luxury .status__icon, .status_luxury .status__title, .status_starting .status__icon, .status_starting .status__title, .status_superior .status__icon, .status_superior .status__title, .status_premium .status__icon, .status_premium .status__title {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.status_luxury .status__title, .status_starting .status__title, .status_superior .status__title, .status_premium .status__title {
  width: 5rem;
  height: 1.25rem;
}
.status_superior .status__icon, .status_premium .status__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.status_luxury .status__icon {
  width: 1.75rem;
  height: 1.75rem;
  background-image: url(../images/payment/luxury-icon.svg);
}
.status_luxury .status__title {
  width: 5rem;
  background-image: url(../images/payment/luxury-title.svg);
}
.status_starting .status__icon {
  width: 1.25rem;
  height: 1.25rem;
  background-image: url(../images/payment/starting-icon.svg);
}
.status_starting .status__title {
  background-image: url(../images/payment/starting-title.svg);
}
.status_superior .status__icon {
  background-image: url(../images/payment/superior-icon.svg);
}
.status_superior .status__title {
  background-image: url(../images/payment/superior-title.svg);
}
.status_premium .status__icon {
  background-image: url(../images/payment/premium-icon.svg);
}
.status_premium .status__title {
  background-image: url(../images/payment/premium-title.svg);
}

.no-scroll {
  overflow: hidden;
  scrollbar-gutter: stable;
  /* when modal active */
  touch-action: none;
  -webkit-overflow-scrolling: none;
  /* Other browsers */
  overscroll-behavior: none;
}
.no-scroll #app {
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.no-scroll-behavior {
  scroll-behavior: auto !important;
}

.is-scroll-behavior {
  scroll-behavior: smooth;
}

.is-hidden {
  display: none;
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.is-flex {
  display: flex;
}

.is-inline-flex {
  display: inline-flex;
}

.is-blur {
  filter: blur(15px);
}

.is-block {
  display: block;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-gradient, .record__button, .button_white > span, .button_link, .button_full span, .button_bordered > span > span, .button_border > span, .page__link, .page_new-story .page__heading, .page_mobile.page_payment .page__heading, .modal__link, .modal_account .modal__title, .modal_tariffs .modal__title, .modal_tariffs .form__link, .description_tariffs .description__credit,
.text-gradient-reverse,
.section__title-promo,
.section__sub-heading,
.section_service .section__description {
  background-clip: text;
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent;*/
  color: transparent;
}

.text-gradient, .record__button, .button_white > span, .button_link, .button_full span, .button_bordered > span > span, .button_border > span, .page__link, .page_new-story .page__heading, .page_mobile.page_payment .page__heading, .modal__link, .modal_account .modal__title, .modal_tariffs .modal__title, .modal_tariffs .form__link, .description_tariffs .description__credit {
  background-image: var(--gradient-text);
}

.text-gradient-reverse, .section__title-promo, .section__sub-heading, .section_service .section__description {
  background-image: var(--gradient-reverse);
}

.text-danger {
  color: var(--state-danger);
}

.text-warning {
  color: var(--state-warning);
}

.text-info {
  color: var(--state-info);
}

.text-success {
  color: var(--state-success);
}

.text-light {
  color: var(--light-gray);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-truncate, .file-input .ui-input, .person__name, .card:not(.card_single) .card__title, .post:not(.post_single) .post__heading, .post_stories .post__short-text, .contact__info, .contact__name, .contact__message, .description__value {
  overflow: hidden;
  text-overflow: ellipsis;
}

.pb-0 {
  padding-bottom: 0;
}

.mb-2 {
  margin-bottom: 20px;
}

.ml-auto {
  margin-left: auto;
}

.text-small {
  font-size: 0.875rem;
}

.text-medium {
  font-size: 0.9375rem;
}

.quote {
  margin: 0;
  padding-left: 30px;
  border-left: 2px solid var(--light-gray);
}

.is_through {
  text-decoration-line: line-through;
}

body .snotify.snotify-rightTop {
  width: 340px;
  margin-bottom: 10px;
  margin-top: 30px;
  margin-right: 14px;
  right: 0;
}
body .snotify.snotify-rightTop .snotifyToast__progressBar {
  display: none;
}
body .snotify.snotify-rightTop .snotifyToast__buttons {
  display: block;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
}
body .snotify.snotify-rightTop .snotifyToast__buttons button {
  position: absolute;
  top: 0;
  height: 100%;
  font-size: 0;
  border: 0;
  background: none;
}
body .snotify.snotify-rightTop .snotifyToast__buttons button:first-child {
  right: 0;
  width: 80%;
}
body .snotify.snotify-rightTop .snotifyToast__buttons button:last-child {
  left: 0;
  width: 20%;
}
body .snotify.snotify-rightTop .snotifyToast__buttons button:last-child::after {
  content: none;
}
body .snotify.snotify-rightTop .snotifyToast__buttons button:last-child::before {
  content: "";
  width: 12px;
  height: 2px;
  background: #4e4e4e;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 10;
}
body .snotify.snotify-rightTop .snotifyToast {
  position: relative;
  background-color: transparent;
  margin: 0;
  border-radius: 0;
  overflow: visible;
}
body .snotify.snotify-rightTop .snotifyToast__inner {
  padding: 5px 12px;
  background: rgba(225, 225, 225, 0.7);
  border-radius: 10px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  height: 98px;
}
body .snotify.snotify-rightTop .snotifyToast__inner .d-flex {
  width: 100%;
  height: 100%;
  align-items: center;
}
body .snotify.snotify-rightTop .snotifyToast__inner .d-flex.is-success .snotifyToast__title {
  color: #fff;
}
body .snotify.snotify-rightTop .snotifyToast__inner .d-flex.is-info .snotifyToast__title {
  color: #fefefe;
}
body .snotify.snotify-rightTop .snotifyToast__inner .d-flex.is-error .snotifyToast__icon, body .snotify.snotify-rightTop .snotifyToast__inner .d-flex.is-warning .snotifyToast__icon {
  display: inline-block;
  width: 70px;
  height: 70px;
  background-color: var(--nav-bg);
  border-radius: 5px;
  background-image: url(../images/logo/logo-mob.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
body .snotify.snotify-rightTop .snotifyToast__inner .d-flex.is-error .snotifyToast__title {
  color: #ED326A;
  font-size: 1.7rem;
}
body .snotify.snotify-rightTop .snotifyToast__inner .d-flex.is-warning .snotifyToast__title {
  color: #F8832F;
}
body .snotify.snotify-rightTop .snotifyToast__inner .snotifyToast__icon {
  position: relative;
  width: 70px;
  height: 70px;
  flex: 0 0 70px;
}
body .snotify.snotify-rightTop .snotifyToast__inner .snotifyToast__icon .status {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 10px;
  z-index: 10;
}
body .snotify.snotify-rightTop .snotifyToast__inner .snotifyToast__icon .status__icon {
  border: 2px solid #fff;
}
body .snotify.snotify-rightTop .snotifyToast__inner .snotifyToast__icon .avatar {
  width: 100%;
  height: 100%;
}
body .snotify.snotify-rightTop .snotifyToast__inner .snotifyToast__body {
  height: 100%;
  padding: 5px 10px 5px 25px;
  flex: 1;
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
body .snotify.snotify-rightTop .snotifyToast__inner .snotifyToast__content .name {
  font-size: 1.25rem;
  font-weight: 700;
  color: #424242;
}
body .snotify.snotify-rightTop .snotifyToast__inner .snotifyToast__content .text {
  display: block;
  width: 90%;
  font-size: 1rem;
  font-weight: 600;
  color: #2c2c2c;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
body .snotify.snotify-rightTop .snotifyToast__inner .snotifyToast__title {
  display: block;
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: underline;
}
body .snotify.snotify-leftTop {
  width: 100%;
  margin: 0 auto;
  padding: 0 11px;
  left: 0;
}
body .snotify.snotify-leftTop .snotifyToast__progressBar {
  display: none;
}
body .snotify.snotify-leftTop .snotifyToast__buttons {
  display: block;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 100;
}
body .snotify.snotify-leftTop .snotifyToast__buttons button {
  position: absolute;
  top: 0;
  height: 100%;
  font-size: 0;
  border: 0;
  background: none;
}
body .snotify.snotify-leftTop .snotifyToast__buttons button:first-child {
  right: 0;
  width: 20%;
}
body .snotify.snotify-leftTop .snotifyToast__buttons button:first-child::after {
  content: none;
}
body .snotify.snotify-leftTop .snotifyToast__buttons button:first-child::before {
  content: "";
  width: 12px;
  height: 2px;
  background: #4e4e4e;
  position: absolute;
  top: 13px;
  right: 13px;
  z-index: 10;
}
body .snotify.snotify-leftTop .snotifyToast__buttons button:last-child {
  left: 0;
  width: 80%;
}
body .snotify.snotify-leftTop .snotifyToast {
  position: relative;
  background-color: transparent;
  margin: 0;
  border-radius: 0;
  overflow: visible;
}
body .snotify.snotify-leftTop .snotifyToast__inner {
  padding: 5px 12px;
  background: rgba(225, 225, 225, 0.7);
  border-radius: 10px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  height: 66px;
}
body .snotify.snotify-leftTop .snotifyToast__inner .d-flex {
  width: 100%;
  height: 100%;
  align-items: center;
}
body .snotify.snotify-leftTop .snotifyToast__inner .d-flex.is-success .snotifyToast__title {
  color: #fff;
}
body .snotify.snotify-leftTop .snotifyToast__inner .d-flex.is-info .snotifyToast__title {
  color: #fefefe;
}
body .snotify.snotify-leftTop .snotifyToast__inner .d-flex.is-error .snotifyToast__icon, body .snotify.snotify-leftTop .snotifyToast__inner .d-flex.is-warning .snotifyToast__icon {
  display: inline-block;
  width: 70px;
  height: 70px;
  background-color: var(--nav-bg);
  border-radius: 5px;
  background-image: url(../images/logo/logo-mob.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
body .snotify.snotify-leftTop .snotifyToast__inner .d-flex.is-error .snotifyToast__title {
  color: #ED326A;
  font-size: 1.7rem;
}
body .snotify.snotify-leftTop .snotifyToast__inner .d-flex.is-warning .snotifyToast__title {
  color: #F8832F;
}
body .snotify.snotify-leftTop .snotifyToast__inner .snotifyToast__icon {
  position: relative;
  width: 60px;
  height: 60px;
  flex: 0 0 60px;
  z-index: 10;
}
body .snotify.snotify-leftTop .snotifyToast__inner .snotifyToast__icon .status {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 10px;
  z-index: 10;
}
body .snotify.snotify-leftTop .snotifyToast__inner .snotifyToast__icon .status__icon {
  border: 2px solid #fff;
}
body .snotify.snotify-leftTop .snotifyToast__inner .snotifyToast__icon .avatar {
  width: 100%;
  height: 100%;
}
body .snotify.snotify-leftTop .snotifyToast__inner .snotifyToast__body {
  height: 100%;
  padding: 6px 10px;
  flex: 1;
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
}
body .snotify.snotify-leftTop .snotifyToast__inner .snotifyToast__content .name {
  font-size: 1.25rem;
  font-weight: 700;
  color: #424242;
}
body .snotify.snotify-leftTop .snotifyToast__inner .snotifyToast__content .text {
  display: block;
  width: 90%;
  font-size: 1rem;
  font-weight: 600;
  color: #2c2c2c;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
body .snotify.snotify-leftTop .snotifyToast__inner .snotifyToast__title {
  display: block;
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: underline;
}
body.mode-dark .snotify .snotifyToast__inner {
  padding: 5px 12px;
  background: rgba(86, 86, 86, 0.7);
}
body.mode-dark .snotify .snotifyToast__inner .snotifyToast__body .snotifyToast__content .name {
  color: #fff;
}
body.mode-dark .snotify .snotifyToast__inner .snotifyToast__body .snotifyToast__content .text {
  color: #fff;
}
body.mode-dark .snotify .snotifyToast__buttons button:first-child::before {
  background: #ffffff;
}
body.mode-dark .snotify .snotifyToast__buttons button:last-child::after {
  background-image: url(../images/icons/close-black.svg);
}
body.mode-dark .snotify .snotifyToast__buttons button:last-child::before {
  background: #ffffff;
}

@media (max-width: 413px) {
  body .snotify {
    width: 260px;
  }
}
@media (max-width: 1279px) {
  body .snotify-leftTop {
    margin-top: 0;
  }
  body .snotify {
    top: calc(60px + 8px - 6px);
    right: 0;
  }
  body .snotify .snotifyToast__inner {
    box-shadow: 0 4px 3px rgba(113, 113, 113, 0.56);
    backdrop-filter: none;
    border-radius: 10px;
    height: auto;
    min-height: 46px;
    padding: 3px 11px;
  }
  body .snotify .snotifyToast__inner .d-flex.is-warning .snotifyToast__icon, body .snotify .snotifyToast__inner .snotifyToast__icon {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
  }
  body .snotify .snotifyToast__inner .snotifyToast__body {
    width: calc(100% - 50px);
  }
  body .snotify .snotifyToast__inner .snotifyToast__content {
    width: 100%;
    margin: 0;
    padding: 0;
    background: transparent;
    border-radius: 0;
    order: 2;
  }
  body .snotify .snotifyToast__inner .snotifyToast__content .text {
    margin: 0;
    font-weight: 400;
    font-size: 1.18rem;
    color: #fff;
  }
  body .snotify .snotifyToast__inner .snotifyToast__content .name {
    font-size: 1.25rem;
  }
  body .snotify .snotifyToast__inner .snotifyToast__title {
    display: none;
  }
}
@media (min-width: 680px) and (max-width: 1024px) {
  body .snotify.snotify-rightTop {
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 680px) {
  body .snotify.snotify-rightTop {
    width: 260px;
  }
}
.profile__card {
  margin-bottom: 30px;
  background: var(--nav-bg);
  box-shadow: 0 10px 25px rgba(126, 126, 126, 0.08);
  border-radius: 10px;
}
.profile__text p:last-child {
  margin: 0;
  word-break: break-word;
}
.profile__title {
  margin-top: 10px;
  margin-bottom: 18px;
  font-size: 1.25rem;
  color: var(--profile-title);
  text-align: center;
}
.profile__about .profile__card {
  padding: 10px;
}
.profile__portfolio {
  margin-bottom: 30px;
}
.profile__promo .profile__card, .profile__stories .profile__card {
  border-radius: 0 0 10px 10px;
}
.profile__promo {
  position: relative;
}
.profile__promo .status_online {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: var(--color-white);
  z-index: 10;
}
.profile__promo-header, .profile__promo-footer {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10;
}
.profile__promo-header {
  top: 0;
  padding: 15px 20px 10px;
  border-radius: 10px 10px 0 0;
  color: var(--color-white);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.81) 0%, rgba(9, 9, 9, 0.42) 57.84%, rgba(19, 19, 19, 0) 100%);
}
.profile__promo-header .person_card {
  align-items: flex-start;
}
.profile__promo-header .person_card .person__name, .profile__promo-header .person_card .person__age {
  font-size: 1.5625rem;
}
.profile__promo-header .person_card .person__location {
  margin-top: 0;
}
.profile__heading {
  position: relative;
  text-align: center;
}
.profile__heading .button_edit {
  position: absolute;
  top: -10px;
  right: 0;
}
.profile__heading .button_edit .badge {
  position: absolute;
  top: -15px;
  left: -15px;
  z-index: 10;
}
.profile__stories .profile__card {
  padding: 15px;
}
.profile__stories-list .list__item:not(:last-child) {
  margin-bottom: 20px;
}
.profile__avatar, .profile__upload {
  background: var(--card-bg);
}
.profile__avatar, .profile__avatar .avatar, .profile__avatar .upload__media {
  border-radius: 50%;
}
.profile__avatar .upload {
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.profile__avatar .upload__label {
  width: 40px;
  height: 40px;
}
.profile__avatar .status {
  position: absolute;
  top: 15px;
  right: -25px;
  z-index: 10;
}
.profile__upload {
  border-radius: 0.625rem;
}
.profile__credits {
  position: relative;
  padding: 15px 20px 15px 120px;
  background: rgba(19, 19, 19, 0.8);
  backdrop-filter: blur(15px);
  border-radius: 10px;
}
.profile__credits .profile__icon {
  position: absolute;
  top: 20px;
  left: 20px;
}
.profile__credits-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.875rem;
}
.profile__credits-balance .label {
  display: inline-block;
  padding: 3px 15px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  color: var(--color-white);
}
.profile__credits-value {
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-white);
}

.profile_account .description_personal .description__value {
  opacity: 0.25;
}
.profile_account .profile__card .profile__text-preview {
  display: flex;
  align-items: center;
  text-align: left;
}
.profile_account .profile__card .profile__text-preview .svg-icon {
  display: inline-block;
  margin-right: 10px;
}
.profile_account .profile__stories {
  position: relative;
}
.profile_account .profile__stories .profile__add-stories {
  position: absolute;
  top: -5px;
  right: 0;
}
.profile_account .profile__stories .slider_portfolio {
  margin: 20px 0;
}

.profile_mobile {
  /* .profile__caption {
       padding: toRem(20px) toRem(25px) toRem(60px);
   }*/
}
.profile_mobile .profile__promo {
  position: relative;
  margin-bottom: 0;
  z-index: 10;
}
.profile_mobile .profile__promo, .profile_mobile .profile__promo .profile__caption {
  border-radius: 0 0 20px 20px;
}
.profile_mobile .profile__promo .profile__caption .person_card .status {
  top: 30px;
  left: 25px;
  bottom: auto;
}
.profile_mobile .profile__title {
  font-size: 1.125rem;
}
.profile_mobile .profile__text, .profile_mobile .post__overlay-content,
.profile_mobile .post__content, .profile_mobile .post__content .button_link {
  font-size: 0.875rem;
}
.profile_mobile .button_bookmark {
  font-size: 1.125rem;
}
.profile_mobile .post__overlay-content .button, .profile_mobile .post_stories .post__form-title {
  font-size: 1rem;
}
.profile_mobile .profile__card {
  border-radius: 20px;
}
.profile_mobile .post_stories .post__meta-data {
  font-size: 0.75rem;
}
.profile_mobile .post_stories .post__heading {
  font-size: 1.625rem;
}
.profile_mobile .post__input .post__textarea {
  min-height: 55px;
  padding: 16px 90px 2px 30px;
}
.profile_mobile .post__input .form__buttons {
  top: 10px;
}
.profile_mobile .profile__about .profile__card {
  padding: 16px 25px 25px;
  text-align: center;
  /* .profile__title {
       text-align: left;
   }*/
}
.profile_mobile .profile__about .profile__card:first-of-type {
  margin-top: -40px;
  padding-top: 60px;
}
.profile_mobile .profile__about .profile__card:not(:last-of-type) {
  margin-bottom: 10px;
}
.profile_mobile .list_hobbies {
  display: grid;
  grid-template-columns: repeat(auto-fill, 68px);
  grid-gap: 7px;
}
.profile_mobile .profile__portfolio {
  margin-bottom: 10px;
}
.profile_mobile:not(.profile_account) .profile__stories .button_bookmark {
  display: block;
  margin: auto;
}
.profile_mobile.profile_account .profile__title {
  text-align: left;
}
.profile_mobile.profile_account .button_theme {
  padding-left: 10px;
  padding-right: 10px;
}
.profile_mobile.profile_account .button_theme .button__icon {
  margin-right: 0;
}
.profile_mobile.profile_account .person {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}
.profile_mobile.profile_account .person__info {
  display: block;
}
.profile_mobile.profile_account .person__section {
  max-width: 100%;
  flex: 0 0 100%;
  margin-top: 10px;
}
.profile_mobile.profile_account .profile__credits {
  text-align: right;
  margin-bottom: 20px;
}
.profile_mobile.profile_account .profile__credits-balance {
  justify-content: flex-end;
}
.profile_mobile.profile_account .profile__credits-balance .text {
  margin-right: 10px;
}
.profile_mobile.profile_account .profile__text {
  text-align: left;
}
.profile_mobile.profile_account .profile__text-preview {
  text-align: left;
  display: flex;
  align-items: center;
}
.profile_mobile.profile_account .profile__text-preview .svg-icon {
  display: inline-block;
  margin: 0 10px 0 0;
}
.profile_mobile.profile_account .profile__about > .profile__card:first-of-type {
  position: relative;
  padding-top: 60px;
  z-index: 10;
}
.profile_mobile.profile_account .profile__avatar {
  position: absolute;
  top: -40px;
  left: 20px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

@media screen and (min-width: 1280px) {
  .profile {
    display: flex;
    margin-top: 8px;
  }
  .profile__container {
    flex: 0 0 565px;
    max-width: 565px;
  }
  .profile__avatar {
    position: absolute;
    bottom: -50px;
    left: 30px;
    z-index: 10;
  }
  .profile__avatar .avatar {
    border: 4px solid var(--body-bg);
  }
  .profile__avatar_centered {
    left: 50%;
    transform: translateX(-50%);
  }
  .profile__promo .button_rounded, .profile__card_promo .button_rounded {
    margin: 0 0 0 auto;
    flex: 0 0 140px;
  }
  .profile__card_promo {
    padding: 20px 10px;
    display: flex;
    align-items: center;
  }
  .profile__card_promo p {
    margin-bottom: 0;
    word-break: break-all;
  }
  .profile__card_promo .profile__title {
    position: relative;
    max-width: 160px;
    flex: 0 0 160px;
    padding-right: 10px;
    margin: 0 10px 0 0;
    text-align: left;
  }
  .profile__card_promo .profile__title::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 2px;
    border-radius: 5px;
    background: rgba(197, 197, 197, 0.3);
  }
  .profile__promo {
    margin-bottom: 50px;
  }
  .profile__about {
    position: relative;
    display: flex;
    gap: 30px;
  }
  .profile__about .profile__card {
    flex: 1;
  }
  .profile__card-wrapper.is-open {
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    padding: 24px 40px 35px;
    background: var(--nav-bg);
    box-shadow: 0 10px 25px rgba(126, 126, 126, 0.08);
    border-radius: 5px;
    z-index: 100;
  }
  .profile__card-wrapper.is-open .button.is-collapsed {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .profile__card-wrapper.is-open .button_edit {
    top: 0;
    right: 60px;
  }
  .profile__card-wrapper.is-open .profile__title {
    text-align: left;
  }
  .profile__card-wrapper.is-open .description_personal {
    columns: 3;
  }
  .profile__stories-list .list__item:not(:last-child) {
    margin-bottom: 50px;
  }
  .profile__stories .profile__card {
    padding: 30px;
  }
  .profile__stories .button_bookmark {
    display: block;
    margin: auto;
  }
}
@media screen and (min-width: 1920px) {
  .profile__container {
    flex: 0 0 870px;
    max-width: 870px;
  }
  .profile__promo .profile__card {
    padding: 20px;
  }
  .profile__promo .profile__title {
    max-width: 160px;
    flex: 0 0 160px;
    padding-right: 20px;
    margin: 0 20px 0 0;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .profile__promo-header .person_card .person__name, .profile__promo-header .person_card .person__age {
    font-size: 1.4285714286rem;
  }
  .profile__avatar .avatar_xl, .profile__avatar .upload {
    width: 70px;
    height: 70px;
  }
  .profile__title {
    font-size: 0.8571428571rem;
  }
  .profile__promo .button_rounded, .profile__card_promo .button_rounded {
    max-width: 100px;
    flex: 0 0 100px;
  }
  .profile__card {
    padding: 10px;
    font-size: 0.7142857143rem;
  }
  .profile__card_promo .profile__title {
    max-width: 130px;
    flex: 0 0 130px;
  }
  .profile__portfolio {
    margin-bottom: 20px;
  }
  .profile__credits {
    padding: 10px 15px 10px 90px;
  }
  .profile__credits .profile__icon {
    top: 10px;
    left: 10px;
  }
  .profile__credits-balance {
    font-size: 0.8571428571rem;
  }
  .profile__credits-balance .text {
    margin-right: 20px;
  }
  .profile .list_hobbies .icon-box {
    width: 44px;
    height: 44px;
  }
  .profile .list_hobbies .icon-box .icon {
    width: 22px;
    height: 22px;
  }
  .profile .list_hobbies .icon-box__title {
    font-size: 0.5714285714rem;
  }
  .profile__credits-value {
    font-size: 1.4285714286rem;
  }
  .profile__card-wrapper.is-open {
    padding: 20px 20px 25px;
  }
  .profile_account .profile__promo .profile__card .button {
    flex: 0 0 100px;
  }
}
.icon-box {
  display: inline-block;
  width: 68px;
  height: 68px;
  padding: 6px 5px 12px;
  background: var(--icon-box-bg);
  border-radius: 5px;
  text-align: center;
}
.icon-box__title {
  display: block;
  margin-top: 5px;
  font-size: 0.75rem;
}
.icon-box .icon {
  width: 34px;
  height: 34px;
}

.chat__header {
  padding: 0.5rem 1rem 0.5rem 1.875rem;
  display: flex;
  align-items: center;
  height: 72px;
  background-color: var(--chat-default-bg);
}
.chat__header .contact__name {
  max-width: 130px;
}
.chat__header .contact .status__icon {
  border: none;
}
.chat__actions {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
}
.chat__close {
  position: absolute;
  top: 5px;
  right: 12px;
  z-index: 10;
}
.chat__date {
  font-size: 0.875rem;
}
.chat__container {
  display: flex;
  flex-direction: column;
  background-color: var(--chat-default-bg);
  box-shadow: 0 10px 25px rgba(136, 136, 136, 0.1);
  border-radius: 10px;
}
.chat__wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  background-size: cover;
}
.chat__wrapper.bg-default {
  background: var(--chat-default-bg);
}
.chat__wrapper.bg-1 {
  background: radial-gradient(144.49% 119.79% at 76.71% 26.69%, rgba(211, 160, 252, 0.44) 25.39%, rgba(255, 255, 255, 0) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #FFD1EA 7.6%, rgba(245, 193, 246, 0.4) 40.2%, rgba(255, 255, 255, 0) 100%), linear-gradient(143deg, #FFDBDB -5.98%, #F4ECFF 24.1%, #FFCFEC 36.33%, #FF87B2 50.8%, #FF498B 68.05%, #FF4E8D 79.46%, #FF5AD1 95.22%);
}
.chat__wrapper.bg-2 {
  background: radial-gradient(180.8% 73.63% at 36.46% 33.89%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 36.96%, rgba(255, 255, 255, 0) 86.47%), linear-gradient(180deg, #FFEEF9 4.95%, #DEE3FD 48.96%, #DEFFFF 98.75%);
}
.chat__wrapper.bg-3 {
  background: radial-gradient(206.66% 98.08% at 73.81% 34.35%, rgba(199, 251, 251, 0.62) 25.39%, rgba(255, 255, 255, 0) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAFDFF 7.6%, #FFDCFB 40.2%, rgba(255, 255, 255, 0) 100%), linear-gradient(162deg, #F5FFFC 10.68%, #B0F1FF 24.34%, #C3EDFF 35.24%, #CBE8FF 43.37%, #90CAFF 51.75%, #819CFF 64.49%, #E373FF 78.93%, #FFB0F7 99.52%);
}
.chat__wrapper.bg-4 {
  background: radial-gradient(206.66% 98.08% at 73.81% 34.35%, rgba(251, 249, 199, 0.62) 25.39%, rgba(255, 255, 255, 0) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #FAFFDA 7.6%, #FFEDDC 40.2%, rgba(255, 255, 255, 0) 100%), linear-gradient(162deg, #E1FAFF 7.25%, #B0FFFF 24.34%, #C3FFF1 35.24%, #D2FFCB 43.37%, #FFFB90 51.75%, #FFD481 64.49%, #FFAE73 78.93%, #FFD1B0 99.52%);
}
.chat__wrapper.bg-5 {
  background: radial-gradient(97.18% 80.12% at 70.63% 38.09%, rgba(217, 255, 253, 0.82) 25.39%, rgba(255, 255, 255, 0) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAFFF6 7.6%, rgba(77, 234, 234, 0.4) 40.2%, rgba(255, 255, 255, 0) 100%), linear-gradient(141deg, #EF7EF1 -6.36%, #FFECFA 23.88%, #CFDAFF 36.17%, #87E2FF 50.72%, #CCFFD1 68.06%, #D5FF7B 83.51%, #FFE589 95.37%);
}
.chat__wrapper.bg-6 {
  background: radial-gradient(97.18% 80.12% at 70.63% 38.09%, rgba(238, 221, 255, 0.82) 25.39%, rgba(255, 255, 255, 0) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAE4FF 7.6%, rgba(255, 255, 255, 0.4) 40.2%, rgba(255, 255, 255, 0) 100%), linear-gradient(141deg, #DBDEFF -6.36%, #ECEEFF 23.88%, #FFCFD3 36.17%, #FF8787 50.72%, #FF543D 68.06%, #FF4F1B 83.51%, #FF965A 95.37%);
}
.chat__body {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: transparent;
  overflow-x: hidden;
  overflow-y: auto;
}
.chat__body::-webkit-scrollbar {
  width: 6px;
}
.chat__body::-webkit-scrollbar-thumb {
  background-color: var(--light-gray);
  border-radius: 3px;
}
.chat__body::-webkit-scrollbar-track {
  background-color: rgba(197, 197, 197, 0.2);
  border-radius: 3px;
}
.chat__list {
  padding: 0 1.25rem;
  margin: auto 0 3.75rem 0;
  list-style: none;
}
.chat__footer {
  position: relative;
  padding: 0 0.5rem 1.875rem;
}
.chat__item {
  position: relative;
  display: flex;
  margin-bottom: 1.25rem;
}
.chat__item .message_base {
  max-width: 85%;
}
.chat__item.is-start {
  align-items: flex-end;
  justify-content: flex-start;
}
.chat__item.is-start .message {
  margin-left: 1.25rem;
  border-radius: 0.625rem 0.625rem 0.625rem 0;
}
.chat__item.is-start .message__triangle {
  transform: scaleX(-1);
  left: -12px;
}
.chat__item.is-start .message::before {
  content: "";
  width: 20px;
  height: 20px;
  clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
  right: 100%;
  bottom: 0;
  background: inherit;
  position: absolute;
  transform: scaleX(-1);
}
.chat__item.is-start .message_color1, .chat__item.is-start .message_color2, .chat__item.is-start .message_color3, .chat__item.is-start .message_color4, .chat__item.is-start .message_color6, .chat__item.is-start .message_color7 {
  background: var(--chat-message-bg);
  color: #292929;
}
.chat__item.is-start .message_color1 .message__triangle path, .chat__item.is-start .message_color2 .message__triangle path, .chat__item.is-start .message_color3 .message__triangle path, .chat__item.is-start .message_color4 .message__triangle path, .chat__item.is-start .message_color6 .message__triangle path, .chat__item.is-start .message_color7 .message__triangle path {
  fill: var(--chat-message-bg);
}
.chat__item.is-start .message_color5 {
  background: linear-gradient(94.5deg, #97E0FF 18.71%, #91AFFF 80.41%);
  color: #000;
}
.chat__item.is-start .message_color5 .message__triangle path {
  fill: #97E0FF;
}
.chat__item.is-start_letter-images, .chat__item.is-start_letter-images::after {
  border-radius: 10px 10px 10px 0;
}
.chat__item.is-end {
  margin: 0 0 1.25rem auto;
  justify-content: flex-end;
}
.chat__item.is-end .message {
  border-radius: 0.625rem 0.625rem 0 0.625rem;
  margin-right: 1.25rem;
  color: var(--color-black);
}
.chat__item.is-end .message__triangle {
  right: -0.75rem;
}
.chat__item.is-end .message_color1 {
  background: linear-gradient(180deg, #FF8AD7 9.46%, #E168FF 22.18%, #9584FF 32.74%, #8E7BFF 55.57%, #95BFFE 67.87%, #6FFFF6 79.05%, #54FFFF 100.26%, #7BFFD7 109.46%);
  background-size: 100% 100vh;
  background-position: 0 95%;
  position: relative;
}
.chat__item.is-end .message_color1::after {
  content: "";
  width: 20px;
  height: 20px;
  clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
  left: 100%;
  bottom: 0;
  background: inherit;
  position: absolute;
}
.chat__item.is-end .message_color2 {
  background: linear-gradient(44deg, #98E6FF -9.91%, #B6FFED 43.92%, #BEFFA7 100%), #EEE;
}
.chat__item.is-end .message_color2::after {
  content: "";
  width: 20px;
  height: 20px;
  clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
  left: 100%;
  bottom: 0;
  background: inherit;
  position: absolute;
}
.chat__item.is-end .message_color2::after, .chat__item.is-end .message_color3::after, .chat__item.is-end .message_color7::after, .chat__item.is-end .message_color8::after {
  background-attachment: fixed;
}
.chat__item.is-end .message_color3 {
  background: linear-gradient(45deg, #FDFF98 -8.88%, #FFA7A7 100%), #EEE;
}
.chat__item.is-end .message_color3::after {
  content: "";
  width: 20px;
  height: 20px;
  clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
  left: 100%;
  bottom: 0;
  background: inherit;
  position: absolute;
}
.chat__item.is-end .message_color4 {
  background: linear-gradient(180deg, #FF8AD7 0%, #E168FF 100%);
}
.chat__item.is-end .message_color4::after {
  content: "";
  width: 20px;
  height: 20px;
  clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
  left: 100%;
  bottom: 0;
  background: inherit;
  position: absolute;
}
.chat__item.is-end .message_color5 {
  background: linear-gradient(180deg, #FFB5EA 0%, #E4FFB7 100%);
}
.chat__item.is-end .message_color5::after {
  content: "";
  width: 20px;
  height: 20px;
  clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
  left: 100%;
  bottom: 0;
  background: inherit;
  position: absolute;
}
.chat__item.is-end .message_color6 {
  background: linear-gradient(180deg, #A6AEFD -16.67%, #6FFFF6 100%);
}
.chat__item.is-end .message_color6::after {
  content: "";
  width: 20px;
  height: 20px;
  clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
  left: 100%;
  bottom: 0;
  background: inherit;
  position: absolute;
}
.chat__item.is-end .message_color7 {
  background: linear-gradient(44deg, #98FFEC -9.91%, #BEC5FF 43.92%, #FFA7E6 100%), #EEE;
}
.chat__item.is-end .message_color7::after {
  content: "";
  width: 20px;
  height: 20px;
  clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
  left: 100%;
  bottom: 0;
  background: inherit;
  position: absolute;
}
.chat__item.is-end .message_color8 {
  background: #E4E4E4;
}
.chat__item.is-end .message_color8::after {
  content: "";
  width: 20px;
  height: 20px;
  clip-path: path("m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z");
  left: 100%;
  bottom: 0;
  background: inherit;
  position: absolute;
}
.chat__item.is-end .message_letter-images, .chat__item.is-end .message_letter-images::before {
  border-radius: 10px 10px 0 10px;
}
.chat__item_settings {
  flex-wrap: wrap;
}
.chat__item_settings .button {
  width: 160px;
}
.chat__options {
  position: absolute;
  top: 5px;
  right: -5px;
  color: var(--color-white);
  text-shadow: 1px 1px 1px #000;
  z-index: 12;
}
.chat__message-menu {
  position: absolute;
  bottom: 90%;
  right: 1.25rem;
  z-index: 20;
}
.chat__check-icon {
  position: relative;
  top: 2px;
  margin-left: 5px;
  color: var(--gray);
}
.chat__check-icon.is-read {
  color: #3DE8FF;
}
.chat__input {
  position: relative;
  display: flex;
  align-items: flex-end;
  z-index: 20;
  padding-right: 6px;
}
.chat__input .ui-input {
  flex: 1;
  min-height: 3.75rem;
  height: auto;
  line-height: 1.5;
  padding: 18px 160px 12px 50px;
  background: var(--body-bg);
  border: 0;
  box-shadow: 0 10px 25px rgba(136, 136, 136, 0.25);
  border-radius: 50px;
  word-break: break-word;
  max-height: calc(1.5em * 11);
  overflow-y: auto;
  overflow-x: hidden;
}
.chat__input .ui-input::-webkit-scrollbar {
  width: 6px;
}
.chat__input .ui-input::-webkit-scrollbar-thumb {
  background-color: var(--light-gray);
  border-radius: 3px;
}
.chat__input .ui-input::-webkit-scrollbar-track {
  background-color: rgba(197, 197, 197, 0.2);
  border-radius: 3px;
}
.chat__input .ui-input .record {
  margin-top: 3px;
}
.chat__input .button_letter {
  margin-right: 6px;
}
.chat__send {
  width: 110px;
  height: 36px;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--color-white);
  text-transform: none;
  border-radius: 50px;
}
.chat__attachment {
  position: absolute;
  bottom: 18px;
  left: 78px;
}
.chat__emoji {
  margin-right: 12px;
}
.chat__emoji .button__icon {
  margin: 0;
}
.chat__buttons {
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: flex;
}
.chat__typing {
  position: absolute;
  transform: translateY(-40px);
  margin-left: 20px;
}
.chat__message-blocked {
  display: block;
  padding-bottom: 10px;
  text-align: center;
  font-weight: 500;
}
.chat__message-blocked i {
  margin-right: 5px;
}
.chat__dropdown {
  position: absolute;
  bottom: 70%;
  left: 5rem;
}
.chat__preview, .chat__caption {
  position: absolute;
  bottom: 80%;
  left: 76px;
  right: 16px;
  padding: 14px 20px 24px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 10px 25px rgba(136, 136, 136, 0.25);
  border-radius: 5px 5px 0 0;
  font-size: 1.25rem;
  font-weight: 500;
  z-index: 12;
}
.chat__preview_record .progress {
  margin-right: 12px;
}
.chat__preview_record .preview__close {
  top: 8px;
  right: 8px;
}
.chat__preview_record .preview__text {
  margin: 0 auto;
}
.chat__caption .button_close {
  position: absolute;
  top: 15px;
  right: 10px;
}
.chat__caption .icon_edit {
  margin-right: 5px;
}
.chat__caption_emoji {
  padding: 20px 35px;
  max-height: 230px;
  overflow-y: auto;
}
.chat__letter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 120;
}
.chat__letter_images {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.chat__letter_images::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2.5px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.chat__letter_images .chat__header {
  position: relative;
  z-index: 20;
}
.chat__letter_images .chat__header .contact__title, .chat__letter_images .chat__header .status {
  padding: 2px 10px;
  border-radius: 40px;
  background-color: var(--bg-opacity);
  backdrop-filter: blur(20px);
}
.chat__letter_images .chat__header .contact__title {
  color: var(--color-white);
}
.chat.is-fixed .chat__container {
  position: fixed;
  top: 0;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 110;
}
.chat.is-absolute {
  position: relative;
}
.chat.is-absolute .chat__container {
  position: absolute;
  bottom: 0;
  z-index: 110;
}
.chat_main .chat__wrapper > .chat__header, .chat_settings .chat__wrapper > .chat__header {
  position: relative;
}
.chat_support .chat__header {
  background: linear-gradient(221.97deg, #6D2CF7 12.18%, #7058FF 72.46%), #131313;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.625rem;
}
.chat_support .chat__header .status {
  color: #fff;
}
.chat_support .contact__title {
  color: var(--color-white);
}
.chat_support .contact {
  position: relative;
}
.chat_support .contact__avatar {
  width: 52px;
  height: 52px;
  background-color: var(--nav-bg);
  border-radius: 5px;
  background-image: url(../images/logo/logo-mob.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80% 80%;
}
.chat_support .status_badge {
  background: rgba(255, 255, 255, 0.8);
}
.chat_support .chat__animated-icon {
  position: absolute;
  top: -5px;
  right: -10px;
}
.chat_support .chat__animated-icon .wheel {
  animation-name: circle1;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-box: fill-box;
  transform-origin: center;
}
.chat_support .chat__animated-icon #wheel1 {
  animation-name: circle2;
}
.chat_support .chat__close {
  padding: 15px 5px;
}
.chat_support .chat__close > span {
  display: block;
  width: 25px;
  height: 3px;
  background: var(--color-white);
}
.chat_support .chat__wrapper {
  background-image: url(../images/support-chat/light/bg-light.webp);
}
.chat_support .chat__input .ui-input {
  padding-left: 65px;
}
.chat_support .chat__attachment {
  left: 28px;
}
.chat_support .chat__item.is-start .message:not(.message_simple) {
  background: var(--chat-message-bg);
  color: var(--dark-gray2);
}
.chat_support .chat__item.is-start .message:not(.message_simple) .message__triangle path {
  fill: var(--chat-message-bg);
}
.chat_support .chat__item.is-end .message:not(.message_simple) {
  background: linear-gradient(94.4deg, #CCD0FB 35.01%, #C3B6F9 78.46%);
  color: #000;
}
.chat_support .chat__item.is-end .message:not(.message_simple)::after {
  background: #C3B6F9;
}
.chat_settings .chat__wrapper {
  overflow-y: auto;
}
.chat_settings .chat__body {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  overflow: initial;
}
.chat_settings .chat__list {
  padding: 0;
  margin: 30px 0 0;
}
.chat_settings .chat__footer {
  padding-bottom: 30px;
  text-align: center;
}
.chat_settings .chat__footer .button {
  width: 180px;
}
.chat_settings .chat__footer .button > span {
  text-transform: none;
}
.chat_settings .chat__overlay {
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  padding: 35px 15px 15px;
  background: rgba(251, 251, 251, 0.85);
  box-shadow: 0 10px 25px rgba(136, 136, 136, 0.25);
  border-radius: 10px;
  z-index: 100;
}
.chat_settings .chat__list-bg {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 0.9375rem;
}
.chat_settings .chat__item-caption {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  background: linear-gradient(221.38deg, #5886FF 16.48%, #D00BF0 93.67%);
  color: var(--color-white);
  border-radius: 50%;
  z-index: 10;
}
.chat_settings .chat__item-caption .svg-icon {
  margin: auto;
}
.chat_settings .chat__bg, .chat_settings .chat__color {
  position: relative;
  transition: box-shadow 0.3s ease-in-out;
}
.chat_settings .chat__bg.is-checked .chat__item-caption, .chat_settings .chat__color.is-checked .chat__item-caption {
  display: flex;
}
.chat_settings .chat__bg {
  position: relative;
  padding-top: 100%;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
}
.chat_settings .chat__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(19, 19, 19, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}
.chat_settings .chat__bg.is-checked::after {
  opacity: 1;
}
.chat_settings .chat__bg_default {
  background: var(--chat-default-bg);
}
.chat_settings .chat__bg_1 {
  background: radial-gradient(144.49% 119.79% at 76.71% 26.69%, rgba(211, 160, 252, 0.44) 25.39%, rgba(255, 255, 255, 0) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #FFD1EA 7.6%, rgba(245, 193, 246, 0.4) 40.2%, rgba(255, 255, 255, 0) 100%), linear-gradient(143deg, #FFDBDB -5.98%, #F4ECFF 24.1%, #FFCFEC 36.33%, #FF87B2 50.8%, #FF498B 68.05%, #FF4E8D 79.46%, #FF5AD1 95.22%);
}
.chat_settings .chat__bg_2 {
  background: radial-gradient(180.8% 73.63% at 36.46% 33.89%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 36.96%, rgba(255, 255, 255, 0) 86.47%), linear-gradient(180deg, #FFEEF9 4.95%, #DEE3FD 48.96%, #DEFFFF 98.75%);
}
.chat_settings .chat__bg_3 {
  background: radial-gradient(206.66% 98.08% at 73.81% 34.35%, rgba(199, 251, 251, 0.62) 25.39%, rgba(255, 255, 255, 0) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAFDFF 7.6%, #FFDCFB 40.2%, rgba(255, 255, 255, 0) 100%), linear-gradient(162deg, #F5FFFC 10.68%, #B0F1FF 24.34%, #C3EDFF 35.24%, #CBE8FF 43.37%, #90CAFF 51.75%, #819CFF 64.49%, #E373FF 78.93%, #FFB0F7 99.52%);
}
.chat_settings .chat__bg_4 {
  background: radial-gradient(206.66% 98.08% at 73.81% 34.35%, rgba(251, 249, 199, 0.62) 25.39%, rgba(255, 255, 255, 0) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #FAFFDA 7.6%, #FFEDDC 40.2%, rgba(255, 255, 255, 0) 100%), linear-gradient(162deg, #E1FAFF 7.25%, #B0FFFF 24.34%, #C3FFF1 35.24%, #D2FFCB 43.37%, #FFFB90 51.75%, #FFD481 64.49%, #FFAE73 78.93%, #FFD1B0 99.52%);
}
.chat_settings .chat__bg_5 {
  background: radial-gradient(97.18% 80.12% at 70.63% 38.09%, rgba(217, 255, 253, 0.82) 25.39%, rgba(255, 255, 255, 0) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAFFF6 7.6%, rgba(77, 234, 234, 0.4) 40.2%, rgba(255, 255, 255, 0) 100%), linear-gradient(141deg, #EF7EF1 -6.36%, #FFECFA 23.88%, #CFDAFF 36.17%, #87E2FF 50.72%, #CCFFD1 68.06%, #D5FF7B 83.51%, #FFE589 95.37%);
}
.chat_settings .chat__bg_6 {
  background: radial-gradient(97.18% 80.12% at 70.63% 38.09%, rgba(238, 221, 255, 0.82) 25.39%, rgba(255, 255, 255, 0) 100%), radial-gradient(130.15% 70.18% at 2.73% 1.8%, #DAE4FF 7.6%, rgba(255, 255, 255, 0.4) 40.2%, rgba(255, 255, 255, 0) 100%), linear-gradient(141deg, #DBDEFF -6.36%, #ECEEFF 23.88%, #FFCFD3 36.17%, #FF8787 50.72%, #FF543D 68.06%, #FF4F1B 83.51%, #FF965A 95.37%);
}
.chat_settings .chat__color {
  display: inline-block;
  width: 70px;
  height: 70px;
  flex: 0 0 70px;
  margin-right: 15px;
  margin-bottom: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(19, 19, 19, 0.2);
  border-radius: 10px;
}
.chat_settings .chat__color_1 {
  background: linear-gradient(180deg, #FF8AD7 6.15%, #E168FF 20.46%, #9584FF 37.05%, rgba(142, 123, 255, 0.92) 41.33%, #95BFFE 57.15%, rgba(111, 255, 246, 0.86) 70.68%, #54FFFF 83.06%, #7BFFD7 100%);
}
.chat_settings .chat__color_2 {
  background: linear-gradient(44deg, #98E6FF -9.91%, #B6FFED 43.92%, #BEFFA7 100%), #EEE;
}
.chat_settings .chat__color_3 {
  background: linear-gradient(45deg, #FDFF98 -8.88%, #FFA7A7 100%), #EEE;
}
.chat_settings .chat__color_4 {
  background: linear-gradient(180deg, #FF8AD7 0%, #E168FF 100%);
}
.chat_settings .chat__color_5 {
  background: linear-gradient(180deg, #FFB5EA 0%, #E4FFB7 100%);
}
.chat_settings .chat__color_6 {
  background: linear-gradient(180deg, #A6AEFD -16.67%, #6FFFF6 100%);
}
.chat_settings .chat__color_7 {
  background: linear-gradient(44deg, #98FFEC -9.91%, #BEC5FF 43.92%, #FFA7E6 100%), #EEE;
}
.chat_settings .chat__color_8 {
  background: linear-gradient(0deg, #E4E4E4 0%, #E4E4E4 100%), #EEE;
}
.chat_settings .chat__item .button, .chat_settings .chat__footer .button {
  font-size: 1.125rem;
}

.chat_mobile {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 150;
}
.chat_mobile:not(.chat_support) .chat__header {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
}
.chat_mobile:not(.chat_support) .chat__input .button_attachment {
  width: 2.25rem;
  height: 2.25rem;
}
.chat_mobile:not(.chat_support) .chat__input .button_attachment .button__icon {
  margin: 0;
}
.chat_mobile.chat_support .chat__attachment {
  left: 1rem;
}
.chat_mobile .chat__header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 15px 12px 8px;
  height: 72px;
  width: 100%;
  z-index: 110;
}
.chat_mobile .chat__container {
  height: 100%;
}
.chat_mobile .chat__body {
  padding-top: 82px;
}
.chat_mobile .chat__body.is-overlay {
  position: relative;
  overflow: hidden;
}
.chat_mobile .chat__body-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(166, 166, 166, 0.4);
  backdrop-filter: blur(4px);
  z-index: 13;
}
.chat_mobile .status_badge {
  background: var(--nav-bg);
}
.chat_mobile .message_letter .message__caption {
  padding-right: 20px;
}
.chat_mobile .button_favorite {
  width: 44px;
  height: 44px;
}
.chat_mobile .button_favorite .button__border {
  display: none;
}
.chat_mobile .button_favorite, .chat_mobile .button_back, .chat_mobile .button_letter {
  background-color: var(--button-bg1);
}
.chat_mobile .button_back {
  margin-right: 0.5rem;
}
.chat_mobile .button_letter {
  width: 44px;
  height: 44px;
  box-shadow: none;
  margin-right: 10px;
}
.chat_mobile .chat__footer {
  padding: 0;
}
.chat_mobile .chat__input .ui-input {
  border-radius: 0;
  background: var(--chat-input-bg);
  min-height: 60px;
  padding: 18px 90px 10px 50px;
  background: var(--chat-input-bg);
  box-shadow: 0px -10px 25px rgba(136, 136, 136, 0.15);
  max-height: calc(1.5em * 8);
}
.chat_mobile .chat__input .button_send {
  width: 36px;
  height: 36px;
}
.chat_mobile .chat__input .button_send .button__icon {
  margin: 0;
}
.chat_mobile .chat__preview, .chat_mobile .chat__caption {
  left: 0;
  right: 0;
  bottom: 100%;
  padding: 15px;
}
.chat_mobile .chat__avatar {
  display: none;
}

[contenteditable][data-placeholder] {
  position: relative;
}

[contenteditable][data-placeholder]:empty {
  outline: 0 solid transparent;
}

[contenteditable][data-placeholder]:empty:not(:focus)::before {
  content: attr(data-placeholder);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--placeholder-color);
}

.chat__letter [contenteditable][data-placeholder]:empty:not(:focus)::before {
  top: 20px;
}

.no-webp .chat__wrapper {
  overflow-y: auto;
}
.no-webp .chat__wrapper.bg-1 {
  background-image: url(../images/chat/light/bg1.jpg);
}
.no-webp .chat__wrapper.bg-2 {
  background-image: url(../images/chat/light/bg2.jpg);
}
.no-webp .chat__wrapper.bg-3 {
  background-image: url(../images/chat/light/bg3.jpg);
}
.no-webp .chat__wrapper.bg-4 {
  background-image: url(../images/chat/light/bg4.jpg);
}
.no-webp .chat__wrapper.bg-5 {
  background-image: url(../images/chat/light/bg5.jpg);
}
.no-webp .chat__wrapper.bg-6 {
  background-image: url(../images/chat/light/bg6.jpg);
}
.no-webp .chat_support .chat__wrapper {
  background-image: url(../images/support-chat/light/bg-light.jpg);
}
.no-webp .chat_settings .chat__bg_1 {
  background-image: url(../images/chat/settings/light/bg1.jpg);
}
.no-webp .chat_settings .chat__bg_2 {
  background-image: url(../images/chat/settings/light/bg2.jpg);
}
.no-webp .chat_settings .chat__bg_3 {
  background-image: url(../images/chat/settings/light/bg3.jpg);
}
.no-webp .chat_settings .chat__bg_4 {
  background-image: url(../images/chat/settings/light/bg4.jpg);
}
.no-webp .chat_settings .chat__bg_5 {
  background-image: url(../images/chat/settings/light/bg5.jpg);
}
.no-webp .chat_settings .chat__bg_6 {
  background-image: url(../images/chat/settings/light/bg6.jpg);
}

@media screen and (max-width: 1279px) {
  .chat__letter-bg .list__item {
    margin-bottom: 10px;
  }
  .chat__letter-overlay {
    top: 80px;
  }
  .chat__preview_record .preview__container {
    justify-content: flex-start;
  }
  .chat .chat__attachment {
    bottom: 10px;
    left: 8px;
  }
  .chat.button_round {
    position: relative;
    padding: 4px;
    width: 44px;
    height: 44px;
    background: #FAFAFA;
  }
  .chat.button_round .button__icon {
    margin-right: 0;
  }
  .chat .letter {
    padding: 82px 15px 15px;
  }
  .chat .letter .letter__subject {
    font-size: 1.25rem;
  }
  .chat .letter_view, .chat .letter .letter__message {
    font-size: 1.125rem;
  }
  .chat_settings .chat__footer {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 575px) {
  .chat_support .chat__wrapper {
    background-image: url(../images/support-chat/mobile/light/bg-light.jpg);
  }
  .chat_settings .chat__bg {
    padding-top: 160%;
  }

  .no-webp .chat__wrapper.bg-1 {
    background-image: url(../images/chat/mobile/light/bg1.jpg);
  }
  .no-webp .chat__wrapper.bg-2 {
    background-image: url(../images/chat/mobile/light/bg2.jpg);
  }
  .no-webp .chat__wrapper.bg-3 {
    background-image: url(../images/chat/mobile/light/bg3.jpg);
  }
  .no-webp .chat__wrapper.bg-4 {
    background-image: url(../images/chat/mobile/light/bg4.jpg);
  }
  .no-webp .chat__wrapper.bg-5 {
    background-image: url(../images/chat/mobile/light/bg5.jpg);
  }
  .no-webp .chat__wrapper.bg-6 {
    background-image: url(../images/chat/mobile/light/bg6.jpg);
  }
  .no-webp .chat_support .chat__wrapper {
    background-image: url(../images/support-chat/mobile/light/bg-light.jpg);
  }
  .no-webp .chat_settings .chat__bg_1 {
    background-image: url(../images/chat/settings/mobile/light/bg1.jpg);
  }
  .no-webp .chat_settings .chat__bg_2 {
    background-image: url(../images/chat/settings/mobile/light/bg2.jpg);
  }
  .no-webp .chat_settings .chat__bg_3 {
    background-image: url(../images/chat/settings/mobile/light/bg3.jpg);
  }
  .no-webp .chat_settings .chat__bg_4 {
    background-image: url(../images/chat/settings/mobile/light/bg4.jpg);
  }
  .no-webp .chat_settings .chat__bg_5 {
    background-image: url(../images/chat/settings/mobile/light/bg5.jpg);
  }
  .no-webp .chat_settings .chat__bg_6 {
    background-image: url(../images/chat/settings/mobile/light/bg6.jpg);
  }
}
@media screen and (min-width: 576px) {
  .chat__item .message_base {
    max-width: 60%;
  }
}
@media screen and (min-width: 1280px) {
  .chat__letter-bg {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .chat_support {
    width: 800px;
  }
  .chat_settings .chat__body {
    padding-left: 50px;
    padding-right: 50px;
  }
  .chat_settings .chat__list {
    margin: 80px 0 0;
  }
  .chat_settings .chat__overlay {
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;
  }
  .chat [contenteditable][data-placeholder]:empty:not(:focus)::before {
    content: none;
  }
}
@media screen and (min-width: 1920px) {
  .chat__header {
    height: 86px;
    padding: 8px 45px 8px 30px;
  }
  .chat__message-blocked {
    font-size: 1.25rem;
  }
  .chat_settings .chat__list-bg {
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  }
  .chat_settings .chat__bg {
    width: 170px;
  }
  .chat__footer .icon_mic-white {
    width: 13px;
    height: 18px;
  }
}
@keyframes circle1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes circle2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@media (hover: hover) {
  .chat__color, .chat__bg, .chat__letter-overlay .list__link:hover {
    cursor: pointer;
  }

  .chat__bg:hover {
    box-shadow: 0 10px 20px rgba(19, 19, 19, 0.2);
  }

  .chat__color:not(.is-checked):hover {
    box-shadow: none;
  }
}
@media (max-width: 1279px) {
  .chat__footer .icon_mic-white {
    min-width: 13px;
    width: 100%;
    height: 18px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .chat__header {
    padding: 8px 30px 8px 20px;
    height: 72px;
  }
  .chat__close {
    top: 8px;
    right: 8px;
  }
  .chat__avatar {
    display: none;
  }
  .chat__date {
    font-size: 0.8571428571rem;
  }
  .chat__list {
    padding: 0 12px;
  }
  .chat__dropdown {
    left: 40px;
  }
  .chat__footer {
    padding: 0 8px 20px;
  }
  .chat__input .ui-input {
    min-height: 35px;
    padding: 5px 90px 5px 45px;
    font-size: 1.1428571429rem;
  }
  .chat__input .ui-input .record {
    margin-top: 5px;
  }
  .chat__footer .chat__send:not(.chat__replay) {
    padding: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }
  .chat__footer .chat__send:not(.chat__replay) .button__text {
    display: none;
  }
  .chat__footer .chat__send:not(.chat__replay) .button__icon {
    margin: 0;
  }
  .chat__footer .icon_mic-white {
    width: 13px;
    height: 18px;
  }
  .chat__footer .icon_envelope,
.chat__footer .icon_emoji,
.chat__footer .icon_attachment {
    width: 14px;
    height: 14px;
  }
  .chat__footer .icon_plane {
    width: 14px;
    height: 12px;
  }
  .chat__footer .icon_close {
    width: 12px;
    height: 12px;
  }
  .chat__footer .icon_attachment .button__icon, .chat__footer .button_letter .button__icon {
    width: 14px;
    height: 14px;
  }
  .chat__attachment {
    bottom: 10px;
    left: 55px;
  }
  .chat__buttons {
    bottom: 6px;
  }
  .chat__preview, .chat__caption {
    left: 50px;
    font-size: 1rem;
    padding: 10px 14px 24px;
  }
  .chat__item .message_base {
    max-width: 70%;
  }
  .chat__message-menu {
    right: 10px;
  }
  .chat__check-icon {
    width: 1rem;
    height: 1rem;
  }
  .chat_settings .chat__body {
    padding-left: 20px;
    padding-right: 20px;
  }
  .chat_settings .chat__list {
    margin: 50px 0 0;
  }
}
.message {
  position: relative;
}
.message__triangle {
  position: absolute;
  bottom: 0;
  width: 12px;
  height: 20px;
}
.message__footer {
  margin-top: 5px;
  display: flex;
  align-items: baseline;
}
.message__date {
  margin: 0 0 0 auto;
  font-size: 0.75rem;
  text-align: right;
}
.message__date, .message__duration {
  display: block;
  font-size: 0.75rem;
}
.message .button_sound {
  margin-right: 10px;
}
.message__caption {
  display: flex;
  align-items: center;
}
.message__icon {
  font-size: 1.25rem;
  font-weight: 500;
  margin-right: 15px;
}
.message__icon .icon {
  margin-right: 8px;
}
.message__preview {
  flex: 1;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.message__thumb {
  display: block;
  position: relative;
  width: 280px;
  height: 200px;
  overflow: hidden;
}
.message__thumb img, .message__thumb video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
}
.message__title {
  display: block;
  margin-top: 5px;
  margin-bottom: 12px;
  font-size: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
}
.message__text:not(.is-inline-flex) {
  word-break: break-word;
}
.message__text.is-inline-flex .message__admin-name {
  flex: 1;
}
.message__information {
  margin-left: 5px;
}
.message__information .message__subtitle, .message__information .message__description {
  display: block;
  font-weight: 700;
}
.message__information .message__subtitle {
  text-decoration: underline;
}
.message__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: rgba(19, 19, 19, 0.3);
  color: var(--color-white);
  border-radius: 5px;
  overflow: hidden;
}
.message__overlay .button_video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.message__overlay .message__icon {
  margin-right: 0;
}
.message__content {
  margin: auto;
  text-align: center;
}
.message__button {
  text-transform: capitalize;
}
.message__audio {
  position: relative;
  display: flex;
  align-items: center;
}
.message__audio .button_audio-play {
  margin-right: 5px;
}
.message__audio .audio {
  display: block;
  flex: 1;
  height: 40px;
}
.message__admin-name {
  margin-right: 8px;
  float: left;
  color: var(--message-admin-color);
}
.message_base {
  padding: 10px 20px 10px 15px;
  min-width: 190px;
}
.message_letter, .message_audio {
  padding: 10px 20px;
}
.message_media {
  padding: 6px;
  margin-bottom: 20px;
}
.message_media .message__date {
  position: absolute;
  top: 100%;
  right: 0;
}
.message_letter .message__caption {
  padding-right: 80px;
}
.message .button_link {
  padding: 0;
  background-image: none;
  font-size: 1.25rem;
  color: var(--color-red);
}
.message .button_link span {
  margin: 0 5px;
}
.message_letter-images {
  position: relative;
  min-width: 80%;
  padding: 10px 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.message_letter-images::before {
  display: none;
}
.message_letter-images .message__icon {
  line-height: 1;
}
.message_letter-images .message__icon .icon {
  margin: -1px;
}
.message_letter-images .message__text {
  min-height: 60px;
  display: block;
  color: #fff;
}
.message_letter-images .message__triangle {
  display: none;
}
.message_letter-images .message__text, .message_letter-images .message__footer {
  position: relative;
}
.message_letter-images .button_options > span, .message_letter-images .button_options > span::after, .message_letter-images .button_options > span::before {
  background-color: var(--color-white);
}
.message_letter-images .message__date, .message_letter-images .button_link {
  display: block;
  border-radius: 4px;
  background: var(--color-light);
}
.message_letter-images .message__date {
  padding: 0 2px;
}
.message_letter-images .button_link span {
  background-image: linear-gradient(251deg, #EF821E 24.57%, #E8033A 80.45%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

@media screen and (min-width: 428px) {
  .message_audio {
    min-width: 280px;
  }
  .message__duration {
    margin-left: 40px;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .message__date {
    font-size: 0.7142857143rem;
  }
  .message__icon {
    font-size: 1rem;
    margin-right: 5px;
  }
  .message__icon .icon {
    margin-right: 0;
  }
  .message__thumb {
    display: block;
    position: relative;
    width: 260px;
    height: 180px;
  }
  .message_letter, .message_audio {
    padding: 8px 15px;
  }
  .message_letter .message__caption {
    padding-right: 0;
  }
  .message__title {
    font-size: 1rem;
  }
  .message_base:not(.message_letter-images) {
    padding: 5px 10px;
  }
  .message .button_link {
    font-size: 1.0714285714rem;
  }
}
.letter {
  padding: 50px 60px 36px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--chat-default-bg);
}
.letter__text {
  display: block;
  margin-bottom: 5px;
  word-wrap: break-word;
}
.letter__form {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.letter__textarea {
  width: 100%;
  background: var(--chat-message-bg);
  border-radius: 5px;
  border: none;
}
.letter__textarea, .letter__message {
  flex: 1;
}
.letter__textarea {
  height: 100%;
  resize: none;
  color: #161616;
}
.letter__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  overflow-y: auto;
  background: rgba(64, 64, 64, 0.9);
  backdrop-filter: blur(2.5px);
  z-index: 110;
}
.letter .button_rounded {
  padding: 0 25px;
  min-width: 130px;
  height: 40px;
  line-height: 38px;
  font-size: 14px;
  text-transform: none;
}
.letter .button_round {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.letter__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.letter__footer {
  position: relative;
  margin-top: 20px;
}
.letter__footer .chat__dropdown {
  left: 0;
}
.letter__footer .chat__dropdown .dropdown__list {
  min-width: 150px;
  max-height: 200px;
  overflow-y: auto;
}
.letter__footer .chat__dropdown .dropdown__list::-webkit-scrollbar {
  width: 2px;
}
.letter__footer .chat__dropdown .dropdown__list::-webkit-scrollbar-thumb {
  background-color: #6A6A6A;
}
.letter__footer .chat__dropdown .dropdown__list::-webkit-scrollbar-track {
  background-color: rgba(197, 197, 197, 0.2);
}
.letter__footer .chat__dropdown .dropdown__list::after, .letter__footer .chat__dropdown .dropdown__list::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 10;
}
.letter__footer .chat__dropdown .dropdown__list::before {
  top: -2px;
  background: linear-gradient(180deg, #FFF 34.29%, rgba(255, 255, 255, 0) 100%);
}
.letter__footer .chat__dropdown .dropdown__list::after {
  background: linear-gradient(0deg, #FFF 34.29%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
}
.letter__footer .chat__dropdown .dropdown__list:hover::after, .letter__footer .chat__dropdown .dropdown__list:hover::before {
  content: none;
}
.letter__footer .chat__dropdown .dropdown__item:not(:last-child) .dropdown__link {
  border-bottom: none;
}
.letter__footer .chat__dropdown .dropdown__link {
  padding: 5px 0;
}
.letter__footer .chat__caption {
  left: 0;
  bottom: 100%;
}
.letter__additional {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.letter__thumb {
  position: relative;
}
.letter__thumb .button {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 2px;
  z-index: 10;
}
.letter__thumb .image {
  max-width: 7.5rem;
}
.letter__image-container {
  position: relative;
  margin: auto auto 0;
  max-width: 512px;
  padding: 0 20px;
  text-align: center;
}
.letter__image-container .button_download {
  position: absolute;
  width: 26px;
  height: 26px;
  bottom: 20px;
  right: 30px;
  padding: 2px;
  line-height: 1;
  border-radius: 5px;
  background: rgba(7, 7, 7, 0.8);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  transition: opacity 0.3s ease;
  z-index: 10;
}
.letter__image-container .button_download:hover {
  opacity: 0.85;
}
.letter__image {
  border-radius: 10px;
}
.letter_view .letter__subject {
  margin-bottom: 30px;
  font-size: 25px;
  color: var(--letter-message-color);
}
.letter_view .letter__message {
  font-size: 22px;
  color: var(--letter-subject-color);
}
.letter_view .letter__footer {
  text-align: center;
}
.letter_images {
  background: transparent;
}
.letter_images .letter__textarea {
  margin-bottom: 40px;
  border: 0;
  height: auto;
  background: transparent;
}
.letter_images .letter__form, .letter_images .letter__footer {
  position: relative;
  z-index: 20;
  background-color: transparent;
}
.letter_images .letter__message {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.letter_images.letter_view .letter__footer {
  text-align: right;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .letter {
    padding: 25px 30px 16px;
  }
  .letter_view .letter__subject {
    margin-bottom: 20px;
    font-size: 1.2857142857rem;
  }
  .letter_view .letter__message {
    font-size: 1.1428571429rem;
  }
}
@media screen and (min-width: 1920px) {
  .letter .button_rounded:not(.button_sound), .letter .button_send {
    min-width: 150px;
    height: 46px;
    line-height: 44px;
  }
}
.upload {
  position: relative;
}
.upload__preview {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.upload__label {
  display: inline-block;
  cursor: pointer;
}
.upload__label_abs {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}
.upload__label_image, .upload__label_video {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.upload__label_image {
  width: 80px;
  height: 80px;
  background-image: url(../images/icons/big-camera.svg);
}
.upload__label_video {
  position: relative;
}
.upload__label_video .label__icon {
  margin-right: 0;
  border: 2px solid var(--light-gray);
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  align-items: center;
  display: flex;
  justify-content: center;
}
.upload__warning {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  margin-bottom: 5px;
  text-align: center;
  color: var(--state-danger);
}
.upload__section {
  position: relative;
  display: block;
  padding: 15px;
  border: 1px solid #C5C5C5;
  border-radius: 5px;
  text-align: center;
}
.upload__button {
  width: auto;
  position: relative;
  z-index: -1;
}
.upload__delete {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  z-index: 10;
}
.upload__delete.button {
  width: 30px;
}
.upload__col {
  display: flex;
}
.upload__col .upload__preview {
  padding: 0 10px;
}
.upload__col .title, .upload__col .heading, .upload__col .section__sub-heading, .upload__col .section__title, .upload__col .icon-box__title {
  margin-bottom: 10px;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .upload__label_image {
    width: 60px;
    height: 60px;
  }
}
.price:not(.price_mobile):not(.price_starting) {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  margin-bottom: 40px;
}
.price:not(.price_mobile) .price__header {
  height: 80px;
  width: 240px;
  padding: 20px;
  border-radius: 0 0 10px 10px;
}
.price:not(.price_mobile) .price__button {
  margin-top: auto;
  width: 100%;
}
.price:not(.price_mobile) .price__description {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(216, 216, 216, 0.75);
  margin-bottom: 26px;
}
.price:not(.price_mobile) .price__body {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px 15px 21px;
  text-align: center;
}
.price:not(.price_mobile) .price__features {
  padding: 0 15px;
  text-align: left;
}
.price:not(.price_mobile) .price__item {
  display: flex;
  flex-direction: column;
  background: var(--nav-bg);
  box-shadow: 0 10px 35px rgba(126, 126, 126, 0.1);
  border-radius: 10px;
  transition: transform 0.25s ease-in-out;
  will-change: transform;
  width: 270px;
  height: 481px;
  margin-top: 27px;
}
.price:not(.price_mobile) .price__item:hover {
  transform: scale(1.1);
}
.price:not(.price_mobile) .price__item_base .price__header {
  background-image: url(../images/payment/header-bg1.svg);
  align-self: center;
  margin-top: -27px;
}
.price:not(.price_mobile) .price__item_expert .price__header {
  background-image: url(../images/payment/header-bg2.svg);
  align-self: center;
  margin-top: -27px;
}
.price:not(.price_mobile) .price__item_pro .price__header {
  background-image: url(../images/payment/header-bg3.svg);
  align-self: center;
  margin-top: -27px;
}
.price:not(.price_mobile) .price__item_top .price__header {
  background-image: url(../images/payment/header-bg4.svg);
  align-self: center;
  margin-top: -27px;
}
.price__header, .price__desription {
  text-align: center;
}
.price__header {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  color: var(--color-white);
}
.price__title {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-white);
}
.price__icon, .price__value, .price__credit {
  display: block;
}
.price__title, .price__value {
  text-transform: uppercase;
}
.price__icon {
  margin: auto;
}
.price__value {
  margin-bottom: 5px;
  font-size: 2.625rem;
  font-weight: 700;
  color: var(--heading-color);
}
.price__credit {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 140%;
}
.price__button > span {
  text-transform: none;
}
.price__button::after {
  content: none;
}
.price__features {
  margin-bottom: 30px;
  font-size: 0.875rem;
  line-height: 16px;
}
.price__features .list__item {
  display: flex;
  align-items: center;
  gap: 5px;
}
.price__features .list__item:not(:last-child) {
  margin-bottom: 10px;
}
.price__item .price__features-title {
  font-size: 1rem;
  font-weight: 700;
}
.price__item_base .svg-icon {
  color: #00F4F4;
}
.price__item_base .price__features-title, .price__item_base .price__features-title .svg-icon {
  color: #808080;
}
.price__item_base .price__credit {
  color: #91A2BB;
}
.price__item_base .price__button {
  background: var(--gradient-price-base);
}
.price__item_expert .svg-icon, .price__item_expert .price__features-title {
  color: #3B7EFF;
}
.price__item_expert .price__credit {
  color: #2799D3;
}
.price__item_expert .price__button {
  background: var(--gradient-price-expert);
}
.price__item_pro .svg-icon, .price__item_pro .price__features-title {
  color: #712FFF;
}
.price__item_pro .price__credit {
  color: #825AF9;
}
.price__item_pro .price__button {
  background: var(--gradient-price-pro);
}
.price__item_top .svg-icon, .price__item_top .price__features-title {
  color: #F601FB;
}
.price__item_top .price__credit {
  color: #E5359B;
}
.price__item_top .price__button {
  background: var(--gradient-price-top);
}
.price__item_starting .price__button {
  background: var(--gradient-price-starting);
}
.price__tab {
  position: relative;
  margin-bottom: 25px;
  background: var(--nav-bg);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  min-height: 81px;
}
.price__tab::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 100%;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
}
.price__tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.price__tab input:checked + .price__tab-label {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
}
.price__tab input:checked ~ .price__tab-content {
  max-height: 100vh;
  padding: 20px;
}
.price__tab.price__item_base::after {
  background-image: url("../images/payment/right-mobile-bg1.svg");
}
.price__tab.price__item_expert::after {
  background-image: url("../images/payment/right-mobile-bg2.svg");
}
.price__tab.price__item_pro::after {
  background-image: url("../images/payment/right-mobile-bg3.svg");
}
.price__tab.price__item_top::after {
  background-image: url("../images/payment/right-mobile-bg4.svg");
}
.price__tab.price__item_starting::after {
  background-image: url("../images/payment/right-mobile-bg5.svg");
}
.price__tab, .price__tab-label {
  border-radius: 10px;
}
.price__tab-label {
  position: relative;
  display: flex;
}
.price__tab-label .price__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 140px;
  flex: 0 0 140px;
  padding: 0 10px;
  border-radius: 10px 0 0 10px;
}
.price__tab-label .price__icon {
  margin: 0;
}
.price__tab-label .price__description {
  padding: 5px 30px 5px 35px;
  flex: 1 1 auto;
}
.price__tab-content {
  max-height: 0;
  text-align: center;
  background: var(--nav-bg);
  transition: all 0.35s ease-in-out;
}
.price__tab-content .price__button {
  width: 240px;
}
.price__tab-content .price__features .list__item {
  justify-content: center;
}
.price_starting:not(.price_mobile) .price__item_starting .price__header {
  background-image: url(../images/payment/header-bg5.svg);
}
.price_starting:not(.price_mobile) .price__features .list__item:last-child {
  text-align: left;
}
.price_starting {
  display: block;
}
.price_mobile .price__item {
  margin-bottom: 30px;
}
.price_mobile.price_starting .price__header {
  background-image: url(../images/payment/header-mobile-bg5.svg);
}
.price_mobile .price__item_base .price__header {
  background-image: url(../images/payment/header-mobile-bg1.svg);
}
.price_mobile .price__item_expert .price__header {
  background-image: url(../images/payment/header-mobile-bg2.svg);
}
.price_mobile .price__item_pro .price__header {
  background-image: url(../images/payment/header-mobile-bg3.svg);
}
.price_mobile .price__item_top .price__header {
  background-image: url(../images/payment/header-mobile-bg4.svg);
}
.price_mobile .price__value {
  font-size: 2.5rem;
}
.price_mobile .price__item_starting .price__header {
  background-image: url(../images/payment/header-mobile-bg5.svg);
}

@media screen and (min-width: 1920px) {
  .price:not(.price_mobile) .price__item {
    height: 480px;
  }
  .price:not(.price_mobile) .price__features {
    padding: 0 25px;
  }
  .price:not(.price_mobile) .price__body {
    padding: 40px 15px 30px;
  }
}
.image-cropper {
  text-align: center;
}
.image-cropper__container {
  width: 100%;
}
.image-cropper__container .cropper-container {
  margin: auto;
}
.image-cropper__container img {
  display: block;
  max-width: 100%;
  margin: auto;
}
.image-cropper__buttons {
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
}
.image-cropper__buttons .button:not(.image-cropper__button) {
  min-width: 120px;
  margin: 0 10px;
  text-transform: none;
}
.image-cropper__button {
  width: 1.875rem;
  display: inline-block;
  background-color: #6366F1;
}

.cropped-image img {
  max-width: 100%;
}

.tag {
  display: inline-block;
  padding: 8px 10px 8px 15px;
  background: var(--nav-header-bg);
  border-radius: 5px;
  white-space: nowrap;
}
.tag .icon {
  margin-left: 5px;
}

.pad {
  color: var(--color-text);
}
.pad__header, .pad__body {
  text-align: center;
}
.pad__header {
  padding: 2px 20px;
  border-radius: 5px;
  background: rgba(51, 41, 68, 0.1);
  font-weight: 700;
  color: var(--heading-color);
}
.pad:not(.pad_modal) .pad__body {
  position: relative;
  padding: 10px 20px 20px;
}
.pad:not(.pad_modal) .pad__body::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(175deg, #17AAFC 0%, #CB77FF 100%);
}
.pad_modal .pad__body {
  text-align: left;
}

@media screen and (min-width: 1280px) {
  .pad__header {
    font-size: 1.5625rem;
  }
  .pad__body {
    font-size: 1.25rem;
  }
}
.banner {
  position: relative;
  margin: 0 auto 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}
.banner__heading {
  position: absolute;
  top: -20px;
  left: 24px;
  width: 200px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.banner__timer {
  margin-left: auto;
  text-align: center;
}
.banner__title {
  margin: 0;
  font-size: 1rem;
  text-transform: uppercase;
  color: inherit;
}
.banner_photo {
  width: 750px;
  height: 100px;
  background-image: url(../images/banner/freechat/banner-freechat.png);
  color: #fff;
}
.banner_photo .banner__heading {
  background-image: url(../images/holidays/photo/heading.svg);
}
.banner_photo_free_chat {
  background-image: url(../images/modal/freechat/freechat-mobile-modal.jpg);
  color: #fff;
}
.banner_photo_free_chat .banner__heading {
  background-image: url(../images/holidays/photo/heading.svg);
}

.countdown {
  position: relative;
  color: inherit;
  display: flex;
  gap: 10px;
}
.countdown__label {
  display: block;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.countdown__item {
  text-align: center;
}
.countdown__value {
  display: inline-block;
  width: 55px;
  height: 48px;
  padding: 5px;
  background-color: var(--color-white);
  border-radius: 5px;
  font-size: 2.5rem;
  line-height: 1;
  color: #333232;
  font-weight: 700;
}

.gifts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px 15px;
  overflow-x: hidden;
  padding: 24px 46px 53px;
  flex: 1;
}

@media (min-width: 968px) and (max-width: 1920px) {
  .gifts {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 15px;
    padding: 17px 46px 33px;
  }
}
@media (min-width: 576px) and (max-width: 968px) {
  .gifts {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 15px;
    padding: 17px 46px 33px;
  }
}
@media screen and (max-width: 576px) {
  .gifts {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    padding: 24px 46px 33px;
  }
}
.gift {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1fr;
  width: 258px;
  height: 249px;
  border-radius: 5px;
  background: var(--nav-bg);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}
.gift__body {
  grid-row: 1/span 2;
  align-items: center;
  justify-content: center;
  display: grid;
}
.gift__footer {
  grid-row: 3/span 1;
  display: grid;
  height: 1px;
}
.gift__footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  padding-top: 6px;
}
.gift__footer-description {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.gift__footer-title {
  color: var(--heading-color);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.gift__footer-crypto {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}
.gift .crypto-amount {
  color: var(--color-text);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.gift .button_gifts {
  padding: 1px;
}

@media (min-width: 968px) and (max-width: 1920px) {
  .gift {
    width: 220px;
    height: 278px;
  }
  .gift__footer-crypto {
    gap: 8px;
  }
}
@media (min-width: 576px) and (max-width: 968px) {
  .gift {
    width: 220px;
    height: 278px;
  }
  .gift__footer-crypto {
    gap: 8px;
  }
}
@media screen and (max-width: 576px) {
  .gift {
    width: 220px;
    height: 278px;
  }
  .gift__footer-crypto {
    gap: 8px;
  }
}
.present-block {
  display: flex;
  align-items: center;
  gap: 5px;
}
.present-block .button__icon {
  width: 24px;
  height: 24px;
}
.present-block .button_gradient {
  width: 40px;
  height: 40px;
  padding: 3px;
}
.present-block .button_gradient .button__border {
  width: 40px;
  height: 40px;
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .present-block .button_bordered {
    width: 52px;
    height: 30px;
    border-radius: 40px;
  }
  .present-block .button_bordered span {
    padding: 0;
    border-radius: 40px;
  }
  .present-block .button__icon {
    width: 16px;
    height: 16px;
  }
}
.landing_welcome {
  position: relative;
  background: #fff;
  min-height: calc(var(--vh, 1vh)* 100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.landing_welcome .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.landing_welcome .header .container {
  display: flex;
  align-items: center;
}
.landing_welcome .section_intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh)* 100);
  z-index: 10;
}
.landing_welcome .video_responsive {
  visibility: visible;
}
.landing_welcome .footer {
  position: relative;
  z-index: 10;
  background: var(--body-bg);
  margin-top: calc(var(--vh, 1vh)* 100);
}
.landing_welcome .footer__section_menu {
  max-width: 1920px;
  justify-content: center;
  margin: 0 auto;
}
.landing_welcome .footer .page__older-men .section_service .container .section__content p {
  font-size: 20px;
}
.landing_welcome .footer__content {
  margin: auto 0 0;
  padding: 0 20px 20vh;
}
.landing_welcome .footer__content .heading, .landing_welcome .footer__content .subheading {
  display: block;
  letter-spacing: 0.04em;
  line-height: 1;
  text-transform: none;
  text-align: left;
}
.landing_welcome .footer__content .is-block {
  white-space: nowrap;
}
.landing_welcome .footer__content .text {
  display: block;
  white-space: nowrap;
}
.landing_welcome .footer__content_asia .heading {
  position: relative;
  padding-left: 20px;
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  color: #fff;
}
.landing_welcome .footer__content_asia .heading .divider {
  position: absolute;
  display: block;
  top: 5%;
  left: 0;
  height: 90%;
  width: 10px;
  background: #8a43ff;
}
.landing_welcome .footer__content_asia .subheading {
  font-family: Work Sans, sans-serif;
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #8a43ff;
}
.landing_welcome .footer__content_black .heading {
  font-weight: 600;
  font-size: 3.5rem;
  margin-bottom: 1.25rem;
  color: #fff;
}
.landing_welcome .footer__content_black .subheading {
  padding: 10px;
  background: #ce2074;
  font-size: 1.8rem;
  font-family: Work Sans, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
}
.landing_welcome .footer__content_older-men {
  margin: calc(var(--vh, 1vh)* 70) auto;
  padding: 0;
}
.landing_welcome .footer__content_older-men .heading {
  color: #FFF;
  text-align: center;
  text-shadow: 0 2px 2px #000;
  font-family: Roboto, sans-serif;
  font-variant: none;
}
.landing_welcome .footer__content_older-men .subheading {
  color: #FFF;
  text-align: center;
  font-variant: none;
}
.landing_welcome .footer__content_mature .heading {
  font-family: Abril Fatface, cursive;
  font-size: 3.5rem;
  margin-bottom: 1.25rem;
  color: #fff;
}
.landing_welcome .footer__content_mature .subheading {
  font-family: Aguafina Script, cursive;
  font-size: 1.8rem;
  font-weight: 500;
  color: #ff285c;
}
.landing_welcome .footer__body {
  position: absolute;
  top: calc(var(--vh, 1vh)* -100);
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh)* 100);
  display: flex;
  z-index: 10;
  justify-content: center;
}
.landing_welcome .footer__caption {
  position: absolute;
  transform: translate(-50%, -50%);
  top: calc(var(--vh, 1vh)* -10);
  left: 50%;
  z-index: 100;
}
.landing_welcome .footer__caption .text {
  display: none;
  margin: 0;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.4;
}
.landing_welcome .footer__caption .text-older {
  display: none;
}
.landing_welcome .footer__caption .text-ret5 {
  display: block;
}
.landing_welcome .footer__caption .button {
  width: 280px;
}
.landing_welcome .footer__caption_older-men50 .button {
  display: none;
}
.landing_welcome .footer .container {
  position: relative;
  max-width: 100%;
  padding: 0;
}

@media only screen and (min-width: 279px) {
  .landing_welcome .footer__content .button_primary {
    height: 48px;
    padding: 13px 20px;
    font-size: 1.25rem;
  }
  .landing_welcome .footer__content_older-men {
    margin: auto auto 5% auto;
    padding: 0 0 0 0;
  }
  .landing_welcome .footer__content_older-men .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Roboto, sans-serif;
    font-size: 1.875rem;
    font-weight: 162;
    line-height: 1;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men .heading .first {
    display: block;
  }
  .landing_welcome .footer__content_older-men .heading .accent {
    color: #FFF;
    text-shadow: 0 1.5px 2px #000;
    font-family: inherit;
    font-size: 1.875rem;
    font-weight: 300;
    line-height: 1.35;
    text-transform: lowercase;
    display: block;
  }
  .landing_welcome .footer__content_older-men .subheading {
    font-family: Oswald, sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.35;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men .button_primary {
    width: 250px;
    margin-top: 64px;
  }
  .landing_welcome .footer__content_older-men50 {
    margin: auto auto 5% auto;
    padding: 0 0 0 0;
  }
  .landing_welcome .footer__content_older-men50 .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 1.875rem;
    font-weight: 300;
    line-height: 1;
    font-variant: none;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 .heading .first {
    display: block;
  }
  .landing_welcome .footer__content_older-men50 .accent {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 1.875rem;
    font-weight: 300;
    line-height: 1;
    text-transform: lowercase;
    display: block;
    padding-right: 20px;
  }
  .landing_welcome .footer__content_older-men50 .subheading {
    color: #FFCD29;
    font-size: 3.125rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    display: block;
    text-align: center;
  }
  .landing_welcome .footer__content_older-men50 .slogan {
    display: none;
  }
  .landing_welcome .footer__content_older-men50 .button_primary {
    width: 250px;
    margin-top: 64px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .landing_welcome .footer__content_older-men50 .icon_aws {
    display: none;
  }
  .landing_welcome .footer__content_ret5 {
    margin: auto auto 5% auto;
    padding: 0 0 0 0;
  }
  .landing_welcome .footer__content_ret5 .before {
    color: #312F2F;
    text-align: left;
    font-family: Oswald, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    padding-left: 30px;
  }
  .landing_welcome .footer__content_ret5 .heading {
    color: #FFF;
    text-align: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #4326F4;
    font-family: Roboto, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .accent {
    color: #2B2A2A;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.1;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_ret5 .slogan {
    display: block;
  }
  .landing_welcome .footer__content_ret5 .button_primary {
    width: 100%;
    margin-top: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 13px 50px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
  }
  .landing_welcome .footer__content_ret5 .text {
    display: none;
  }
  .landing_welcome .footer__content_ret5 .icon_aws {
    display: none;
  }
}
@media screen and (min-width: 427px) {
  .landing_welcome .footer__content_older-men .heading {
    text-shadow: 0 2px 2px #000;
    font-size: 3.125rem;
    font-weight: 162;
    line-height: 1;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men .heading .first {
    display: block;
  }
  .landing_welcome .footer__content_older-men .heading .accent {
    color: #FFF;
    text-shadow: 0 1.5px 2px #000;
    font-family: inherit;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1;
    text-transform: lowercase;
    display: block;
  }
  .landing_welcome .footer__content_older-men .subheading {
    font-family: Oswald, sans-serif;
    font-size: 5rem;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men50 .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 3.125rem;
    font-weight: 300;
    line-height: 1;
    font-variant: none;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 .heading .first {
    display: block;
  }
  .landing_welcome .footer__content_older-men50 .accent {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 2.1875rem;
    font-weight: 300;
    line-height: 1;
    text-transform: lowercase;
    display: block;
    padding-right: 0;
  }
  .landing_welcome .footer__content_older-men50 .subheading {
    color: #FFCD29;
    font-size: 3.75rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    display: block;
  }
  .landing_welcome .footer__content_older-men50 .slogan {
    display: none;
  }
  .landing_welcome .footer__content_older-men50 .button_primary {
    width: 330px;
    margin-top: 64px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .landing_welcome .footer__content_ret5 .before {
    color: #312F2F;
    text-align: left;
    font-family: Oswald, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    padding-left: 30px;
  }
  .landing_welcome .footer__content_ret5 .heading {
    color: #FFF;
    text-align: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #4326F4;
    font-family: Roboto, sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .accent {
    color: #2B2A2A;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .slogan {
    display: block;
  }
  .landing_welcome .footer__content_ret5 .button_primary {
    width: 390px;
    margin-top: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
  }
}
@media screen and (min-width: 575px) {
  .landing_welcome .footer__content .button_primary {
    height: 48px;
    padding: 13px 20px;
    font-size: 1.25rem;
  }
  .landing_welcome .footer__content_older-men .heading {
    font-size: 3.75rem;
    font-weight: 162;
    line-height: 1;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men .heading .first {
    display: block;
  }
  .landing_welcome .footer__content_older-men .heading .accent {
    color: #FFF;
    text-shadow: 0 1.5px 2px #000;
    font-family: inherit;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.35;
    text-transform: lowercase;
    display: block;
  }
  .landing_welcome .footer__content_older-men .subheading {
    font-family: Oswald, sans-serif;
    font-size: 5rem;
    font-weight: 600;
    line-height: 1.35;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men50 .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 3.75rem;
    font-weight: 300;
    line-height: 1;
    font-variant: none;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 .heading .first {
    display: block;
  }
  .landing_welcome .footer__content_older-men50 .accent {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1;
    text-transform: lowercase;
    display: block;
    padding-right: 0;
  }
  .landing_welcome .footer__content_older-men50 .subheading {
    color: #FFCD29;
    font-size: 5rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    display: block;
  }
  .landing_welcome .footer__content_older-men50 .slogan {
    display: none;
  }
  .landing_welcome .footer__content_ret5 .before {
    color: #312F2F;
    text-align: left;
    font-family: Oswald, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    padding-left: 30px;
  }
  .landing_welcome .footer__content_ret5 .heading {
    color: #FFF;
    text-align: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #4326F4;
    font-family: Roboto, sans-serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .accent {
    color: #2B2A2A;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .slogan {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .landing_welcome .footer__content_older-men .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Roboto, sans-serif;
    font-size: 5rem;
    font-weight: 200;
    line-height: 1;
    font-variant: none;
    text-transform: capitalize;
  }
  .landing_welcome .footer__content_older-men .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .heading .accent {
    color: #FFF;
    text-shadow: 0 1.5px 2px #000;
    font-family: inherit;
    font-size: 3.75rem;
    font-weight: 300;
    line-height: 1.75;
    text-transform: lowercase;
    display: block;
  }
  .landing_welcome .footer__content_older-men .subheading {
    color: #FFF;
    font-family: Oswald, sans-serif;
    text-shadow: 0 2px 2px #1A1A1A;
    font-size: 5rem;
    font-weight: 600;
    line-height: 1.35;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men .button_primary {
    width: 390px;
    height: 70px;
    left: 0;
    transform: translate(0, 0);
    font-size: 1.875rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men50 .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 3.125rem;
    font-weight: 300;
    line-height: 1;
    font-variant: none;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 .heading .first {
    display: block;
  }
  .landing_welcome .footer__content_older-men50 .accent {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 3.125rem;
    font-weight: 300;
    line-height: 1;
    text-transform: lowercase;
    display: block;
    padding-right: 0;
  }
  .landing_welcome .footer__content_older-men50 .subheading {
    color: #FFCD29;
    font-size: 5rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    display: block;
  }
  .landing_welcome .footer__content_older-men50 .slogan {
    display: none;
  }
  .landing_welcome .footer__content_ret5 .before {
    color: #312F2F;
    text-align: left;
    font-family: Oswald, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    padding-left: 40px;
  }
  .landing_welcome .footer__content_ret5 .heading {
    color: #FFF;
    text-align: center;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #4326F4;
    font-family: Roboto, sans-serif;
    font-size: 100px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .accent {
    color: #2B2A2A;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .slogan {
    display: block;
  }
}
@media screen and (min-width: 1023px) {
  .landing_welcome .footer__content_older-men .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Roboto, sans-serif;
    font-size: 5rem;
    font-weight: 200;
    line-height: 1;
    font-variant: none;
    text-transform: capitalize;
  }
  .landing_welcome .footer__content_older-men .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .heading .accent {
    color: #FFF;
    text-shadow: 0 1.5px 2px #000;
    font-family: inherit;
    font-size: 3.75rem;
    font-weight: 300;
    line-height: 1.75;
    text-transform: lowercase;
    display: block;
  }
  .landing_welcome .footer__content_older-men .subheading {
    color: #FFF;
    font-family: Oswald, sans-serif;
    text-shadow: 0 2px 2px #1A1A1A;
    font-size: 5rem;
    font-weight: 600;
    line-height: 1.35;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men .button_primary {
    width: 330px;
    height: 70px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.875rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men50 .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 3.125rem;
    font-weight: 300;
    line-height: 1;
    font-variant: none;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 .heading .first {
    display: block;
  }
  .landing_welcome .footer__content_older-men50 .accent {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 3.125rem;
    font-weight: 300;
    line-height: 1;
    text-transform: lowercase;
    display: block;
    padding-right: 0;
  }
  .landing_welcome .footer__content_older-men50 .subheading {
    color: #FFCD29;
    font-size: 5rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    display: block;
  }
  .landing_welcome .footer__content_older-men50 .slogan {
    display: none;
  }
  .landing_welcome .footer__content_ret5 .before {
    color: #312F2F;
    text-align: left;
    font-family: Oswald, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    padding-left: 40px;
  }
  .landing_welcome .footer__content_ret5 .heading {
    text-align: left;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #4326F4;
    font-family: Roboto, sans-serif;
    font-size: 160px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 3.2px;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .accent {
    color: #2B2A2A;
    text-align: left;
    font-family: Roboto, sans-serif;
    font-size: 90px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .slogan {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .landing_welcome .footer__content_ret5 .button_primary {
    width: 390px;
    margin-top: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
  }
  .landing_welcome .footer__content_ret5 .text {
    display: none;
  }
  .landing_welcome .footer__content_ret5 .icon_aws {
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .landing_welcome .footer .container {
    max-width: 100%;
  }
  .landing_welcome .footer__content_mature .footer__text {
    width: 1020px;
  }
  .landing_welcome .footer__content_mature .heading {
    font-size: 10rem;
    margin-bottom: 3rem;
  }
  .landing_welcome .footer__content_mature .subheading {
    width: 640px;
    font-size: 6rem;
  }
  .landing_welcome .footer__content_older-men .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .heading .accent {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men50 .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 3.75rem;
    font-weight: 300;
    line-height: 1;
    font-variant: none;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 .heading .first {
    display: block;
  }
  .landing_welcome .footer__content_older-men50 .accent {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 3.75rem;
    font-weight: 300;
    line-height: 1;
    text-transform: lowercase;
    display: block;
    padding-right: 0;
  }
  .landing_welcome .footer__content_older-men50 .subheading {
    color: #FFCD29;
    font-size: 5rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    display: block;
  }
  .landing_welcome .footer__content_older-men50 .slogan {
    display: none;
  }
  .landing_welcome .footer__content_ret5 .before {
    color: #312F2F;
    text-align: left;
    font-family: Oswald, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    padding-left: 40px;
  }
  .landing_welcome .footer__content_ret5 .heading {
    text-align: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #4326F4;
    font-family: Roboto, sans-serif;
    font-size: 110px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 2.2px;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .accent {
    color: #2B2A2A;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .slogan {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .landing_welcome .footer__content_ret5 .button_primary {
    width: 390px;
    margin-top: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
  }
  .landing_welcome .footer__content_ret5 .icon_aws {
    display: none;
  }
}
@media screen and (min-width: 1280px) {
  .landing_welcome .footer__caption {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  .landing_welcome .footer__caption .text {
    text-align: left;
  }
  .landing_welcome .footer__caption .text-older {
    display: none;
  }
  .landing_welcome .footer__caption .text-ret5 {
    display: block;
  }
  .landing_welcome .footer .page__older-men .section_service .container .section__content p {
    font-size: 16px;
  }
  .landing_welcome .footer__content {
    margin: auto 0;
    padding: 0;
  }
  .landing_welcome .footer__content_asia .footer__text {
    width: 820px;
  }
  .landing_welcome .footer__content_asia .heading {
    font-size: 6rem;
    margin-bottom: 4rem;
  }
  .landing_welcome .footer__content_asia .subheading {
    width: 720px;
    font-size: 4rem;
  }
  .landing_welcome .footer__content_black .footer__text {
    width: 780px;
  }
  .landing_welcome .footer__content_black .heading {
    font-size: 7rem;
    margin-bottom: 3rem;
  }
  .landing_welcome .footer__content_black .subheading {
    font-size: 4rem;
  }
  .landing_welcome .footer__content_black .subheading .is-block {
    text-align: right;
  }
  .landing_welcome .footer__content_older-men {
    margin: auto auto 0 auto;
    padding: 0 0 5% 0;
  }
  .landing_welcome .footer__content_older-men .heading {
    font-size: 5rem;
    font-weight: 200;
    line-height: 1;
    font-variant: none;
    text-transform: capitalize;
  }
  .landing_welcome .footer__content_older-men .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .heading .accent {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .subheading {
    font-size: 4.375rem;
    font-weight: 400;
    line-height: 1;
    text-transform: capitalize;
  }
  .landing_welcome .footer__content_older-men .button_primary {
    min-width: 330px;
    height: 70px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.875rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men50 {
    margin: auto auto 0 0;
    padding: 0 0 5% 0;
  }
  .landing_welcome .footer__content_older-men50 .footer__text {
    padding-left: 15%;
    line-height: 1;
  }
  .landing_welcome .footer__content_older-men50 .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 3.125rem;
    font-weight: 500;
    line-height: 1;
    font-variant: none;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men50 .accent {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 3.125rem;
    font-weight: 500;
    line-height: 1;
    text-transform: capitalize;
    display: inline-block;
    padding-right: 20px;
  }
  .landing_welcome .footer__content_older-men50 .subheading {
    color: #FFCD29;
    font-size: 3.125rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men50 .slogan {
    color: white;
    font-size: 1.25rem;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 1px;
    display: block;
    text-align: left;
    margin-top: 34px;
  }
  .landing_welcome .footer__content_older-men50 .button_primary {
    width: 330px;
    height: 70px;
    margin-top: 34px;
    left: 0;
    transform: translate(0, 0);
    font-size: 1.875rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_older-men50 .icon_aws {
    display: block;
    width: 111px;
    height: 151px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .landing_welcome .footer__content_ret5 {
    margin: auto auto 0 65px;
    padding: 0 0 5% 0;
  }
  .landing_welcome .footer__content_ret5 .before {
    color: #312F2F;
    text-align: left;
    font-family: Oswald, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    padding-left: 40px;
  }
  .landing_welcome .footer__content_ret5 .heading {
    text-align: left;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #4326F4;
    font-family: Roboto, sans-serif;
    font-size: 110px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 2.2px;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .accent {
    color: #2B2A2A;
    text-align: left;
    font-family: Roboto, sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .slogan {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding-top: 100px;
  }
  .landing_welcome .footer__content_ret5 .button_primary {
    margin-top: 34px;
    left: 0;
    transform: translate(0, 0);
    font-size: 1.875rem;
    font-weight: 700;
    text-transform: uppercase;
    width: 323px;
  }
  .landing_welcome .footer__content_ret5 .text {
    display: block;
    color: #4E4E4E;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 34px;
    margin-top: 0;
  }
  .landing_welcome .footer__content_ret5 .icon_aws {
    display: none;
  }
  .landing_welcome .footer__content_mature .footer__text {
    width: 820px;
  }
  .landing_welcome .footer__content_mature .heading {
    font-size: 6rem;
    margin-bottom: 2rem;
  }
  .landing_welcome .footer__content_mature .subheading {
    width: 520px;
    font-size: 4rem;
  }
  .landing_welcome .footer__content_mature .subheading .is-block {
    text-align: right;
  }
  .landing_welcome .footer__caption .text {
    display: inline-block;
  }
  .landing_welcome .footer__caption .text-older {
    display: none;
  }
  .landing_welcome .footer__caption .text-ret5 {
    display: block;
  }
  .landing_welcome .footer__caption .button {
    width: 330px;
  }
}
@media only screen and (max-width: 1279px) {
  .landing_welcome .footer .page__older-men .section_service .container .section__description {
    width: auto;
    font-size: 20px;
    margin: 0;
  }
  .landing_welcome .footer .page__older-men .section_service .container .section__content p {
    font-size: 16px;
  }
  .landing_welcome .footer .section_participate .media__image {
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .landing_welcome .footer .page__older-men .section_service .container .section__content p {
    font-size: 18px;
  }
  .landing_welcome .footer .page__older-men .section_participate .container {
    max-width: 100%;
  }
  .landing_welcome .footer__content_older-men .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Roboto, sans-serif;
    font-size: 5rem;
    font-weight: 200;
    line-height: 1;
    font-variant: none;
    text-transform: capitalize;
  }
  .landing_welcome .footer__content_older-men .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .heading .accent {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .subheading {
    color: #FFF;
    font-family: Roboto, sans-serif;
    font-size: 5rem;
    font-weight: 400;
    line-height: 1;
    text-transform: capitalize;
  }
  .landing_welcome .footer__content_older-men50 {
    margin-left: unset;
  }
  .landing_welcome .footer__content_older-men50 .footer__text {
    padding-left: 15%;
    line-height: 1;
  }
  .landing_welcome .footer__content_older-men50 .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 5rem;
    font-weight: 500;
    line-height: 1;
    font-variant: none;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men50 .accent {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 5rem;
    font-weight: 500;
    line-height: 1;
    text-transform: capitalize;
    display: inline-block;
    padding-right: 20px;
  }
  .landing_welcome .footer__content_older-men50 .subheading {
    color: #FFCD29;
    font-size: 5rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men50 .slogan {
    color: white;
    font-size: 1.875rem;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 1px;
    display: block;
    text-align: left;
  }
  .landing_welcome .footer__content_ret5 {
    margin: auto auto 0 106px;
    padding: 0 0 5% 0;
  }
  .landing_welcome .footer__content_ret5 .before {
    color: #312F2F;
    text-align: left;
    font-family: Oswald, sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    padding-left: 60px;
  }
  .landing_welcome .footer__content_ret5 .heading {
    text-align: left;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #4326F4;
    font-family: Roboto, sans-serif;
    font-size: 160px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 3.2px;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .accent {
    color: #2B2A2A;
    text-align: left;
    font-family: Roboto, sans-serif;
    font-size: 90px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .slogan {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding-top: 100px;
  }
  .landing_welcome .footer__content_ret5 .icon_aws {
    display: block;
    width: 111px;
    height: 151px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
@media screen and (min-width: 1919px) {
  .landing_welcome .footer .page__older-men .section_service .container .section__content p {
    font-size: 24px;
  }
  .landing_welcome .footer__content_older-men .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Roboto, sans-serif;
    font-size: 5rem;
    font-weight: 200;
    line-height: 1;
    font-variant: none;
    text-transform: capitalize;
  }
  .landing_welcome .footer__content_older-men .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .heading .accent {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .subheading {
    color: #FFF;
    font-family: Roboto, sans-serif;
    font-size: 5rem;
    font-weight: 400;
    line-height: 1;
    text-transform: capitalize;
  }
  .landing_welcome .footer__content_older-men50 {
    margin-left: unset;
  }
  .landing_welcome .footer__content_older-men50 .footer__text {
    padding-left: 15%;
  }
  .landing_welcome .footer__content_older-men50 .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 6.25rem;
    font-weight: 500;
    line-height: 1;
    font-variant: none;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men50 .accent {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 6.25rem;
    font-weight: 500;
    line-height: 1;
    text-transform: capitalize;
    display: inline-block;
    padding-right: 20px;
  }
  .landing_welcome .footer__content_older-men50 .subheading {
    color: #FFCD29;
    font-size: 6.25rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men50 .slogan {
    color: white;
    font-size: 2.5rem;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 1px;
    display: block;
    text-align: left;
  }
  .landing_welcome .footer__content_ret5 .before {
    color: #312F2F;
    text-align: left;
    font-family: Oswald, sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    padding-left: 60px;
  }
  .landing_welcome .footer__content_ret5 .heading {
    text-align: left;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #4326F4;
    font-family: Roboto, sans-serif;
    font-size: 160px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 3.2px;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .accent {
    color: #2B2A2A;
    text-align: left;
    font-family: Roboto, sans-serif;
    font-size: 90px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .slogan {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding-top: 100px;
  }
}
@media screen and (min-width: 3440px) {
  .landing_welcome .footer__content_older-men .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Roboto, sans-serif;
    font-size: 6.875rem;
    line-height: 1;
    font-weight: 200;
    font-variant: none;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .heading .accent {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .subheading {
    color: #FFF;
    font-family: Roboto, sans-serif;
    font-size: 6.875rem;
    font-weight: 400;
    line-height: 1;
    text-transform: capitalize;
    text-align: center;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 {
    margin-left: unset;
  }
  .landing_welcome .footer__content_older-men50 .footer__text {
    padding-left: 15%;
    line-height: 1.25;
  }
  .landing_welcome .footer__content_older-men50 .heading {
    color: #FFF;
    text-align: left;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 6.25rem;
    font-weight: 500;
    line-height: 1.2;
    font-variant: none;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men50 .accent {
    color: #FFF;
    text-align: left;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 6.25rem;
    font-weight: 500;
    line-height: 1;
    text-transform: capitalize;
    display: inline-block;
    padding-right: 50px;
  }
  .landing_welcome .footer__content_older-men50 .subheading {
    color: #FFCD29;
    font-size: 6.25rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men50 .slogan {
    color: white;
    font-size: 2.5rem;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 1;
    display: block;
    text-align: left;
  }
  .landing_welcome .footer__content_ret5 {
    margin: auto auto 0 318px;
    padding: 0 0 5% 0;
  }
  .landing_welcome .footer__content_ret5 .before {
    color: #312F2F;
    text-align: left;
    font-family: Oswald, sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    padding-left: 80px;
  }
  .landing_welcome .footer__content_ret5 .heading {
    text-align: left;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: #4326F4;
    font-family: Roboto, sans-serif;
    font-size: 200px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 4px;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .accent {
    text-align: left;
    color: #2B2A2A;
    font-family: Roboto, sans-serif;
    font-size: 110px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .slogan {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding-top: 150px;
  }
}
@media only screen and (min-width: 3840px) {
  .landing_welcome .footer__content_older-men .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Roboto, sans-serif;
    font-size: 10rem;
    font-weight: 200;
    line-height: 1.12;
    font-variant: none;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .heading .accent {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men .subheading {
    color: #FFF;
    font-family: Roboto, sans-serif;
    font-size: 10rem;
    font-weight: 400;
    line-height: 1.12;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 {
    margin-left: unset;
  }
  .landing_welcome .footer__content_older-men50 .footer__text {
    padding-left: 15%;
    line-height: 1.25;
  }
  .landing_welcome .footer__content_older-men50 .heading {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 12.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-variant: none;
    text-transform: capitalize;
    white-space: nowrap;
  }
  .landing_welcome .footer__content_older-men50 .heading .first {
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men50 .accent {
    color: #FFF;
    text-align: center;
    text-shadow: 0 2px 2px #000;
    font-family: Inter, sans-serif;
    font-size: 12.5rem;
    font-weight: 500;
    line-height: 1.2;
    text-transform: capitalize;
    display: inline-block;
    padding-right: 50px;
  }
  .landing_welcome .footer__content_older-men50 .subheading {
    color: #FFCD29;
    font-size: 12.5rem;
    font-family: Inter, sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    display: inline-block;
  }
  .landing_welcome .footer__content_older-men50 .slogan {
    color: white;
    font-size: 5rem;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 1.2px;
    display: block;
    text-align: left;
  }
  .landing_welcome .footer__content_ret5 .before {
    color: #312F2F;
    text-align: left;
    font-family: Oswald, sans-serif;
    font-size: 100px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    padding-left: 90px;
  }
  .landing_welcome .footer__content_ret5 .heading {
    text-align: left;
    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: #4326F4;
    font-family: Roboto, sans-serif;
    font-size: 270px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 5.4px;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .accent {
    color: #2B2A2A;
    font-family: Roboto, sans-serif;
    font-size: 150px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .landing_welcome .footer__content_ret5 .text {
    color: #4E4E4E;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 34px;
    margin-top: 0;
  }
  .landing_welcome .footer__content_ret5 .slogan {
    padding-top: 250px;
  }
}
.contactus__heading {
  text-transform: uppercase;
  color: #131313;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.1;
  text-align: center;
}
.contactus__sub-heading {
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.1;
  padding-bottom: 16px;
  padding-top: 20px;
  color: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
}

.form__contactus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button.button_contactus {
  width: 160px;
}

@media screen and (max-width: 768px) {
  .contactus__heading {
    font-size: 20px;
    text-align: center;
  }
  .contactus__sub-heading {
    font-size: 20px;
    color: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
  }

  .form__contactus {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .button.button_contactus {
    width: 100%;
  }
}
.images {
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
}
.images__list {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}
.images__body {
  position: relative;
  padding: 30px 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}
.images__body::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2.5px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 10;
}
.images__container, .images__actions {
  position: relative;
  z-index: 20;
}
.images__item {
  position: relative;
  width: 33.3%;
  max-height: 110px;
  max-width: 110px;
}
.images__item::after, .images__item::before {
  content: "";
  position: absolute;
  z-index: 25;
}
.images__item::before {
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  background: url(../images/icons/checkmark.svg) no-repeat center;
  background-size: contain;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.images__item::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(11, 11, 11, 0.4);
  transition: opacity 0.25s ease-out;
}
.images__item.is-checked::after, .images__item:hover::after {
  opacity: 0;
}
.images__item.is-checked::before {
  opacity: 1;
}
.images__actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.images__thumbnail {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1280px) {
  .images__item {
    max-height: 145px;
    max-width: 145px;
  }
}
@media screen and (min-width: 1680px) {
  .images__item {
    max-height: 185px;
    max-width: 185px;
  }
}
@media screen and (min-width: 1920px) {
  .images {
    padding: 60px 35px;
  }
  .images__body {
    padding: 40px 80px;
  }
  .images__item::before {
    width: 48px;
    height: 48px;
  }
  .images__actions {
    margin-top: 40px;
  }
}
.badge {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.badge_warning {
  border: 1px solid var(--body-bg);
  background-color: var(--state-warning);
}
.badge_animate {
  animation: pulse 1.5s infinite ease-in-out;
}
.badge_inform {
  width: 22px;
  height: 22px;
  background-image: url(../images/icons/inform.svg);
  background-repeat: no-repeat;
  background-position: center;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.progress {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress circle {
  fill: none;
  stroke-width: 1;
}
.progress__circle {
  transform: rotate(-90deg);
}
.progress__bg {
  stroke: #848484;
  stroke-dasharray: 4, 4;
  /* Пунктирная линия для фона */
}
.progress__line {
  stroke: #848484;
  stroke-dasharray: 113;
  stroke-dashoffset: 113;
  /* Здесь устанавливаем длину dasharray равной длине окружности */
  transition: stroke-dashoffset 0.5s;
}
.progress__label {
  position: absolute;
  font-weight: 500;
  color: #6D6D6D;
}

.record {
  display: flex;
  align-items: center;
}
.record.is-center {
  justify-content: center;
}
.record__circle {
  display: inline-block;
  margin-right: 8px;
  width: 14px;
  height: 14px;
  background-color: #FE5353;
  border-radius: 50%;
  animation: record-pulse 2s infinite ease-in-out;
}
.record__label, .record__text {
  font-size: 12px;
}
.record__label {
  font-weight: 500;
}
.record__text {
  font-weight: 400;
  margin-left: 5px;
}
.record__button {
  display: inline-block;
  padding: 0 10px;
  margin-left: 8px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

@keyframes record-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@media (min-width: 1280px) and (max-width: 1919px) {
  .record__circle {
    width: 10px;
    height: 10px;
  }
}