.footer {
    background-color: initial;
    font-size: toRem(14px);
    color: var(--footer-color);


    .container {
        margin: 0 auto;
    }

    &__menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 12px;

        .menu {

            &__link {
                font-size: toRem(16px);
                font-weight: 700;
                line-height: 1.2;
                color: var(--footer-color);

                &:hover {
                    //color: rgba(255, 255, 255, 0.6);
                }
            }
        }
    }

    &__section {
        display: flex;
        flex-direction: row;
        gap: 80px;
        justify-content: space-between;

        &_menu {
            padding-top: 76px;
            padding-bottom: 56px;
        }

        &_protection {
            display: flex;
            margin: 0 auto;
            justify-content: center;
            gap: 30px;
            flex-wrap: wrap;
            padding: 0 10px 24px 10px;
            align-items: center;

            .icon_aws {
                width: 86px;
                height: 116px;
            }
            .icon_safe-labeling {
                width: 149px;
                height: 53px;
            }
            .icon_dmca {
                width: 122px;
                height: 50px;
            }
            .icon_sectigo {
                width: 149px;
                height: 51px;
            }
        }

        &_copyright {
            padding-top: 30px;
            border-top: 1px solid var(--footer-color);
            margin: 0 auto;

            .menu {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 16px;

                .menu__link {
                    color: var(--footer-color);

                    &:hover {
                        //color: var(--color-white);
                    }
                }
            }
            .flex_copyright{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 30px;
                padding-bottom: 35px;
                gap: 15px;
                flex-wrap: wrap;

                .sectigo_secured_logo{
                    width: 100px;
                    height: 39px;
                }
            }
        }
        .app {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .contacts {
            min-width: 193px;
        }
        .icon_marker,
        .icon_letter-full
        {
           display: block;
            width: 20px;
            height: 20px;
        }

        .email, .city {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
        }
        .city span,
        .text,
        .email a {
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;
        }
        .email a {
            color: var(--footer-color);
            text-decoration: none;
            font-style: normal;
        }
    }

    &__copyright {
        //padding-top: 30px;
        //padding-bottom: 35px;
        font-size: toRem(12px);
        text-align: center;
    }

    &__heading {;
        margin: 0;
        font-size: inherit;
        color: var(--footer-color);
    }

    &__title {
        margin: 0;
        font-size: toRem(16px);
        line-height: 1.2;
        color: var(--footer-color);
    }

    .social {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .social_light {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }
    .social__item {
        display: inline-block;
        width: 30px;
        height: 30px;
    }

    .payment {
        display: flex;
        flex-direction: column;
        min-width: 193px;
        gap: 12px;
    }

    .list_payment {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: baseline;
        gap: 12px 16px;
    }
    .list__item {
        width: 30px;
        height: 30px;
        display: block;
    }
}

@include create-mq($grid__bp-lg - 1, 'max') {
    .footer {
        text-align: center;

        .footer__title {
            display: none;
        }

        .list_partners,
        .footer__menu {
            display: flex;
        }


        .footer__menu,
        .social,
        .list_partners,
        .list_payment {
            justify-content: center;
        }

        .list_payment {
            margin-bottom: 20px;
        }

        .footer__copyright {
            padding-top: 0;
        }

        .footer__icon {
            margin-bottom: 0;
            //margin-right: 10px;
            width: 142px;
        }
    }
}

@media (max-width: 1279px){
    .footer {
        font-size: toRem(16px);

        &__menu {
            flex-direction: row;
            justify-content: center;
            gap: 20px;

            .menu {

                &__link {
                    color: var(--footer-color);

                    &:hover {
                        //color: rgba(255, 255, 255, 0.6);
                    }
                }
            }
        }

        &__section {
            display: flex;
            flex-direction: column;
            gap: 24px;
            justify-content: center;
            align-items: center;

            &_menu {
                padding-top: 36px;
                padding-bottom: 24px;
            }

            &_copyright {
                padding-top: 0;
                border-top: 1px solid #131313;
                margin: 0 auto;

                .menu {
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    padding: 24px 0;
                }
            }
            .app {
                flex-direction: row;
                align-items: center;
                gap: 16px;
                justify-content: center;
                order: 0;
                padding: 0 10px;
            }
            .contacts {
                max-width: 336px;
                text-align: left;
                order: 1;
            }

            .social {
                order: 2;
            }
            .links {
                order: 3;
            }
            .payment {
                order: 4;
            }
            .icon_marker,
            .icon_letter-full
            {
                display: block;
                width: 20px;
                height: 20px;
            }

            .email, .city {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
            }
            .city span,
            .text,
            .email a {
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
            }
            .email a {
                color: var(--footer-color);
                text-decoration: none;
                font-style: normal;
            }
        }

        &__copyright {
            font-size: toRem(12px);
            text-align: center;
        }

        &__heading {
            display: inline;
            margin: 0;
            font-size: inherit;
            color: var(--footer-color);
        }

        &__title {
            display: none;
        }

        .social {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
        }

        .social_light {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
        }
        .social__item {
            display: inline-block;
            width: 30px;
            height: 30px;
        }

        .payment {
            display: flex;
            flex-direction: column;
            min-width: 193px;
            gap: 12px;
        }

        .list_payment {
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0 16px;
        }
        .list__item {
            width: 30px;
            height: 30px;
            display: block;
        }
    }

    @include create-mq($grid__bp-lg - 1, 'max') {
        .footer {
            text-align: center;

            .footer__title {
                display: none;
            }

            .list_partners,
            .footer__menu {
                display: flex;
            }


            .footer__menu,
            .social,
            .list_partners,
            .list_payment {
                justify-content: center;
            }

            .list_payment {
                margin-bottom: 20px;
            }

            .footer__copyright {
                padding-top: 0;
            }

            .footer__icon {
                margin-bottom: 0;
                //margin-right: 10px;
                width: 142px;
            }
        }
    }
}
