.upload {
    position: relative;

    &__preview {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__label {
        display: inline-block;
        cursor: pointer;

        &_abs {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
        }

        &_image, &_video {
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        &_image {
            width: 80px;
            height: 80px;
            background-image: url(../images/icons/big-camera.svg);

        }

        &_video {
            position: relative;

            .label__icon {
                margin-right: 0;
                border: 2px solid var(--light-gray);
                border-radius: 50%;
                height: 3rem;
                width: 3rem;
                align-items: center;
                display: flex;
                justify-content: center;
            }
        }
    }

    &__warning {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        margin-bottom: 5px;
        text-align: center;
        color: var(--state-danger)
    }

    &__section {
        position: relative;
        display: block;
        padding: 15px;
        border: 1px solid #C5C5C5;
        border-radius: 5px;
        text-align: center;
    }

    &__button {
        width: auto;
        position: relative;
        z-index: -1;
    }

    &__delete {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        z-index: 10;

        &.button {
            width: 30px;
        }
    }

    &__col {
        display: flex;

        .upload__preview {
            padding: 0 10px;
        }

        .title {
            margin-bottom: 10px;
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .upload {
        &__label {
            &_image {
                width: 60px;
                height: 60px;
            }
        }
    }
}
