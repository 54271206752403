.present-block {
    display: flex;
    align-items: center;
    gap: 5px;

    .button__icon {
        width: 24px;
        height: 24px;
    }

    .button_gradient {
        width: 40px;
        height: 40px;
        padding: 3px;

        .button__border {
            width: 40px;
            height: 40px;
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {

    .present-block {
        .button_bordered {
            width: 52px;
            height: 30px;
            border-radius: 40px;

            span {
                padding: 0;
                border-radius: 40px;
            }
        }


        .button__icon {
            width: 16px;
            height: 16px;
        }
    }

}
