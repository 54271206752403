.profile {
    &__card {
        margin-bottom: 30px;
        background: var(--nav-bg);
        box-shadow: 0 10px 25px rgba(126, 126, 126, 0.08);
        border-radius: 10px;
    }

    &__text {
        p:last-child {
            margin: 0;
            word-break: break-word;
        }
    }

    &__title {
        margin-top: 10px;
        margin-bottom: 18px;
        font-size: toRem(20px);
        color: var(--profile-title);
        text-align: center;
    }

    &__about {
        .profile__card {
            padding: 10px;
        }
    }

    &__portfolio {
        margin-bottom: 30px;
    }

    &__promo, &__stories {
        .profile__card {
            border-radius: 0 0 10px 10px;
        }
    }

    &__promo {
        position: relative;

        .status_online {
            position: absolute;
            bottom: 20px;
            left: 20px;
            color: var(--color-white);
            z-index: 10;
        }
    }

    &__promo-header, &__promo-footer {
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 10;
    }

    &__promo-header {
        top: 0;
        padding: 15px 20px 10px;
        border-radius: 10px 10px 0 0;
        color: var(--color-white);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.81) 0%, rgba(9, 9, 9, 0.42) 57.84%, rgba(19, 19, 19, 0.00) 100%);

        .person_card {
            align-items: flex-start;

            .person__name, .person__age {
                font-size: toRem(25px);
            }

            .person__location {
                margin-top: 0;
            }
        }
    }

    &__heading {
        position: relative;
        text-align: center;
        .button_edit {
            position: absolute;
            top: -10px;
            right: 0;

            .badge {
                position: absolute;
                top: -15px;
                left: -15px;
                z-index: 10;
            }
        }
    }

    &__stories {
        .profile__card {
            padding: 15px;
        }
    }

    &__stories-list {
        .list__item:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__avatar, &__upload {
        background: var(--card-bg);
    }

    &__avatar {

        &, .avatar, .upload__media {
            border-radius: 50%;
        }

        .upload {
            width: 100px;
            height: 100px;
            overflow: hidden;


            &__label {
                width: 40px;
                height: 40px;
            }
        }

        .status {
            position: absolute;
            top: 15px;
            right: -25px;
            z-index: 10;
        }
    }

    &__upload {
        border-radius: toRem(10px);
    }

    &__credits {
        position: relative;
        padding: 15px 20px 15px 120px;
        background: rgba(19, 19, 19, 0.8);
        backdrop-filter: blur(15px);
        border-radius: 10px;
        .profile__icon {
            position: absolute;
            top: 20px;
            left: 20px;
        }
    }

    &__credits-balance {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        font-size: toRem(14px);
        .label {
            display: inline-block;
            padding: 3px 15px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 5px;
            color: var(--color-white)
        }
    }

    &__credits-value {
        font-size: toRem(32px);
        font-weight: 700;
        color: var(--color-white);
    }
}

.profile_account {

    .description_personal {
        .description__value {
            opacity: .25;
        }
    }

    .profile__card {

        .profile__text-preview {
            display: flex;
            align-items: center;
            text-align: left;

            .svg-icon {
                display: inline-block;
                margin-right: 10px;
            }
        }
    }


    .profile__stories {
        position: relative;
        .profile__add-stories {
            position: absolute;
            top: -5px;
            right: 0;
        }
        .slider_portfolio {
            margin: 20px 0;
        }
    }
}

.profile_mobile {

    .profile__promo {
        position: relative;
        margin-bottom: 0;
        z-index: 10;

        &, .profile__caption {
            border-radius: 0 0 20px 20px;
        }

        .profile__caption {
            .person_card {
                .status {
                    top: 30px;
                    left: 25px;
                    bottom: auto;
                }
            }
        }
    }

    .profile__title {
        font-size: toRem(18px);
    }

    .profile__text, .post__overlay-content,
    .post__content, .post__content .button_link {
        font-size: toRem(14px);
    }

    .button_bookmark {
        font-size: toRem(18px);
    }

    .post__overlay-content .button, .post_stories .post__form-title {
        font-size: toRem(16px);
    }
   /* .profile__caption {
        padding: toRem(20px) toRem(25px) toRem(60px);
    }*/

    .profile__card {
        border-radius: 20px;
    }

    .post_stories .post__meta-data {
        font-size: toRem(12px);
    }

    .post_stories .post__heading {
        font-size: toRem(26px);
    }

    .post__input {
        .post__textarea {
            min-height: 55px;
            padding: 16px 90px 2px 30px;
        }
        .form__buttons {
            top: 10px
        }
    }

    .profile__about {

        .profile__card {
            padding: 16px 25px 25px;
            text-align: center;

            &:first-of-type {
                margin-top: -40px;
                padding-top: 60px;
            }

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }
           /* .profile__title {
                text-align: left;
            }*/
        }
    }



    .list_hobbies {
        display: grid;
        grid-template-columns: repeat(auto-fill, 68px);
        grid-gap: 7px;
    }

    .profile__portfolio {
        margin-bottom: 10px;
    }

    &:not(.profile_account) {
        .profile__stories {
            .button_bookmark {
                display: block;
                margin: auto;
            }
        }
    }

    &.profile_account {

        .profile__title {
            text-align: left;
        }

        .button_theme {
            padding-left: 10px;
            padding-right: 10px;
            .button__icon {
                margin-right: 0;
            }
        }

        .person {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 20px;
            &__info {
                display: block;
            }
            &__section {
                max-width: 100%;
                flex: 0 0 100%;
                margin-top: 10px;
            }
        }

        .profile__credits {
            text-align: right;
            margin-bottom: 20px;
        }

        .profile__credits-balance {
            justify-content: flex-end;
            .text {
                margin-right: 10px;
            }
        }

        .profile__text {
            text-align: left;
        }

        .profile__text-preview {
            text-align: left;
            display: flex;
            align-items: center;
            .svg-icon {
                display: inline-block;
                margin: 0 10px 0 0;
            }
        }

        .profile__about > .profile__card:first-of-type {
            position: relative;
            padding-top: 60px;
            z-index: 10;
        }

        .profile__avatar {
            position: absolute;
            top: -40px;
            left: 20px;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            z-index: 10;
        }
    }
}

@include create-mq($grid__bp-xl, "min") {
    .profile {
        display: flex;
        margin-top: 8px;

        &__container {
            flex: 0 0 565px;
            max-width: 565px;
        }

        &__avatar {
            position: absolute;
            bottom: -50px;
            left: 30px;
            z-index: 10;

            .avatar {
                border: 4px solid var(--body-bg);
            }
        }

        &__avatar_centered {
            left: 50%;
            transform: translateX(-50%);
        }

        &__promo, &__card_promo {

            .button_rounded {
                margin: 0 0 0 auto;
                flex: 0 0 140px
            }
        }

        &__card {

            &_promo {
                padding: 20px 10px;
                display: flex;
                align-items: center;

                p {
                    margin-bottom: 0;
                    word-break: break-all;
                }

                .profile__title {
                    position: relative;
                    max-width: 160px;
                    flex: 0 0 160px;
                    padding-right: 10px;
                    margin: 0 10px 0 0;
                    text-align: left;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        width: 2px;
                        border-radius: 5px;
                        background: rgba(197, 197, 197, .3);
                    }
                }
            }
        }

        &__promo {
            margin-bottom: 50px;
        }

        &__about {
            position: relative;
            display: flex;
            gap: 30px;

            .profile__card {
                flex: 1;
            }
        }

        &__card-wrapper {
            &.is-open {
                position: absolute;
                top: -5px;
                left: 0;
                width: 100%;
                padding: 24px 40px 35px;
                background: var(--nav-bg);
                box-shadow: 0 10px 25px rgba(126, 126, 126, 0.08);
                border-radius: 5px;
                z-index: 100;

                .button.is-collapsed {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                }

                .button_edit {
                    top: 0;
                    right: 60px;
                }

                .profile__title {
                    text-align: left;
                }

                .description_personal {
                    columns: 3
                }
            }
        }

        &__stories-list {
            .list__item:not(:last-child) {
                margin-bottom: 50px;
            }
        }

        &__stories {
            .profile__card {
                padding: 30px;
            }

            .button_bookmark {
                display: block;
                margin: auto;
            }
        }

    }
}
@include create-mq($grid__bp-4xl, "min") {
    .profile {

        &__container {
            flex: 0 0 870px;
            max-width: 870px;
        }

        &__promo {
            .profile__card {
                padding: 20px;
            }
            .profile__title {
                max-width: 160px;
                flex: 0 0 160px;
                padding-right: 20px;
                margin: 0 20px 0 0;
            }
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .profile {

        &__promo-header {

            .person_card {

                .person__name, .person__age {
                    font-size: toRemSm(20px);
                }

            }
        }

        &__avatar {
            .avatar_xl, .upload {
                width: 70px;
                height: 70px;
            }
        }

        &__title {
            font-size: toRemSm(12px);
        }

        &__promo .button_rounded, &__card_promo .button_rounded {
            max-width: 100px;
            flex: 0 0 100px;
        }

        &__card {
            padding: 10px;
            font-size: toRemSm(10px);

            &_promo {
                .profile__title {
                    max-width: 130px;
                    flex: 0 0 130px;
                }
            }
        }

        &__portfolio {
            margin-bottom: 20px;
        }

        &__credits {
            padding: 10px 15px 10px 90px;

            .profile__icon {
                top: 10px;
                left: 10px;
            }
        }

        &__credits-balance {
            font-size: toRemSm(12px);
            .text {
                margin-right: 20px;
            }
        }

        .list_hobbies {

            .icon-box {
                width: 44px;
                height: 44px;

                .icon {
                    width: 22px;
                    height: 22px;
                }

                &__title {
                    font-size: toRemSm(8px);
                }
            }
        }

        &__credits-value {
            font-size: toRemSm(20px);
        }

        &__card-wrapper.is-open {
            padding: 20px 20px 25px;
        }

        &_account {

            .profile__promo {
                .profile__card {
                    .button {
                        flex: 0 0 100px
                    }
                }
            }
        }
    }
}
