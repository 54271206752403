.gallery {

    &__stop {
        position: absolute;
        visibility: hidden;
        height: 500px;

        &#gallery_top {
            top: 0
        }

        &#gallery_end {
            bottom: 0
        }
    }
}

@media (min-width: 576px) and (max-width: 1278px) {
    .gallery {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .card_gallery {
            height: 100%;
        }
    }
}
