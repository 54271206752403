.card {
    &__col {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__icon {
        display: inline-block;
        position: absolute;
        z-index: 10;
        line-height: 1;
        top: 10px;

        &_new {
            //top: 0;
            left: 20px;
        }

        &_view {
            //width: toRem(30px);
            //height: toRem(30px);
            padding: 5px;
            //top: toRem(12px);
            right: 20px;
            //background: rgba(19, 19, 19, 0.1);
            background: rgba(19, 19, 19, 0.3);
            border-radius: 2px;
            display: flex;
            align-items: center;
        }
    }

    &:not(.card_single) {
        .card__title {
            @extend .text-truncate;
        }
    }

    &_member {
        background: var(--body-bg);
        box-shadow: 0 10px 25px rgba(126, 126, 126, 0.08);
        border-radius: 10px;

        .card__meta {
            display: flex;
            justify-content: space-between;
        }

        .card__body {
            padding: 10px 25px 25px;
        }

        .card__title {
            margin-bottom: 20px;
            margin-top: 20px;
            font-size: toRem(18px);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .card__buttons {
            margin: 0 0 0 auto;

            .button + .button {
                margin-left: 10px;
            }
        }

        .person {
            &__name,
            &__age {
                display: block;
            }

            &_age {
                font-size: toRem(12px);
                color: rgba(126, 126, 126, 0.8);
            }

            &__avatar {
                margin-top: -35px;
                margin-right: 12px;
                position: relative;
                z-index: 12;
            }

            &__name {
                margin-bottom: 5px;
                max-width: 130px;
            }
        }

        @include create-mq($grid__bp-md - 1, "max") {
            .button_more {
                font-size: toRem(14px);
            }
            .button_social,
            .person__date {
                font-size: toRem(12px);
            }
        }
        @include create-mq($grid__bp-md, "min") {
            .card__body {
                padding: 13px 10px 20px;
            }
            .card__title {
                font-size: toRem(20px);
            }
        }
        @include create-mq($grid__bp-xl, "min") {
            .card__body {
                padding: 13px 20px 30px;
            }
        }
    }

    &_gallery {
        position: relative;

        .card__media, .card__holder {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;

            &_holder {
                object-fit: contain;
            }
        }

        &:not(.card_mobile) {

            .card__caption {
                padding: 58px 27px 24px;
            }

            .card__media, .card__holder {
                border-radius: 10px;
            }
        }

        .person_card {

            .status {
                position: absolute;
                top: -14px;
                left: 0;
                z-index: 1;
                font-size: toRem(14px);
                background: rgba(0, 0, 0, 0.35);
            }
        }

        .card__footer {
            margin-top: 10px;
            margin-bottom: 34px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 26px;
        }

        .card__photo {
            position: relative;
            overflow: hidden;
            padding-bottom: 71%;

            .card__media, .card__holder {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;

            }
        }

        &.card_mobile {
            .card__caption {
                padding: 45% 20px 24px;
            }

            .person_card {

                .status {
                    top: -24px;
                }

                .person__name, .person__age {
                    font-size: toRem(20px);
                }

                .person__info .person__location {
                    font-size: 1rem;
                }
            }

        }

    }


    &_single {
        .card__buttons {
            text-align: right;
        }

        .poster {
            &::after {
                padding-bottom: 46.75%;
            }

            &__media {
                border-radius: 10px 10px 0 0;
            }
        }
    }

    &.is-blocked {
        overflow: hidden;
        * {
            pointer-events: none;
        }

        .card__media {
            filter: blur(10px);
        }
    }

    &_personal {

        .card__title {

        }

        .card__content {
            p {
                margin: 0;
            }
        }
    }

}


@media (hover: hover) {
    .card_person {
        transition: box-shadow 0.35s ease-in-out;

        &:hover {
            box-shadow: 0 5px 15px rgba(126, 126, 126, 0.08);
        }
    }
}
@include create-mq($grid__bp-xl - 1, "max") {
    .card {
        &_gallery {
            .poster {
                &__media {
                    border-radius: 0;
                }
            }
        }
    }
}
@include create-mq($grid__bp-xl, "min") {
    .card {
        &_gallery {
            &:not(.card_mobile) {

                .card__caption {
                    padding: 8px 20px 20px;
                    border-radius: 0 0 10px 10px;
                }

                .button_favorite {
                    width: 85px;
                    height: 28px;
                    font-size: toRemSm(10px);
                }
            }

            .person_card {
                .status {
                    top: -20px;
                    font-size: toRemSm(12px);
                }
            }
        }
    }
}
@include create-mq($grid__bp-3xl, "min") {
    .card {
        &_gallery {
            &:not(.card_mobile) {
                .card__caption {
                    padding: 8px 30px 30px;
                }
            }
        }
    }
}

@include create-mq($grid__bp-4xl, "min") {
    .card {
        &_gallery {
            &:not(.card_mobile) {

                .card__caption {
                    padding: 8px 32px 30px;
                }

                .button_favorite {
                    width: 130px;
                    height: 38px;
                    font-size: toRem(16px);
                }

                .person_card {
                    .status {
                        font-size: toRem(14px);
                    }
                }
            }
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px)  {
    .card {
        &_gallery:not(.card_mobile) {
            .icon_new {
                width: 32px;
                height: 23px;
            }
        }
    }
}
