.ui-select {
    position: relative;
    background-color: var(--nav-bg);
    border: 1px solid var(--button-color3);
    border-radius: 10px;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 1 7 7 7-7' stroke='%23C5C5C5' stroke-width='1.3'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
    padding-left: 1rem;
    padding-right: 2rem;
    height: 40px;
    font-size: 1rem;
    line-height: 1.1;
    color: var(--light-gray);

    &::placeholder {
        color: var(--light-gray);
    }
}

@media (hover: hover) {
    .ui-select,
    .custom-select {
        cursor: pointer;
    }
}
