.button {
    margin: 0;
    display: inline-block;
    background-color: transparent;
    box-shadow: none;
    border: none;
    vertical-align: top;
    touch-action: manipulation;
    appearance: none;
    user-select: none;
    font-family: var(--font-base);
    font-size: var(--font-size);
    font-weight: 600;
    backface-visibility: hidden;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;

    &:disabled,
    &.disabled {
        box-shadow: none;
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }


    &__icon {
        position: relative;

        &:not(.icon) {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &__border {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    &_xs {
        height: 38px;

        &:not(.button_bordered), &.button_bordered > span {
            padding: 10px 12px;
        }

        &.button_bordered {
            > span {
                padding: 10px 12px;
            }
        }
    }


    &_sm {
        height: 44px;

        &:not(.button_bordered), &.button_bordered > span {
            padding-right: 25px;
            padding-left: 25px;
            line-height: 42px;
        }
    }

    &_md {
        height: 48px;

        &:not(.button_bordered), &.button_bordered > span {
            padding-right: 50px;
            padding-left: 50px;
            line-height: 44px;
        }
    }

    &_lg {
        height: 70px;

        &:not(.button_bordered), &.button_bordered > span {
            padding: 13px 50px;
            font-size: toRem(30px);
        }
    }

    &_light,
    &_primary,
    &_top,
    &_bordered,
    &_border,
    &_white,
    &_payment {
        transition: box-shadow var(--transition-duration) var(--transition-easing);

        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        }

        &:active {
            box-shadow: none;
        }
    }

    &_light,
    &_primary {
        text-transform: uppercase;
    }


    &_light, &_white {
        background: var(--body-bg);
        color: var(--button-color1);
    }

    &_white {
        box-shadow: 0 10px 20px rgba(19, 19, 19, 0.2);

        > span {
            text-transform: none;
            @extend .text-gradient;
            display: inline-block;
        }
    }

    &_link {
        @extend .text-gradient;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;
        transition: 0.35s opacity ease;
        white-space: nowrap;

        &:hover {
            opacity: 0.85;
        }
    }

    &_social {
        height: toRem(34px);
        line-height: toRem(32px);
        text-transform: capitalize;
        background: #1877f2;
        padding: 0 toRem(16px);
        font-weight: 700;
        font-size: toRem(14px);
        color: var(--color-white);
        transition: opacity 0.35s ease;

        &:hover {
            opacity: 0.85;

            .icon_like {
                animation: swing 1s ease-in-out;
            }
        }
    }

    &_primary {
        position: relative;
        overflow: hidden;
        background: var(--gradient);
        text-transform: uppercase;
        color: var(--color-white);

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            background: var(--gradient-reverse);
            transition: opacity 1s var(--transition-easing);
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }

        span:not(.tooltip) {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            z-index: 1;
        }
    }

    &_full {
        background-color: var(--body-bg);
        transition: background-color var(--transition-duration) var(--transition-easing);

        span {
            @extend .text-gradient;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.56);
        }
    }

    &_filled {
        padding: toRem(4px);
        background: rgba(255, 255, 255, .6);
        border-radius: 5px;
    }

    &_danger {
        border: 1px solid var(--state-danger);
        color: var(--state-danger);
        white-space: nowrap;
    }

    &_favorite {
        .button__icon {
            width: toRem(24px);
            height: toRem(24px);
            background-image: url(../images/icons/favorite.svg);
        }

        &.is-active {
            .button__icon {
                background-image: url(../images/icons/favorite-check.svg);
            }
        }

        &.button_round {
            width: toRem(50px);
            height: toRem(50px);
            padding: 4px;

            &:active, &.is-active {
                background-color: var(--body-bg);
            }
        }
    }

    &_gradient {
        position: relative;
        background-color: transparent;
        background-clip: content-box, border-box;
        background-origin: border-box;
        transition: background-color var(--transition-duration) var(--transition-easing);

        &.button_round {

            > span {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
        }
    }

    &_rounded, &_rounded.button_bordered > span {
        border-radius: toRem(30px);
    }

    &_round {
        border-radius: 50%;

        &.button_primary {
            &::after {
                border-radius: 50%;
            }
        }

        &.button_bordered {
            span {
                border-radius: 50%;
            }
        }
    }


    &_bordered {
        background: var(--gradient);
        padding: 1px;

        > span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            gap: 5px;
            background: var(--body-bg);
            border-radius: 4px;

            > span {
                @extend .text-gradient;
            }
        }
    }

    &_round_payment {
        margin-left: -27px;
        height: 55px;
        width: 55px;
    }

    &_bg {
        padding: 5px;
        background: rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(3px);
    }


    &_border {
        background: var(--nav-bg);
        border: 1px solid rgba(197, 197, 197, 0.4);

        > span {
            @extend .text-gradient;
        }
    }

    &_payment {
        padding: toRem(10px) toRem(12px);
        background: var(--nav-bg);
        border-radius: 10px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    }

    &_icon,
    &_letter,
    &_options,
    &_mute,
    &_collapse,
    &_like,
    &_emoji,
    &_attachment,
    &_play,
    &_edit,
    &_back,
    &_video-play {
        padding: 0;
        line-height: 0;
    }
}

.button_back {
    width: 35px;
    height: 35px;
    padding: 10px;
    color: var(--button-color1);
}

.button_menu {
    padding: 10px;

    > span:not(.tooltip) {
        display: block;
        position: relative;
        width: 20px;
        height: 2px;

        &,
        &::after,
        &::before {
            background-color: var(--button-color1);
            border-radius: 2px;
        }

        &::after,
        &::before {
            content: "";
            position: absolute;
            right: 0;
            width: 100%;
            height: 2px;
            transition: top var(--transition-duration) var(--transition-easing);
        }

        &::before {
            top: -5px;
            width: 80%;
        }

        &::after {
            top: 5px;
            width: 60%;
        }
    }

    &:hover {
        > span {
            &::before {
                top: -7px;
            }

            &::after {
                top: 7px;
            }
        }
    }
}

.button_options {
    position: relative;
    width: 20px;
    height: 24px;
    line-height: 0;
    text-align: left;

    > span {
        &,
        &::after,
        &::before {
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            margin-top: 2px;
            background-color: var(--button-color2);
        }

        &::after,
        &::before {
            content: '';
            position: absolute;
            left: 0;
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }
    }
}

.button_scroll {
    display: block;
    position: relative;
    padding-top: 50px;
    width: 2px;
    background: rgba(255, 255, 255, 0.35);
    color: var(--color-white);
    font-size: toRem(12px);
    font-weight: 400;
    line-height: toRem(40px);
    text-transform: none;
    text-align: center;
    transition: opacity 0.25s ease-in;

    &:hover {
        opacity: 0.85;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 1px;
        left: 50%;
        margin-left: -1px;
        width: 2px;
        height: 30px;
        background: var(--color-white);
        animation: elasticus 3s cubic-bezier(0, 0, 0.35, 0.1) infinite;
    }

    .button__text {
        position: absolute;
        top: -40px;
        left: -15px;
    }

    .button__arrow {
        display: block;
        margin: 0 0 auto -5px;
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: 0;
        left: 50%;

        &::after {
            content: "";
            display: block;
            margin: 0;
            padding: 0;
            width: 10px;
            height: 10px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(135deg);
        }
    }
}

.button_mute {
    position: relative;
    color: var(--color-white);
    transition: opacity var(--transition-duration) var(--transition-easing);

    &::after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--state-danger);
        transform: translateY(-50%) rotate(-45deg);
    }

    .button__icon {
        width: 32px;
        height: 32px;
    }

    &.is-muted {
        opacity: 0.56;

        &::after {
            content: "";
        }
    }
}

.button_top {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    line-height: 28px;
    border: 0;
    border-radius: 50%;
    background-image: var(--gradient);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: visibility var(--transition-duration) var(--transition-easing),
    opacity var(--transition-duration) var(--transition-easing);
    z-index: 100;
    color: #fff;

    .button__icon {
        width: 24px;
        height: 24px;
    }

    &.is-visible {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }
}

.button_collapse {
    display: block;
    width: 70px;
    height: 35px;
    margin: auto;
    border: 1px solid rgba(234, 234, 234, .7);
    border-radius: 5px;
    color: var(--color-text);

    .button__icon {
        display: block;
        margin: auto;
    }

    &.is-collapsed {

        .button__icon {
            rotate: 180deg;
        }
    }
}

.button_bookmark {
    height: 60px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: toRem(20px);
    font-weight: 500;
    background: var(--button-bg1);
    border-radius: 5px 5px 0 0;
    cursor: default;
    color: var(--profile-title);

    .button__icon {
        margin-right: 5px;
    }
}

.button_like {
    width: 24px;
    height: 24px;
    background-image: url(../images/icons/heart.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: default;

    &.is-active {
        background-image: url(../images/icons/heart-hover.svg);
    }
}

.button_letter {
    width: 60px;
    height: 60px;
    border-radius: 20px;
    display: flex;
    background: var(--button-bg2);
    box-shadow: 0 10px 25px rgba(136, 136, 136, 0.25);

    .button__icon {
        margin: auto;
    }
}

.button_chat {
    span {
        text-transform: none;
    }
}

.button_close {
    @extend .link;
}

.button_video-play {
    position: relative;

    .button__icon {
        border: 2px solid var(--color-white);
        border-radius: 50%;
        height: 3rem;
        width: 3rem;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

.button_audio-play {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--button-bg3);
    cursor: pointer;
    overflow: hidden;
    color: var(--state-success);

    &.is-paid {
        color: var(--state-danger);
    }

    .button__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &_stop {
            display: inline-block;
            width: 18px;
            height: 18px;
            border-radius: 2px;
            background: #FF4A4A;
        }
    }

    &.is-play {
        .button__icon_pause {
            display: none;
        }
    }

    &.is-pause {
        .button__icon_play {
            display: none;
        }
    }

    &.is-stop {
        .button__icon_play, .button__icon_pause {
            display: none;
        }
    }
}

.button_sound {
    padding: 0 6px;
    height: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .button__icon {
        width: 15px;
        height: 13px;
        margin: 0;
        flex: 0 0 15px;
    }

    .button__circle {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: var(--gradient);
    }

    .button__wave {
        display: none;
        align-content: center;
        gap: 3px;
        margin-left: 3px;
    }

    &.is-animated {
        .button__wave {
            display: flex;
        }

        .button__circle {
            animation: wave-animation 2s infinite ease-out;
            opacity: 0;

            &:nth-child(2) {
                animation-delay: .65s;
            }
            &:nth-child(3) {
                animation-delay: 1.25s;
            }
        }
    }

    @include create-mq($grid__bp-xl, 'min') {
        height: 23px;

        .button__icon {
            width: 20px;
            height: 17px;
            flex: 0 0 20px;
        }
    }
}

@keyframes wave-animation {
    0% {
        transform: scale(0);
        opacity: 0.85;
    }
    50% {
        transform: scale(0.9);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}


.button_edit {
    padding: 5px;
    background: rgba(197, 197, 197, 0.4);
    border-radius: 5px;
}

.button_presentation {
    position: relative;

    .button__icon {
        position: absolute;
        right: -4px;
        top: -4px;
        padding: 1px;
        width: 50px;
        height: 50px;
        background: var(--gradient);
        z-index: 10;

        &, span {
            border-radius: 50%;
        }

        span {
            width: 100%;
            height: 100%;
            display: flex;
            background: var(--body-bg);

            .icon {
                margin: auto;
            }
        }
    }
}

.button_attachment {
    input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        margin: 0;
        direction: ltr;
        cursor: pointer;
        opacity: 0;
    }
}

.button_send, .button_attachment {
    transition: none;
}

@keyframes elasticus {
    0% {
        transform: translate3d(0, -20px, 0) scaleY(2);
        opacity: 0;
    }
    50% {
        transform: translate3d(0, 10px, 0) scaleY(0.5);
        opacity: 1;
    }
    100% {
        transform: translate3d(0, 0, 0) scaleY(1);
        opacity: 0;
    }
}

@media (hover: hover) {
    .button {
        &:not(.button_like):not(.button_bookmark):not(.button_bg):hover {
            cursor: pointer;
        }

        &_favorite.button_round {
            &:hover {
                background-color: var(--body-bg);
            }
        }
    }
}

@keyframes swing {
    20% {
        transform: rotate3d(0, 0, 1, 10deg);
    }
    40% {
        transform: rotate3d(0, 0, 1, -10deg);
    }
    60% {
        transform: rotate3d(0, 0, 1, 5deg);
    }
    80% {
        transform: rotate3d(0, 0, 1, -5deg);
    }
    100% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
   .button {

       &_favorite {
           &.button_round {
               padding: 2px;

               &, .button__border {
                 width: 30px;
                 height: 30px;
               }

               .button__icon {
                   width: 16px;
                   height: 16px;
               }
           }
       }

       &_letter {
           width: 34px;
           height: 34px;
       }

       &_collapse {
           width: 45px;
           height: 25px;
       }

       &_edit {
           padding: 2px;
       }

       &_options {
           position: relative;
           width: 18px;
           height: 18px;
       }

       &_bookmark {
           height: 30px;
           padding-left: 15px;
           padding-right: 15px;
           font-size: toRemSm(12px);

           .icon {
               width: 14px;
               height: 14px;
           }
       }

       &_like {
           width: 18px;
           height: 18px;
       }

       &_presentation {
           .button__icon {
               width: 34px;
               height: 34px;
           }
       }

       &_delete {
           top: 10px;
           right: 10px;
       }


       &_sm:not(.price__button) {
            height: 30px;

            &:not(.button_bordered), &.button_bordered > span {
                padding-right: 15px;
                padding-left: 15px;
                line-height: 28px;
                font-size: 12px;
            }
        }

        &_md {
            height: 38px;

            &:not(.button_bordered), &.button_bordered > span {
                padding-right: 25px;
                padding-left: 25px;
                line-height: 36px;
            }
        }

        &_lg {
            height: 50px;

            &:not(.button_bordered), &.button_bordered > span {
                padding: 8px 25px;
                font-size: toRemSm(20px);
            }
        }
    }
}
