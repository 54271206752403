//.animation {
//    position: relative;
//
//    &__arrow {
//        position: relative;
//        display: block;
//        background-image: url(../images/landing/arrow-animated.svg);
//        background-repeat: no-repeat;
//        width: 250px;
//        height: 40px;
//    }
//
//    &__wrapper {
//        position: absolute;
//        top: 0;
//        right: 0;
//        margin-top: -50px;
//        width: 350px;
//        height: 150px;
//        background-color: var(--color-white);
//        will-change: transform;
//        transform: rotate(10deg);
//        z-index: 10;
//    }
//
//    &_2 {
//        .animation__arrow {
//            transform: matrix(1, 0, 0, -1, 0, 0);
//        }
//    }
//
//    &__coin,
//    &__image {
//        position: absolute;
//        background-repeat: no-repeat;
//        background-size: contain;
//    }
//
//    &__image {
//        top: 100%;
//        opacity: 0;
//
//        &:nth-child(2),
//        &:nth-child(3),
//        &:nth-child(4) {
//            transform: translateY(50px);
//            transition: opacity 0.35s ease-in-out, transform 0.65s ease-in-out 0.35s;
//        }
//
//        &:nth-child(1) {
//            left: -100px;
//            margin-top: 220px;
//            width: 463px;
//            height: 248px;
//
//            transition: opacity 0.45s ease-in-out;
//            z-index: 20;
//        }
//
//        &:nth-child(2) {
//            margin-top: -10px;
//            left: 0;
//            width: 397px;
//            height: 334px;
//            z-index: 15;
//        }
//
//        &:nth-child(3) {
//            right: -20px;
//            width: 402px;
//            height: 329px;
//            z-index: 10;
//        }
//
//        &:nth-child(4) {
//            right: -20px;
//            margin-top: 15px;
//            width: 226px;
//            height: 312px;
//            z-index: 5;
//        }
//    }
//
//    &__coins {
//        position: absolute;
//        top: -420px;
//        width: 280px;
//        height: 340px;
//        z-index: 4;
//
//        &_left {
//            left: -20px;
//        }
//
//        &_right {
//            right: -20px;
//        }
//    }
//
//    &__coin {
//        opacity: 0;
//        transform: translateY(0%);
//
//        &:nth-child(1) {
//            top: 5px;
//            left: 60px;
//            width: 60px;
//            height: 100px;
//        }
//
//        &:nth-child(2) {
//            top: 35px;
//            right: 60px;
//            width: 86px;
//            height: 83px;
//        }
//
//        &:nth-child(3) {
//            top: 80px;
//            left: 30px;
//            width: 80px;
//            height: 75px;
//        }
//
//        &:nth-child(4) {
//            bottom: 110px;
//            right: 120px;
//            width: 54px;
//            height: 89px;
//        }
//
//        &:nth-child(5) {
//            bottom: 90px;
//            right: 35px;
//            width: 69px;
//            height: 82px;
//        }
//
//        &:nth-child(6) {
//            bottom: 45px;
//            left: 65px;
//            width: 82px;
//            height: 69px;
//        }
//
//        &:nth-child(7) {
//            bottom: 15px;
//            right: 70px;
//            width: 52px;
//            height: 87px;
//        }
//
//        &:nth-child(8) {
//            bottom: 8px;
//            right: 0;
//            width: 81px;
//            height: 58px;
//        }
//
//        &:nth-child(9) {
//            bottom: 0;
//            left: 0;
//            width: 76px;
//            height: 70px;
//        }
//    }
//}

.is-animated {
    .animation {
        &__scale {
            transform: scale(1);
            z-index: 50;

            &_1 {
                transition: all 1s ease;
            }
            &_2 {
                transition: all 2s ease;
            }
            &_3 {
                transition: all 3s ease;
            }
        }
        &__rotate_right {
            animation-name: rotate-right;
            animation-duration: 10s;
            //animation-iteration-count: infinite;
            animation-timing-function: linear;
            border-radius: 50%;
            transition: all 3s ease-in;
        }
        &__rotate_left {
            animation-name: rotate-left;
            animation-duration: 10s;
            //animation-iteration-count: infinite;
            animation-timing-function: linear;
            border-radius: 50%;
            transition: all 6s ease-in;
        }
        @keyframes rotate-right{
            from{ transform: rotate(-360deg); }
            to{ transform: rotate(360deg); }
        }
        @keyframes rotate-left{
            from{ transform: rotate(360deg); }
            to{ transform: rotate(-360deg); }
        }

        &__scale_gifts {
            animation: fadeInFromNone 1s ease 1s forwards;
            opacity: 0;
        }
        &__scale_video {
            animation: fadeInFromNone 1s ease 1.5s forwards;
            opacity: 0;
        }
        &__scale_voice {
            animation: fadeInFromNone 1s ease 2s forwards;
            opacity: 0;
        }
        &__scale_messages1 {
            animation: fadeInFromNone 1s ease 2.5s forwards;
            opacity: 0;

        }
        &__scale_messages2 {
            animation: fadeInFromNone 1s ease 3s forwards;
            opacity: 0;
        }
        &__scale_bonuses1 {
            animation: fadeInFromNone 1s ease 3.5s forwards;
            opacity: 0;
        }
        &__scale_bonuses2 {
            animation: fadeInFromNone 2s ease 4s forwards;
            opacity: 0;
        }
        &__scale_video {
            animation: fadeInFromNone 2s ease 4.5s forwards;
            opacity: 0;
        }
        &__scale_mystories {
            animation: fadeInFromNone 2s ease 5s forwards;
            opacity: 0;
        }
        &__scale_calls {
            animation: fadeInFromNone 2s ease 5.5s forwards;
            opacity: 0;
        }
        &__scale_private_contents {
            animation: fadeInFromNone 2s ease 6s forwards;
            opacity: 0;
        }
        @keyframes fadeInFromNone {
            0% {
                scale: 0;
            }

            100% {
                scale: 1;
                opacity: 1;
            }
        }

        &__image {
            opacity: 1;
            transform: translateX(0);

            &:nth-child(2) {
                transition-delay: 0.25s;
            }

            &:nth-child(3) {
                transition-delay: 0.45s;
            }

            &:nth-child(4) {
                transition-delay: 0.8s;
            }
        }

        &__coins {
            &_left {
                .animation__coin:nth-child(1) {
                    animation: coin-fall 2s linear;
                    animation-delay: 0s;
                }

                .animation__coin:nth-child(2) {
                    animation: coin-fall 2.2s linear;
                    animation-delay: 0.45s;
                }

                .animation__coin:nth-child(3) {
                    animation: coin-fall 2.4s linear;
                    animation-delay: 0.56s;
                }

                .animation__coin:nth-child(4) {
                    animation: coin-fall 2.6s linear;
                    animation-delay: 0.86s;
                }

                .animation__coin:nth-child(5) {
                    animation: coin-fall 2s linear;
                    animation-delay: 1.2s;
                }

                .animation__coin:nth-child(6) {
                    animation: coin-fall 2.5s linear;
                    animation-delay: 1.65s;
                }

                .animation__coin:nth-child(7) {
                    animation: coin-fall 3s linear;
                    animation-delay: 2.25s;
                }

                .animation__coin:nth-child(8) {
                    animation: coin-fall 2.56s linear;
                    animation-delay: 3s;
                }

                .animation__coin:nth-child(9) {
                    animation: coin-fall 3s linear;
                    animation-delay: 4.25s;
                }
            }

            &_right {
                .animation__coin:nth-child(1) {
                    animation: coin-fall 3.5s ease-in-out infinite;
                    animation-delay: 5.2s;
                }

                .animation__coin:nth-child(2) {
                    animation: coin-fall 3s ease-in-out infinite;
                    animation-delay: 6s;
                }

                .animation__coin:nth-child(3) {
                    animation: coin-fall 2.8s ease-in-out infinite;
                    animation-delay: 6.85s;
                }

                .animation__coin:nth-child(4) {
                    animation: coin-fall 2.6s ease-in-out infinite;
                    animation-delay: 7.5s;
                }

                .animation__coin:nth-child(5) {
                    animation: coin-fall 2.4s ease-in-out infinite;
                    animation-delay: 7.8s;
                }

                .animation__coin:nth-child(6) {
                    animation: coin-fall 2.7s ease-in-out infinite;
                    animation-delay: 8.2s;
                }

                .animation__coin:nth-child(7) {
                    animation: coin-fall 3.2s ease-in-out infinite;
                    animation-delay: 8.56s;
                }

                .animation__coin:nth-child(8) {
                    animation: coin-fall 2.9s ease-in-out infinite;
                    animation-delay: 8.86s;
                }

                .animation__coin:nth-child(9) {
                    animation: coin-fall 2.3s ease-in-out infinite;
                    animation-delay: 9s;
                }
            }
        }

        &__wrapper {
            animation: slide-out 1s linear forwards;
        }

        &_2 {
            .animation__wrapper {
                animation-delay: 1s;
            }
        }

        &__message {
            //transition: margin-left 4s ease-in-out;
            opacity: 0;

            &_1 {
                animation: fadeOutFromNone 1s ease 2.5s forwards;
                opacity: 0;
            }
            &_2 {
                animation: fadeOutFromNone 1s ease 2s forwards;
                opacity: 0;
            }
            &_3 {
                animation: fadeOutFromNone 1s ease 1.5s forwards;
                opacity: 0;
            }
            &_4 {
                animation: fadeOutFromNone 1s ease 1s forwards;
                opacity: 0;
            }
        }
        @keyframes fadeOutFromNone {
            0% {
                scale: 0;
            }

            100% {
                scale: 1;
                opacity: 1;
            }
        }
    }
}

@include create-mq($grid__bp-xl - 1, 'max') {
    .animation {
        &__image {
            &:not(:nth-child(1)) {
                display: none;
            }

            &:nth-child(1) {
                margin-top: 0;
                top: 40px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &__coins {
            &_left {
                left: 0;
            }

            &_right {
                right: 0;
            }
        }
    }
}

@keyframes coin-fall {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(400px);
    }
}
@keyframes coin-fall_mobile {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(100px);
    }
}

@keyframes bgFadeIn {
    from {
        background-size: 0%;
    }
    to {
        background-size: 100%;
    }
}

.no-webp {
    .animation {
        &__coin {
            &:nth-child(1) {
                background-image: url(../images/landing/coin1.svg);
            }

            &:nth-child(2) {
                background-image: url(../images/landing/coin2.svg);
            }

            &:nth-child(3) {
                background-image: url(../images/landing/coin3.svg);
            }

            &:nth-child(4) {
                background-image: url(../images/landing/coin4.svg);
            }

            &:nth-child(5) {
                background-image: url(../images/landing/coin5.svg);
            }

            &:nth-child(6) {
                background-image: url(../images/landing/coin6.svg);
            }

            &:nth-child(7) {
                background-image: url(../images/landing/coin7.svg);
            }

            &:nth-child(8) {
                background-image: url(../images/landing/coin8.svg);
            }

            &:nth-child(9) {
                background-image: url(../images/landing/coin9.svg);
            }
        }

        &__image {
            &:nth-child(1) {
                background-image: url(../images/landing/coins.svg);
            }

            &:nth-child(2) {
                background-image: url(../images/landing/phone1.png);
            }

            &:nth-child(3) {
                background-image: url(../images/landing/phone2.png);
            }

            &:nth-child(4) {
                background-image: url(../images/landing/phone3.png);
            }
        }
    }
}

.webp {
    .animation {
        &__coin {
            &:nth-child(1) {
                background-image: url(../images/landing/coin1.webp);
            }

            &:nth-child(2) {
                background-image: url(../images/landing/coin2.webp);
            }

            &:nth-child(3) {
                background-image: url(../images/landing/coin3.webp);
            }

            &:nth-child(4) {
                background-image: url(../images/landing/coin4.webp);
            }

            &:nth-child(5) {
                background-image: url(../images/landing/coin5.webp);
            }

            &:nth-child(6) {
                background-image: url(../images/landing/coin6.webp);
            }

            &:nth-child(7) {
                background-image: url(../images/landing/coin7.webp);
            }

            &:nth-child(8) {
                background-image: url(../images/landing/coin8.webp);
            }

            &:nth-child(9) {
                background-image: url(../images/landing/coin9.webp);
            }
        }

        &__image {
            &:nth-child(1) {
                background-image: url(../images/landing/coins.webp);
            }

            &:nth-child(2) {
                background-image: url(../images/landing/phone1.webp);
            }

            &:nth-child(3) {
                background-image: url(../images/landing/phone2.webp);
            }

            &:nth-child(4) {
                background-image: url(../images/landing/phone3.webp);
            }
        }
    }
}

@keyframes slide-out {
    from {
        width: 100%;
    }

    to {
        width: 1px;
    }
}

@media (min-width: 1280px) and (max-width: 1919px)  {
    .animation__image:nth-child(1) {
        margin-top: 200px;
    }
}
