.badge {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;

    &_warning {
        border: 1px solid var(--body-bg);
        background-color: var(--state-warning);
    }

    &_animate {
        animation: pulse 1.5s infinite ease-in-out;
    }

    &_inform {
        width: 22px;
        height: 22px;
        background-image: url(../images/icons/inform.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
