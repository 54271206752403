.logo {
    transition: opacity var(--transition-duration) var(--transition-easing);

    &:hover {
        opacity: 0.9;
    }

    &__image {
        display: inline-block;
        vertical-align: middle;
        width: 32px;
        height: 42px;
        background-repeat: no-repeat;
        background-image: url(../images/logo/logo-mob.svg);
        background-size: contain;

        @include create-mq($grid__bp-md, 'min') {
            width: 98px;
            height: 24px;
            background-image: url(../images/logo/logo.svg);
        }

        @include create-mq($grid__bp-4xl, 'min') {
            width: 140px;
            height: 34px;
        }
    }
}
