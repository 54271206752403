.triangle {
    border-style: solid;
    height: 0;
    width: 0;
    --triangle-size: 0.5rem;

    &_sm {
        --triangle-size: 0.5rem
    }

    &_md {
        --triangle-size: 2rem
    }

    &_lg {
        --triangle-size: 4rem
    }

    &_t {
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #d1d5db rgba(0, 0, 0, 0);
        border-width: 0 var(--triangle-size) var(--triangle-size) var(--triangle-size)
    }

    &_r {
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #d1d5db;
        border-width: var(--triangle-size) 0 var(--triangle-size) 1rem
    }

    &_b {
        border-color: #d1d5db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        border-width: var(--triangle-size) var(--triangle-size) 0 var(--triangle-size)
    }

    &_l {
        border-color: rgba(0, 0, 0, 0) #d1d5db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        border-width: var(--triangle-size) 1rem var(--triangle-size) 0
    }

    &_tr {
        border-color: rgba(0, 0, 0, 0) #d1d5db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        border-width: 0 var(--triangle-size) var(--triangle-size) 0
    }

    &_br {
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #d1d5db rgba(0, 0, 0, 0);
        border-width: 0 0 var(--triangle-size) var(--triangle-size)
    }

    &_bl {
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #d1d5db;
        border-width: var(--triangle-size) 0 0 var(--triangle-size)
    }

    &_tl {
        border-color: #d1d5db rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        border-width: var(--triangle-size) var(--triangle-size) 0 0
    }


}
