.heading {
    margin: 0;
    @extend .title;
    font-size: toRem(44px);
    text-transform: capitalize;
    @include create-mq($grid__bp-lg, 'min') {
        font-size: toRem(60px);
        text-align: center;


    }
}
