.gift {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr;
    width: 258px;
    height: 249px;
    border-radius: 5px;
    background: var(--nav-bg);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));

    &__body {
        grid-row: 1 / span 2;
        align-items: center;
        justify-content: center;
        display: grid;
    }

    &__footer {
        grid-row: 3 / span 1;
        display: grid;
        height: 1px;
    }

    &__footer-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-end;
        padding-top: 6px;
    }

    &__footer-description {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__footer-title {
        color: var(--heading-color);
        font-family: Roboto, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }

    &__footer-crypto {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 14px;
    }

    .crypto-amount {
        color: var(--color-text);
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }

    .button_gifts {
        padding: 1px;
    }
}
@media (min-width: 968px) and (max-width: 1920px) {
    .gift {
            width: 220px;
            height: 278px;

            &__footer-crypto {
                gap: 8px;
            }
        }
    }
@media (min-width: 576px) and (max-width: 968px) {
    .gift {
            width: 220px;
            height: 278px;

            &__footer-crypto {
                gap: 8px;
            }
        }
    }

@include create-mq($grid__bp-sm, "max") {
    .gift {
            width: 220px;
            height: 278px;

            &__footer-crypto {
                gap: 8px;
            }
        }
    }



