.gifts {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px 15px;
    overflow-x: hidden;
    padding: 24px 46px 53px;
    flex: 1;
  }

@media (min-width: 968px) and (max-width: 1920px) {
    .gifts {
        grid-template-columns: repeat(3, 1fr);
        gap: 10px 15px;
        padding: 17px 46px 33px;
    }
}

@media (min-width: 576px) and (max-width: 968px) {
    .gifts {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px 15px;
        padding: 17px 46px 33px;
    }
}
@include create-mq($grid__bp-sm, "max") {
    .gifts {
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        padding: 24px 46px 33px;
    }
}


