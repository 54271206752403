.ui-switcher {
    display: flex;
    align-items: center;

    &__box {
        display: inline-flex;
        align-items: center;
        position: relative;
        margin: 0 0 0 auto;
    }

    .icon {
        margin-right: 5px;
        flex: 0 0 1.5rem;
    }

    &__title {
        font-weight: 500;
        line-height: 1;
        margin-right: 10px;
    }

    input[type="checkbox"] {
        height: 0;
        width: 0;
        visibility: hidden;
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked + .ui-switcher__label {
            background-color: var(--state-danger);

            &::after {
                left: calc(100% - 25px);
                transform: translateX(calc(-100% + 24px));
            }
        }

        &:disabled + .ui-switcher__label,
        &.is-disabled + .ui-switcher__label {
            opacity: .5;
            pointer-events: none;
            background-color: #b7b7b7;
        }
    }

    .ui-switcher__label {
        display: inline-block;
        position: relative;
        text-indent: -9999px;
        width: 26px;
        height: 14px;
        background-color: var(--state-info);
        border-radius: 10px;
        transition: background-color .2s ease-in;

        &::after {
            content: "";
            position: absolute;
            top: 1px;
            left: 1px;
            width: 12px;
            height: 12px;
            background-color: #fff;
            border-radius: 50%;
        }
    }
}

@media (hover: hover) {
    .ui-switcher__label:hover {
        cursor: pointer;
    }
}
