.dropdown {
    position: relative;
    z-index: 100;

    &__menu {
        @extend .list;
        position: absolute;
        min-width: auto;
        padding: 20px 0 20px;
        background: var(--nav-bg);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        z-index: 110;

        &::before {
            content: '';
            position: absolute;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-image: url(../images/icons/corner.svg);
        }
    }

    &__item {
        padding-left: 30px;
        padding-right: 30px;
        white-space: nowrap;
        transition: background-color .25s ease-out;

        &:not(:last-child) {
            .dropdown__link {
                border-bottom: 1px solid rgba(197, 197, 197, .3);
            }
        }

        &:hover {
            background-color: var(--drobdown-hover-bg);

            .dropdown__link {
                border-color: transparent;
            }
        }

        .is-flex {
            justify-content: space-between;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .dropdown__title {
                flex: 1;
            }

            .dropdown__text {
                margin-left: 50px;
                max-width: 120px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    &__label {
        margin-right: 10px;
    }

    &__link {
        display: block;
        padding: toRem(10px) 0;
        font-weight: 500;

        .icon {
            margin-right: 5px;
        }
    }

    &__text {
        color: #C5C5C5;
    }

    &_right {
        .dropdown__menu {
            top: calc(100% + 30px);
            right: 20px;

            &::before {
                top: -15px;
                right: 0;
            }
        }
    }

    &_bottom {
        .dropdown__menu {
            bottom: calc(100% + 30px);
            left: 20px;

            &::before {
                bottom: -15px;
                left: 0;
                transform: rotate(180deg);
            }
        }
    }
    &_content {
        .dropdown__menu {
            padding: 20px 15px 25px;
            .title {
                display: block;
                margin-bottom: 8px;
            }
            .ui-input {
                width: 100%;
            }
            .button {
                display: block;
                width: 160px;
                margin: 24px auto 0;
            }
            .is-flex {
                align-items: center;
                gap: 12px
            }
        }
    }

    &_contact, &_donate  {
        .dropdown__menu {
            top: calc(100% + 20px);
        }
    }

    &_contact {
        .dropdown__menu {
            min-width: 300px;
        }
    }

}

@include create-mq($grid__bp-sm - 1, "max") {
    .dropdown {

        &_contact, &_donate  {
            position: static;

            .dropdown__menu {
                top: 100%;
                left: 50%;
                right: auto;
                transform: translateX(-50%);

                &::before {
                    content: none;
                }
            }
        }

        &_contact {
            .dropdown__menu {
                min-width: 250px;
            }
        }

    }
}

@include create-mq($grid__bp-xl - 1, "max") {

    .dropdown_icon {
        .dropdown__label {
            display: none;
        }
    }
}

@include create-mq($grid__bp-sm, "min") {
    .dropdown {

        &_contact {
            transform: translateX(-70px);
        }

        &_donate {
            transform: translateX(-40px);
        }

    }
}

@include create-mq($grid__bp-xl, "min") {
    .dropdown {

        &_contact {
            transform: translateX(-150px);
        }

        &_donate {
            transform: translateX(-80px);
        }

    }

    .dropdown_icon {
        .dropdown__link {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

@include create-mq($grid__bp-4xl, "min") {
    .dropdown {
        &_lg {
            .dropdown__menu {
                width: 290px;
            }
        }

        &_md {
            .dropdown__menu {
                min-width: 240px;
            }
        }

        &_sm {
            .dropdown__menu {
                min-width: 200px;
            }
        }

        &_right {
            .dropdown__menu {
                min-width: 240px;
            }
        }
        &_content {
            .dropdown__menu {
                min-width: 280px;
            }
        }
    }
}
@media (hover: hover) {
    .dropdown {
        &__link {
            &:hover {
                cursor: pointer;
            }
        }
    }
}
@media (min-width: 1280px) and (max-width: 1919px)  {
    .dropdown {
        &__menu {
            padding: 14px 0 14px;
        }

        &__item {
            padding-left: 15px;
            padding-right: 15px;
        }

        &__link {
            font-size: toRemSm(14px);
        }

        &_contact {
            transform: translateX(-120px);
        }

        &_donate {
            transform: translateX(-65px);
        }
    }
}
