.poster {
    position: relative;

    &::after {
        content: "";
        display: block;
    }

    &__media,
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        border-radius: 10px
    }

    &__overlay {
        display: flex;
    }

    &__holder {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &__title {
        margin: auto;
        font-size: 12vh;
        color: #fff;
        text-transform: uppercase;
    }
    &_horizontal {
        &::after {
            padding-bottom: 56.25%;
        }
    }
    &_personal {
        aspect-ratio: 380/530;
    }
    &_member {
        &::after {
            padding-bottom: 97.28%;
        }

        .poster__media {
            border-radius: 10px 10px 0 0;
        }
    }

    &_gallery {
        &::after {
            padding-bottom: 162%;
        }
        .poster__media_holder {
            object-fit: contain;
            object-position: 60%;
        }
    }
}

@media (hover: hover) {
    .poster {
        &_member {
            .poster__media {
                cursor: pointer;
            }
        }
    }
}

@include create-mq($grid__bp-sm - 1, "max") {
    .poster {
        &_personal {
            .poster__holder {
                position: absolute;
                bottom: 50px;
            }
        }
    }
}

@include create-mq($grid__bp-sm, "min") {
    .poster {
        &_personal {
            aspect-ratio: 16/9;
        }
    }
}
@include create-mq($grid__bp-xl, "min") {
    .poster {
        &_gallery {
            &::after {
                padding-bottom: 53.5%;
            }
        }
        &_personal {
            aspect-ratio: 250/336;
        }
    }
}
