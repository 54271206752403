:root {
    --avatar-size-xs: 40px;
    --avatar-size-sm: 50px;
    --avatar-size-md: 72px;
    --avatar-size-lg: 80px;
    --avatar-size-xl: 100px;
}

.avatar {

    display: block;
    position: relative;
    background-size: cover;
    overflow: hidden;
    border-radius: 5px;
    object-fit: cover;
    object-position: top;

    &_xs {
        width: var(--avatar-size-xs);
        height: var(--avatar-size-xs);
    }

    &_sm {
        width: var(--avatar-size-sm);
        height: var(--avatar-size-sm);
    }

    &_md {
        width: var(--avatar-size-md);
        height: var(--avatar-size-md);
    }

    &_lg {
        width: var(--avatar-size-lg);
        height: var(--avatar-size-lg);
    }

    &_xl {
        width: var(--avatar-size-xl);
        height: var(--avatar-size-xl);
    }

    &_holder {
        position: relative;
        padding: 0;
        background: rgba(197, 197, 197, 0.2);
        overflow: hidden;
        .avatar__image {
            position: absolute;
            top: toRem(5px);
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
