.slider {
    &__container {
        position: relative;
    }

    &__item {
        position: relative;
        overflow: hidden;
        display: block;
        user-select: none;
    }

    &__button {
        display: inline-block;
        padding: 0;
        position: absolute;
        top: 50%;
        height: 45px;
        line-height: 0;
        font-size: 0;
        background: rgba(255, 255, 255, .75);
        transform: translateY(-50%);
        transition: opacity .25s ease;
        z-index: 10;

        .button__icon {
            width: 18px;
            margin: 0;
            transform: rotate(90deg);
            transition: transform .35s ease-in-out;
        }

        &_dark {
            width: 28px;
            background: rgba(19, 19, 19, .5);
            border-radius: 0 5px 5px 0;
            color: rgba(255, 255, 255, .5)
        }

        &_prev {
            left: 0;
            border-radius: 0 5px 5px 0;
        }

        &_next {
            right: 0;
            border-radius: 5px 0 0 5px;

            .button__icon {
                transform: rotate(-90deg);
                transform-origin: initial;
            }
        }

        &:hover {
            opacity: .85;

            &_prev {
                .button__icon {
                    transform: rotate(90deg) translateY(5px);
                }
            }

            &_next {
                .button__icon {
                    transform: rotate(-90deg) translateY(5px);
                }
            }
        }
    }

    &__delete {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
    }
    .hooper {
        .hooper-pagination {
            padding: 0;
        }
        .hooper-indicators {
            line-height: 1;
            li {
                .hooper-indicator {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    display: inline-block;
                    background: #C5C5C5;
                    &:hover, &.is-active {
                        background: var(--gradient);
                    }
                }
            }
        }
        .hooper-slide {
            transform: translate3d(0,0,0)
        }
    }
    &_promo, &_portfolio {
        .slider__holder {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
        .button_video-play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
        }
    }

    &_promo {
        .hooper {
            width: 100%;
            position: relative;
            overflow: hidden;
            height: auto;
            &::before {
                content: '';
                display: block;
                padding-top: 56.25%;/*16:9*/
            }
            .hooper-list {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .slider__item {
            height: 100%;
        }
        .slider__media {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            border-radius: 0 0 20px 20px;
        }

        .slider__holder {
            bottom: -20px;
        }
        .slider__caption {
            position: absolute;
            display: inline-flex;
            flex-direction: column;
            justify-content: flex-end;
            bottom: 20px;
            right: 20px;
            .button {
                margin: 0 0 0 auto;
            }
            .profile__credits {
                display: inline-block;
                margin-top: 20px;
            }
        }
    }
    &_thumbs {
        margin-top: 25px;
        height: 140px;
        .hooper {
            height: 100%;
        }
        .slider__item {
            margin-right: 15px;
        }
        .button_close {
            position: absolute;
            top: 5px;
            right: 5px;
            padding: 2px;
        }
    }

    &_portfolio {
        .slider__media {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            aspect-ratio: 1 / 1;
            border-radius: 10px
        }
        .slide__item {
            position: relative;
        }

        .slider__delete {
            top: 10px;
            right: 20px;
        }

        .slider__button {
            width: 25px;
        }

        .upload {
            width: 100%;
            height: 100%;
        }
        + .slider_portfolio {
            margin-top: 30px;
        }
    }
    &_content {
        padding-top: 40px;
        .hooper {
            height: auto;
            min-height: 160px;
        }
    }

    &_gallery {
        .hooper, .slider__item {
            height: calc((var(--vh, 1vh) * 100) - 60px);
        }
        .gallery__item, .card_gallery {
            position: relative;
            width: 100%;
            height: 100%;
        }
        .card__media {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

@include create-mq($grid__bp-xl - 1, "max") {
    .slider {
        &_promo {
            .slider__holder {
                bottom: 20vh;
            }
            .slider__delete {
                top: 90px;
            }
            .hooper {
                .hooper-pagination {
                    bottom: 60px
                }
                &::before {
                    padding-top: 80vh;
                }
            }
        }
        &_portfolio {
            .slider__holder {
                bottom: 0;
            }
            .slider__delete {
                top: 25px;
            }
        }
        &_portfolio {
            margin-top: -20px;
            .hooper {
                height: 480px;
            }
            .slider__item {
                width: 100%;
            }
            .slider__item, img, video {
                border-radius: 0 0 20px 20px
            }
            .hooper {
               .hooper-pagination {
                   width: 100%;
                   padding: 40px 0 20px;
                   justify-content: center;
                   border-radius: 0 0 20px 20px;
                   background: linear-gradient(360deg, #131313 10.74%, rgba(0, 0, 0, 0.00) 66.94%);
               }
            }
        }
    }
}
@include create-mq($grid__bp-md - 1, "max") {
    .profile_account {
        .slider {
            &_promo {
                .upload:not(.upload_simple) {
                    .upload__label {
                        top: 30vh;
                        bottom: auto;
                    }
                }
            }
        }
    }
}
@include create-mq($grid__bp-xl, "min") {
    .slider {
        &_promo {
            .slider__caption {
                text-align: right;
            }
        }
        &_portfolio {
            .hooper {
                height: 170px;
            }
            .slider__item {
                padding-left: 15px;
                padding-right: 15px;
            }
            .slider__holder {
                bottom: -50px;
            }
        }
    }
}
@include create-mq($grid__bp-4xl, "min") {
    .slider {
        &__button {
            height: 70px;
            &_dark {
                width: 46px;
            }
            .button__icon {
                width: 28px;
            }
        }
        &_portfolio {
            .hooper {
                height: 270px;
            }
            .slider__button {
                width: 35px;
            }
        }
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .slider {
        &_promo {
            .slider__holder {
                bottom: 0;
                width: 300px;
                height: 300px;
            }
        }
        &_portfolio {
            .slider__holder {
                bottom: 0;
            }
        }
    }
}
