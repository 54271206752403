.navigation {
    height: calc(var(--vh, 1vh) * 100);
    background: var(--nav-bg);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    border-radius: 10px 0 0 10px;
    overflow-y: auto;

    &__close {
        position: absolute;
        top: 15px;
        left: 15px;
        display: block;
        width: 18px;
        height: 18px;
        cursor: pointer;
        z-index: 10;

        &::after {
            content: '';
            position: absolute;
            width: 14px;
            height: 2px;
            background: var(--gradient);
        }
    }

    &__menu {
        &:not(:last-of-type) {
            padding-bottom: 15px;
            border-bottom: 2px solid rgba(197, 197, 197, 0.15);
        }

        &:not(:first-of-type) {
            padding-top: 20px;
        }

        .menu__item {
            position: relative;

            .badge {
                position: absolute;
                top: -4px;
                right: -6px;
                z-index: 20;
            }
        }
    }

    &__menu-sub {
        margin-top: 20px;
        margin-left: auto;

        .ui-switcher {
            justify-content: flex-end;
        }
    }
}

@include create-mq($grid__bp-xl, "min") {
    .navigation {
        background-image: url(../images/navigation/bg.png);
        background-repeat: no-repeat;
        background-position: 0 0;

        &__menu-sub {
            margin-top: 30px;

        }

        .menu_sub-settings {
            width: 180px
        }
    }
}
