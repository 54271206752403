.navbar {
    &__menu {
        display: flex;
        align-items: center;

        .menu__item:not(:last-child) {
            padding-right: 15px;
        }

        .menu__link {
            position: relative;
            padding: 0 10px;
            font-weight: 700;
            line-height: 1.1;
            color: var(--gray);
            text-transform: uppercase;

            &::after {
                content: '';
                position: absolute;
                bottom: -5px;
                left: 0;
                transform: scale(0);
                transform-origin: center;
                width: 100%;
                height: 3px;
                border-radius: 2px;
                transition: transform 0.35s ease-in-out;
                background: linear-gradient(250.44deg, #5886ff 33.27%, #d00bf0 102.24%);
            }

            &:hover {
                color: var(--dark-gray);

                &::after {
                    transform: scale(1);
                }
            }
        }
    }
}

@include create-mq($grid__bp-lg - 1, 'max') {
    .navbar {
        position: fixed;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        padding: 0 30px;
        display: flex;
        opacity: 0;
        visibility: hidden;
        flex-direction: column;
        overflow-y: auto;
        transition: opacity 0.56s ease-in-out;
        z-index: 100;

        &:not(.is-active) {
            pointer-events: none;
        }

        &::after {
            content: ' ';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(var(--vh, 1vh) * 100);
            opacity: 0;
            z-index: 99;
            pointer-events: none;
            transition: opacity 0.35s linear;
            background: rgba(19, 19, 19, 0.75);
            backdrop-filter: blur(10px);
        }

        &.is-active {
            visibility: visible;
            opacity: 1;

            &::after {
                opacity: 1;
                pointer-events: auto;
            }

            .menu {
                &__item {
                    opacity: 1;
                    transform: translateY(0);

                    &:nth-child(1) {
                        transition-delay: 0.1s;
                    }

                    &:nth-child(2) {
                        transition-delay: 0.2s;
                    }

                    &:nth-child(3) {
                        transition-delay: 0.3s;
                    }

                    &:nth-child(4) {
                        transition-delay: .4s;
                    }

                    &:nth-child(5) {
                        transition-delay: .5s;
                    }

                    &:nth-child(6) {
                        transition-delay: .6s;
                    }
                }
            }
        }

        .menu {
            position: relative;
            z-index: 999;
            flex-direction: column;
            text-align: center;
            margin: auto;

            &__item {
                display: block;
                opacity: 0;
                transform: translateY(100%);
                transition: transform 0.56s ease-in-out, opacity 0.35s ease-out 0.1s;

                &:not(:last-child) {
                    margin: 0 0 40px;
                    padding-right: 0;
                }
            }

            &__link {
                font-size: toRem(32px);

                &,
                &:hover,
                &:active {
                    color: var(--color-white);
                }
            }
        }
    }
}
