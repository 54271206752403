.status {
    user-select: none;
    &,
    &__icon {
        display: inline-block;
        vertical-align: baseline;;
        line-height: 1;
    }

    &_online {
        .status__icon {
            background-color: var(--state-online);
        }

        .status__icon {
            width: toRem(12px);
            height: toRem(12px);
            border-radius: 6px;
        }
    }

    &_offline {
        .status__icon {
            background-color: var(--state-danger);
        }
    }
    &_badge {
        display: flex;
        align-items: center;
        padding: toRem(2px) toRem(6px);
        background: rgba(24, 23, 23, 0.39);
        border-radius: toRem(20px);

        .status__icon {
            margin-right: 5px;
        }
    }
    &_luxury, &_starting, &_superior, &_premium {

        .status__icon, .status__title {
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        .status__title {
            width: toRem(80px);
            height: toRem(20px);
        }
    }
    &_superior, &_premium  {
        .status__icon {
            width: toRem(24px);
            height: toRem(24px);
        }
    }
    &_luxury {
        .status__icon {
            width: toRem(28px);
            height: toRem(28px);
            background-image: url(../images/payment/luxury-icon.svg);
        }
        .status__title {
            width: toRem(80px);
            background-image: url(../images/payment/luxury-title.svg);
        }
    }
    &_starting {
        .status__icon {
            width: toRem(20px);
            height: toRem(20px);
            background-image: url(../images/payment/starting-icon.svg);
        }
        .status__title {
            background-image: url(../images/payment/starting-title.svg);
        }
    }
    &_superior {
        .status__icon {
            background-image: url(../images/payment/superior-icon.svg);
        }
        .status__title {
            background-image: url(../images/payment/superior-title.svg);
        }
    }
    &_premium {
        .status__icon {
            background-image: url(../images/payment/premium-icon.svg);
        }
        .status__title {
            background-image: url(../images/payment/premium-title.svg);
        }
    }
}
