.record {
    display: flex;
    align-items: center;

    &.is-center {
        justify-content: center;
    }

    &__circle {
        display: inline-block;
        margin-right: 8px;
        width: 14px;
        height: 14px;
        background-color: #FE5353;
        border-radius: 50%;
        animation: record-pulse 2s infinite ease-in-out;
    }

    &__label, &__text {
        font-size: 12px;
    }

    &__label {
        font-weight: 500;
    }

    &__text {
        font-weight: 400;
        margin-left: 5px;
    }

    &__button {
        display: inline-block;
        padding: 0 10px;
        margin-left: 8px;
        @extend .text-gradient;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
    }
}


@keyframes record-pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}


@media (min-width: 1280px) and (max-width: 1919px) {
    .record {
        &__circle {
            width: 10px;
            height: 10px;
        } }
}
