.table {
    width: 100%;
    margin-bottom: 1rem;
    color: var(--color-text);
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
}

.table_page {
    &:not(.table_modal) {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: -webkit-linear-gradient(275deg, #17AAFC 0%, #CB77FF 100%);
            background: -o-linear-gradient(275deg, #17AAFC 0%, #CB77FF 100%);
            background: linear-gradient(175deg, #17AAFC 0%, #CB77FF 100%);
        }
    }

    thead th, tbody td {
        padding: toRem(5px) toRem(20px);
        text-align: left;
    }
    thead {
        border-radius: 5px;
        background: rgba(51, 41, 68, 0.10);
        color: var(--heading-color);
    }
    .title {
        text-align: center;
        color: var(--heading-color);
        white-space: nowrap;
    }
}

@include create-mq($grid__bp-sm - 1, "max") {
    .table_page:not(.table_responsive) {
        tbody td {
            font-size: toRem(12px);
            padding: toRem(5px) toRem(10px);
        }
    }
}

@include create-mq($grid__bp-md - 1, "max") {

    .table_responsive {
        padding-bottom: 30px;
        &, thead, tbody, tr {
            display: block;
        }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            margin: 0 0 1rem 0;
        }

        &.table_page td, td {
            display: flex;
            justify-content: space-between;
            margin: 0.5em 0;
            border: none;
            position: relative;
            padding-left: 50%;
            white-space: normal;
            text-align: right;
        }

        .table__cell {
            &::before {
                position: absolute;
                left: 10px;
                width: 45%;
                padding-left: 10px;
                padding-right: 10px;
                background: rgba(51, 41, 68, 0.10);
                color: var(--heading-color);
                white-space: nowrap;
                font-weight: 600;
                text-align: left;
            }
        }

        .table__date::before {
            content: "Data:";
        }

        .table__description::before {
            content: "Description:";
        }

        .table__credit::before {
            content: "Credit:";
        }

        .table__amount::before {
            content: "Amount:";
        }
    }
}

@include create-mq($grid__bp-xl, "min") {
    .table_page {

        thead th, tbody td {
            padding: toRem(5px) toRem(20px);
        }

        thead {
            font-size: toRem(20px);
        }

        tbody {
            font-size: toRem(18px);
        }

        .title {
            font-size: toRem(25px);
        }
    }
}
