.social {
    @extend .list;
    display: flex;
    line-height: 1;

    //&__item:not(:last-child) {
    //    margin-right: 20px;
    //}

    &__link {
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        transition: opacity var(--transition-duration) var(--transition-easing);

        &:hover {
            opacity: .85
        }
    }
}

.social_light {
    .social__link {
        color: #fff;
    }
}
