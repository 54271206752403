.no-scroll {
    overflow: hidden;
    scrollbar-gutter: stable;
    /* when modal active */
    touch-action: none;
    -webkit-overflow-scrolling: none;
    /* Other browsers */
    overscroll-behavior: none;
    //position: fixed;

    #app {
        position: fixed;
        overflow: hidden;
        height: 100%;
        width: 100%;
    }
}

.no-scroll-behavior {
    scroll-behavior: auto !important;
}

.is-scroll-behavior {
    scroll-behavior: smooth;
}

.is-hidden {
    display: none;
}

.visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

.is-flex {
    display: flex;
}
.is-inline-flex {
    display: inline-flex;
}
.is-blur {
    filter: blur(15px);
}
.is-block {
    display: block;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-gradient,
.text-gradient-reverse {
    background-clip: text;
    -webkit-background-clip: text;
   /* -webkit-text-fill-color: transparent;*/
    color: transparent;
}
.text-gradient {
    background-image: var(--gradient-text);
}
.text-gradient-reverse {
    background-image: var(--gradient-reverse);
   }

.text-danger {
    color: var(--state-danger)
}

.text-warning {
    color: var(--state-warning)
}

.text-info {
    color: var(--state-info)
}

.text-success {
    color: var(--state-success)
}

.text-light {
    color: var(--light-gray)
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}


.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
}

.pb-0 {
    padding-bottom: 0;
}
.mb-2 {
    margin-bottom: 20px;
}
.ml-auto {
    margin-left: auto;
}
.text-small {
    font-size: toRem(14px);
}

.text-medium {
    font-size: toRem(15px);
}

.quote {
    margin: 0;
    padding-left: 30px;
    border-left: 2px solid var(--light-gray);
}
.is_through {
    text-decoration-line: line-through;
}
body {
    .snotify.snotify-rightTop{
        width: 340px;
        margin-bottom: 10px;
        margin-top: 30px;
        margin-right: 14px;
        right: 0;

        .snotifyToast__progressBar {
            display: none;
        }
        .snotifyToast__buttons {
            display: block;
            border:0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            font-size: 0;
            button {
                position: absolute;
                top: 0;
                height: 100%;
                font-size: 0;
                border:0;
                background: none;
                &:first-child {
                    right: 0;
                    width: 80%;
                }
                &:last-child {
                    left: 0;
                    width: 20%;
                    &::after {
                        content: none
                    }
                    &::before {
                        content: '';
                        width: 12px;
                        height: 2px;
                        //background: url(../images/icons/close-white.svg) no-repeat;;
                        background: rgba(78, 78, 78, 1);
                        position: absolute;
                        top: 13px;
                        left: 13px;
                        z-index: 10;
                    }
                }
            }
        }
        .snotifyToast {
            position: relative;
            background-color: transparent;
            margin: 0;
            border-radius: 0;
            overflow: visible;
        }
        .snotifyToast__inner {
            padding: 5px 12px;
            background: rgba(225, 225, 225, 0.7);
            //backdrop-filter: blur(20px);
            border-radius: 10px;
            box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
            height: 98px;
            .d-flex {
                width: 100%;
                height: 100%;
                align-items: center;
                &.is-success {
                    .snotifyToast__title {
                        color: #fff;
                    }
                }
                &.is-info {
                    .snotifyToast__title {
                        color: #fefefe;
                    }
                }
                &.is-error, &.is-warning {
                    .snotifyToast__icon {
                        display: inline-block;
                        width: 70px;
                        height: 70px;
                        background-color: var(--nav-bg);
                        border-radius: 5px;
                        background-image: url(../images/logo/logo-mob.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
                &.is-error {
                    .snotifyToast__title {
                        color: #ED326A;
                        font-size: 1.7rem;
                    }
                }
                &.is-warning {
                    .snotifyToast__title {
                        color: #F8832F;
                    }
                }
            }
            .snotifyToast__icon {
                position: relative;
                width: 70px;
                height: 70px;
                flex: 0 0 70px;
                //margin-left: 10px;
                .status {
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    width: 10px;
                    z-index: 10;
                    &__icon {
                        border: 2px solid #fff;
                    }
                }
                .avatar {
                    width: 100%;
                    height: 100%;
                }
            }
            .snotifyToast__body {
                height: 100%;
                padding: 5px 10px 5px 25px;
                flex: 1;
                width: calc(100% - 80px);
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .snotifyToast__content {
                .name {
                    font-size: 1.25rem;
                    font-weight: 700;
                   color: rgba(66, 66, 66, 1);
                }
                .text {
                    display: block;
                    width: 90%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: rgba(44, 44, 44, 1);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .snotifyToast__title {
                display: block;
                font-size: 1.25rem;
                font-weight: 500;
                text-decoration: underline;
            }
        }
    }
    .snotify.snotify-leftTop{
        //width: 360px;
        width: 100%;
        //margin-bottom: 10px;
        //margin-top: 10px;
        //margin-left: 2%;
        margin: 0 auto;
        padding: 0 11px;
        left: 0;
        .snotifyToast__progressBar {
            display: none;
        }
        .snotifyToast__buttons {
            display: block;
            border:0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            font-size: 0;
            z-index: 100;
            button {
                position: absolute;
                top: 0;
                height: 100%;
                font-size: 0;
                border:0;
                background: none;
                &:first-child {
                    right: 0;
                    width: 20%;
                    &::after {
                        content: none
                    }
                    &::before {
                        content: '';
                        width: 12px;
                        height: 2px;
                        background: #4e4e4e;
                        //background: url(../images/icons/close-white.svg) no-repeat;
                        position: absolute;
                        top: 13px;
                        right: 13px;
                        z-index: 10;
                    }
                }
                &:last-child {
                    left: 0;
                    width: 80%;
                }
            }
        }
        .snotifyToast {
            position: relative;
            background-color: transparent;
            margin: 0;
            border-radius: 0;
            overflow: visible;
        }
        .snotifyToast__inner {
            padding: 5px 12px;
            background: rgba(225, 225, 225, 0.7);
            //backdrop-filter: blur(20px);
            border-radius: 10px;
            box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
            //height: 98px;
            height: 66px;

            //&::before{
            //    content: '';
            //    position: absolute;
            //    opacity: 70%;
            //    width: 100%;
            //    height: inherit;
            //    background: #e1e1e1;
            //    left: 0;
            //    z-index: 1;
            //    border-radius: 10px;
            //    backdrop-filter: blur(20px);
            //    //box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
            //
            //}
            .d-flex {
                width: 100%;
                height: 100%;
                align-items: center;

                &.is-success {
                    .snotifyToast__title {
                        color: #fff;
                    }
                }
                &.is-info {
                    .snotifyToast__title {
                        color: #fefefe;
                    }
                }
                &.is-error, &.is-warning {
                    .snotifyToast__icon {
                        display: inline-block;
                        width: 70px;
                        height: 70px;
                        background-color: var(--nav-bg);
                        border-radius: 5px;
                        background-image: url(../images/logo/logo-mob.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
                &.is-error {
                    .snotifyToast__title {
                        color: #ED326A;
                        font-size: 1.7rem;
                    }
                }
                &.is-warning {
                    .snotifyToast__title {
                        color: #F8832F;
                    }
                }
            }
            .snotifyToast__icon {
                position: relative;
                width: 60px;
                height: 60px;
                flex: 0 0 60px;
                //margin-left: 10px;
                z-index: 10;
                .status {
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    width: 10px;
                    z-index: 10;
                    &__icon {
                        border: 2px solid #fff;
                    }
                }
                .avatar {
                    width: 100%;
                    height: 100%;
                }
            }
            .snotifyToast__body {
                height: 100%;
                padding: 6px 10px;
                flex: 1;
                width: calc(100% - 80px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                z-index: 10;
            }
            .snotifyToast__content {
                .name {
                    font-size: 1.25rem;
                    font-weight: 700;
                    color: #424242;
                }
                .text {
                    display: block;
                    width: 90%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #2c2c2c;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .snotifyToast__title {
                display: block;
                font-size: 1.25rem;
                font-weight: 500;
                text-decoration: underline;
            }
        }
    }
    &.mode-dark {
        .snotify {
            .snotifyToast__inner {
                padding: 5px 12px;
                background: rgba(86, 86, 86, 0.7);
                .snotifyToast__body{
                    .snotifyToast__content {
                        .name {
                            color: #fff;
                        }
                        .text{
                            color: #fff;
                        }
                    }
                }
            }

            .snotifyToast__buttons {
                button {
                    &:first-child {
                        &::before {
                            background: #ffffff;
                        }
                    }
                    &:last-child {
                        &::after {
                            background-image: url(../images/icons/close-black.svg);
                        }
                        &::before {
                            background: #ffffff;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 413px) {
    body {
        .snotify {
            width: 260px
        }
    }
}

@media (max-width: 1279px) {
    body {
        .snotify-leftTop {
            margin-top: 0;
        }

        .snotify {
            //top: 8%;
            top: calc(60px + 8px - 6px);
            right: 0;
            .snotifyToast__inner {
                box-shadow: 0 4px 3px rgba(113, 113, 113, 0.56);
                backdrop-filter: none;
                border-radius: 10px;
                height: auto;
                min-height: 46px;
                padding: 3px 11px;
                .d-flex.is-warning .snotifyToast__icon, .snotifyToast__icon {
                    width: 40px;
                    height: 40px;
                    flex: 0 0 40px;
                }
                .snotifyToast__body {
                    width: calc(100% - 50px);
                }
                .snotifyToast__content {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    border-radius: 0;
                    order: 2;
                    .text {
                        margin: 0;
                        font-weight: 400;
                        font-size: 1.18rem;
                        color: #fff;
                    }
                    .name {
                        font-size: toRem(20px);
                    }
                }
                .snotifyToast__title {
                    display: none;
                }
            }
        }
    }
}
@media (min-width: 680px ) and (max-width: 1024px) {
    body {
        .snotify.snotify-rightTop {
            width: 300px;
        }
    }
}
@media (min-width: 576px ) and (max-width: 680px) {
    body {
        .snotify.snotify-rightTop {
            width: 260px;
        }
    }
}

