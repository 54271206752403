.custom-checkbox {
    position: relative;
    display: inline-block;

    &__input {
        position: absolute;
        top: 0;
        opacity: 0;

        &:checked + .custom-checkbox__label {
            border-color: #D00BF0
        }
    }

    &__label {
        position: relative;
        user-select: none;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        border: 1px solid transparent;
    }
}
