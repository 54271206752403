.icon {
    display: inline-block;
    vertical-align: middle;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.icon_fb,
.icon_in,
.icon_yb {
    width: 50px;
    height: 50px;
}

.icon_fb {
    background-image: url(../images/icons/fb.svg);
}

.icon_in {
    background-image: url(../images/icons/in.svg);
}

.icon_yb {
    background-image: url(../images/icons/yb.svg);
}

.icon_phone,
.icon_marker,
.icon_mail,
.icon_view,
.icon_eye,
.icon_favorite,
.icon_favorite-check,
.icon_money,
.icon_bell,
.icon_bell-red,
.icon_block-user,
.icon_chat-bubble-gradient,
.icon_chat-bubble-light,
.icon_chat-bubble-bordered,
.icon_dark,
.icon_light,
.icon_headset,
.icon_sound-off,
.icon_sound-on,
.icon_search,
.icon_search-white,
.icon_setting,
.icon_settings,
.icon_subscribe,
.icon_envelope,
.icon_subscribe-on,
.icon_plus,
.icon_question,
.icon_info,
.icon_account,
.icon_block-user,
.icon_unsubscribe,
.icon_complain,
.icon_tariff,
.icon_logout,
.icon_stories,
.icon_emoji,
.icon_delete,
.icon_theme,
.icon_unlock,
.icon_edit,
.icon_chat,
.icon_box,
.icon_warning,
.icon_letter-full,
.icon_image,
.icon_mic,
.icon_video,
.icon_camera,
.icon_secured,
.icon_heart-white,
.icon_attachment-white,
.icon_crown,
.icon_star,
.icon_cristal,
.icon_image-fill {
    width: 24px;
    height: 24px;
}
.icon_present {
    width: 34px;
    height: 38px;
}
.icon_letter-white,
.icon_letter-dark
{
    width: 43px;
    height: 30px;
}
.icon_image-fill {
    background-image: url(../images/icons/image.svg);
}
.icon_buy-credits {
    width: 55px;
    height: 55px;
    background-image: url(../images/icons/buy-credits.svg);
}

.icon_attachment-white {
    background-image: url(../images/icons/attachment-white.svg);
}
.icon_present {
    background-image: url(../images/icons/present.svg);
}
.icon_crown {
    background-image: url(../images/icons/crown.svg);
}
.icon_star {
    background-image: url(../images/icons/star.svg);
}
.icon_heart-white {
    background-image: url(../images/icons/heart-white.svg);
}
.icon_cristal {
    background-image: url(../images/icons/cristal.svg);
}
.icon_secured {
    background-image: url(../images/icons/secured.svg);
}
.icon_image {
    background-image: url(../images/icons/image-upload.svg);
}
.icon_mic {
    background-image: url(../images/icons/mic.svg);
}
.icon_mic-white {
    //min-width: 12px;
    //height: 18px;
    background-image: url(../images/icons/mic-white.svg);
}
.icon_video {
    background-image: url(../images/icons/video.svg);
}
.icon_camera {
    background-image: url(../images/icons/camera.svg);
}
.icon_warning {
    background-image: url(../images/icons/warning.svg);
}
.icon_chat {
    background-image: url(../images/icons/chat.svg);
}
.icon_box {
    background-image: url(../images/icons/box-open.svg);
}
.icon_letter-full {
    background-image: url(../images/icons/letter-full.svg);
}
.icon_edit {
    background-image: url(../images/icons/edit.svg);
}
.icon_unlock {
    background-image: url(../images/icons/unlock.svg);
}
.icon_theme {
    background-image: url(../images/icons/theme.svg);
}
.icon_setting {
    background-image: url(../images/icons/setting.svg);
}
.icon_delete {
    background-image: url(../images/icons/delete.svg);
}
.icon_stories {
    background-image: url(../images/icons/stories.svg);
}
.icon_emoji {
    background-image: url(../images/icons/emoji.svg);
}
.icon_unsubscribe {
    background-image: url(../images/icons/unsubscribe.svg);
}
.icon_block-user {
    background-image: url(../images/icons/block-user.svg);
}
.icon_complain {
    background-image: url(../images/icons/complain.svg);
}

.icon_settings {
    background-image: url(../images/icons/settings.svg);
}

.icon_account {
    background-image: url(../images/icons/account.svg);
}

.icon_tariff {
    background-image: url(../images/icons/tariff.svg);
}

.icon_logout {
    background-image: url(../images/icons/logout.svg);
}

.icon_info {
    background-image: url(../images/icons/info.svg);
}

.icon_question {
    background-image: url(../images/icons/question.svg);
}

.icon_dark {
    background-image: url(../images/icons/dark.svg);
}

.icon_search {
    background-image: url(../images/icons/search.svg);
}
.icon_search-white {
    background-image: url(../images/icons/search-white.svg);
}

.icon_sound-off {
    background-image: url(../images/icons/sound-off.svg);
}

.icon_sound-on {
    background-image: url(../images/icons/sound-on.svg);
}

.icon_subscribe {
    background-image: url(../images/icons/subscribe.svg);
}

.icon_subscribe-on {
    background-image: url(../images/icons/subscribe-on.svg);
}

.icon_plus {
    background-image: url(../images/icons/plus.svg);
}

.icon_headset {
    background-image: url(../images/icons/headset.svg);
}

.icon_light {
    background-image: url(../images/icons/light.svg);
}

.icon_chat-bubble-gradient {
    background-image: url(../images/icons/chat-bubble-gradient.svg);
}

.icon_chat-bubble-light {
    background-image: url(../images/icons/chat-bubble-light.svg);
}

.icon_chat-bubble-bordered {
    background-image: url(../images/icons/chat-bubble-bordered.svg);
}

.icon_block-user {
    background-image: url(../images/icons/block-user.svg);
}

.icon_money {
    background-image: url(../images/icons/money.svg);
}

.icon_bell {
    background-image: url(../images/icons/bell.svg);
}

.icon_bell-red {
    background-image: url(../images/icons/bell-red.svg);
}

.icon_phone {
    background-image: url(../images/icons/phone.svg);
}

.icon_marker {
    background-image: url(../images/icons/marker.svg);
}

.icon_mail {
    background-image: url(../images/icons/mail.svg);
}

.icon_close {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    text-align: center;
    background-image: url(../images/icons/close.svg);
    flex-shrink: 0;
    margin: 0 auto;
    display: block !important;
}
.icon_eye {
    background-image: url(../images/icons/eye.svg);
}

.icon_envelope {
    background-image: url(../images/icons/envelope.svg);
}
.icon_favorite {
    background-image: url(../images/icons/favorite.svg);
}

.icon_favorite-check {
    background-image: url(../images/icons/favorite-check.svg);
}

.icon_more {
    width: 18px;
    height: 8px;
    background-image: url(../images/icons/arrow-right.svg);
}

.icon_calendar {
    width: 16px;
    height: 16px;
    background-image: url(../images/icons/calendar.svg);
}

.icon_like {
    width: 17px;
    height: 16px;
    background-image: url(../images/icons/like.svg);
}

.icon_user {
    width: 17px;
    height: 16px;
    background-image: url(../images/icons/user.svg);
}

.icon_new {
    width: 44px;
    height: 30px;
    background-image: url(../images/icons/new.svg);
}

.icon_view {
    width: 20px;
    height: 20px;
    background-image: url(../images/icons/view.svg);
}

.icon_history {
    width: 8px;
    height: 17px;
    background-image: url(../images/icons/history.svg);
}
.icon_location {
    width: 10px;
    height: 14px;
    background-image: url(../images/icons/location.svg);
}
.icon_send {
    width: 36px;
    height: 36px;
    background-image: url(../images/icons/send.svg);
}
.icon_lock {
    width: 36px;
    height: 36px;
    background-image: url(../images/icons/lock.svg);
}
.icon_plane {
    width: 16px;
    height: 16px;
    background-image: url(../images/icons/plane.svg);
}
.icon_attachment {
    width: 24px;
    height: 24px;
    background-image: url(../images/icons/attachment.svg);
}
.icon_download {
    width: 16px;
    height: 18px;
    background-image: url(../images/icons/download.svg);
}
.icon_letter {
    width: 44px;
    height: 28px;
    background-image: url(../images/icons/letter.svg);
}
.icon_letter-white {
    border-radius: 5px;
    background-image: url(../images/icons/letter-white.svg);
}
.icon_letter-dark {
    border-radius: 5px;
    background-image: url(../images/icons/letter-dark.svg);
}
.icon_letter-open {
    width: 44px;
    height: 44px;
    background-image: url(../images/icons/letter-open.svg);
}
.icon_letter-dark-open {
    width: 44px;
    height: 44px;
    background-image: url(../images/icons/letter-dark-open.svg);
}
.icon_background {
    width: 25px;
    height: 20px;
    background-image: url(../images/icons/image-bg.svg);
}
.icon_dollar {
    width: 60px;
    height: 60px;
    background-image: url(../images/icons/dollar.svg);
}
.icon_choose {
    width: 14px;
    height: 22px;
    background-image: url(../images/icons/choose.svg);
}
.icon_back {
    width: 14px;
    height: 14px;
    background-image: url(../images/icons/back-arrow.svg);
}
.icon_camera-white {
    width: 20px;
    height: 16px;
    background-image: url(../images/icons/camera-white.svg);
}
.icon_video-white {
    width: 28px;
    height: 16px;
    background-image: url(../images/icons/video-white.svg);
}

.icon_copy {
    width: 12px;
    height: 16px;
    background-image: url(../images/icons/copy.svg);
}

.icon_sound {
    width: 28px;
    height: 16px;
    background-image: url(../images/icons/sound.svg);
}


.icon_zodiac {
    width: 28px;
    height: 28px;
}

.icon_gifts_tr {
    background-image: url(../images/icons/gifts/tr.svg);
    height: 1px;
    width: 238px;
    margin: 0 auto;
}

.icon_crypto {
    background-image: url(../images/icons/gifts/crypto.svg);
    height: 23px;
    width: 25px;
}
//.icon_arrow-prev {
//    display: none;
//}

//@media (max-width: 1279px) {
//
//    .icon_arrow-prev {
//        background-image: url(../images/icons/gifts/arrow-prev.svg);
//        height: 34px;
//        width: 35px;
//        display: block;
//        align-self: center;
//    }
//}
@media (max-width: 1920px) {
    .icon_gifts_tr {
        width: 203px;
    }
}
@media (max-width: 1280px) {
    /*.icon_zodiac {
        width: toRemSm(22px);
        height: toRemSm(22px);
    }
    .icon_letter {
        width: toRemSm(28px);
        height: toRemSm(18px);
    }
    .icon_letter-open {
        width: toRemSm(28px);
        height: toRemSm(28px);
    }*/
}

.icon_aquarius {
    background-image: url(../images/icons/zodiac/01-aquarius.svg);
}

.icon_pisces {
    background-image: url(../images/icons/zodiac/02-pisces.svg);
}

.icon_aries {
    background-image: url(../images/icons/zodiac/03-aries.svg);
}

.icon_taurus {
    background-image: url(../images/icons/zodiac/04-taurus.svg);
}

.icon_gemini {
    background-image: url(../images/icons/zodiac/05-gemini.svg);
}

.icon_leo {
    background-image: url(../images/icons/zodiac/06-leo.svg);
}

.icon_cancer {
    background-image: url(../images/icons/zodiac/07-cancer.svg);
}

.icon_virgo {
    background-image: url(../images/icons/zodiac/08-virgo.svg);
}

.icon_libra {
    background-image: url(../images/icons/zodiac/09-libra.svg);
}

.icon_scorpio {
    background-image: url(../images/icons/zodiac/10-scorpio.svg);
}

.icon_sagittarius {
    background-image: url(../images/icons/zodiac/11-sagittarius.svg);
}

.icon_capricorn {
    background-image: url(../images/icons/zodiac/12-capricorn.svg);
}
.icon_hobbies {
    width: 34px;
    height: 34px;
}
.travel {
    background-image: url(../images/icons/hobbies/travel.svg);
}
.shopping {
    background-image: url(../images/icons/hobbies/shopping.svg);
}
.camping {
    background-image: url(../images/icons/hobbies/camping.svg);
}
.reading {
    background-image: url(../images/icons/hobbies/reading.svg);
}
.music {
    background-image: url(../images/icons/hobbies/music.svg);
}
.movies {
    background-image: url(../images/icons/hobbies/movies.svg);
}
.sailing {
    background-image: url(../images/icons/hobbies/sailing.svg);
}
.natural {
    background-image: url(../images/icons/hobbies/natural.svg);
}
.dancing {
    background-image: url(../images/icons/hobbies/dancing.svg);
}
.biking {
    background-image: url(../images/icons/hobbies/biking.svg);
}
.cars {
    background-image: url(../images/icons/hobbies/cars.svg);
}
.cooking {
    background-image: url(../images/icons/hobbies/cooking.svg);
}
.art {
    background-image: url(../images/icons/hobbies/art.svg);
}
.party {
    background-image: url(../images/icons/hobbies/party.svg);
}
.fashion {
    background-image: url(../images/icons/hobbies/fashion.svg);
}
.sport {
    background-image: url(../images/icons/hobbies/sport.svg);
}
.diving {
    background-image: url(../images/icons/hobbies/diving.svg);
}
.yoga {
    background-image: url(../images/icons/hobbies/yoga.svg);
}
.animals {
    background-image: url(../images/icons/hobbies/animals.svg);
}

@media (hover: hover) {
    .icon_close {
        cursor: pointer;
    }
}

.icon_aws {
    background-image: url(../images/icons/protection/aws.svg);
}
.icon_sectigo {
    background-image: url(../images/icons/protection/sectigo.svg);
}
.icon_dmca {
    background-image: url(../images/icons/protection/dmca.svg);
}
.icon_safe-labeling {
    background-image: url(../images/icons/protection/safe-labeling.svg);
}

@media (min-width: 1280px) and (max-width: 1919px) {

    .icon_phone,
    .icon_marker,
    .icon_mail,
    .icon_view,
    .icon_eye,
    .icon_favorite,
    .icon_favorite-check,
    .icon_money,
    .icon_bell,
    .icon_bell-red,
    .icon_block-user,
    .icon_chat-bubble-gradient,
    .icon_chat-bubble-light,
    .icon_chat-bubble-bordered,
    .icon_dark,
    .icon_light,
    .icon_headset,
    .icon_sound-off,
    .icon_sound-on,
    .icon_search,
    .icon_search-white,
    .icon_setting,
    .icon_settings,
    .icon_subscribe,
    .icon_envelope,
    .icon_subscribe-on,
    .icon_plus,
    .icon_question,
    .icon_info,
    .icon_account,
    .icon_block-user,
    .icon_unsubscribe,
    .icon_complain,
    .icon_tariff,
    .icon_logout,
    .icon_stories,
    .icon_emoji,
    .icon_delete,
    .icon_theme,
    .icon_unlock,
    .icon_edit,
    .icon_chat,
    .icon_box,
    .icon_warning,
    .icon_letter-full,
    .icon_image,
    .icon_mic,
    .icon_mic-white,
    .icon_video,
    .icon_camera,
    .icon_secured,
    .icon_heart-white,
    .icon_attachment-white,
    .icon_crown,
    .icon_star,
    .icon_cristal {
        width: 20px;
        height: 20px;
    }
    .icon_zodiac {
        width: 22px;
        height: 22px;
    }
    .icon_letter {
        width: 28px;
        height: 18px;
    }
    .icon_letter-open {
        width: 28px;
        height: 28px;
    }
}
