.preview {
    &__container {
        display: flex;
        justify-content: flex-end;
    }

    &__message {
        display: block;
    }

    &__text {
        display: block;
        color: var(--color-danger);
    }

    &__media:not(.preview__media_audio) {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


    &__item {
        position: relative;

        &:not(.preview__item_audio) {
            max-width: 160px;
            max-height: 160px;
        }
    }
    .message__audio {
        width: 200px;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 2px;
        background: #DADADA;
        border-radius: 5px;
        z-index: 10;
    }
}

