html {
    font-size: 100%;
}
body {
  font-size: var(--font-size);
  font-family: var(--font-base);
  font-weight: 400;
  line-height: var(--line-height);
  background-color: var(--body-bg);
  color: var(--color-text);
}
@include headings {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  line-height: var(--heading-line-height);
  color: var(--heading-color);
}
h1 {
  font-size: toRem(92px);
}
h2 {
  font-size: toRem(60px);
}
h3 {
  font-size: toRem(32px);
}
h4 {
  font-size: toRem(20px);
}
h5,
h6 {
  font-size: 1rem;
}


@include create-mq($grid__bp-xl, "min"){
    html {
        font-size: 87.5%;
    }
}

/* only between 1920 and 1280 1rem = 14px */

@include create-mq($grid__bp-4xl, "min") {
    html {
        font-size: 100%;
    }
}
