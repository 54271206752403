.pad {
    color: var(--color-text);

    &__header, &__body {
        text-align: center;
    }

    &__header {
        padding: 2px 20px;
        border-radius: 5px;
        background: rgba(51, 41, 68, 0.10);

        font-weight: 700;
        color: var(--heading-color);
    }

    &:not(.pad_modal) {
        .pad__body {
            position: relative;
            padding:10px 20px 20px;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(175deg, #17AAFC 0%, #CB77FF 100%);
            }
        }
    }

    &_modal {
        .pad__body {
            text-align: left;
        }
    }

}

@include create-mq($grid__bp-xl, "min") {
    .pad {
        &__header {
            font-size: toRem(25px);
        }

        &__body {
            font-size: toRem(20px);
        }
    }
}
