.media {
    
    &__icon {
        display: inline-block;
        padding: 15px 20px 23px;
        background: #ebe1ff;
        border-radius: 25px 25px 25px 0;
    }

    &__title {
        margin-top: 60px;
        margin-bottom: 30px;
        font-size: toRem(32px);
        line-height: 1.1;
        text-transform: uppercase;
    }

    &__content {
        line-height: 1.4;
    }
}

@include create-mq($grid__bp-lg, 'min') {
    .media {
        &__icon {
            padding: 0;
            background: none;
            border-radius: 0;
        }
    }
}
