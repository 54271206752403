.custom-radio {
    position: relative;
    user-select: none;

    &__input {
        position: relative;
        display: flex;
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
    }

    &__label {
        margin: auto;
        font-size: 1rem;
        line-height: 1.1;
        color: var(--light-gray);
    }

    input[type='radio'] {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    input[type='radio']:checked + .custom-radio__input {
        border-color: var(--gray);
    }

    input[type='radio']:checked + .custom-radio__input::after {
        content: '\2713';
        color: var(--state-success);
        position: absolute;
        top: 10px;
    }
}

@media (hover: hover) {
    .custom-radio {
        cursor: pointer;
    }
}
