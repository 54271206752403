.progress {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    circle {
        fill: none;
        stroke-width: 1;
    }

    &__circle {
        transform: rotate(-90deg);
    }

    &__bg {
        stroke: #848484;
        stroke-dasharray: 4, 4; /* Пунктирная линия для фона */
    }

    &__line {
        stroke: #848484;
        stroke-dasharray: 113;
        stroke-dashoffset: 113; /* Здесь устанавливаем длину dasharray равной длине окружности */
        transition: stroke-dashoffset 0.5s;
    }

    &__label {
        position: absolute;
        font-weight: 500;
        color: #6D6D6D;
    }

}
