.tag {
    display: inline-block;
    padding: 8px 10px 8px 15px;
    background: var(--nav-header-bg);
    border-radius: 5px;
    white-space: nowrap;

    .icon {
        margin-left: 5px;
    }
}
