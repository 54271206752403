.ui-range {
    &_bg {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        height: 44px;
        padding: 12px 20px;
        box-shadow: 0 10px 20px rgba(19, 19, 19, 0.2);
        background: var(--nav-bg);
        border-radius: 5px;
    }

    &:focus {
        outline: none;
    }

    &_sm {
        width: 200px;
    }

    &__input {
        appearance: none;
        height: 2px;
        background: #c5c5c5;
        background-image: linear-gradient(221.38deg, #5886FF 16.48%, #D00BF0 93.67%);
        background-size: 50% 100%;
        background-repeat: no-repeat;
        border-radius: 5px;
        width: 100%;

        &:focus {
            outline: none;
        }

        &::-webkit-slider-thumb {
            appearance: none;
            height: 20px;
            width: 20px;
            background: linear-gradient(221.38deg, #5886FF 16.48%, #D00BF0 93.67%);
            border-radius: 50%;
        }

        &::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }

        &__label {
            display: block;
            text-align: center;
        }
    }

    &_double {
        position: relative;
        margin-top: 20px;
        height: 2px;
        background: #c5c5c5;
        border-radius: 5px;

        .ui-range__input {
            position: absolute;
            width: 100%;
            height: 1px;
            background: none;
            pointer-events: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            top: 50%;
            transform: translateY(-50%);

            &::-webkit-slider-thumb {
                pointer-events: auto;
                -webkit-appearance: none;
                cursor: pointer;
                margin-bottom: 1px;
            }

            &::-moz-range-thumb {
                pointer-events: auto;
                -moz-appearance: none;
                cursor: pointer;
            }
        }

        .ui-range__track {
            height: 100%;
            position: absolute;
            border-radius: 5px;
            background: linear-gradient(221.38deg, #5886FF 16.48%, #D00BF0 93.67%);
        }
    }
}

@media (hover: hover) {
    .ui-range__input {
        cursor: pointer;
    }
}

@media (min-width: 1280px) and (max-width: 1919px) {
    .ui-range_bg {
        height: 34px;
        padding: 8px 10px;
    }
}
