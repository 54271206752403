.section {
    &__sub-heading,
    &__title {
        @extend .title;
    }

    &__content-title,
    &__title-promo,
    &__title {
        margin-top: 0;
    }

    &__sub-heading,
    &__content-title,
    &__title-promo {
        font-size: toRem(26px);
    }

    &__title-promo,
    &__sub-heading {
        @extend .text-gradient-reverse;
    }

    &__sub-heading {
        line-height: 1.1;
    }

    &__title {
        font-size: toRem(18px);
        line-height: 1.4;
        color: var(--gray);
    }

    &_contact,
    &_about,
    &_promo {
        .section__heading {
            text-transform: uppercase;
        }
    }

    &_contact,
    &_install,
    &_features,
    &_service
       {
        padding-bottom: 60px;
    }

    &_about {
        padding-top: 35px;
        padding-bottom: 10px;

        .section__sub-heading {
            margin: 0;
            text-transform: uppercase;
            line-height: 1.1;
        }

        .section__heading {
            margin-bottom: 40px;
        }

        .section__title {
            margin-bottom: 20px;
            line-height: 1.4;
        }
    }
    &_confidence {
        padding-top: 100px;
        padding-bottom: 100px;
        background-color: var(--color-white);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;

        .container {
            max-width: 100%;
        }

        .heading {
            font-size: toRem(40px);
            font-weight: 700;
            line-height: 135%;
            text-transform: uppercase;
        }

        .list_confidence {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding-top: toRem(60px);
            width: 100%;
            gap: 40px;
        }

        .list__item,
        .list__item_confidence
        {
            position: relative;
            display: flex;
            width: 504px;
            padding: 37px 42px;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            border-radius: 40px;
            background: #FFF;
            box-shadow: 0 4px 62px 0 rgba(11, 40, 115, 0.06);
            transform: scale(0.1);


            &:nth-child(1)::before {
                content: url("../images/landing/flicker1.svg");
                position: absolute;
                left: -60px;
                top: -30px;
            }


            &:nth-child(1)::after {
                content: url("../images/landing/flicker2.svg");
                position: absolute;
                right: -60px;
                bottom: -60px;
            }

            &:nth-child(2)::after {
                content: url("../images/landing/flicker3.svg");
                position: absolute;
                top: -31px;
                right: 0;
            }

            &:nth-child(3)::after {
                content: url("../images/landing/flicker4.svg");
                position: absolute;
                bottom: -49px;
                right: -60px;
            }


            .icon_user,
            .icon_heart,
            .icon_member {
                width: 100px;
                height: 100px;
                display: block;
            }

            .icon_user {
                background-image: url("../images/landing/icon_user.svg");
            }

            .icon_heart {
                background-image: url("../images/landing/icon_heart.svg");
            }

            .icon_member {
                background-image: url("../images/landing/icon_member.svg");
            }

            .title,
            .content {
                color: #131313;
                text-align: center;
            }

            .title {
                font-size: 24px;
                font-weight: 700;
            }

            .content {
                font-size: 20px;
                line-height: normal;
            }
        }
    }

    &_advantages {
        padding-top: 0;
         background-color: var(--color-white);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 100%;
        height: auto;

        .container {
            max-width: 100%;
            padding-bottom: 100px;
            display: flex;
            flex-direction: column;
        }

        .heading {
            font-size: toRem(48px);
            font-weight: 600;
            line-height: 1.40;
            text-transform: uppercase;
        }

        .is-flex {
            align-items: center;
            padding: 30px 0;
            gap: 252px;
            justify-content: center;
        }

        .list_advantages {
            display: flex;
            flex-direction: column;
            gap: 28px;
            position: relative;
            z-index: 50;
        }

        .list_advantages::before, .picture::before {
            content: "";
            background: linear-gradient(229deg, rgba(255, 94, 239, 0.70) 2.25%, rgba(92, 99, 252, 0.70) 47.27%, rgba(0, 215, 215, 0.70) 98.75%);
            width: 584px;
            height: 584px;
            border-radius: 584px;
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            animation: bg-gradient 3s infinite ease-in-out;
            background-size: 600%;
            filter: blur(100px);
        }

        @keyframes bg-gradient {
            0% {
                background-position: 80% 0;
            }
            50% {
                background-position: 20% 100%;
            }
            100% {
                background-position: 80% 0;
            }
        }

        .list__item {
            width: 621px;
            height: 203px;
            flex-shrink: 0;
            border-radius: 40px;
            background: rgba(255, 255, 255, 0.95);
            box-shadow: 0 4px 62px 0 rgba(11, 40, 115, 0.06);
            display: flex;
            flex-direction: column;
            gap: 6px;
            position: relative;
        }

        .wrapper {
            padding: 28px 0 44px 90px;

            .title {
                color: #131313;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 1.40;
            }
        }

        .list__item:nth-child(1)::before,
        .list__item:nth-child(2)::before,
        .list__item:nth-child(3)::before {
            position: absolute;
            background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
            background-clip: text;
            color: transparent;
            width: 35px;
            height: 80px;
            display: block;
            font-size: 60px;
            font-weight: 600;
            line-height: 1.40;
            text-transform: uppercase;
            left: calc(90px - 35px - 15px);
            top: 0;
        }

        .list__item:nth-child(1)::before {
            content: "1";
        }

        .list__item:nth-child(2)::before {
            content: "2";
        }

        .list__item:nth-child(3)::before {
            content: "3";
        }

        .content {
            color: #131313;
            font-size: 20px;
            font-style: normal;
            line-height: 1.40;
            display: block;
            width: 450px;
        }

        .button_lg {
            width: 260px;
            align-self: center;
        }

        .picture {
            position: relative;
            max-width: 715px;
        }


        .ellipse1 {
            background: radial-gradient(125.83% 68.45% at 49.07% 50%, rgba(255, 94, 239, 0.60) 0%, rgba(92, 99, 252, 0.22) 46.66%, rgba(0, 215, 215, 0.00) 100%);
            border-radius: 715px;
            height: 715px;
            width: 715px;
            z-index: 10;
        }

        .ellipse2,
        .ellipse3
        {
            position: absolute;
            display: block;
        }

        .ellipse2 {
            z-index: 10;
            width: 649px;
            height: 649px;
            border-radius: 649px;
            background: linear-gradient(229deg, rgba(255, 94, 239, 0.70) 2.25%, rgba(92, 99, 252, 0.70) 47.27%, rgba(0, 215, 215, 0.70) 98.75%);
            top: 33px;
            left: 33px;
        }

        .ellipse3 {
            z-index: 20;
            width: 575px;
            height: 575px;
            border-radius: 575px;
            background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
            top: 70px;
            left: 70px;
        }

        .gifts,
        .calls,
        .bonuses1,
        .bonuses2,
        .messages,
        .messages2,
        .voice,
        .video,
        .private_contents,
        .mystories {
            position: absolute;
            display: block;
            z-index: 60;
        }

        .gifts {
            content: url("../images/landing/landing-slider/gifts.svg");
            top: 40%;
            right: -33px;
        }

        .calls {
            content: url("../images/landing/landing-slider/calls.svg");
            top: 35%;
            right: 60px;
            width: 79px;
            height: 50px;
        }

        .bonuses1 {
            content: url("../images/landing/landing-slider/bonuses.svg");
            top: 60%;
            left: 33px;
            width: 52px;
            height: 52px;
        }

        .bonuses2 {
            content: url("../images/landing/landing-slider/bonuses2.svg");
            left: 50%;
            bottom: 33px;
            width: 57px;
            height: 57px;
        }

        .messages {
            content: url("../images/landing/landing-slider/messages.svg");
            top: 100px;
            left: 100px;
            width: 109px;
            height: 109px;
        }

        .messages2 {
            content: url("../images/landing/landing-slider/messages2.svg");
            top: 0;
            left: 50%;
            width: 67px;
            height: 67px;
        }

        .voice {
            content: url("../images/landing/landing-slider/voice.svg");
            top: 65%;
            left: 115px;
            width: 68px;
            height: 68px;

        }

        .video {
            content: url("../images/landing/landing-slider/video.svg");
            top: 45%;
            left: 60px;
            width: 66px;
            height: 66px;

        }

        .mystories {
            content: url("../images/landing/landing-slider/mystories.svg");
            top: 70%;
            right: 0;
            width: 169px;
            height: 50px;

        }

        .private_contents {
            content: url("../images/landing/landing-slider/private_contents.svg");
            left: 60%;
            top: 66px;
            width: 77px;
            height: 77px;
        }
    }


    &_participate {
        background: var(--gradient);
        background-repeat: no-repeat;
        width: 100%;
        background-size: cover;
        //height: 800px;

        .container {
            max-width: 100%;
            padding: 0;
            position: relative;
            z-index: 50;
        }

        .wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 60px;
            padding-top: 110px;
            padding-right: 236px;

            &::after {
                content: url("../images/landing/confetti_1.svg");
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                animation: coin-fall 6s linear;
                transition: transform 1s;
                transform-style: preserve-3d;
                background-repeat: no-repeat;
            }
            &::before {
                content: url("../images/landing/confetti_2.svg");
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                animation: coin-fall 6s linear;
                transition: transform 1s;
                transform-style: preserve-3d;
                background-repeat: no-repeat;
            }
        }

        .media__image {
            //width: 406px;
            //height: 718px;
            //display: block;
            background-repeat: no-repeat;
            margin-top: 80px;
            z-index: 20;
            //padding: 0 10px 0 10px;
        }
        .media__video {
            //width: 100%;
            //height: 100%;
            border-radius: 55px;
            //object-fit: fill;

            &_desktop {
                display: block;
            }
            &_mobile {
                display: none;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            max-width: 609px;
            gap: 60px;
        }

        .button_lg {
            max-width: 323px;
            height: 70px;
            background-color: var(--color-white);
        }

        .button_lg > span {
            background: var(--gradient-reverse);
            background-clip: text;
            color: transparent;
            text-transform: uppercase;
            font-size: 30px;
            font-weight: 700;
            line-height: 1.35;
        }

        .title,
        .content {
            color: var(--color-white);
        }

        .title {
            font-size: 60px;
            font-weight: 600;
            line-height: 1.2;
            text-transform: uppercase;
        }

        .content {
            text-align: left;
            font-size: 40px;
            font-weight: 700;
            line-height: normal;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .message {
            display: flex;
            flex-direction: column;
            margin-top: 174px;
            margin-left: -60px;
            z-index: 50;
        }

        .message__body {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 97px;
            border-radius: 10px 10px 10px 10px;
            background-color: rgba(225, 225, 225, 0.20);
            backdrop-filter: blur(5px);
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            padding: 21px 10px 21px 50px;
            align-items: center;
            justify-content: space-between;
        }

        .message__text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: var(--color-white);
            line-height: 1.4;
        }

        .message__title {
            color: var(--color-white);
            font-size: 20px;
            font-weight: 700;
            line-height: 1.4;
            text-transform: none;
        }

        .message__content {
            font-size: 16px;
            font-weight: 600;
            text-align: left;
        }

        .message__picture {
            width: 71px;
            height: 71px;
        }

        .message__image {
            background-size: cover;
            object-fit: cover;
        }
    }


    &_features {
        padding-top: 50px;
        background-image: url(../images/landing/bg-mob.svg);
        background-repeat: no-repeat;
        background-position: center;

        .animation {
            display: block;
            overflow: hidden;
        }
    }

    &_install {
        padding-top: 0;
        overflow: hidden;

        .section__title {
            margin-top: 38px;
            margin-bottom: 30px;
        }

        .section__buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;

            .button_primary {
                width: 100%;
                margin-top: 30px;
            }

            .svg-icon {
                margin-right: 14px;
            }
        }
    }

    &_promo {
        padding-top: 90px;
        padding-bottom: 150px;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;

        .section__promo {
            position: relative;
            z-index: 30;
        }

        .section__heading {
            margin-bottom: 30px;
            line-height: 1.3;
            color: var(--color-white);
        }
    }

    &_about,
    &_contact,
    &_meet {
        .section__heading {
            text-align: left;
        }
    }

    &_contact {
        padding-top: 60px;

        .container {
            background-image: url(../images/modal/hablario.svg);
            background-repeat: no-repeat;
            background-position: 0 0;
            width: 620px;
            height: 540px;
        }

        .section__heading {
            margin-bottom: 40px;
            text-transform: uppercase;
        }

        .section__sub-heading {
            margin-top: 60px;
            margin-bottom: 30px;
            font-size: 2rem;
        }

        .ui-textarea {
            min-height: 160px;
        }

        .form {
            &__row {
                margin-bottom: 20px;
            }

            &__label {
                margin: 0;
            }

            .button {
                margin-top: 20px;
            }
        }
    }

    &_service {

        .section__description {
            @extend .text-gradient-reverse;
            text-align: left;
            width: 735px;
        }
        .section__wrapper {
            display: flex;
            flex-direction: row;
            align-items:flex-end;
            gap: 25px;
        }

        .section__content {
            width: 553px;
            font-size: 20px;
            font-weight: 300;
            line-height: 1.7;
            color: #1F1F1F;
        }
        .section__link a {
            display: block;
            text-transform: uppercase;
            color: #131313;
            font-size: 20px;
            font-weight: 400;
        }
        .section__images {
            display: block;
            width: 907px;
            min-width: 907px;
            margin-top: -200px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &_statistic {
        .section__title-promo {
            text-align: center;
            width: 830px;
            margin-left: calc(50% - 415px);
            padding-bottom: 92px;
        }
    }

    &_meet {
        padding-top: 80px;

        .wrapper {
            display: flex;
            flex-direction: row;
            gap: 100px;
        }

        .section__heading,
        .section__content {
            width: 570px;
        }

        .section__heading {
            padding-bottom: 40px;
        }
        .section__content p {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.4;
            color: #7E7E7E;
        }
        .section__picture {
            width: 568px;
            min-width: 568px;
            margin-top: -184px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    .image {
        border-radius: 10px
    }

    &_faq {
        .section__heading {
            margin-bottom: 50px;
        }

        .section__title {
            font-size: toRem(24px);
        }
    }

    &_blog {
        .section__heading {
            margin-bottom: 12px;
        }

        .pagination {
            margin-top: 40px;
        }
    }

    &_members, &_page {
        .section__heading {
            margin-bottom: 50px;
        }
    }
    &_members {
        padding-bottom: 50px;
    }
    &_page {
        line-height: 1.8;
        h1, h2, h3, h4, h5, h6 {
            &:first-child {
                margin-top: 0;
            }
        }
        h2, h3 {
            margin-top: 20px;
            margin-bottom: 30px;
        }
        h2 {
            font-size: toRem(32px);
        }
        p {
            margin-bottom: 25px;
        }
        .link {
            color: var(--color-text);
            text-decoration: underline;
        }
        .list {
            line-height: 1.8;
        }
    }
}

.no-webp {
    .section {
        &_promo {
            background-image: url(../images/landing/bg-section1_mob.png);
        }
    }
}

.webp {
    .section {
        &_promo {
            background-image: url(../images/landing/bg-section1_mob.webp);
        }
    }
}

@include create-mq($grid__bp-xl - 1, 'max') {
    .section {
        &_promo {
            text-align: center;

            .button_light {
                min-width: 290px;
            }
        }
    }
}

@include create-mq($grid__bp-md - 1, 'max') {
    .section {
        &_contact,
        &_install,
        &_about {
            padding-left: 25px;
            padding-right: 25px;
        }

        &_promo {
            padding-left: 10px;
            padding-right: 10px;
        }

        &_service {
            .section__heading,
            .section__description {
                text-align: left;
            }
        }

        &_statistic {

            .statistic__item:not(:last-child) {
                margin-bottom: 60px;
            }
        }

        &_meet {
            .image {
                margin-bottom: 40px;
                aspect-ratio: 16/9;
                width: 100%;
                object-fit: cover;
                object-position: top center;
            }
        }
    }
}

@include create-mq($grid__bp-xxs - 1, 'max') {
    .section {
        &_contact,
        &_install,
        &_about {
            padding-left: 0;
            padding-right: 0;
        }

        &_promo {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@include create-mq($grid__bp-md, 'min') {
    .section {
        &_install {
            .section__buttons {
                .button_primary {
                    margin-top: 0;
                    width: initial;
                }
            }
        }

        &_meet {
            .section__column {
                position: relative;
                min-height: 495px;

                .image {
                    position: absolute;
                    width: calc(100% - 15px);
                    top: 0;
                    right: calc(-100% + 15px);
                    z-index: 1;
                    margin-bottom: 0;
                }
            }
        }

        &_blog {
            .post {
                margin-top: 50px;
            }
        }
    }
}

@include create-mq($grid__bp-lg, 'min') {
    .section {
        &__sub-heading,
        &__content-title,
        &__title-promo {
            font-size: toRem(32px);
        }

        &__title {
            font-size: toRem(20px);
        }

        &_contact,
        &_install,
        &_features,
        &_meet {
            padding-bottom: 120px;
        }

        &_service {
            padding-bottom: 142px;
            display: flex;
            background-color: rgba(255, 255, 255, 0.10);
            backdrop-filter: blur(100px);

            .container {
                max-width: 100%;
                padding: 100px 170px 0 266px;
            }


        .section__heading {
            font-size: 72px;
            font-weight: 600;
            line-height: 1.2;
            text-transform: uppercase;
            text-align: left;
        }
            .section__description {
                background-image: var(--gradient-reverse);
                margin-bottom: 35px;
                font-size: 2rem;
                margin-top: 35px;
            }
    }

        &_about {
            .section__heading {
                margin-bottom: 50px;
            }

            .section__title {
                margin-bottom: 30px;
            }
        }

        &_install {
            .section__buttons {
                justify-content: flex-start;
            }
        }

        &_contact,
        &_meet {
            padding-top: 120px;
        }

        &_contact {
            .section__sub-heading {
                margin-top: 100px;
            }

            .form__footer {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .button {
                    margin-top: 0;
                    width: 160px;
                }
            }
        }

        &_features {
            padding-top: toRem(136px);
            background-image: url(../images/landing/bg.svg);
            background-position: 55% 25%;
        }

        &_service {
            .section__content-title {
                width: 80%;
            }

        }

        &_faq {
            .section__title {
                font-size: toRem(32px);
            }
        }
        &_blog {
            .list_sort {
                justify-content: center;
            }
        }
    }
}

//@include create-mq($grid__bp-xs - 1, 'min')
@media (min-width: 429px) and (max-width: 1279px) {
    .section {
        &_confidence {
            padding: 40px 20px;

            .heading {
                font-size: toRem(24px);
                text-align: center;
                width: 326px;
                margin: 0 auto;
            }

            .list_confidence {
                flex-direction: column;
                padding-top: toRem(26px);
                gap: 26px;
            }

            .list__item,
            .list__item_confidence
            {
                width: 389px;
                padding: 20px 40px;
                gap: 16px;
                transform: scale(0.1);


                &:nth-child(2)::after {
                    content: url("../images/landing/flicker3.svg");
                    position: absolute;
                    bottom: -31px;
                    left: 0;
                    right: 75%;
                    transform: rotate(180deg);
                }

                .icon_user,
                .icon_heart,
                .icon_member {
                    width: 50px;
                    height: 50px;
                }

                .title {
                    font-size: 20px;
                }

                .content {
                    font-size: 16px;
                }
            }
        }

        &_advantages {
            padding-top: 40px;

            .container {
                padding-bottom: 40px;
            }

            .heading {
                font-size: toRem(24px);
                text-align: center;
                width: 326px;
                margin: 0 auto;
            }

            .is-flex {
                flex-direction: column;
                padding: 30px 0;
                gap: 20px;

            }

            .list_advantages {
                gap: 16px;
                order: 2;
            }

            .list_advantages::before, .picture::before {
                content: "";
                width: 357px;
                height: 357px;
                border-radius: 357px;
            }

            .list__item {
                width: 390px;
                height: 130px;
                border-radius: 30px;
                box-shadow: 0 3px 20px 0 rgba(11, 40, 115, 0.06);
                gap: 4px;
            }

            .wrapper {
                padding: 12px 17px 26px 56px;

                .title {
                    font-size: 20px;
                }
            }
            .list__item:nth-child(1)::before,
            .list__item:nth-child(2)::before,
            .list__item:nth-child(3)::before {
                width: 26px;
                height: 60px;
                font-size: 48px;
                left: calc(56px - 18px - 13px);
            }

            .content {
                font-size: 16px;
                width: 316px;
            }

            .button_lg {
                width: 390px;
                align-self: center;
            }

            .picture {
                max-width: 358px;
            }


            .ellipse1 {
                border-radius: 358px;
                height: 358px;
                width: 358px;
            }

            .ellipse2 {
                width: 325px;
                height: 325px;
                border-radius: 325px;
                top: 16px;
                left: 16px;
            }

            .ellipse3 {
                width: 288px;
                height: 288px;
                border-radius: 288px;
                top: 35px;
                left: 35px;
            }

            .gifts {
                top: 40%;
                right: -56px;
            }

            .calls {
                content: url("../images/landing/landing-slider/calls.svg");
                top: 35%;
                right: 35px;
                width: 40px;
                height: 25px;
            }

            .bonuses1 {
                content: url("../images/landing/landing-slider/bonuses.svg");
                top: 60%;
                left: 16px;
                width: 36px;
                height: 36px;
            }

            .bonuses2 {
                content: url("../images/landing/landing-slider/bonuses2.svg");
                left: 50%;
                bottom: 16px;
                width: 38px;
                height: 38px;
            }

            .messages {
                content: url("../images/landing/landing-slider/messages.svg");
                top: 56px;
                left: 56px;
                width: 55px;
                height: 55px;
            }

            .messages2 {
                content: url("../images/landing/landing-slider/messages2.svg");
                top: 0;
                left: 50%;
                width: 34px;
                height: 34px;
            }

            .voice {
                content: url("../images/landing/landing-slider/voice.svg");
                top: 65%;
                left: 56px;
                width: 40px;
                height: 40px;

            }

            .video {
                content: url("../images/landing/landing-slider/video.svg");
                top: 45%;
                left: 35px;
                width: 33px;
                height: 33px;

            }

            .mystories {
                content: url("../images/landing/landing-slider/mystories.svg");
                top: 70%;
                right: 0;
                width: 85px;
                height: 25px;

            }

            .private_contents {
                content: url("../images/landing/landing-slider/private_contents.svg");
                left: 60%;
                top: 35px;
                width: 38px;
                height: 38px;
            }
        }


        &_participate {
            background-image: none;
            //height: auto;
            margin: 0 auto;

            &::before {
                content: '';
            }
            &::after {
                content:"";
            }

            .container {
                z-index: 10;
            }
            .wrapper {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 0;
                gap: 40px;
            }

            .text {
                gap: 32px;
                padding-top: 82px;
                padding-bottom: 82px;
                padding-right: 0;
                order: 2;
                background: var(--gradient);
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
                z-index: 10;

                &::after {
                    content: url("../images/landing/confetti_1.svg");
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    animation: coin-fall_mobile 6s infinite;
                    transition: transform 1s;
                    transform-style: preserve-3d;
                    background-repeat: no-repeat;
                }
                &::before {
                    content: "";
                }
            }

            .media__image {
                //width: 282px;
                //height: 560px;
                //display: block;
                background-repeat: no-repeat;
                //padding: 10px;
                margin-top: 0;

            }
            .media__video {
                border-radius: 55px;
                object-fit: cover;

                &_desktop {
                    display: none;
                }
                &_mobile {
                    display: block;
                }
            }


            .body {
                width: 330px;
                gap: 32px;
                margin: 0 auto;
            }

            .button_lg {
                width: 390px;
                height: 60px;
                margin: 0 auto;
                align-items: center;
            }

            .button_lg > span {
                font-size: 24px;
            }

            .title {
                font-size: 32px;
                text-align: center;
            }

            .content {
                font-size: 24px;
                gap: 8px;
                margin: 0 auto;
                text-align: left;
            }

            .message {
                display: none;
            }
        }
        &_service {
            padding-bottom: 0;

            .container {
                padding: 40px 20px 0 20px;
            }
            .section__heading {
                font-size: 24px;
                text-align: center;
                text-transform: uppercase;
            }

            .section__description {
                text-align: center;
                width: 359px;
                font-size: 20px;
                margin: 12px auto;
            }

            .section__wrapper {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0;
            }

            .section__content {
                width: 390px;
                font-size: 16px;
                order: 1;
            }
            .section__link a {
                font-size: 20px;
                padding-top: 20px;
            }

            .section__images {
                display: block;
                width: 430px;
                min-width: 430px;
                margin-top: 0;
                order: 0;
            }
        }

        &_statistic {
            .section__title-promo {
                width: 390px;
                margin-left: calc(50% - 195px);
                font-size: 16px;
                padding-bottom: 24px;
            }
        }

        &_meet {
            padding-top: 77px;
            padding-bottom: 20px;

            .wrapper {
                gap: 0;
                flex-direction: column;
                align-items: center;
            }
            .section__heading {
                font-size: 24px;
            }
            .section__heading,
            .section__content {
                width: 390px;
            }
            .section__content {
               font-size: 16px;
                order: 1;
            }
            .section__heading {
                padding-bottom: 0;
                margin: 0 auto;
                text-transform: uppercase;
                text-align: center;
                position: relative;
            }

            .section__heading::before {
                content: url("../images/landing/about/stars.svg");
                position: absolute;
                width: 95px;
                height: 14px;
                display: block;
                top: -50px;
                left: calc(50% - 47px);
                z-index: 50;
            }

            .section__picture {
                width: 390px;
                min-width: 390px;
                margin-top: 20px;
                margin-bottom: 20px;
                background-repeat: no-repeat;
                order: 0;
            }
        }
    }
}

@media (max-width: 429px) {
    .section {
        &_confidence {
            padding: 40px 20px;

            .heading {
                font-size: toRem(24px);
                text-align: center;
                width: 100%;
                margin: 0 auto;
            }

            .list_confidence {
                flex-direction: column;
                padding-top: toRem(26px);
                gap: 26px;
            }

            .list__item,
            .list__item_confidence
            {
                width: 100%;
                padding: 20px 40px;
                gap: 16px;
                transform: scale(0.1);


                &:nth-child(2)::after {
                    content: url("../images/landing/flicker3.svg");
                    position: absolute;
                    bottom: -31px;
                    left: 0;
                    right: 75%;
                    transform: rotate(180deg);
                }

                .icon_user,
                .icon_heart,
                .icon_member {
                    width: 50px;
                    height: 50px;
                }

                .title {
                    font-size: 20px;
                }

                .content {
                    font-size: 16px;
                }
            }
        }

        &_advantages {
            padding-top: 40px;

            .container {
                padding-bottom: 40px;
            }

            .heading {
                font-size: toRem(24px);
                text-align: center;
                width: 100%;
                margin: 0 auto;
            }

            .is-flex {
                flex-direction: column;
                padding: 30px 0;
                gap: 20px;

            }

            .list_advantages {
                gap: 16px;
                order: 2;
            }

            .list_advantages::before, .picture::before {
                content: "";
                width: 357px;
                height: 357px;
                border-radius: 357px;
            }

            .list__item {
                width: 100%;
                height: auto;
                border-radius: 30px;
                box-shadow: 0 3px 20px 0 rgba(11, 40, 115, 0.06);
                gap: 4px;
            }

            .wrapper {
                padding: 12px 17px 26px 56px;

                .title {
                    font-size: 20px;
                }
            }
            .list__item:nth-child(1)::before,
            .list__item:nth-child(2)::before,
            .list__item:nth-child(3)::before {
                width: 26px;
                height: 60px;
                font-size: 48px;
                left: calc(56px - 18px - 13px);
            }

            .content {
                font-size: 16px;
                width: fit-content;
            }

            .button_lg {
                align-self: center;
                width: 343px;
            }

            .picture {
                max-width: 358px;
            }


            .ellipse1 {
                border-radius: 358px;
                height: 358px;
                width: 358px;
            }

            .ellipse2 {
                width: 325px;
                height: 325px;
                border-radius: 325px;
                top: 16px;
                left: 16px;
            }

            .ellipse3 {
                width: 288px;
                height: 288px;
                border-radius: 288px;
                top: 35px;
                left: 35px;
            }

            .gifts {
                top: 40%;
                right: -56px;
            }

            .calls {
                content: url("../images/landing/landing-slider/calls.svg");
                top: 35%;
                right: 35px;
                width: 40px;
                height: 25px;
            }

            .bonuses1 {
                content: url("../images/landing/landing-slider/bonuses.svg");
                top: 60%;
                left: 16px;
                width: 36px;
                height: 36px;
            }

            .bonuses2 {
                content: url("../images/landing/landing-slider/bonuses2.svg");
                left: 50%;
                bottom: 16px;
                width: 38px;
                height: 38px;
            }

            .messages {
                content: url("../images/landing/landing-slider/messages.svg");
                top: 56px;
                left: 56px;
                width: 55px;
                height: 55px;
            }

            .messages2 {
                content: url("../images/landing/landing-slider/messages2.svg");
                top: 0;
                left: 50%;
                width: 34px;
                height: 34px;
            }

            .voice {
                content: url("../images/landing/landing-slider/voice.svg");
                top: 65%;
                left: 56px;
                width: 40px;
                height: 40px;

            }

            .video {
                content: url("../images/landing/landing-slider/video.svg");
                top: 45%;
                left: 35px;
                width: 33px;
                height: 33px;

            }

            .mystories {
                content: url("../images/landing/landing-slider/mystories.svg");
                top: 70%;
                right: 0;
                width: 85px;
                height: 25px;

            }

            .private_contents {
                content: url("../images/landing/landing-slider/private_contents.svg");
                left: 60%;
                top: 35px;
                width: 38px;
                height: 38px;
            }
        }


        &_participate {
            background-image: none;
            //height: auto;
            margin: 0 auto;

            .wrapper {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 0;
                gap: 40px;
            }

            .text {
                gap: 32px;
                padding-top: 82px;
                padding-bottom: 82px;
                padding-right: 0;
                order: 2;
                background: var(--gradient);
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
                z-index: 20;


                &::after {
                    content: url("../images/landing/confetti_1.svg");
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    animation: coin-fall_mobile 3s infinite;
                    transition: transform 1s;
                    transform-style: preserve-3d;
                    background-repeat: no-repeat;
                }
                &::before {
                    content: '';
                }
            }

            .media__image {
                //width: 282px;
                //height: 560px;
                //display: block;
                //padding: 10px 20px 10px 10px;
                margin-top: 0;
            }
            .media__video {
                //width: 100%;
                //height: 100%;
                //border-radius: 20px;
                &_desktop {
                    display: none;
                }
                &_mobile {
                    display: block;
                }
            }

            .body {
                max-width: 330px;
                gap: 32px;
                margin: 0 auto;
            }

            .button_lg {
                height: 60px;
                margin: 0 auto;
                align-items: center;
            }

            .button_lg > span {
                font-size: 24px;
            }

            .title {
                font-size: 32px;
                text-align: center;
            }

            .content {
                font-size: 24px;
                gap: 8px;
                margin: 0 auto;
                text-align: left;
                padding-left: 10px;
            }

            .message {
                display: none;
            }
        }
        &_service {
            padding-bottom: 0;

            .container {
                padding: 40px 20px 0 20px;
            }
            .section__heading {
                font-size: 24px;
                text-align: center;
                text-transform: uppercase;
            }

            .section__description {
                text-align: center;
                width: 100%;
                font-size: 20px;
                margin: 12px auto;
            }

            .section__wrapper {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0;
            }

            .section__content {
                width: 100%;
                font-size: 16px;
                order: 1;
                padding: 10px;
            }
            .section__link a {
                font-size: 20px;
                padding-top: 20px;
            }

            .section__images {
                display: block;
                width: auto;
                min-width: auto;
                margin-top: 0;
                order: 0;
            }
        }

        &_statistic {
            .section__title-promo {
                width: 100%;
                margin-left: 0;
                font-size: 16px;
                padding-bottom: 24px;
            }
        }

        &_meet {
            padding-top: 77px;
            padding-bottom: 20px;

            .wrapper {
                gap: 0;
                flex-direction: column;
                align-items: center;
            }
            .section__heading {
                font-size: 24px;
            }
            .section__heading,
            .section__content {
                width: 100%;
            }
            .section__content {
                font-size: 16px;
                order: 1;
            }
            .section__heading {
                padding-bottom: 0;
                margin: 0 auto;
                text-transform: uppercase;
                text-align: center;
                position: relative;
            }

            .section__heading::before {
                content: url("../images/landing/about/stars.svg");
                position: absolute;
                width: 95px;
                height: 14px;
                display: block;
                top: -50px;
                left: calc(50% - 47px);
                z-index: 50;
            }

            .section__picture {
                width: auto;
                min-width: auto;
                margin-top: 20px;
                margin-bottom: 20px;
                background-repeat: no-repeat;
                order: 0;
            }
        }
    }
}

//@include create-mq($grid__bp-xl, 'min')
@media (min-width: 1279px) and (max-width: 1919px) {
    .no-webp {
        .section {
            &_promo {
                background-image: url(../images/landing/bg-section1.png);
            }
        }
    }
    .webp {
        .section {
            &_promo {
                background-image: url(../images/landing/bg-section1.webp);
            }
        }
    }
    .section {

        &_about {
            padding-top: 135px;

            .carousel {
                width: 95%;
            }
        }
        &_confidence {
            padding-top: 60px;
            padding-bottom: 60px;

            .heading {
                font-size: 28px;
            }
            .list_confidence {
                gap: 14px;
                padding-top: toRem(40px);
            }

            .list__item,
            .list__item_confidence
            {
                width: 378px;
                padding: 28px 50px;
                border-radius: 30px;
                box-shadow: 0 2px 36px 0 rgba(11, 40, 115, 0.08);

                .icon_user,
                .icon_heart,
                .icon_member {
                    width: 74px;
                    height: 74px;
                }

                .title {
                    font-size: 18px;
                }

                .content {
                    font-size: 16px;
                }
            }
        }
        &_advantages {

            .container {
                padding-top: 60px;
                padding-bottom: 47px;
            }

            .heading {
                font-size: toRem(32px);
            }

            .is-flex {
                gap: 76px;
            }

            .list_advantages {
                gap: 20px;
            }

            .list_advantages::before, .picture::before {
                content: "";
                width: 430px;
                height: 430px;
                border-radius: 430px;
            }

            .list__item {
                width: 466px;
                height: 152px;
                display: block;
                border-radius: 30px;
                background: rgba(255, 255, 255, 0.95);
                box-shadow: 0 2px 44px 0 rgba(11, 40, 115, 0.06);
                gap: 4px;
            }

            .wrapper {
                padding: 20px 0 34px 65px;

                .title {
                    font-size: 24px;
                }
            }

            .list__item:nth-child(1)::before,
            .list__item:nth-child(2)::before,
            .list__item:nth-child(3)::before {
                width: 26px;
                height: 62px;
                font-size: 44px;
                left: calc(65px - 27px - 13px);
            }

            .content {
                font-size: 16px;
                width: 358px;
            }

            .picture {
                position: relative;
                max-width: 536px;
            }


            .ellipse1 {
                border-radius: 536px;
                height: 536px;
                width: 536px;
            }

            .ellipse2 {
                width: 487px;
                height: 487px;
                border-radius: 487px;
                top: 25px;
                left: 25px;
            }

            .ellipse3 {
                width: 431px;
                height: 431px;
                border-radius: 431px;
                top: 52px;
                left: 52px;
            }

            .gifts,
            .calls,
            .bonuses1,
            .bonuses2,
            .messages,
            .messages2,
            .voice,
            .video,
            .private_contents,
            .mystories {
                position: absolute;
                display: block;
                z-index: 60;
            }

            .gifts {
                content: url("../images/landing/landing-slider/gifts.svg");
                top: 40%;
                right: -33px;
            }

            .calls {
                content: url("../images/landing/landing-slider/calls.svg");
                top: 35%;
                right: 60px;
                width: 59px;
                height: 37px;
            }

            .bonuses1 {
                content: url("../images/landing/landing-slider/bonuses.svg");
                top: 60%;
                left: 33px;
                width: 39px;
                height: 39px;
            }

            .bonuses2 {
                content: url("../images/landing/landing-slider/bonuses2.svg");
                left: 50%;
                bottom: 33px;
                width: 43px;
                height: 43px;
            }

            .messages {
                content: url("../images/landing/landing-slider/messages.svg");
                top: 100px;
                left: 100px;
                width: 82px;
                height: 82px;
            }

            .messages2 {
                content: url("../images/landing/landing-slider/messages2.svg");
                top: 0;
                left: 50%;
                width: 50px;
                height: 50px;
            }
            .voice {
                content: url("../images/landing/landing-slider/voice.svg");
                top: 65%;
                left: 115px;
                width: 50px;
                height: 50px;

            }
            .video {
                content: url("../images/landing/landing-slider/video.svg");
                top: 45%;
                left: 60px;
                width: 50px;
                height: 50px;

            }
            .mystories {
                content: url("../images/landing/landing-slider/mystories.svg");
                top: 70%;
                right: 0;
                width: 127px;
                height: 38px;

            }
            .private_contents {
                content: url("../images/landing/landing-slider/private_contents.svg");
                left: 60%;
                top: 66px;
                width: 57px;
                height: 57px;
            }
        }
        &_participate {
            background: var(--gradient);
            //height: 600px;

            .text {
                gap: 40px;
                padding-top: 100px;
                padding-right: 115px;

                &::after {
                    content: url("../images/landing/confetti_1.svg");
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    animation: coin-fall_mobile 6s infinite;
                    transition: transform 1s;
                    transform-style: preserve-3d;
                    background-repeat: no-repeat;
                    opacity: 0;
                }

                &::before {
                    content: url("../images/landing/confetti_2.svg");
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: -1;
                    animation: coin-fall_mobile 6s infinite;
                    transition: transform 1s;
                    transform-style: preserve-3d;
                    background-repeat: no-repeat;
                    opacity: 0;
                }
            }

            //.media {
            //    min-width: 301px;
            //    padding-top: 60px;
            //}

            .media__image {
                //width: 301px;
                //height: 540px;
                //background-image: url("../images/landing/phone.png");
                background-repeat: no-repeat;
                margin-top: 60px;
                //padding: 0 20px 0 20px;

            }
            .media__video {
                //width: 100%;
                //height: 100%;
                border-radius: 55px;
                //object-fit: cover;

                &_desktop {
                    display: block;
                }
                &_mobile {
                    display: none;
                }
            }

            .body {
                width: 406px;
                gap: 40px;
            }
            .button_lg {
                width: 240px;
                height: 56px;
            }
            .button_lg > span {
                font-size: 20px;
            }

            .title {
                font-size: 40px;
            }
            .content {
                font-size: 24px;
                gap: 12px;
            }

            .message {
                margin-top: 131px;
                margin-left: -60px;
            }
            .message__body {
                width: 255px;
                height: 72px;
                border-radius: 7px;
                background-color: rgba(225, 225, 225, 0.20);
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                padding: 15px 10px 15px 37px;
                fill: rgba(225, 225, 225, 0.20);
                backdrop-filter: blur(7.5px);
            }

            .message__title {
                font-size: 15px;
            }
            .message__content {
                font-size: 12px;
            }
            .message__picture {
                width: 54px;
                height: 54px;
            }
        }


        &_service {
            .container {
                padding: 70px 55px 0 90px;
            }
            .section__heading {
                font-size: 48px;
            }

            .section__description {
                text-align: left;
                width: 550px;
                font-size: 18px;
            }

            .section__content {
                width: 432px;
                font-size: 1rem;
            }
            .section__content p, .section__content ul li{
                font-size: 1rem;
            }
            .section__link a {
                font-size: 16px;
            }
            .section__images {
                display: block;
                width: 681px;
                min-width: 681px;
                margin-top: -200px;
            }
        }

        &_statistic {
            .section__title-promo {
                width: 623px;
                margin-left: calc(50% - 312px);
                padding-bottom: 40px;
            }
        }

        &_meet {
            padding-top: 40px;

            .wrapper {
                gap: 75px;
                justify-content: center;
            }
            .section__heading {
                font-size: 48px;
                margin-left: calc(50% - 428px - 37px);
            }
            .section__heading,
            .section__content {
                width: 428px;
            }

            .section__heading {
                padding-bottom: 22px;
            }

            .section__picture {
                width: 426px;
                min-width: 426px;
                margin-top: -138px;
            }
        }

        &_install {
            .section__title {
                width: 60%;
            }
        }

        &_promo {
            padding-bottom: 92px;
            padding-top: 80px;

            .section__heading {
                text-align: left;
            }

            .container {
                display: flex;
            }

            .section__promo {
                width: 50%;
            }

            .animation {
                position: relative;
                width: 50%;
                height: 100%;
            }
        }

        &_contact {
            .form_horizontal {
                width: 82%;
            }
            .ui-input,
            .ui-textarea {
                font-size: toRem(14px);
            }
        }
    }
}

@include create-mq($grid__bp-4xl, 'min') {
    .section {
        &_confidence {
            padding-top: 100px;
            padding-bottom: 100px;
            background-color: var(--color-white);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 100%;

            .container {
                max-width: 100%;
            }

            .heading {
                font-size: toRem(40px);
                font-weight: 700;
                line-height: 135%;
                text-transform: uppercase;
            }

            .list_confidence {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-top: toRem(60px);
                width: 100%;
                gap: 40px;
            }

            .list__item,
            .list__item_confidence
            {
                display: flex;
                width: 504px;
                padding: 37px 42px;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                border-radius: 40px;
                background: #FFF;
                box-shadow: 0 4px 62px 0 rgba(11, 40, 115, 0.06);
                transform: scale(0.1);


                &:nth-child(1)::before {
                    content: url("../images/landing/flicker1.svg");
                    position: absolute;
                    left: -60px;
                    top: -30px;
                }


                &:nth-child(1)::after {
                    content: url("../images/landing/flicker2.svg");
                    position: absolute;
                    right: -60px;
                    bottom: -60px;
                }

                &:nth-child(2)::after {
                    content: url("../images/landing/flicker3.svg");
                    position: absolute;
                    top: -31px;
                    right: 0;
                }

                &:nth-child(3)::after {
                    content: url("../images/landing/flicker4.svg");
                    position: absolute;
                    bottom: -49px;
                    right: -60px;
                }


                .icon_user,
                .icon_heart,
                .icon_member {
                    width: 100px;
                    height: 100px;
                    display: block;
                }

                .icon_user {
                    background-image: url("../images/landing/icon_user.svg");
                }

                .icon_heart {
                    background-image: url("../images/landing/icon_heart.svg");
                }

                .icon_member {
                    background-image: url("../images/landing/icon_member.svg");
                }

                .title,
                .content {
                    color: #131313;
                    text-align: center;
                }

                .title {
                    font-size: 24px;
                    font-weight: 700;
                }

                .content {
                    font-size: 20px;
                    line-height: normal;
                }
            }
        }

        &_advantages {
            padding-top: 0;
            background-color: var(--color-white);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 100%;
            height: auto;

            .container {
                max-width: 100%;
                padding-bottom: 100px;
                display: flex;
                flex-direction: column;
            }

            .heading {
                font-size: toRem(48px);
                font-weight: 600;
                line-height: 1.40;
                text-transform: uppercase;
            }

            .is-flex {
                align-items: center;
                padding: 30px 0;
                gap: 252px;
                justify-content: center;
            }

            .list_advantages {
                display: flex;
                flex-direction: column;
                gap: 28px;
                position: relative;
                z-index: 50;
            }

            .list_advantages::before, .picture::before {
                content: "";
                background: linear-gradient(229deg, rgba(255, 94, 239, 0.70) 2.25%, rgba(92, 99, 252, 0.70) 47.27%, rgba(0, 215, 215, 0.70) 98.75%);
                width: 584px;
                height: 584px;
                border-radius: 584px;
                position: absolute;
                z-index: 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                animation: bg-gradient 3s infinite ease-in-out;
                background-size: 600%;
                filter: blur(100px);
            }

            @keyframes bg-gradient {
                0% {
                    background-position: 80% 0;
                }
                50% {
                    background-position: 20% 100%;
                }
                100% {
                    background-position: 80% 0;
                }
            }

            .list__item {
                width: 621px;
                height: 203px;
                flex-shrink: 0;
                border-radius: 40px;
                background: rgba(255, 255, 255, 0.95);
                box-shadow: 0 4px 62px 0 rgba(11, 40, 115, 0.06);
                display: flex;
                flex-direction: column;
                gap: 6px;
                position: relative;
            }

            .wrapper {
                padding: 28px 0 44px 90px;

                .title {
                    color: #131313;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.40;
                }
            }

            .list__item:nth-child(1)::before,
            .list__item:nth-child(2)::before,
            .list__item:nth-child(3)::before {
                position: absolute;
                background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
                background-clip: text;
                color: transparent;
                width: 35px;
                height: 80px;
                display: block;
                font-size: 60px;
                font-weight: 600;
                line-height: 1.40;
                text-transform: uppercase;
                left: calc(90px - 35px - 15px);
                top: 0;
            }

            .list__item:nth-child(1)::before {
                content: "1";
            }

            .list__item:nth-child(2)::before {
                content: "2";
            }

            .list__item:nth-child(3)::before {
                content: "3";
            }

            .content {
                color: #131313;
                font-size: 20px;
                font-style: normal;
                line-height: 1.40;
                display: block;
                width: 450px;
            }

            .button_lg {
                width: 260px;
                align-self: center;
            }

            .picture {
                position: relative;
                max-width: 715px;
            }


            .ellipse1 {
                background: radial-gradient(125.83% 68.45% at 49.07% 50%, rgba(255, 94, 239, 0.60) 0%, rgba(92, 99, 252, 0.22) 46.66%, rgba(0, 215, 215, 0.00) 100%);
                border-radius: 715px;
                height: 715px;
                width: 715px;
                z-index: 10;
            }

            .ellipse2,
            .ellipse3,
            .slide {
                position: absolute;
                display: block;
            }

            .ellipse2 {
                z-index: 10;
                width: 649px;
                height: 649px;
                border-radius: 649px;
                background: linear-gradient(229deg, rgba(255, 94, 239, 0.70) 2.25%, rgba(92, 99, 252, 0.70) 47.27%, rgba(0, 215, 215, 0.70) 98.75%);
                top: 33px;
                left: 33px;
            }

            .ellipse3 {
                z-index: 20;
                width: 575px;
                height: 575px;
                border-radius: 575px;
                background: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
                top: 70px;
                left: 70px;
            }

            .slide {
                z-index: 30;
                width: 492px;
                height: 492px;
            }

            .slide_1 {
                top: 111px;
                left: 111px;
            }

            .gifts,
            .calls,
            .bonuses1,
            .bonuses2,
            .messages,
            .messages2,
            .voice,
            .video,
            .private_contents,
            .mystories {
                position: absolute;
                display: block;
                z-index: 60;
            }

            .gifts {
                content: url("../images/landing/landing-slider/gifts.svg");
                top: 40%;
                right: -33px;
            }

            .calls {
                content: url("../images/landing/landing-slider/calls.svg");
                top: 35%;
                right: 60px;
                width: 79px;
                height: 50px;
            }

            .bonuses1 {
                content: url("../images/landing/landing-slider/bonuses.svg");
                top: 60%;
                left: 33px;
                width: 52px;
                height: 52px;
            }

            .bonuses2 {
                content: url("../images/landing/landing-slider/bonuses2.svg");
                left: 50%;
                bottom: 33px;
                width: 57px;
                height: 57px;
            }

            .messages {
                content: url("../images/landing/landing-slider/messages.svg");
                top: 100px;
                left: 100px;
                width: 109px;
                height: 109px;
            }

            .messages2 {
                content: url("../images/landing/landing-slider/messages2.svg");
                top: 0;
                left: 50%;
                width: 67px;
                height: 67px;
            }

            .voice {
                content: url("../images/landing/landing-slider/voice.svg");
                top: 65%;
                left: 115px;
                width: 68px;
                height: 68px;

            }

            .video {
                content: url("../images/landing/landing-slider/video.svg");
                top: 45%;
                left: 60px;
                width: 66px;
                height: 66px;

            }

            .mystories {
                content: url("../images/landing/landing-slider/mystories.svg");
                top: 70%;
                right: 0;
                width: 169px;
                height: 50px;

            }

            .private_contents {
                content: url("../images/landing/landing-slider/private_contents.svg");
                left: 60%;
                top: 66px;
                width: 77px;
                height: 77px;
            }
        }


        &_participate {
            background: var(--gradient);
            background-repeat: no-repeat;
            background-size: cover;
            //height: 800px;
            position: relative;
            //perspective: 800px;

            .container {
                max-width: 100%;
                position: relative;
                z-index: 50;
            }

            .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

            .text {
                display: flex;
                flex-direction: column;
                gap: 60px;
                padding-top: 110px;
                padding-right: 236px;

                &::after {
                    content: url("../images/landing/confetti_1.svg");
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    animation: coin-fall 6s infinite;
                    transition: transform 1s;
                    transform-style: preserve-3d;
                    background-repeat: no-repeat;
                    opacity: 0;
                }
                &::before {
                    content: url("../images/landing/confetti_2.svg");
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: -1;
                    animation: coin-fall 6s infinite;
                    transition: transform 1s;
                    transform-style: preserve-3d;
                    background-repeat: no-repeat;
                    opacity: 0;
                }
            }

            .media__image {
                //width: 406px;
                //height: 718px;
                //display: block;
                background-repeat: no-repeat;
                margin-top: 80px;
                z-index: 20;
                //padding: 10px 0 0 10px;

            }
            .media__video {
                //width: 100%;
                //height: 100%;
                border-radius: 65px;
                //object-fit: fill;

                &_desktop {
                    display: block;
                }
                &_mobile {
                    display: none;
                }
            }

            .body {
                display: flex;
                flex-direction: column;
                width: 609px;
                gap: 60px;
            }

            .button_lg {
                width: 323px;
                height: 70px;
                background-color: var(--color-white);
            }

            .button_lg > span {
                background: var(--gradient-reverse);
                background-clip: text;
                color: transparent;
                text-transform: uppercase;
                font-size: 30px;
                font-weight: 700;
                line-height: 1.35;
            }

            .title,
            .content {
                color: var(--color-white);
            }

            .title {
                font-size: 60px;
                font-weight: 600;
                line-height: 1.2;
                text-transform: uppercase;
            }

            .content {
                text-align: left;
                font-size: 40px;
                font-weight: 700;
                line-height: normal;
                display: flex;
                flex-direction: column;
                gap: 10px;
            }

            .message {
                display: flex;
                flex-direction: column;
                margin-top: 174px;
                margin-left: -60px;
                z-index: 50;
            }

            .message__body {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 97px;
                border-radius: 10px 10px 10px 10px;
                background-color: rgba(225, 225, 225, 0.20);
                backdrop-filter: blur(5px);
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                padding: 21px 10px 21px 50px;
                align-items: center;
                justify-content: space-between;
            }

            .message__text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                color: var(--color-white);
                line-height: 1.4;
            }

            .message__title {
                color: var(--color-white);
                font-size: 20px;
                font-weight: 700;
                line-height: 1.4;
                text-transform: none;
            }

            .message__content {
                font-size: 16px;
                font-weight: 600;
                text-align: left;
            }

            .message__picture {
                width: 71px;
                height: 71px;
            }

            .message__image {
                background-size: cover;
                object-fit: cover;
            }
        }
    }
}


