.tooltip {
    display: inline-block;
    position: relative;
    line-height: 1;

    &__content {
        width: fit-content;
        color: #fff;
    }

    &_secondary {
        .tooltip__content {
            display: block;
            padding: 1px 3px;
            min-width: 20px;
            height: 20px;
            line-height: 18px;
            font-weight: 500;
            font-size: toRem(12px);
            border-radius: 50%;
            background: var(--gradient);
            text-align: center;
        }
    }

    &_primary {
        .tooltip__content {
            padding: 1px 4px;
            font-size: toRem(10px);
            background-color: var(--color-light-green);
            border-radius: toRem(8px);
        }
    }
    &_abs {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }
}
