.menu {
    @extend .list;

    &__link {
        text-decoration: none;
        transition: color var(--transition-duration) var(--transition-easing);
    }

    &_contact {
        margin-bottom: 40px;
        color: var(--dark-gray);

        .menu__item {
            display: flex;
            line-height: 1.1;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .icon {
                flex: 0 0 toRem(24px);
                margin-right: 20px;
            }
        }

        .menu__link {
            color: var(--dark-gray);

            &:hover {
                color: var(--gray);
            }
        }

        .icon_phone {
            position: relative;
            top: -2px;
        }

        .address {
            line-height: 1.4;
        }
    }

    &_settings {
        .menu__item:not(:last-child) {
            margin-bottom: 30px;
        }

        .menu__item {
            &_parent {
                overflow: hidden;

                > .menu__link {
                    position: relative;
                    z-index: 1;

                    &::before {
                        content: "";
                        width: 8px;
                        height: 6px;
                        margin-right: 5px;
                        background-image: url(../images/icons/triangle.svg);
                        transition: transform 0.35s ease;
                        transform: rotate(180deg);
                    }
                }

                &.is-active {
                    > .menu__link::before {
                        transform: none;
                    }
                }
            }
        }

        .menu__link {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: 500;
            color: var(--color-text);
            transition: color var(--transition-duration) var(--transition-easing);
            .tooltip {
                top: -5px;
            }
            .icon {
                margin-left: 1rem;
            }

            &:hover {
                color: var(--light-gray);
            }
        }
    }

    &_sub {
        .menu__item:not(:last-child) {
            margin-bottom: 15px;
        }

        .menu__link {
            color: var(--light-gray);

            &:hover {
                color: var(--color-text);
            }
        }
    }

    &_sub-settings {
        .menu__item:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    &_upload {
        display: flex;
        justify-content: space-around;
        input[type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            direction: ltr;
            opacity: 0;
        }

        .menu__item {
            position: relative;
        }
        .icon_image,
        .icon_mic,
        .icon_video,
        .icon_camera {
            width: 28px;
            height: 28px;
        }
    }
}

@media (hover: hover) {
    .menu__title:hover, .menu__link {
        cursor: pointer;
    }
}
@include create-mq($grid__bp-xl, "min") {
    .menu {
        &_upload {
            display: block;
            .menu__link {
                display: flex;
                align-items: center;
            }
            .icon_image,
            .icon_mic,
            .icon_video,
            .icon_camera {
                width: 18px;
                height: 18px;
                margin-left: auto;
            }
        }
    }
}
