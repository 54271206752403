.contactus {

    &__heading {
        text-transform: uppercase;
        color: #131313;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.1;
        text-align: center;
    }

    &__sub-heading {
        text-transform: uppercase;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.1;
        padding-bottom: 16px;
        padding-top: 20px;
        //color:var(--gradient-text);
        color: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
    }
}

.form {
    &__contactus {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.button.button_contactus {
    width: 160px;
}

@include create-mq($grid__bp-md, "max") {
    .contactus {
        &__heading {
            font-size: 20px;
            text-align: center;
        }
        &__sub-heading {
            font-size: 20px;
            //color:var(--gradient-text);
            color: linear-gradient(229deg, #FF5EEF 2.25%, #5C63FC 47.27%, #00D7D7 98.75%);
        }
    }
    .form {
        &__contactus {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }
    }
    .button.button_contactus {
        width: 100%;
    }
}

