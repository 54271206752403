.ui-input {
    padding-left: 15px;
    height: 40px;
    padding-right: 15px;
    line-height: 1.2;
    background-color: var(--nav-bg);
    border: 1px solid var(--button-color3);
    border-radius: 10px;
    font-size: 1rem;
    color: var(--color-text);

    &::placeholder {
        color: var(--light-gray)
    }
}

.file-input {
    position: relative;
    vertical-align: middle;

    > input[type='file'] {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        font-size: toRem(23px);
        direction: ltr;
        cursor: pointer;
        opacity: 0;
    }

    .ui-input {
        display: block;
        padding: 8px 15px;
        white-space: nowrap;
        @extend .text-truncate;
    }
}
