.panel {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        z-index: 10;
        background: linear-gradient(
                0deg,
                #ffffff 34.29%,
                rgba(255, 255, 255, 0) 100%
        );
    }

    &__tabs {
        margin: toRem(10px) 0;
    }

    &__content {
        @extend .ui-scroll;
        margin: 0 8px 0 10px;
        padding-bottom: 80px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__handler {
        width: 100%;
        height: toRem(5px);
        padding: 0;
        position: relative;
        cursor: row-resize;
        z-index: 110;

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background: #f2f2f2;
            border: 1px solid #e7e7e7;
            border-bottom: none;
            margin: 0 auto;
        }
    }

    &_contacts {
        height: 300px;

        .panel__content {
            height: calc(100% - 55px);
        }
    }

    &_chat {
        height: calc(100% - 300px);

        .panel__content {
            height: calc(100% - 80px);
        }

        .panel__header {
            background: var(--body-bg);
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 40px;
            padding: 8px 10px 0;
            text-align: center;
        }

        .panel__title {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: toRem(14px);
            font-weight: 600;
            color: var(--heading-color);

            .icon_plus {
                width: 16px;
                height: 16px;
            }
        }
    }

    &_mobile {
        .panel__tabs {
            padding-left: 50px;
            padding-right: 60px;
        }

        .button_back {
            position: absolute;
            top: 1px;
            left: toRem(10px);
        }

        .panel__content {
            height: calc((var(--vh, 1vh) * 100) - 62px);
            overflow-y: auto;
        }
    }
}

@media (max-height: 982px) {
    .panel {
        &_contacts {
            height: 200px;
        }

        &_chat {
            height: calc(100% - 200px);
        }
    }
}
